/** @format */

import React from "react";
import Modal from "@mui/material/Modal";
import SuccessImg from "../../../Assets/Images/secure-documents.svg";
import FailImg from "../../../Assets/Images/Group 39938.svg";
import closeIcon from "../../../Assets/Images/Icon ionic-ios-close-circle-outline.svg";
import "./index.scss";
export default function SuccessAndFailModal(props) {
  return (
    <Modal open={props.open}>
      <div className='success-modal'>
        <img
          src={closeIcon}
          className='closeIcon'
          onClick={() => props.toggleModal(props.modalName)}
        />
        <img
          className={
            props.modalName !== "failModal"
              ? "success-modal__success-modal"
              : null
          }
          src={props.modalName === "failModal" ? FailImg : SuccessImg}
        />
        <span>{props.msg}</span>
        <button onClick={() => props.toggleModal(props.modalName)}>
          {props.buttonTitle}
        </button>
      </div>
    </Modal>
  );
}
