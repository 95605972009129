/** @format */

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { languages } from "../../../Constants/Languages";
import CancelIcon from "../../../Assets/Images/Group 39839Red.svg";
import InputField from "../../InputField";

export default function EditCompanyForm(props) {
  const [disableSubmitt, toggleDisable] = useState(false);
  const [packageAndPay, setPackageAndPay] = useState({
    package: null,
    // paymentMethod: null,
  });
  const [userFieldsData, setFieldsData] = useState({
    companyName: null,
    email: null,
    representativeName: null,
    phone: null,
    industry: null,
    requestStatus: null,
    // password: "",
    // confirmPass: "",
  });
  const [errors, setErrors] = useState({
    companyName: null,
    email: null,
    representativeName: null,
    phone: null,
    industry: null,
    package: null,
    requestStatus: null,
    // password: null,
    // confirmPass: null,
    // paymentMethod: null,
  });
  let { lang, dir } = useSelector((state) => state.lang);
  useEffect(() => {
    if (props.customerInfo?.allData) {
      let data = { ...props.customerInfo };
      data.requestStatus = {
        label: props.customerInfo.allData.requestStatus,
        value: data.requestStatusId,
      };

      setFieldsData({ ...userFieldsData, ...data });
    }
    if (props.companyDetails) {
      setFieldsData(props.customerInfo);
    }
  }, [lang, props.customerInfo?.allData, props.customerInfo]);

  const getChanges = (key, value) => {
    if (key === "package") {
      getPackageAndPayment(key, value);
    }
    {
      let errorsClone = { ...errors };
      errorsClone = { ...errorsClone, [key]: null };
      let flag = Object.values(errorsClone).every((error) => error === null);
      toggleDisable(!flag);
      setErrors(errorsClone);
      setFieldsData({ ...userFieldsData, [key]: value });
    }
  };
  const getPackageAndPayment = (key, value) => {
    let errorsClone = { ...errors };
    errorsClone = { ...errorsClone, [key]: null };
    let flag = Object.values(errorsClone).every((error) => error === null);
    toggleDisable(!flag);
    setErrors(errorsClone);
    setPackageAndPay({ ...packageAndPay, [key]: value });
  };
  const submit = () => {
    let errorsClone = {};
    Object.keys(userFieldsData).forEach((field) => {
      let value =
        field === "requestStatus" || field === "industry"
          ? userFieldsData[field]?.value ?? userFieldsData[field]
          : userFieldsData[field];

      if (value === null || value === undefined) {
        errorsClone = {
          ...errorsClone,
          [field]: languages[lang].requiredField,
        };
      }
    });

    if (userFieldsData.requestStatus?.value === 3 || props.addNewCompany) {
      Object.keys(packageAndPay).forEach((field) => {
        let value = packageAndPay[field]?.value;

        if (value === null || value === undefined) {
          errorsClone = {
            ...errorsClone,
            [field]: languages[lang].requiredField,
          };
        }
      });
    }
    toggleDisable(Object.keys(errorsClone).length > 0);
    setErrors(errorsClone);
    if (Object.keys(errorsClone).length === 0) {
      props.saveChanges(userFieldsData, packageAndPay);
    }
  };
  const validatData = (key, value) => {
    let errorsClone = { ...errors };
    let flag = false;
    errorsClone = { ...errorsClone, [key]: null };
    if (key === "email") {
      let regex = new RegExp("[a-z0-9]+@[a-z]+.[a-z]{2,3}");
      if (!regex.test(value)) {
        errorsClone = { ...errorsClone, email: languages[lang].validEmail };
        flag = true;
      }
    }
    toggleDisable(flag);
    setErrors(errorsClone);
  };
  return (
    <div className="customer-form" dir={dir} lang={lang}>
      <div className="customer-form__userFields">
        {props.userFields.map((field) => (
          <InputField
            title={field.title}
            placeholder={field.placeholder}
            value={userFieldsData[field.key]}
            getChanges={getChanges}
            stateName={field.key}
            errors={errors}
            icon={field.icon}
            select={field.type === "select"}
            disabled={field.disabled}
            options={field.options}
            validatData={validatData}
            getPackageAndPayment={getPackageAndPayment}
            type={field.type}
          />
        ))}
        {userFieldsData.requestStatus?.value === 3
          ? props.paymentMethodField
            ? props.paymentMethodField.map((field) => (
                <InputField
                  title={field.title}
                  placeholder={field.placeholder}
                  value={packageAndPay[field.key]}
                  getChanges={getPackageAndPayment}
                  stateName={field.key}
                  errors={errors}
                  icon={field.icon}
                  select={field.type === "select"}
                  disabled={field.disabled}
                  options={field.options}
                />
              ))
            : null
          : null}
      </div>
      <div className="customer-form__userFields">
        {props.passFields
          ? props.passFields.map((field) => (
              <InputField
                title={field.title}
                placeholder={field.placeholder}
                value={userFieldsData[field.key]}
                getChanges={getChanges}
                stateName={field.key}
                errors={errors}
                icon={field.icon}
                select={field.type === "select"}
                disabled={field.disabled}
              />
            ))
          : null}
      </div>

      {props.paymentMethodField ? (
        <div className="customer-form__userFields">
          <button disabled={disableSubmitt} onClick={submit}>
            {
              languages[lang][
                props.customerInfo ? "saveChanges" : "addNewCompany"
              ]
            }
          </button>
          {/* {props.deleteRequest ? (
            <button className='customer-form__userFields__cancelBtn'>
              <img src={CancelIcon} /> {languages[lang].cancelOrder}
            </button>
          ) : null} */}
        </div>
      ) : null}
    </div>
  );
}
