/** @format */
import { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";

import Logo from "../../../Assets/Images/Group 39808.png";
import InputField from "../../InputField";
import EmailIcon from "../../../Assets/Images/Group 39800.svg";

import closeIcon from "../../../Assets/Images/Icon ionic-ios-close-circle-outline.svg";
import { forgetPassService } from "../../../Services/commonServices";
import { useSelector } from "react-redux";
import { languages } from "../../../Constants/Languages";
import Loader from "../../Shared/Loader";

const fieldsDataInit = {
  email: null,
};
const errorsInit = {
  email: null,
};
export default function ForgetPass(props) {
  const [fieldsData, setData] = useState(fieldsDataInit);
  const [errors, setErrors] = useState(errorsInit);
  let { lang, dir } = useSelector((state) => state.lang);
  useEffect(() => {
    setData(fieldsDataInit);
    setErrors(errorsInit);
  }, [props.open]);
  const getChanges = (key, value) => {
    setErrors({ ...errors, [key]: null });
    setData({ ...fieldsData, [key]: value });
  };
  const forgetPass = async () => {
    let errorsClone = {};
    Object.keys(errors).forEach((field) => {
      if (!fieldsData[field]?.length) {
        errorsClone = {
          ...errorsClone,
          [field]: languages[lang].requiredField,
        };
      }
    });
    setErrors(errorsClone);

    if (Object.keys(errorsClone).length === 0) {
      props.toggleLoading(true);
      let response = await forgetPassService(fieldsData);

      if (!response.data.isSuccess) {
        let errorObj = { email: response.data.message[lang] };
        setErrors(errorObj);
      } else {
        props.sendEmail(fieldsData.email);
      }
      props.toggleLoading(false);
    }
  };
  const validatData = (key, value) => {
    let errorsClone = { ...errors };
    errorsClone = { ...errorsClone, [key]: null };
    if (key === "email") {
      let regex = new RegExp("[a-z0-9]+@[a-z]+.[a-z]{2,3}");
      if (!regex.test(value)) {
        errorsClone = { ...errorsClone, email: languages[lang].validEmail };
      }
    }
    setErrors(errorsClone);
  };
  return (
    <Modal open={props.open} onClose={() => props.toggleModal(props.modalName)}>
      <div className='signup-cont'>
        <img
          src={closeIcon}
          className='closeIcon'
          onClick={() => props.toggleModal(props.modalName)}
        />
        <img className='logo' src={Logo} />
        <h5 className='signup-cont__title'>{languages[lang].restorePass}</h5>
        <div style={{ textAlign: "start" }}>
          <InputField
            title={languages[lang].phoneOrEmail}
            placeholder={languages[lang].writeEmailOrPhone}
            value={fieldsData.email}
            getChanges={getChanges}
            stateName={"email"}
            errors={errors}
            icon={EmailIcon}
            validatData={validatData}
          />

          <button onClick={forgetPass}>{languages[lang].restorePass}</button>
          <div className='signup-cont__forget-cont' dir={dir} lang={lang}>
            <span onClick={() => props.toggleModal("signup")}>
              {languages[lang].registerNewAcc}
            </span>{" "}
            <span onClick={() => props.toggleModal("login")}>
              {" "}
              {languages[lang].login}
            </span>
          </div>
        </div>
        <Loader loading={props.loading} />
      </div>
    </Modal>
  );
}
