/** @format */

import "./App.css";
import { useEffect } from "react";
import Footer from "./Components/Footer";
import Navbar from "./Components/Navbar";
import CompanyForm from "./Containers/CompanyForm";
import Home from "./Containers/Home";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import TranslatePage from "./Containers/TranslatePage";
import Dashboard from "./Containers/Admin/Dashboard";
import Orders from "./Containers/Admin/OrdersAndShipping/Orders";
import Order from "./Containers/Admin/Order";
import Shipments from "./Containers/Admin/OrdersAndShipping/Shipping";
import OrdersAndShipping from "./Containers/Admin/OrdersAndShipping";
import Customers from "./Containers/Admin/Customers/inedx";
import CutomerDetails from "./Containers/Admin/Customers/CutomerDetails";
import AddNewCustomer from "./Containers/Admin/Customers/AddNewCustomer";
import Companies from "./Containers/Admin/Companies";
import CompanyDetails from "./Containers/Admin/Companies/CompanyDetails";
import AddNewCompany from "./Containers/Admin/Companies/AddNewCompany";
import AddNewPackage from "./Containers/Admin/Companies/AddNewPackage";
import PackageDetailes from "./Containers/Admin/Companies/PackageDetailes";
import CompanyRequest from "./Containers/Admin/Companies/CompanyRequest";
import Translators from "./Containers/Admin/Translators";
import TranslatorDetails from "./Containers/Admin/Translators/TranslatorDetails/TranslatorDetails";
import Transactions from "./Containers/Admin/Transactions";
import Settings from "./Containers/Admin/Settings";
import EditLangPricing from "./Containers/Admin/Settings/LangPricing/EditLangPricing";
import LangFieldsPricing from "./Containers/Admin/Settings/LangFieldsPricing";
import TranslationField from "./Containers/Admin/Settings/LangFieldsPricing/TranslationField";
import TranslationService from "./Containers/Admin/Settings/AddOns/LangService";
import DeleviryLocation from "./Containers/Admin/Settings/DeliveryPricing/DeleviryLocation";
import TranslationArea from "./Containers/Admin/Settings/TranslationAreas/TranslationArea";
import AddAdminUser from "./Containers/Admin/Settings/AdminUsers/AdminUser";
import AddTranslator from "./Containers/Admin/Translators/AddTranslator";
import CustomerRequets from "./Containers/Customer/Requests";
import CustomerProfile from "./Containers/Customer/Profile";
import AddRequest from "./Containers/Customer/Requests/AddRequest";
import TranslatorRequets from "./Containers/Translators/Requests";
import TranslatorProfile from "./Containers/Translators/Profile";
import TranslatorRequest from "./Containers/Translators/Requests/Request";
import ResetPassword from "./Containers/ResetPassword";
import RequestSent from "./Containers/SuccessAndErrorPages/Success";
import AddLang from "./Containers/Admin/Settings/LangPricing/AddLang";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "@reduxjs/toolkit";
import { adminActions } from "./actions/adminActions";
import { commonActions } from "./actions/commonActions";
import { changeLang } from "./Redux/lang";
import CompanyRequets from "./Containers/Company/Requests";
import CompanyProfile from "./Containers/Company/Profile";
import CompanyPackages from "./Containers/Company/Packages";
import AddComapnyRequest from "./Containers/Company/Requests/AddRequest";
import CompanyUsers from "./Containers/Company/CompanyUsers/insdex";
import AddCompanyUser from "./Containers/Company/CompanyUsers/AddCompanyUser";
import NotificationPage from "./Containers/NotificationPage";
import Error from "./Containers/SuccessAndErrorPages/Error";
import { Helmet } from "react-helmet";

function App() {
  let dispatch = useDispatch();
  let { lang, dir } = useSelector((state) => state.lang);

  let {
    getBundleTypes,
    getCompanyIndustries,
    getCompanyReqStatus,
    getTranslationFieldsAction,
    getOrderTypesAction,
    getLanguagesAction,
  } = bindActionCreators(adminActions, dispatch);
  let { getOrderStatusesActions } = bindActionCreators(commonActions, dispatch);
  useEffect(() => {
    getBundleTypes();
    getCompanyIndustries();
    getCompanyReqStatus();
    getTranslationFieldsAction();
    getOrderTypesAction();
    getLanguagesAction();
    getOrderStatusesActions();
    handleLanguageFromQuery();
  }, []);
  
  const handleLanguageFromQuery = () => {
    const queryParams = new URLSearchParams(window.location.search);
    const langParam = queryParams.get("lang");
    if (langParam && (langParam === "ar" || langParam === "en")) {
      dispatch(changeLang(langParam));
    }
  };

  const getAlternateUrl = (currentLang) => {
    const url = new URL(window.location.href);
    url.searchParams.set("lang", currentLang === "ar" ? "en" : "ar");
    return url.toString();
  };

  const currentUrl = window.location.href;
  const canonicalUrl = currentUrl.replace(/^https?:\/\/www\./, 'https://');

  return (
    <div className="App">
      <Helmet>
        <link
          rel="alternate"
          hreflang="ar"
          href={getAlternateUrl("en")}
        />
        <link
          rel="alternate"
          hreflang="en"
          href={getAlternateUrl("ar")}
        />
      </Helmet>
      <Helmet>
        <link rel="canonical" href={canonicalUrl} />
      </Helmet>
      <div className="pageBody">
        {/* <div className='gradient-cont' /> */}
        <BrowserRouter>
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route
              exact
              path="/company-application"
              element={<CompanyForm />}
            />
            <Route exact path="/translate" element={<TranslatePage />} />
            <Route exact path="/admin-dashboard" element={<Dashboard />} />
            <Route
              exact
              path="/admin-dashboard/requests/:id"
              element={<Order />}
            />
            <Route
              exact
              path="/dashboard/requests&shipping"
              element={<OrdersAndShipping />}
            />
            <Route
              exact
              path="/dashboard/requests&shipping/request/:id"
              element={<Order />}
            />
            <Route
              exact
              path="/dashboard/requests&shipping/shipment/:id"
              element={<Order />}
            />
            <Route exact path="/dashboard/customers" element={<Customers />} />
            <Route
              exact
              path="/dashboard/customers/:id"
              element={<CutomerDetails />}
            />
            <Route
              path="/dashboard/customers/request/:id"
              element={<Order />}
            />
            <Route
              path="/dashboard/customers/add"
              element={<AddNewCustomer />}
            />
            <Route exact path="/dashboard/companies" element={<Companies />} />
            <Route
              path="/dashboard/companies/:id"
              element={<CompanyDetails />}
            />
            <Route
              path="/dashboard/companies/request/:id"
              element={<Order />}
            />
            <Route
              path="/dashboard/companies/add"
              element={<AddNewCompany />}
            />
            <Route
              path="/dashboard/companies/add-package"
              element={<AddNewPackage />}
            />
            <Route
              path="/dashboard/companies/packages/:id"
              element={<PackageDetailes />}
            />
            <Route
              path="/dashboard/companies/company-request/:id"
              element={<CompanyRequest />}
            />
            <Route
              exact
              path="/dashboard/translators"
              element={<Translators />}
            />
            <Route
              path="/dashboard/translators/:id"
              element={<TranslatorDetails />}
            />
            <Route
              path="/dashboard/translators/add"
              element={<AddTranslator />}
            />
            <Route
              path="/dashboard/translators/request/:id"
              element={<Order />}
            />
            <Route
              exact
              path="/dashboard/transactions"
              element={<Transactions />}
            />
            <Route exact path="/dashboard/settings" element={<Settings />} />
            <Route
              path="/dashboard/settings/langPrice/:id"
              element={<EditLangPricing />}
            />
            <Route
              path="/dashboard/settings/langPrice/add"
              element={<EditLangPricing />}
            />
            <Route
              path="/dashboard/settings/langPrice/add-lang"
              element={<AddLang />}
            />
            <Route
              path="/dashboard/settings/translation-field/:id"
              element={<TranslationField />}
            />
            <Route
              path="/dashboard/settings/translation-field/add"
              element={<TranslationField />}
            />
            <Route
              path="/dashboard/settings/translation-service/:id"
              element={<TranslationService />}
            />
            <Route
              path="/dashboard/settings/translation-service/add"
              element={<TranslationService />}
            />
            <Route
              path="/dashboard/settings/delivery-location/:id"
              element={<DeleviryLocation />}
            />
            <Route
              path="/dashboard/settings/delivery-location/add"
              element={<DeleviryLocation />}
            />
            <Route
              path="/dashboard/settings/transaction-area/add"
              element={<TranslationArea />}
            />
            <Route
              path="/dashboard/settings/transaction-area/:id"
              element={<TranslationArea />}
            />
            <Route
              path="/dashboard/settings/admin-user/:id"
              element={<AddAdminUser />}
            />
            <Route
              path="/dashboard/settings/admin-user/add"
              element={<AddAdminUser />}
            />
            <Route
              exact
              path="/customer/profile"
              element={<CustomerProfile />}
            />
            <Route
              exact
              path="/customer/requests"
              element={<CustomerRequets />}
            />
            <Route
              exact
              path="/customer/requests/:id"
              element={<Order hideSelect />}
            />
            <Route
              exact
              path="/company/requests/:id"
              element={<Order hideSelect />}
            />{" "}
            <Route
              exact
              path="/customer/requests/add"
              element={<AddRequest />}
            />
            <Route
              exact
              path="/translator/requests"
              element={<TranslatorRequets />}
            />
            <Route
              exact
              path="/translator/requests/:id"
              element={<TranslatorRequest />}
            />
            <Route
              exact
              path="/translator/profile"
              element={<TranslatorProfile />}
            />
            <Route
              exact
              path="/company/requests"
              element={<CompanyRequets />}
            />
            <Route
              exact
              path="/company/packages"
              element={<CompanyPackages />}
            />
            <Route
              exact
              path="/company/requests/add"
              element={<AddComapnyRequest />}
            />
            <Route exact path="/company/profile" element={<CompanyProfile />} />
            <Route exact path="/reset-password" element={<ResetPassword />} />
            <Route exact path="/request-sent" element={<RequestSent />} />
            <Route exact path="/request-error" element={<Error />} />
            <Route exact path="/company/users" element={<CompanyUsers />} />
            <Route
              exact
              path="/company/users/add"
              element={<AddCompanyUser />}
            />
            <Route exact path="/notification" element={<NotificationPage />} />
          </Routes>
        </BrowserRouter>
      </div>
      <div className="copyright">
        <span>
          {lang == "ar"
            ? "جميع الحقوق محفوظة كويت قاموس © " + new Date().getFullYear()
            : "All rights reserved Q8Qamous © " + new Date().getFullYear()}
        </span>
      </div>
    </div>
  );
}

export default App;
