/** @format */

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import CustomNavbar from "../../../../Components/Shared/CustomNavbar";
import Sidebar from "../../../../Components/Shared/Sidebar";

import { prepareDashboardSideBar } from "../../../../Constants/PrepareData";
import { languages } from "../../../../Constants/Languages";
import PackageForm from "../../../../Components/AdminComponents/PackageForm";
import NavbarAndSidebarCont from "../../../../Components/Shared/NavbarAndSidebarCont";
import { bindActionCreators } from "@reduxjs/toolkit";
import { adminActions } from "../../../../actions/adminActions";

export default function PackageDetailes(props) {
  const [packageInfo, setPackageInfo] = useState({});
  const [packageFields, setPackageFields] = useState([]);
  let { lang, dir } = useSelector((state) => state.lang);
  let { bundleTypes } = useSelector((state) => state.commonData);
  const dispatch = useDispatch();
  const { getBundleTypes, addPackageAction } = bindActionCreators(
    adminActions,
    dispatch
  );
  useEffect(() => {
    let packageFieldsConst = [
      {
        title: languages[lang].packageName,
        key: "name",
        placeholder: languages[lang].writePackageName,
      },
      {
        title: languages[lang].wordsNum,
        key: "wordNumber",
        disabled: true,
        placeholder: languages[lang].writeNumOffWords,
      },
      {
        title: languages[lang].serviceType,
        key: "addOn",
        placeholder: languages[lang].chooseServiceType,
        type: "select",
        options: [
          { label: { ar: "أساسية", en: "Basic" }, value: 1 },
          { label: { ar: "إضافية", en: "Extra" }, value: 2 },
        ],
      },
      {
        title: languages[lang].subscriptionPeriod,
        key: "type",
        placeholder: languages[lang].chooseSubscriptionPeriod,
        type: "select",
        options: bundleTypes,
      },
      {
        title: languages[lang].price,
        key: "price",
        placeholder: languages[lang].writePrice,
      },
    ];
    let data = JSON.parse(localStorage.getItem("packageData"));
    setPackageFields(packageFieldsConst);
    setPackageInfo({
      ...data,
      name: data?.[lang],
      wordNumber: data?.wordCount,
      addOn: {
        label: { ar: data?.bundleClass, en: data?.bundleClass },
        value: data?.bundleClassId,
      },
      type: {
        label: { ar: data?.bundleType, en: data?.bundleType },
        value: data?.bundleTypeId,
      },
    });
  }, [lang]);
  console.log(packageInfo);
  return (
    <div className="customers-cont">
      <NavbarAndSidebarCont>
        <div className={`cutomer-details`}>
          <h4>{packageInfo.name ?? languages[lang].addNewPackage}</h4>
          <PackageForm
            packageFields={packageFields}
            packageInfo={packageInfo}
            isEdit={true}
          />
        </div>
      </NavbarAndSidebarCont>
    </div>
  );
}
