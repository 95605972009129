/** @format */

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import RequestsSearchbar from "../../../../Components/AdminComponents/RequestsSearchbar";

import Pagination from "../../../../Components/Shared/Pagination";
import addIcon from "../../../../Assets/Images/Group 2784.svg";
import wightAdd from "../../../../Assets/Images/Group 2784.png";
import { languages } from "../../../../Constants/Languages";
import CustomersTable from "../../../../Components/Shared/CustomersTable";
import { bindActionCreators } from "@reduxjs/toolkit";
import { adminActions } from "../../../../actions/adminActions";
let paginationData = {
  TotalPages: 1,
  PageSize: 10,
  CurrentPage: 1,
  HasNext: false,
  HasPrev: false,
};
export default function AllCompanies(props) {
  const [isHovering, toggleHover] = useState(false);
  const [agents, setAgents] = useState([]);
  const [searchValue, setSearch] = useState("");
  const [pagination, setPaginationData] = useState(paginationData);
  const [tableHeaderConstatnt, setTableHeader] = useState([]);
  let { lang, dir } = useSelector((state) => state.lang);
  let { companies, adminUserPermissions } = useSelector((state) => state.admin);

  let dispatch = useDispatch();
  let { getCompaniesAction } = bindActionCreators(adminActions, dispatch);
  useEffect(() => {
    getCompaniesAction(1);
    props.toggleLoading(true);
  }, []);
  useEffect(() => {
    if (companies?.data?.length >= 0) {
      props.toggleLoading(false);
      let CustomersClone = companies.data.map((company) => ({
        ...company,
        bundle: company.bundle?.[lang],
        bundleRemaining: `${company.bundleRemaining} ${languages[lang].word}`,
      }));

      let tableHeader = [
        { title: languages[lang].companyName, key: "name" },
        { title: languages[lang].email, key: "email" },
        { title: languages[lang].responsibleName, key: "representativeName" },
        { title: languages[lang].phoneNum, key: "phone" },
        { title: languages[lang].package, key: "bundle" },
        { title: languages[lang].rest, key: "bundleRemaining" },
        { title: languages[lang].ordersNum, key: "ordersNum" },
      ];
      setTableHeader(tableHeader);
      setAgents(CustomersClone);
      setPaginationData(companies.pagination);
    }
  }, [lang, companies?.data?.length]);
  const getPageData = async (pageNum) => {
    if (pageNum !== companies.pagination.CurrentPage) {
      props.toggleLoading();
      let res = await getCompaniesAction(pageNum, searchValue);
      props.toggleLoading();
    }
  };
  const getSearchValues = async (searchString) => {
    props.toggleLoading();
    setSearch(searchString);
    let res = await getCompaniesAction(1, searchString);
    props.toggleLoading();
  };

  return (
    <div>
      {" "}
      <div className="customers-cont__body__info" dir={dir} lang={lang}>
        <h4>{languages[lang].allRequests}</h4>
        {adminUserPermissions.includes("PermissionCompaniesAdd") ? (
          <Link to="/dashboard/companies/add">
            <button
              onMouseEnter={() => toggleHover(true)}
              onMouseLeave={() => toggleHover(false)}
            >
              <img src={isHovering ? wightAdd : addIcon} />{" "}
              {languages[lang].add}
            </button>
          </Link>
        ) : null}
      </div>
      <RequestsSearchbar searchOnly getSearchValues={getSearchValues} />
      <CustomersTable
        agents={agents}
        url={"companies"}
        tableHeaderConstatnt={tableHeaderConstatnt}
        saveToSessionStorege="companyData"
        removeDelete
      />
      {pagination.TotalPages === 1 ? null : (
        <Pagination pagination={pagination} getPageData={getPageData} />
      )}
    </div>
  );
}
