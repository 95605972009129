/** @format */

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import RequestsSearchbar from "../../../../Components/AdminComponents/RequestsSearchbar";

import Pagination from "../../../../Components/Shared/Pagination";
import addIcon from "../../../../Assets/Images/Group 2784.svg";
import wightAdd from "../../../../Assets/Images/Group 2784.png";
import { languages } from "../../../../Constants/Languages";
import CustomersTable from "../../../../Components/Shared/CustomersTable";
import { bindActionCreators } from "@reduxjs/toolkit";
import { adminActions } from "../../../../actions/adminActions";

export default function ComapniesRequests(props) {
  const [isHovering, toggleHover] = useState(false);
  const [searchValue, setSearch] = useState("");
  const [agents, setAgents] = useState([]);
  const [pagination, setPaginationData] = useState({});
  const [tableHeaderConstatnt, setTableHeader] = useState([]);
  let { lang, dir } = useSelector((state) => state.lang);
  let { companiesAppliedReq, adminUserPermissions } = useSelector(
    (state) => state.admin
  );

  const dispatch = useDispatch();
  let { getComapniesAppliedRequests } = bindActionCreators(
    adminActions,
    dispatch
  );
  useEffect(() => {
    props.toggleLoading(true);
    getComapniesAppliedRequests();
  }, []);
  useEffect(() => {
    // let paginationData = {
    //   totalCount: 10,
    //   tageSize: 9,

    //   currentPage: 7,
    //   hasNext: true,
    //   hasPrevious: true,
    // };
    //

    if (companiesAppliedReq.data) {
      props.toggleLoading(false);
      setPaginationData(companiesAppliedReq.pagination);
      let CustomersClone = companiesAppliedReq.data.map((item) => ({
        ...item,
        allData: item,
        industry: item?.industry?.[lang],
        requestStatus: item.requestStatus[lang],
      }));
      let tableHeader = [
        { title: languages[lang].companyName, key: "companyName" },
        { title: languages[lang].email, key: "email" },
        {
          title: languages[lang].responsibleNameHeader,
          key: "representativeName",
        },
        { title: languages[lang].phoneNum, key: "phone" },
        { title: languages[lang].companyBusiness, key: "industry" },
        { title: languages[lang].status, key: "requestStatus" },
      ];
      setTableHeader(tableHeader);
      setAgents(CustomersClone);
    }
  }, [lang, companiesAppliedReq]);
  const getPageData = async (pageNum) => {
    if (pageNum !== pagination.CurrentPage) {
      props.toggleLoading(true);

      await getComapniesAppliedRequests(pageNum, searchValue);
      props.toggleLoading(false);
    }
  };
  const getSearchValues = async (searchString) => {
    props.toggleLoading(true);
    setSearch(searchString);
    let res = await getComapniesAppliedRequests(1, searchString);
    props.toggleLoading(false);
  };
  return (
    <div>
      {" "}
      <div className='customers-cont__body__info' dir={dir} lang={lang}>
        <h4>{languages[lang].companiesRequests}</h4>
      </div>
      <RequestsSearchbar searchOnly getSearchValues={getSearchValues} />
      <CustomersTable
        agents={agents}
        url={"companies/company-request"}
        tableHeaderConstatnt={tableHeaderConstatnt}
        saveToSessionStorege='comapnyReq'
        removeDelete
      />
      {pagination.TotalPages === 1 ? null : (
        <Pagination pagination={pagination} getPageData={getPageData} />
      )}
    </div>
  );
}
