/** @format */

import React, { useState } from "react";
import { useSelector } from "react-redux";
import { languages } from "../../../Constants/Languages";
import "./index.scss";
export default function CustomerHeader(props) {
  const [selectedpage, setPage] = useState("info");
  let { lang, dir } = useSelector((state) => state.lang);

  return (
    <div className='customer-header' dir={dir} lang={lang}>
      <h4>{props.customerInfo.companyName ?? props.customerInfo.name}</h4>
      <div className='customer-header__buttons'>
        <button
          onClick={() => props.changePage("info")}
          className={`${
            props.selectedpage === "info" && "customer-header__buttons__active"
          }`}>
          {props.dataBtnTitle}
        </button>
        {props.hideList ? null : (
          <button
            onClick={() => props.changePage("requests")}
            className={`${
              props.selectedpage === "requests" &&
              "customer-header__buttons__active"
            }`}>
            {languages[lang].requests}
          </button>
        )}
      </div>
    </div>
  );
}
