/** @format */

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomerForm from "../../../../Components/AdminComponents/CutomerForm";
import EditCompanyForm from "../../../../Components/AdminComponents/EditComapnyForm";
import CustomNavbar from "../../../../Components/Shared/CustomNavbar";
import CustomerHeader from "../../../../Components/Shared/CutomerHeader";
import OrderTable from "../../../../Components/Shared/OrderTable";
import Sidebar from "../../../../Components/Shared/Sidebar";
import UserIcon from "../../../../Assets/Images/Icon awesome-user-alt.svg";
import phoneIcon from "../../../../Assets/Images/Icon ionic-md-phone-portrait.svg";
import CompanyIcon from "../../../../Assets/Images/Path 11449.svg";
import EmailIcon from "../../../../Assets/Images/Icon ionic-ios-mail.svg";
import EyeIcon from "../../../../Assets/Images/Icon ionic-ios-eye.svg";
import { languages } from "../../../../Constants/Languages";
import { adminActions } from "../../../../actions/adminActions";
import NavbarAndSidebarCont from "../../../../Components/Shared/NavbarAndSidebarCont";
import { bindActionCreators } from "@reduxjs/toolkit";
import Loader from "../../../../Components/Shared/Loader";
import SuccessAndFailModal from "../../../../Components/Modals/SuccessAndFailModal";
import { Navigate } from "react-router-dom";

export default function CompanyRequest() {
  const [loading, setLoading] = useState(true);
  const [isOpen, setOpen] = useState({ successModal: false, failModal: false });
  const [modalMsg, setModalMsg] = useState("");
  const [customerInfo, setCustomerInfo] = useState({});
  const [paymentMethodField, setPaymentMethodField] = useState({});
  const [userFields, setUserFields] = useState([]);
  const [redirect, setRedireact] = useState(false);
  let dispatch = useDispatch();
  let {
    getPackages,
    getCompanyIndustries,
    getCompanyReqStatus,
    updateCompanyReqStatus,
  } = bindActionCreators(adminActions, dispatch);
  let { lang, dir } = useSelector((state) => state.lang);
  let { packages, industries } = useSelector((state) => state.commonData);
  let { reqStatus } = useSelector((state) => state.admin);

  useEffect(() => {
    if (packages.length === 0) {
      getPackages();
    }
    if (industries.length === 0) {
      getCompanyIndustries();
    }
    if (reqStatus.length === 0) {
      getCompanyReqStatus();
    }

    if (Object.keys(customerInfo).length === 0) {
      let x = JSON.parse(localStorage.getItem("comapnyReq"));
      setCustomerInfo({ ...x });
    }
  }, []);
  useEffect(() => {
    if (packages.length && industries.length && reqStatus.length) {
      setLoading(false);
      let mainPackages = packages.filter((item) => item.bundleClassId === 1);

      let userFieldsConst = [
        {
          title: languages[lang].companyName,
          key: "companyName",
          icon: CompanyIcon,
          placeholder: languages[lang].pleaseWriteName,
          disabled: true,
        },
        {
          title: languages[lang].email,
          key: "email",
          icon: EmailIcon,
          placeholder: languages[lang].pleaseWriteEmail,
          disabled: true,
        },
        {
          title: languages[lang].responsibleName,
          key: "representativeName",
          icon: UserIcon,
          placeholder: languages[lang].pleaseWriteResponsibleName,
          disabled: true,
        },
        {
          title: languages[lang].phoneNum,
          key: "phone",
          icon: phoneIcon,
          placeholder: languages[lang].pleaseWritePhone,
          disabled: true,
        },
        {
          title: languages[lang].companyBusiness,
          key: "industry",
          placeholder: languages[lang].chooseBusiness,
          disabled: true,
          type: "text",
          options: industries.map((item) => ({
            label: { ar: item.ar, en: item.en },
            value: item.id,
          })),
        },
        {
          title: languages[lang].status,
          key: "requestStatus",
          icon: phoneIcon,
          placeholder: languages[lang].selectStatus,
          disabled: false,
          options: reqStatus.map((item) => ({
            label: {
              ar: item.ar,
              en: item.en,
            },
            value: item.id,
          })),
          type: "select",
        },
        {
          title: languages[lang].preferredLanguage,
          key: "preferredLanguageId",
          type: "select",
          options: [
            { label: { ar: "العربية", en: "العربية" }, value: 1 },
            { label: { ar: "English", en: "English" }, value: 2 },
          ],
          placeholder: languages[lang].selectPreferredLang,
          // disabled: params?.id ? true : false,
        },
      ];
      let paymentMethodField = [
        // {
        //   title: languages[lang].password,
        //   placeholder: languages[lang].pleaseWritePass,
        //   key: "password",
        //   icon: EyeIcon,
        //   type: "password",
        // },
        // {
        //   title: languages[lang].confirmPass,
        //   placeholder: languages[lang].pleaseWritePass,
        //   key: "confirmPassword",
        //   icon: EyeIcon,
        //   type: "password",
        // },
        {
          title: languages[lang].package,
          key: "package",
          icon: phoneIcon,
          placeholder: languages[lang].writePackageName,
          disabled: false,
          options: mainPackages.map((item) => ({
            label: {
              ar: item.ar + `(${item.bundleType.ar})`,
              en: item.en + `(${item.bundleType.en})`,
            },
            value: item.id,
          })),
          type: "select",
        },

        // {
        //   title: languages[lang].paymentMethod,
        //   key: "paymentMethod",
        //   placeholder: languages[lang].choosePaymentMethod,
        //   type: "select",
        //   options: [],
        // },
      ];
      setPaymentMethodField(paymentMethodField);
      setUserFields(userFieldsConst);
    }
  }, [lang, packages.length, reqStatus.length, industries.length]);
  const saveChanges = async (data, packageAndPay) => {
    setLoading(true);

    let params = {
      requestId: data.allData.id,
      statusId: data.requestStatus.value,
      preferredLanguageId: data.preferredLanguageId.value,
    };

    let response = await updateCompanyReqStatus(params);
    if (response.data.isSuccess) {
      if (data.requestStatus.value === 3) {
        let companyParams = {
          companyName: data.companyName,
          representativeName: data.representativeName,
          email: data.email,
          phone: data.phone,
          industryId: data.industryId,
          bundleId: packageAndPay.package.value,
          preferredLanguageId: data?.preferredLanguageId?.value,
        };
        let res = await adminActions.createCompany(companyParams);

        if (res.data.isSuccess) {
          toggleModal("successModal");
          setModalMsg(res.data?.message?.[lang] ?? "Done");
        } else {
          toggleModal("failModal");
          setModalMsg(res.data?.message?.[lang]);
        }
      } else {
        toggleModal("successModal");
        setModalMsg(response.data?.message?.[lang] ?? "Done");
      }
    } else {
      toggleModal("failModal");
      setModalMsg(response.data?.message?.[lang]);
    }
    setLoading(false);
  };
  const toggleModal = (stateName) => {
    setOpen({ ...isOpen, [stateName]: !isOpen[stateName] });
    if (stateName === "successModal" && isOpen.successModal) {
      setRedireact(!redirect);
    }
  };
  return (
    <div>
      <NavbarAndSidebarCont
        isOpen={isOpen}
        loading={loading}
        toggleModal={toggleModal}
        modalMsg={modalMsg}
      >
        <div className={`cutomer-details `}>
          <h4>{customerInfo.companyName ?? customerInfo.name}</h4>

          <EditCompanyForm
            customerInfo={customerInfo}
            userFields={userFields}
            paymentMethodField={paymentMethodField}
            deleteRequest
            saveChanges={saveChanges}
          />
        </div>

        {redirect ? <Navigate to="/dashboard/companies" /> : null}
      </NavbarAndSidebarCont>
    </div>
  );
}
