/** @format */

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { languages } from "../../../Constants/Languages";
import { prepareOrderDetails } from "../../../Constants/PrepareData";
import Bill from "../../Bill";
import "./index.scss";
export default function OrderDetail(props) {
  const [orderConstant, setOrderConstant] = useState([]);
  const [userConstant, setUserConstant] = useState([]);
  const [addressConst, setAddressConst] = useState([]);
  let { lang, dir } = useSelector((state) => state.lang);

  useEffect(() => {
    let dataConstant = prepareOrderDetails(lang, true);

    let userInfoConstant = [
      { label: languages[lang].customerName, key: "userName" },
      // { label: languages[lang].phoneNum, key: "phone" },
      // { label: languages[lang].email, key: "email" },
    ];
    let addressConstatnt = [
      { label: languages[lang].city, key: "city" },
      { label: languages[lang].customerName, key: "details" },
      { label: null, key: "" },
      { label: languages[lang].autoNum, key: "autoNum" },
    ];
    // setAddressConst(addressConstatnt);
    setOrderConstant(dataConstant);
    setUserConstant(userInfoConstant);
  }, [lang]);
  const prepareDate = (data) => {
    const options = {
      minute: "numeric",
      hour: "numeric",
      day: "numeric",
      month: "numeric",
      year: "numeric",
    };
    const date = new Date(data).toLocaleDateString("en-EN", options);

    return date;
  };
  const renderSingleInfo = (label, data, key) => {
    return typeof data === "string" || typeof data === "boolean" ? (
      <div
        key={key}
        className={`order-details__info__singleInfo  ${
          props.translator && "order-details__info__singleInfo--translator"
        }  `}
      >
        <span
          dir={dir}
          lang={lang}
          className="order-details__info__singleInfo__label"
        >
          {label}
        </span>

        <span
          dir={dir}
          lang={lang}
          className="order-details__info__singleInfo__data"
        >
          {typeof data === "boolean"
            ? data
              ? languages[lang].yes
              : languages[lang].no
            : key.includes("At")
            ? prepareDate(data)
            : data}
        </span>
      </div>
    ) : null;
  };
  return (
    <div>
      <div className="order-details">
        <div
          className={`order-details__info ${
            props.translator && "order-details__info--translator"
          }`}
        >
          {orderConstant.map((info) =>
            renderSingleInfo(info.label, props.orderData[info.key], info.key)
          )}
          <br/>
          {
            props.orderData.withDelivery == true ? renderSingleInfo(languages[lang].address, props.orderData.address?.details, "address") : null
          }
          {
            props.orderData.withDelivery == true ? renderSingleInfo(languages[lang].city, props.orderData.address?.city[lang], "City") : null
          }
          {
            props.orderData.withDelivery == true ? renderSingleInfo(languages[lang].autoNum, props.orderData.address?.autoNumber, "autoNumber") : null
          }
        </div>
        {/* {props.translator ? null : (
          <Bill
            removeFooter
            numberOfWords={props.orderData.wordCount}
            totalPrice={props.orderData.totalPrice}
            from={props.orderData.fromLanguage}
            to={props.orderData.toLanguage}
          />
        )} */}
      </div>
    </div>
  );
}
