/** @format */
import { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import ArrowIcon from "../../../Assets/Images/Icon ionic-ios-arrow-up.svg";
import fileIcon from "../../../Assets/Images/Icon awesome-file-alt.svg";
import UploadImg from "../../../Assets/Images/Icon feather-upload-cloud.svg";
import CloseIcon from "../../../Assets/Images/Icon ionic-ios-close.svg";
import { languages } from "../../../Constants/Languages";
import InputField from "../../InputField";
import "./index.scss";
import { getLanguages } from "../../../Services/commonServices";
import { setLanguages } from "../../../Redux/commonData";
const errorsInit = {
  formLangId: null,
  toLangId: null,
};
const fieldsDataInit = {
  formLangId: null,
  toLangId: null,
};
export default function FirstPhaseTranslation(props) {
  const [files, setFiles] = useState([]);
  const [textInput, setTextInput] = useState("");
  const [fieldsConstant, setFields] = useState([]);
  const [errors, setErrors] = useState(errorsInit);
  const [fieldsData, setData] = useState(fieldsDataInit);
  let { lang, dir } = useSelector((state) => state.lang);
  let { languagesData } = useSelector((state) => state.commonData);
  let dispatch = useDispatch();
  const { acceptedFiles, fileRejections, getRootProps, getInputProps } =
    useDropzone({
      maxFiles: 1,
    });

  // useEffect(() => {
  //
  //   let firstPhaseData = JSON.parse(
  //     window.localStorage.getItem("firstPhaseData")
  //   );
  //   if (firstPhaseData) {
  //     setData(firstPhaseData.selectedLanguages);

  //     // setFiles(JSON.parse(window.localStorage.getItem("files")));
  //   }
  // }, [languagesData]);
  useEffect(() => {
    if (languagesData.length) {
      let { chooseLang, langOfTheText, translateLang, translateLangTo } =
        languages[lang];
      const fields = [
        {
          title: langOfTheText,
          placeholder: chooseLang,
          key: "formLangId",
          type: "select",
          options: languagesData,
        },
        {
          title: translateLang,
          placeholder: translateLangTo,
          key: "toLangId",
          type: "select",
          options: languagesData,
        },
      ];

      setFields(fields);
    }
  }, [lang, languagesData]);
  useEffect(() => {
    if (acceptedFiles.length) {
      setFiles(acceptedFiles);
    }
  }, [acceptedFiles]);
  useEffect(() => {
    setErrors(props.errors);
  }, [props.errors]);
  const deleteFile = (selectedFile) => {
    let filesClone = files.filter(
      (file) =>
        file.lastModified !== selectedFile.lastModified &&
        file.name !== selectedFile.name
    );
    setFiles(filesClone);
  };
  const getChanges = (key, value) => {
    setErrors({ ...errors, [key]: null });
    setData({ ...fieldsData, [key]: value });

    if (key === "formLangId") {
      let { chooseLang, langOfTheText, translateLang, translateLangTo } =
        languages[lang];
      const fields = [
        {
          title: langOfTheText,
          placeholder: chooseLang,
          key: "formLangId",
          type: "select",
          options: languagesData,
        },
        {
          title: translateLang,
          placeholder: translateLangTo,
          key: "toLangId",
          type: "select",
          options: languagesData.filter(
            (option) => option.value !== value.value
          ),
        },
      ];

      setFields(fields);
    }
  };
  const sendFiles = () => {
    let errorsClone = {};
    Object.keys(fieldsData).forEach((field) => {
      if (!fieldsData[field].value?.length) {
        errorsClone = {
          ...errorsClone,
          [field]: languages[lang].requiredField,
        };
      }
    });
    setErrors(errorsClone);
    if (Object.keys(errorsClone).length === 0) {
      let selectedLang = {
        formLangId: fieldsData.formLangId.value,
        toLangId: fieldsData.toLangId.value,
      };

      props.postFiles(
        acceptedFiles.length ? acceptedFiles : textInput,
        selectedLang,
        fieldsData
      );
    }
  };
  return (
    <div className="first-phase">
      <div
        className="seconed-phase__inputFields__primary-fields seconed-phase__inputFields__primary-fields--firstPhase "
        style={{ textAlign: "start", width: "70%", margin: "auto" }}
      >
        {fieldsConstant.map((field) => (
          <InputField
            key={field.key}
            title={field.title}
            placeholder={field.placeholder}
            value={fieldsData[field.key]}
            getChanges={getChanges}
            stateName={field.key}
            errors={errors}
            select
            options={field.options}
            icon={ArrowIcon}
          />
        ))}
      </div>
      <div className="first-phase__upload-files" dir={dir} lang={lang}>
        <div
          className={`first-phase__upload-files__section-cont  ${
            textInput.length > 0 ? "opacity-disabled" : ""
          }`}
        >
          <span className="first-phase__upload-files__section-cont__title">
            {languages[lang].attachDoc}
          </span>

          <div
            {...getRootProps({
              className: "first-phase__upload-files__section-cont__dropzone",
            })}
          >
            <input {...getInputProps()} />
            <img src={UploadImg} />
            <span className="first-phase__upload-files__section-cont__dropzone__choose-file">
              {languages[lang].chooseFile}
            </span>{" "}
            <span>{languages[lang].dragFile}</span>
          </div>
          {props.percentCompleted > 0 && props.percentCompleted < 100 ? (
            <progress
              className="mt-1 w-100"
              style={{ width: "100%", marginTop: "8px" }}
              id="progress"
              value={props.percentCompleted}
              max="100"
            >
              {" "}
            </progress>
          ) : null}

          <div className="first-phase__upload-files__section-cont__file-cont">
            {files.map((file) => (
              <div
                className="first-phase__upload-files__section-cont__file-cont__file"
                key={file.lastModified}
              >
                <img src={CloseIcon} onClick={() => deleteFile(file)} />
                <span>{file.size / 1000} KB</span>
                <span className="first-phase__upload-files__section-cont__file-cont__file__fileName">
                  {file.name} <img src={fileIcon} />
                </span>
              </div>
            ))}
          </div>
        </div>

        <div className="first-phase__upload-files__seperator">
          {" "}
          {languages[lang].or}
        </div>
        <div className="first-phase__upload-files__section-cont">
          <span className="first-phase__upload-files__section-cont__title">
            {languages[lang].textInput}
          </span>
          <div className="first-phase__upload-files__section-cont__textArea-cont">
            <textarea
              disabled={files.length > 0}
              placeholder={languages[lang].writeTheText}
              onChange={(event) => setTextInput(event.target.value)}
              value={textInput}
            />
          </div>
        </div>
      </div>
      {/* <input
        type='file'
        onChange={(event) => props.getFiles(event.target.files[0])}
      /> */}
      <div className="first-phase__footer-cont" dir={dir} lang={lang}>
        {/* {files.map((file) => (
          <div
            className='first-phase__footer-cont__file'
            key={file.lastModified}>
            <img src={CloseIcon} onClick={() => deleteFile(file)} />
            <span>{file.size / 1000} KB</span>
            <span className='first-phase__footer-cont__file__fileName'>
              {file.name} <img src={fileIcon} />
            </span>
          </div>
        ))} */}

        <button
          disabled={
            (props.percentCompleted > 0 && props.percentCompleted < 100) ||
            ((textInput.length === 0 ||
              fieldsData?.formLangId?.value?.length == null ||
              fieldsData?.toLangId?.value?.length == null) &&
              (files.length === 0 ||
                fieldsData?.formLangId?.value?.length == null ||
                fieldsData?.toLangId?.value?.length == null))
          }
          onClick={() => sendFiles()}
        >
          {languages[lang].next}
        </button>
      </div>
    </div>
  );
}
