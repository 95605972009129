/** @format */

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import RequestsSearchbar from "../../../Components/AdminComponents/RequestsSearchbar";
import CustomNavbar from "../../../Components/Shared/CustomNavbar";
import NavbarAndSidebarCont from "../../../Components/Shared/NavbarAndSidebarCont";
import OrderTable from "../../../Components/Shared/OrderTable";
import Pagination from "../../../Components/Shared/Pagination";
import Sidebar from "../../../Components/Shared/Sidebar";
import { languages } from "../../../Constants/Languages";
import { adminActions } from "../../../actions/adminActions";
import { bindActionCreators } from "@reduxjs/toolkit";

let styleObject = {
  New: "yellow",
  Done: "green",
  "In Translation": "blue",
  Translated: "green",
  "Waiting Delivery": "yellow",
  "In Delivery": "blue",
  Cancelled: "red",
};
export default function Transactions() {
  let paymentMethodList = [
    {
      paymentMethodId: 7,
      paymentMethodAr: "البطاقات المدينة  قطر",
      paymentMethodEn: "QPay",
      paymentMethodCode: "np",
      isDirectPayment: false,
      serviceCharge: 0.104,
      totalAmount: 0.236,
      currencyIso: "KWD",
      imageUrl: "https://demo.myfatoorah.com/imgs/payment-methods/np.png",
      isEmbeddedSupported: false,
      paymentCurrencyIso: "QAR",
    },
    {
      paymentMethodId: 6,
      paymentMethodAr: "مدى",
      paymentMethodEn: "MADA",
      paymentMethodCode: "md",
      isDirectPayment: false,
      serviceCharge: 1,
      totalAmount: 0.132,
      currencyIso: "KWD",
      imageUrl: "https://demo.myfatoorah.com/imgs/payment-methods/md.png",
      isEmbeddedSupported: true,
      paymentCurrencyIso: "SAR",
    },
    {
      paymentMethodId: 1,
      paymentMethodAr: "كي نت",
      paymentMethodEn: "KNET",
      paymentMethodCode: "kn",
      isDirectPayment: false,
      serviceCharge: 1.002,
      totalAmount: 0.132,
      currencyIso: "KWD",
      imageUrl: "https://demo.myfatoorah.com/imgs/payment-methods/kn.png",
      isEmbeddedSupported: false,
      paymentCurrencyIso: "KWD",
    },
    {
      paymentMethodId: 11,
      paymentMethodAr: "أبل الدفع",
      paymentMethodEn: "Apple Pay",
      paymentMethodCode: "ap",
      isDirectPayment: false,
      serviceCharge: 1.002,
      totalAmount: 0.132,
      currencyIso: "KWD",
      imageUrl: "https://demo.myfatoorah.com/imgs/payment-methods/ap.png",
      isEmbeddedSupported: true,
      paymentCurrencyIso: "KWD",
    },
    {
      paymentMethodId: 2,
      paymentMethodAr: "فيزا / ماستر",
      paymentMethodEn: "VISA/MASTER",
      paymentMethodCode: "vm",
      isDirectPayment: false,
      serviceCharge: 0.101,
      totalAmount: 0.132,
      currencyIso: "KWD",
      imageUrl: "https://demo.myfatoorah.com/imgs/payment-methods/vm.png",
      isEmbeddedSupported: true,
      paymentCurrencyIso: "KWD",
    },
    {
      paymentMethodId: 14,
      paymentMethodAr: "STC Pay",
      paymentMethodEn: "STC Pay",
      paymentMethodCode: "stc",
      isDirectPayment: false,
      serviceCharge: 0.101,
      totalAmount: 0.132,
      currencyIso: "KWD",
      imageUrl: "https://demo.myfatoorah.com/imgs/payment-methods/stc.png",
      isEmbeddedSupported: false,
      paymentCurrencyIso: "SAR",
    },
    {
      paymentMethodId: 8,
      paymentMethodAr: "كروت الدفع المدنية (الإمارات)",
      paymentMethodEn: "UAE Debit Cards",
      paymentMethodCode: "uaecc",
      isDirectPayment: false,
      serviceCharge: 0.104,
      totalAmount: 0.236,
      currencyIso: "KWD",
      imageUrl: "https://demo.myfatoorah.com/imgs/payment-methods/uaecc.png",
      isEmbeddedSupported: true,
      paymentCurrencyIso: "AED",
    },
    {
      paymentMethodId: 9,
      paymentMethodAr: "Visa/Master Direct 3DS Flow",
      paymentMethodEn: "Visa/Master Direct 3DS Flow",
      paymentMethodCode: "vm",
      isDirectPayment: true,
      serviceCharge: 0.104,
      totalAmount: 0.236,
      currencyIso: "KWD",
      imageUrl: "https://demo.myfatoorah.com/imgs/payment-methods/vm.png",
      isEmbeddedSupported: true,
      paymentCurrencyIso: "KWD",
    },
    {
      paymentMethodId: 20,
      paymentMethodAr: "Visa/Master Direct",
      paymentMethodEn: "Visa/Master Direct",
      paymentMethodCode: "vm",
      isDirectPayment: true,
      serviceCharge: 0.101,
      totalAmount: 0.132,
      currencyIso: "KWD",
      imageUrl: "https://demo.myfatoorah.com/imgs/payment-methods/vm.png",
      isEmbeddedSupported: true,
      paymentCurrencyIso: "KWD",
    },
    {
      paymentMethodId: 3,
      paymentMethodAr: "اميكس",
      paymentMethodEn: "AMEX",
      paymentMethodCode: "ae",
      isDirectPayment: false,
      serviceCharge: 0.104,
      totalAmount: 0.236,
      currencyIso: "KWD",
      imageUrl: "https://demo.myfatoorah.com/imgs/payment-methods/ae.png",
      isEmbeddedSupported: true,
      paymentCurrencyIso: "USD",
    },
    {
      paymentMethodId: 25,
      paymentMethodAr: "أبل باي (مدي)",
      paymentMethodEn: "Apple Pay (Mada)",
      paymentMethodCode: "ap",
      isDirectPayment: false,
      serviceCharge: 0.002,
      totalAmount: 0.132,
      currencyIso: "KWD",
      imageUrl: "https://demo.myfatoorah.com/imgs/payment-methods/ap.png",
      isEmbeddedSupported: true,
      paymentCurrencyIso: "KWD",
    },
    {
      paymentMethodId: 32,
      paymentMethodAr: "جوجل للدفع",
      paymentMethodEn: "GooglePay",
      paymentMethodCode: "gp",
      isDirectPayment: false,
      serviceCharge: 0.003,
      totalAmount: 0.132,
      currencyIso: "KWD",
      imageUrl: "https://demo.myfatoorah.com/imgs/payment-methods/gp.png",
      isEmbeddedSupported: true,
      paymentCurrencyIso: "AED",
    },
    {
      paymentMethodId: 5,
      paymentMethodAr: "بنفت",
      paymentMethodEn: "Benefit",
      paymentMethodCode: "b",
      isDirectPayment: false,
      serviceCharge: 0.103,
      totalAmount: 0.132,
      currencyIso: "KWD",
      imageUrl: "https://demo.myfatoorah.com/imgs/payment-methods/b.png",
      isEmbeddedSupported: false,
      paymentCurrencyIso: "BHD",
    },
  ];
  const [orders, setOrders] = useState([]);
  const [pagination, setPaginationData] = useState({});
  const [tranactionsConst, setTranactionsConst] = useState([]);
  const [headerStatuses, setHeaderStatuses] = useState([]);
  const [searchValue, setSearch] = useState("");

  let { lang, dir } = useSelector((state) => state.lang);
  let { Transactions } = useSelector((state) => state.admin);
  let { orderStatuses } = useSelector((state) => state.commonData);
  let dispatch = useDispatch();
  let { getTransactionsAdmin } = bindActionCreators(adminActions, dispatch);

  useEffect(() => {
    getTransactionsAdmin(1, 10);
  }, []);

  useEffect(() => {
    if (Transactions?.data?.length >= 0) {
      let Statuses = [
        {
          label: {
            ar: languages["ar"].Pending,
            en: languages["en"].Pending,
          },
          value: 1,
        },
        {
          label: {
            ar: languages["ar"].Paid,
            en: languages["en"].Paid,
          },
          value: 2,
        },
        {
          label: { ar: languages["ar"].Failed, en: languages["en"].Failed },
          value: 3,
        },
        {
          label: { ar: languages["ar"].Refunded, en: languages["en"].Refunded },
          value: 4,
        },
      ];

      let orderStatusesClone = paymentMethodList.map((status) => ({
        ...status,
        num: 0,
        style: styleObject[status.en],
        label: { ar: status.paymentMethodAr, en: status.paymentMethodEn },
        value: status.paymentMethodId,
      }));
      setHeaderStatuses({
        Statuses: Statuses,
        paymentMethodList: orderStatusesClone,
      });
      let tranactionsConst = [
        { label: languages[lang].operationNum, key: "transactionId" },
        { label: languages[lang].operationDate, key: "updatedAt" },
        { label: languages[lang].paymentMethod, key: "paymentMethodId" },
        { label: languages[lang].price, key: "totalCost" },
        { label: languages[lang].orderNum, key: "orderId" },
        { label: languages[lang].transactionType, key: "type" },
        { label: languages[lang].status, key: "status" },
      ];
      let paymentMethodName =
        lang === "ar" ? "paymentMethodAr" : "paymentMethodEn";
      let list = Transactions.data.map((res) => {
        return {
          ...res,
          paymentMethodId: paymentMethodList.find(
            (resa) => resa?.paymentMethodId === res?.paymentMethodId
          )?.[paymentMethodName],
          totalCost: res.totalCost + " " + languages[lang].DK,
          type:
            res.type === 1
              ? languages[lang].UserOrder
              : res.type === 2
              ? languages[lang].CompanyOrder
              : res.type === 3
              ? languages[lang].Bundle
              : "-",
          status:
            res.status === 1
              ? languages[lang].Pending
              : res.status === 2
              ? languages[lang].Paid
              : res.status === 3
              ? languages[lang].Failed
              : res.status === 4
              ? languages[lang].Refunded
              : "-",
        };
      });
      console.log(list);
      setPaginationData(Transactions.pagination);
      setTranactionsConst(tranactionsConst);
      setOrders(list);
    }
  }, [Transactions && Transactions?.data]);

  const getSearchValues = async (searchString) => {
    setSearch(searchString);
    await getTransactionsAdmin(1, searchString);
  };
  const getPageData = async (pageNum) => {
    if (pageNum !== pagination.CurrentPage) {
      await getTransactionsAdmin(pageNum, searchValue);
    }
  };
  return (
    <div>
      <NavbarAndSidebarCont>
        <div className="orders-cont">
          <div className={`orders-cont__body`}>
            <div className="orders-cont__body__info" dir={dir} lang={lang}>
              <h4>{languages[lang].payments}</h4>
            </div>
            <RequestsSearchbar
              statusOptions={headerStatuses.Statuses}
              getSearchValues={getSearchValues}
              hasSelect={true}
              selectOptions={headerStatuses.paymentMethodList}
              selectKey={"paymentMethodId"}
              selectName={"paymentMethod"}
              hideFieldSearch
            />
            <OrderTable
              orders={orders}
              constants={tranactionsConst}
              style="transactions-table"
              removeArrow
              disableRouting
            />
          </div>
          <Pagination pagination={pagination} getPageData={getPageData} />
        </div>
      </NavbarAndSidebarCont>
    </div>
  );
}
