/** @format */
import "./index.scss";
export default function Card({ icon, title, body }) {
  return (
    <div className='card-cont'>
      <div className='card-cont__img-cont'>
        <img src={icon} />
      </div>
      <h5 dir='rtl' lang='ar'>
        {title}
      </h5>
      <span dir='rtl' lang='ar'>
        {body}
      </span>
    </div>
  );
}
