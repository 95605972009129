/** @format */

import React from "react";
import LoaderCont from "../../../Assets/Images/Group 39832.svg";
import Spinner from "../../../Assets/Images/Image 23.png";
import "./index.scss";
export default function Loader({ loading }) {
  // console.log(">>>>>>>>>loading", loading);
  if (loading) {
    return (
      <div className='loader-cont'>
        <div className='loader-cont__spinnerAndParantImg'>
          <img
            src={LoaderCont}
            className='loader-cont__spinnerAndParantImg__parantImg'
          />
          <img
            src={Spinner}
            className='loader-cont__spinnerAndParantImg__spinner'
          />
        </div>
      </div>
    );
  } else {
    return null;
  }
}
