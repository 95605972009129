/** @format */

import { deleteReq, postReq } from "./APIMethods";

export const createOrderType = async (params) => {
  let response = await postReq("OrderTypes/add-order-type", params);
  return response;
};

export const createTranslationField = async (params) => {
  let response = await postReq(
    "TranslationFields/add-translation-field",
    params
  );
  return response;
};
export const deleteTranslationField = async (params) => {
  let response = await deleteReq(
    "TranslationFields/admin-delete-translation-field" + `?id=${params}`
  );
  return response;
};
export const addAddOnService = async (params) => {
  let response = await postReq("AddOns/add-add-on", params);
  return response;
};
export const deleteAddOnService = async (params) => {
  let response = await deleteReq("AddOns/admin-delete-addon" + `?id=${params}`);
  return response;
};
export const addCityService = async (params) => {
  let response = await postReq("Cities/add-city", params);
  return response;
};
export const deleteCityService = async (params) => {
  let response = await deleteReq("Cities/admin-delete-city" + `?id=${params}`);
  return response;
};
export const deleteLanguageService = async (params) => {
  let response = await deleteReq(
    "Languages/admin-delete-language" + `?id=${params}`
  );
  return response;
};
