/** @format */

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import RequestsSearchbar from "../../../Components/AdminComponents/RequestsSearchbar";
import CustomNavbar from "../../../Components/Shared/CustomNavbar";
import Sidebar from "../../../Components/Shared/Sidebar";
import Pagination from "../../../Components/Shared/Pagination";
import addIcon from "../../../Assets/Images/Group 2784.svg";
import wightAdd from "../../../Assets/Images/Group 2784.png";
import { languages } from "../../../Constants/Languages";

import "./index.scss";
import { prepareDashboardSideBar } from "../../../Constants/PrepareData";
import CustomersTable from "../../../Components/Shared/CustomersTable";
import { Link } from "react-router-dom";
import AllCompanies from "./AllCompanies";
import Packages from "./Packages";
import NavbarAndSidebarCont from "../../../Components/Shared/NavbarAndSidebarCont";
import ComapniesRequests from "./CompanyRequests";
import SuccessAndFailModal from "../../../Components/Modals/SuccessAndFailModal";

export default function Companies(props) {
  const [loading, setLoading] = useState(false);
  const [isOpen, setOpen] = useState({ successModal: false, failModal: false });
  const [modalMsg, setModalMsg] = useState("");
  let { lang, dir, selectedOption } = useSelector((state) => state.lang);
  const toggleLoading = (flag) => {
    setLoading(flag);
  };
  const getModalMsg = (msg) => {
    setModalMsg(msg);
  };
  const toggleModal = (stateName) => {
    setOpen({ ...isOpen, [stateName]: !isOpen[stateName] });
  };

  console.log(languages[lang][selectedOption]);
  return (
    <div className="customers-cont">
      <NavbarAndSidebarCont
        isOpen={isOpen}
        loading={loading}
        toggleModal={toggleModal}
        modalMsg={modalMsg}
      >
        <div className={`customers-cont__body `}>
          {languages[lang][selectedOption] === languages[lang].allCompanies ? (
            <AllCompanies
              toggleModal={toggleModal}
              toggleLoading={toggleLoading}
              loading={loading}
              getModalMsg={getModalMsg}
            />
          ) : languages[lang][selectedOption] === languages[lang].packages ? (
            <Packages
              toggleModal={toggleModal}
              toggleLoading={toggleLoading}
              loading={loading}
              getModalMsg={getModalMsg}
            />
          ) : (
            <ComapniesRequests
              toggleModal={toggleModal}
              toggleLoading={toggleLoading}
              loading={loading}
              getModalMsg={getModalMsg}
            />
          )}
        </div>
      </NavbarAndSidebarCont>
    </div>
  );
}
