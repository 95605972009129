/** @format */

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import UserIcon from "../../../../Assets/Images/Icon awesome-user-alt.svg";
import phoneIcon from "../../../../Assets/Images/Icon ionic-md-phone-portrait.svg";
import CompanyIcon from "../../../../Assets/Images/Path 11449.svg";
import EmailIcon from "../../../../Assets/Images/Icon ionic-ios-mail.svg";
import EyeIcon from "../../../../Assets/Images/Icon ionic-ios-eye.svg";
import NavbarAndSidebarCont from "../../../../Components/Shared/NavbarAndSidebarCont";

import { prepareDashboardSideBar } from "../../../../Constants/PrepareData";
import EditCompanyForm from "../../../../Components/AdminComponents/EditComapnyForm";
import { languages } from "../../../../Constants/Languages";
import { bindActionCreators } from "@reduxjs/toolkit";
import { adminActions } from "../../../../actions/adminActions";
import { Navigate } from "react-router-dom";

export default function AddNewCompany(props) {
  const [loading, setLoading] = useState(false);
  const [isOpen, setOpen] = useState({ successModal: false, failModal: false });
  const [modalMsg, setModalMsg] = useState("");
  const [passFields, setPassFields] = useState([]);
  const [paymentMethodField, setPaymentMethodField] = useState({});
  const [redirect, setRedireact] = useState(false);
  const [userFields, setUserFields] = useState([]);
  let { lang, dir } = useSelector((state) => state.lang);
  let { packages, industries } = useSelector((state) => state.commonData);
  let { reqStatus } = useSelector((state) => state.admin);
  let dispatch = useDispatch();
  let {
    getPackages,
    getCompanyIndustries,
    getCompanyReqStatus,
    updateCompanyReqStatus,
  } = bindActionCreators(adminActions, dispatch);
  useEffect(() => {
    if (packages.length === 0) {
      getPackages();
    }
    if (industries.length === 0) {
      getCompanyIndustries();
    }
    if (reqStatus.length === 0) {
      getCompanyReqStatus();
    }
  }, []);
  useEffect(() => {
    if (packages.length && industries.length && reqStatus.length) {
      setLoading(false);
      let mainPackages = packages.filter((item) => item.bundleClassId === 1);
      let userFieldsConst = [
        {
          title: languages[lang].companyName + " *",
          key: "companyName",
          icon: CompanyIcon,
          placeholder: languages[lang].pleaseWriteName,
        },
        {
          title: languages[lang].email + " *",
          key: "email",
          icon: EmailIcon,
          placeholder: languages[lang].pleaseWriteEmail,
        },
        {
          title: languages[lang].responsibleName + " *",
          key: "representativeName",
          icon: UserIcon,
          placeholder: languages[lang].pleaseWriteResponsibleName,
        },
        {
          title: languages[lang].phoneNum + " *",
          key: "phone",
          icon: phoneIcon,
          type: "phoneNumber",
          placeholder: languages[lang].pleaseWritePhone,
        },
        {
          title: languages[lang].companyBusiness + " *",
          key: "industry",
          placeholder: languages[lang].chooseBusiness,

          type: "select",
          options: industries.map((item) => ({
            label: { ar: item.ar, en: item.en },
            value: item.id,
          })),
        },
        {
          title: languages[lang].status + " *",
          key: "requestStatus",
          icon: phoneIcon,
          placeholder: languages[lang].selectStatus,

          options: reqStatus.map((item) => ({
            label: {
              ar: item.ar,
              en: item.en,
            },
            value: item.id,
          })),
          type: "select",
        },
        {
          title: languages[lang].package + " *",
          key: "package",
          icon: phoneIcon,
          placeholder: languages[lang].writePackageName,
          disabled: false,
          options: mainPackages.map((item) => ({
            label: {
              ar: item.ar + `(${item.bundleType.ar})`,
              en: item.en + `(${item.bundleType.en})`,
            },
            value: item.id,
          })),
          type: "select",
        },
        {
          title: languages[lang].preferredLanguage,
          key: "preferredLanguageId",
          type: "select",
          options: [
            { label: { ar: "العربية", en: "العربية" }, value: 1 },
            { label: { ar: "English", en: "English" }, value: 2 },
          ],
          placeholder: languages[lang].selectPreferredLang,
          // disabled: params?.id ? true : false,
        },
      ];
      // let passwordFields = [
      //   {
      //     title: languages[lang].password,
      //     key: "password",
      //     icon: EyeIcon,
      //     placeholder: languages[lang].pleaseWritePass,
      //   },
      //   {
      //     title: languages[lang].confirmPass,
      //     key: "confirmPass",
      //     icon: EyeIcon,
      //     placeholder: languages[lang].pleaseWritePass,
      //   },
      // ];
      // let paymentMethodField = {
      //   title: languages[lang].paymentMethod,
      //   key: "paymentMethod",
      //   placeholder: languages[lang].choosePaymentMethod,

      //   type: "select",
      // };
      // setPaymentMethodField(paymentMethodField);
      // setPassFields(passwordFields);
      setUserFields(userFieldsConst);
    }
  }, [lang, packages, industries, reqStatus]);
  const toggleModal = (stateName) => {
    setOpen({ ...isOpen, [stateName]: !isOpen[stateName] });
    if (stateName === "successModal" && isOpen.successModal) {
      setRedireact(!redirect);
    }
  };

  const saveChanges = async (data, packageAndPay) => {
    setLoading(true);

    let companyParams = {
      ...data,
      preferredLanguageId: data.preferredLanguageId.value,
      industryId: data.industry.value,
      bundleId: packageAndPay.package.value,
    };
    let res = await adminActions.createCompany(companyParams);
    if (res.data.isSuccess) {
      toggleModal("successModal");
      setModalMsg(res.data?.message?.[lang] ?? "Done");
    } else {
      toggleModal("failModal");
      setModalMsg(res.data?.message?.[lang]);
    }

    setLoading(false);
  };

  return (
    <NavbarAndSidebarCont
      isOpen={isOpen}
      loading={loading}
      toggleModal={toggleModal}
      modalMsg={modalMsg}>
      <h4>{languages[lang].addNewCompany}</h4>
      <EditCompanyForm
        userFields={userFields}
        passFields={passFields}
        paymentMethodField={paymentMethodField}
        saveChanges={saveChanges}
        addNewCompany
      />
      {redirect ? <Navigate to='/dashboard/companies' /> : null}
    </NavbarAndSidebarCont>
  );
}
