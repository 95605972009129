/** @format */
import FlagsImg from "../../../Assets/Images/Image 13.png";
import Checked from "../../../Assets/Images/Path 1539.png";
import BtnPorder from "../../../Assets/Images/Group 143.svg";
import BtnPorder2 from "../../../Assets/Images/Group 144.svg";
import "./index.scss";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { languages } from "../../../Constants/Languages";
export default function WhatWeOfferPart2() {
  const fields1 = [
    "legalTranslation",
    "financialTranslation",

    "manufacturingTranslation",
    "travelTourism",
    "mediaEntertainment",
    "MedicalTranslation",

    "telecomSectorTranslation",
    "sportsSectorTranslation",
    "energyMining",
    "marketingTranslation",
    "officialGovernmental",
    "sectorTranslation",
  ];

  let { lang, dir } = useSelector((state) => state.lang);
  return (
    <section className='offerSec2-cont' lang={lang} dir={dir}>
      <div className='offerSec2-cont__flags-cont'>
        <img src={FlagsImg} />
      </div>
      <div className='offerSec2-cont__info'>
        <h3>{languages[lang].coverAllLang}</h3>
        <span>{languages[lang].coverAllLangBody}</span>
        <h4> {languages[lang].getCertifiedTranslation}</h4>

        <div className='offerSec2-cont__info__fields' lang={lang} dir={dir}>
          {fields1.map((field) => (
            <span>
              <img src={Checked} /> {languages[lang][field]}
            </span>
          ))}
        </div>
        <div className='offerSec2-cont__info__btn-cont'>
          <Link to='/translate'>
            <button className='offerSec2-cont__info__btn-cont__firstBtn'>
              <span> {languages[lang].startNow}</span>
              <img src={BtnPorder} />
            </button>
          </Link>
          <Link to='/company-application'>
            <button className='offerSec2-cont__info__btn-cont__secBtn'>
              <span> {languages[lang].companyNeeds}</span>
              <img src={BtnPorder2} />
            </button>
          </Link>
        </div>
      </div>
    </section>
  );
}
