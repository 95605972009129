/** @format */

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
  lang: localStorage.getItem("lang") ?? "ar",
  dir: localStorage.getItem("lang") === "en" ? "ltr" : "rtl",
  selectedOption: localStorage.getItem("selectedOption"),
  isOpen: localStorage.getItem("isOpen") ?? false,
};

export const languageSlice = createSlice({
  name: "language",
  initialState,
  reducers: {
    changeLang: (state, action) => {
      localStorage.setItem("lang", action.payload);
      state.lang = action.payload;
      state.dir = action.payload === "ar" ? "rtl" : "ltr";
    },
    changeSelectedOption: (state, action) => {
      localStorage.setItem("selectedOption", action.payload);
      state.selectedOption = action.payload;
    },
    toggleSideMenu: (state, action) => {
      localStorage.setItem("isOpen", !state.isOpen);
      state.isOpen = !state.isOpen;
    },
  },
});

// Action creators are generated for each case reducer function
export const { changeLang, changeSelectedOption, toggleSideMenu } =
  languageSlice.actions;

export default languageSlice.reducer;
