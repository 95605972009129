/** @format */

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import RequestsSearchbar from "../../../Components/AdminComponents/RequestsSearchbar";
import NavbarAndSidebarCont from "../../../Components/Shared/NavbarAndSidebarCont";
import OrderTable from "../../../Components/Shared/OrderTable";
import Pagination from "../../../Components/Shared/Pagination";
import { languages } from "../../../Constants/Languages";
import addIcon from "../../../Assets/Images/Group 2784.svg";
import wightAdd from "../../../Assets/Images/Group 2784.png";
import filter from "../../../Assets/Images/Path 11471.svg";
import { clintActions } from "../../../actions/clintActions";
import { bindActionCreators } from "@reduxjs/toolkit";
import ResponsiveTable from "../../../Components/Shared/ResponsiveOrderTable";
const OrdersTypes = [
  { langKey: "waitingForApproval", style: "yellow" },
  { langKey: "underTranslation", style: "blue" },
  { langKey: "translationSent", style: "green" },
  { langKey: "canceled", style: "red" },
];
let styleObject = {
  New: "yellow",
  Done: "green",
  "In Translation": "blue",
  Translated: "green",
  "Waiting Delivery": "yellow",
  "In Delivery": "blue",
  Cancelled: "red",
};
export default function CutomerRequets(props) {
  const [openFilter, setOpenFilter] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isOpen, setOpen] = useState({ successModal: false, failModal: false });
  const [modalMsg, setModalMsg] = useState("");
  const [searchValue, setSearch] = useState("");
  const [ordersList, setOrders] = useState([]);
  const [headerStatuses, setHeaderStatuses] = useState([]);
  const [pagination, setPaginationData] = useState({});
  const [isHovering, toggleHover] = useState(false);
  const [headerConstant, setHeaderConstant] = useState([]);
  let { lang, dir } = useSelector((state) => state.lang);
  let { orders } = useSelector((state) => state.clint);
  let { orderStatuses } = useSelector((state) => state.commonData);
  let dispatch = useDispatch();
  let { getClintOrdersAction } = bindActionCreators(clintActions, dispatch);

  useEffect(() => {
    let userData = JSON.parse(localStorage.getItem("userRole"));
    getClintOrdersAction(1);
  }, []);
  useEffect(() => {
    let tableHeaderConstant = [
      { label: "#", key: "id" },
      { label: languages[lang].requestData, key: "createdAt" },

      { label: languages[lang].translateFrom, key: "fromLang" },
      { label: languages[lang].translateTo, key: "toLang" },
      { label: languages[lang].translationField, key: "translationField" },
      { label: languages[lang].translationType, key: "orderType" },
      { label: languages[lang].serviceType, key: "addOn" },
      { label: languages[lang].price, key: "price" },

      { label: languages[lang].deleviryData, key: "fininshedAt" },
      { label: languages[lang].status, key: "status" },
    ];
    setHeaderConstant(tableHeaderConstant);
  }, [lang]);
  useEffect(() => {
    if (orders.pagination && orderStatuses.length) {
      setLoading(false);
      let ordersClone = [...orders.data];
      ordersClone = ordersClone.map((order) => ({
        ...order,
        addOn: order.addOn[lang],
        orderType: order.orderType[lang],
        translationField: order.translationField[lang],
      }));
      let orderStatusesClone = orderStatuses.map((status) => ({
        ...status,
        num: 0,
        style: styleObject[status.en],
        label: { ar: status.ar, en: status.en },
        value: status.id,
      }));
      let modifiedOrders = [];
      ordersClone.forEach((order) => {
        let statusIndex = orderStatuses.findIndex(
          (status) => status.id === order.statusId
        );
        orderStatusesClone[statusIndex].num =
          orderStatusesClone[statusIndex].num + 1;
        order = {
          ...order,
          status: orderStatuses[statusIndex][lang],
        };
        modifiedOrders = [...modifiedOrders, order];
      });
      setHeaderStatuses(orderStatusesClone);
      setOrders(modifiedOrders);
      setPaginationData(orders.pagination);
    }
  }, [orders, orderStatuses]);
  const toggleModal = (stateName) => {
    setOpen({ ...isOpen, [stateName]: !isOpen[stateName] });
  };
  const toggleFilterModal = () => {
    setOpenFilter(!openFilter);
  };
  const getPageData = async (text) => {
    setLoading(true);
    let res = await getClintOrdersAction(text, searchValue);
    setLoading(false);
  };
  const getSearchValues = async (searchString) => {
    setLoading(true);
    setSearch(searchString);
    let res = await getClintOrdersAction(1, searchString);
    setLoading(false);
  };
  const renderOrdersTypes = (type, index) => {
    return (
      <div
        dir={dir}
        lang={lang}
        className='orders-cont__body__info__types__type'
        key={type.langKey + index}>
        <div
          className={`orders-cont__body__info__types__type__dot orders-cont__body__info__types__type__dot--${type.style}`}
        />
        <div>
          <span>{type.num}</span>
          <span>{type[lang]}</span>
        </div>
      </div>
    );
  };
  return (
    <NavbarAndSidebarCont
      userSidebar='customer'
      isOpen={isOpen}
      loading={loading}
      toggleModal={toggleModal}
      modalMsg={modalMsg}>
      <div className='orders-cont'>
        <div className={`orders-cont__body`}>
          <div className='orders-cont__body__info ' dir={dir} lang={lang}>
            <h4>{languages[lang].allRequests}</h4>
            <div className='orders-cont__body__info__types orders-cont__body__info__types--normalView'>
              {headerStatuses.map((type, index) =>
                renderOrdersTypes(type, index)
              )}
            </div>
            <span
              className='orders-cont__body__info__filter'
              onClick={toggleFilterModal}>
              <img src={filter} /> {languages[lang].filter}
            </span>
            <Link to='/customer/requests/add'>
              <button
                onMouseEnter={() => toggleHover(true)}
                onMouseLeave={() => toggleHover(false)}>
                <img src={isHovering ? wightAdd : addIcon} />{" "}
                {languages[lang].add}
              </button>
            </Link>
          </div>
          <div className='orders-cont__body__info__types--responsiveView'>
            {headerStatuses.map((type, index) =>
              renderOrdersTypes(type, index)
            )}
          </div>
          <RequestsSearchbar
            statusOptions={headerStatuses}
            getSearchValues={getSearchValues}
            openFilter={openFilter}
            toggleFilterModal={toggleFilterModal}
          />

          <OrderTable
            url='customer/requests'
            orders={ordersList}
            constants={headerConstant}
            style='customer-requests'
            saveToSessionStorege='orderDetails'
          />
        </div>

        <ResponsiveTable
          url='company/requests'
          orders={ordersList}
          constants={headerConstant}
          style='customer-requests'
          saveToSessionStorege='orderDetails'
        />

        {pagination.TotalPages < 2 ? null : (
          <Pagination pagination={pagination} getPageData={getPageData} />
        )}
      </div>
    </NavbarAndSidebarCont>
  );
}
