/** @format */

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import addIcon from "../../../../Assets/Images/Group 2784.svg";
import wightAdd from "../../../../Assets/Images/Group 2784.png";
import { languages } from "../../../../Constants/Languages";
import NavbarAndSidebarCont from "../../../../Components/Shared/NavbarAndSidebarCont";
import RequestsSearchbar from "../../../../Components/AdminComponents/RequestsSearchbar";
import CustomersTable from "../../../../Components/Shared/CustomersTable";
import { adminActions } from "../../../../actions/adminActions";
import { bindActionCreators } from "@reduxjs/toolkit";
import {
  getOrderTypes,
  getTranslationFields,
} from "../../../../Services/commonServices";
import { setOrderTypes } from "../../../../Redux/commonData";

export default function LangFieldsPricing(props) {
  const [id, setId] = useState();
  const [isHovering, toggleHover] = useState(false);
  const [translationTypes, setTranslationTypes] = useState([]);
  const [isModalOpen, setModalOpen] = useState({ cancelModal: false });
  const [tableHeaderConstatnt, setTableHeader] = useState([]);
  let { lang, dir } = useSelector((state) => state.lang);
  let { orderTypes } = useSelector((state) => state.commonData);
  let dispatch = useDispatch();
  let { deleteTranslationField } = bindActionCreators(adminActions, dispatch);
  const toggleCancelModal = (modalName, event, id) => {
    setId(id);

    setModalOpen({ ...isModalOpen, [modalName]: !isModalOpen[modalName] });
    if (event) {
      event.preventDefault();
    }
  };
  useEffect(() => {
    let CustomersClone = orderTypes.map((type) => ({
      ...type,
      label: type.label[lang],
    }));
    let tableHeader = [
      { title: languages[lang].translationType, key: "label" },
      { title: languages[lang].price, key: "additionalCost" },
    ];
    setTableHeader(tableHeader);
    setTranslationTypes(CustomersClone);
  }, [lang, orderTypes]);
  const deleteField = async (id) => {
    props.toggleLoading(true);
    let response = await deleteTranslationField(id);
    if (response.data?.isSuccess) {
      toggleCancelModal("cancelModal");

      getOrderTypes().then((res) => {
        if (res.data.isSuccess) {
          let options = props.prepareOptions(res.data.data, "fields");
          dispatch(setOrderTypes(options));
        }
      });
      props.toggleModal("successModal");
      props.getModalMsg(response.data.message[lang]);
    } else {
      props.toggleModal("failModal");
      props.getModalMsg(
        response.data?.message
          ? response.data?.message[lang]
          : languages[lang].wentWrong
      );
    }
    props.toggleLoading(false);
  };
  return (
    <div>
      <div className='customers-cont__body__info' dir={dir} lang={lang}>
        <h4>{languages[lang].tralnsationTypePricing}</h4>
        {props.canEdit ? (
          <Link to='/dashboard/settings/translation-field/add'>
            <button
              onMouseEnter={() => toggleHover(true)}
              onMouseLeave={() => toggleHover(false)}>
              <img src={isHovering ? wightAdd : addIcon} />{" "}
              {languages[lang].add}
            </button>
          </Link>
        ) : null}
      </div>
      {/* <RequestsSearchbar searchOnly /> */}

      <CustomersTable
        toggleModal={toggleCancelModal}
        isOpen={isModalOpen.cancelModal}
        id={id}
        agents={translationTypes}
        url='settings/translation-field'
        tableHeaderConstatnt={tableHeaderConstatnt}
        msg={languages[lang].deleteType}
        removeDelete={!props.canEdit}
        loading={props.loading}
        disableRouting
        deleteAction={deleteField}
      />
    </div>
  );
}
