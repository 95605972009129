/** @format */

import React from "react";
import { useSelector } from "react-redux";
import "./index.scss";
export default function Pagination({ pagination, getPageData }) {
  let { lang, dir } = useSelector((state) => state.lang);
  const renderSpan = (style, text) => {
    return (
      <div
        dir={dir}
        lang={lang}
        className={`pagination-cont__${style}`}
        onClick={() => getPageData(text)}>
        {text}
      </div>
    );
  };
  return (
    <div dir={dir} lang={lang} className='pagination-cont'>
      {!pagination.HasNext && pagination.CurrentPage - 2 > 0
        ? renderSpan("notSelected ", pagination.CurrentPage - 2)
        : null}
      {pagination.HasPrev
        ? renderSpan("notSelected ", pagination.CurrentPage - 1)
        : null}
      {renderSpan("selected", pagination.CurrentPage)}
      {pagination.HasNext
        ? renderSpan("notSelected ", pagination.CurrentPage + 1)
        : null}
      {!pagination.HasPrev &&
      pagination.HasNext &&
      pagination.TotalPages === pagination.CurrentPage + 2
        ? renderSpan("notSelected ", pagination.CurrentPage + 2)
        : null}
      {pagination.CurrentPage + 2 >= pagination.TotalPages ? null : (
        <span>....</span>
      )}
      {pagination.CurrentPage + 2 >= pagination.TotalPages
        ? null
        : renderSpan("notSelected ", pagination.TotalPages)}
    </div>
  );
}
