/** @format */

import React, { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import { languages } from "../../../Constants/Languages";
import Logout from "../../../Assets/Images/Icon open-account-logout.png";
import Lock from "../../../Assets/Images/Icon awesome-lock.png";
import "./index.scss";
import ChangePass from "../ChangePass";
import { Navigate } from "react-router-dom";
export default function LogoutModal(props) {
  const [openChangePass, toggleChangePass] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const { lang } = useSelector((state) => state.lang);
  let logoutRef = useRef(null);
  const handleClickOutside = (event) => {
    if (logoutRef.current && !logoutRef.current.contains(event.target)) {
      props.toggleModal();
      props.onClickOutside && props.onClickOutside();
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  });
  const logOut = () => {
    localStorage.removeItem("userRole");
    setRedirect(true);
  };
  return (
    <div
      ref={logoutRef}
      className={`logout-cont ${props.showModal && "logout-cont--show"}`}>
      <span onClick={() => toggleChangePass(true)}>
        {languages[lang].changePass} <img src={Lock} />
      </span>
      <span onClick={logOut}>
        {languages[lang].logout} <img src={Logout} />
      </span>
      <ChangePass
        open={openChangePass}
        toggleModal={() => toggleChangePass(false)}
      />
      {redirect ? <Navigate to='/' /> : null}
    </div>
  );
}
