/** @format */

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import NavbarAndSidebarCont from "../../Components/Shared/NavbarAndSidebarCont";
import notificationIcon from "../../Assets/Images/Group 39851.png";
import seenNotification from "../../Assets/Images/Group 39848.png";
import { HubConnectionBuilder } from "@microsoft/signalr";
import { languages } from "../../Constants/Languages";
import "./index.scss";
import Pagination from "../../Components/Shared/Pagination";
export default function NotificationPage() {
  const [notificationObj, setNotification] = useState({});
  const [connection, setConnection] = useState();
  const [pageNum, setPageNum] = useState(1);
  const { lang, dir, isOpen } = useSelector((state) => state.lang);
  useEffect(() => {
    var newConnection = new HubConnectionBuilder()
      .withUrl("https://api.q8qamous.com/hubs/notification", {
        accessTokenFactory: () =>
          JSON.parse(localStorage.getItem("userRole"))?.token,
      })
      .withAutomaticReconnect()
      .build();
    setConnection(newConnection);
    newConnection.on("addNotification", function (notification) {
      let notificationsclone = { ...notificationObj };
      notificationsclone.notification = [
        ...notificationsclone.notification,
        notification,
      ];
      setNotification(notificationsclone);
    });

    newConnection?.start().then((result) => {
      newConnection.invoke("getNotificationsPage", 1, 10).then((value) => {
        setNotification(value);
      });
    });
  }, []);
  const getNotificationPage = (e) => {
    setPageNum(e);
    connection?.invoke("getNotificationsPage", e, 10).then((value) => {
      setNotification(value);
    });
  };

  useEffect(() => {
    let unSeenNotificationIds = notificationObj.notification
      ?.filter((item) => !item.isSeen)
      .map((notification) => notification.id);
    if (unSeenNotificationIds && unSeenNotificationIds.length > 0) {
      connection
        ?.invoke("markNotificationsAsSeen", unSeenNotificationIds)
        .catch(function (err) {
          return console.error(err.toString());
        });
      getNotificationPage(pageNum);
    }
  }, [notificationObj]);

  const renderNotificationRow = (notification) => {
    return (
      <Link to={notification.url} key={notification.id}>
        <div
          className={`notifications-cont__notification notificationPageCont__customStyle ${
            notification.isSeen && "notifications-cont__notification--seen"
          }`}
        >
          <img
            src={notification.isSeen ? seenNotification : notificationIcon}
          />
          <div className="notifications-cont__notification__info">
            <span className="notifications-cont__notification__info__msg">
              {notification?.[lang]}
            </span>
            <div className="notifications-cont__notification__info__date">
              <span>
                {new Date(notification.createdAt).toLocaleDateString("en-US")}
              </span>{" "}
              &nbsp;
              <span>
                {new Date(notification.createdAt).toLocaleTimeString()}
              </span>{" "}
            </div>
          </div>
        </div>
      </Link>
    );
  };
  return (
    <NavbarAndSidebarCont
      notificationPage
      notificationObj={notificationObj}
      getNotificationPage={getNotificationPage}
      connection={connection}
    >
      <div className="notificationPageCont" lang={lang} dir={dir}>
        <h3>{languages[lang].allNotification}</h3>
        <div>
          {notificationObj.notification?.map((notification) =>
            renderNotificationRow(notification)
          )}
        </div>
      </div>
      {notificationObj?.pagination ? (
        JSON.parse(notificationObj?.pagination).TotalPages > 1 ? (
          <Pagination
            pagination={
              notificationObj?.pagination
                ? JSON.parse(notificationObj?.pagination)
                : {}
            }
            getPageData={getNotificationPage}
          />
        ) : null
      ) : null}
    </NavbarAndSidebarCont>
  );
}
