/** @format */

import {
  setCompaniesAppliedReq,
  setReqStatus,
  setClients,
  setCompanies,
  setClientOrders,
  setCompaniesOrders,
  setClientsOrders,
  setSystemAdmins,
  setAdminPermissions,
  setTranslatorsList,
  setTranslatorInfo,
  setTranslatorOrders,
  setLangPricing,
  setDeliveryOrdersList,
  setAdminUserPermissions,
  setCompanyOrders,
  setTransactions,
} from "../Redux/admin";
import {
  setPackages,
  setBundleTypes,
  setIndustries,
  setTranslationFields,
  setLanguages,
  setOrderTypes,
} from "../Redux/commonData";
import { deleteReq, getReq, postReq, putReq } from "../Services/APIMethods";
import { getPackagesServices } from "../Services/commonServices";
export const adminActions = {
  getPackages,
  deletePackageAction,
  getBundleTypes,
  addPackageAction,
  getComapniesAppliedRequests,
  getCompanyIndustries,
  getCompanyReqStatus,
  updateCompanyReqStatus,
  addLangAction,
  createCompany,
  addLangPriceAction,
  getTranslationFieldsAction,
  getLanguagesAction,
  addTranslatorAction,
  addSuperAdminAction,
  getClientsAction,
  getCompaniesAction,
  getClientOrdersAction,
  getCompaniesOrdersAction,
  getClientsOrdersAction,
  getOrderTypesAction,
  getSystemAdminsAction,
  getAdminPermissionsAction,
  editAdminPermissionsAction,
  getTranslatorsAction,
  getTranslatorInfoAction,
  getTranslatorOrdersAction,
  editTranslatorInfoAction,
  changeOrderStatusAction,
  assignOrderToTranslatorAction,
  getLangPricingAction,
  getWordPricingAction,
  getDeliveryOrdersAction,
  getAdminUserPermissionsAction,
  getCompanyOrderAction,
  deleteTranslationField,
  getTransactionsAdmin,
};
function getPackages() {
  return async (dispatch) => {
    let response = await getPackagesServices();

    if (response.data.isSuccess) {
      dispatch(setPackages(response.data.data));
    }
  };
}
function getBundleTypes() {
  return async (dispatch) => {
    let response = await getReq("Bundles/get-bundle-types");
    if (response.data.isSuccess) {
      dispatch(
        setBundleTypes(
          response.data.data.map((type) => ({
            value: type.id,
            label: { ar: type.ar, en: type.en },
          }))
        )
      );
    }
  };
}
function addPackageAction(params) {
  return async (dispatch) => {
    let response = await postReq("Bundles/add-bundle", params);
    if (response.data.isSuccess) {
      // dispatch(setPackages(packages.filter((item) => item.id !== params)));
    }
    return response;
  };
}
function deletePackageAction(params, packages) {
  return async (dispatch) => {
    let response = await deleteReq("Bundles/add-bundle" + `?id=${params}`);
    if (response.data.isSuccess) {
      dispatch(setPackages(packages.filter((item) => item.id !== params)));
    }
    return response;
  };
}
function deleteTranslationField(params) {
  return async (dispatch) => {
    let response = await deleteReq(
      "OrderTypes/delete-order-type" + `?id=${params}`
    );

    return response;
  };
}

function getComapniesAppliedRequests(pageNum, searchString) {
  return async (dispatch) => {
    let response = await getReq(
      `CompanyRequests/get-company-requests?${searchString ?? ""}pageNum=${
        pageNum ?? 1
      }&pageSize=10`
    );
    if (response.data.isSuccess) {
      let data = prepareDataWithPagination(response);
      dispatch(setCompaniesAppliedReq(data));
    }
  };
}
function getCompanyIndustries() {
  return async (dispatch) => {
    let response = await getReq("CompanyRequests/get-company-Industries");
    if (response.data.isSuccess) {
      dispatch(setIndustries(response.data.data));
    }
  };
}
function getCompanyReqStatus() {
  return async (dispatch) => {
    let response = await getReq("CompanyRequests/get-request-statuses");
    if (response.data.isSuccess) {
      dispatch(setReqStatus(response.data.data));
    }
  };
}
function updateCompanyReqStatus(params) {
  return async (dispatch) => {
    let response = await putReq(
      "CompanyRequests/update-request-status",
      params
    );

    return response;
  };
}
function addLangAction(params) {
  return async (dispatch) => {
    let response = await postReq("Languages", params);

    return response;
  };
}
function getOrderTypesAction() {
  return async (dispatch) => {
    let response = await getReq("OrderTypes/get-order-types");
    if (response.data.isSuccess) {
      dispatch(
        setOrderTypes(
          response.data.data.map((type) => ({
            ...type,
            value: type.id,
            label: { ar: type.ar, en: type.en },
          }))
        )
      );
    }
  };
}
function getTranslationFieldsAction() {
  return async (dispatch) => {
    let response = await getReq("TranslationFields/get-translation-fields");
    if (response.data.isSuccess) {
      dispatch(
        setTranslationFields(
          response.data.data.map((type) => ({
            ...type,
            value: type.id,
            label: { ar: type.ar, en: type.en },
          }))
        )
      );
    }
  };
}
function getLanguagesAction() {
  return async (dispatch) => {
    let response = await getReq("Languages/get-languages");
    if (response.data.isSuccess) {
      dispatch(
        setLanguages(
          response.data.data.map((type) => ({
            ...type,
            value: type.id,
            label: { ar: type.name, en: type.name },
          }))
        )
      );
    }
  };
}
async function createCompany(params) {
  let response = await postReq("Companies/admin-create-company", params);

  return response;
}
async function addLangPriceAction(params) {
  let response = await postReq("WordPrices/add-word-prices", params);

  return response;
}
async function addTranslatorAction(params) {
  let response = await postReq("Translators/create-translator", params);

  return response;
}
async function addSuperAdminAction(params) {
  let response = await postReq("Users/admin/create-admin", params);

  return response;
}
function getClientsAction(pageNum, searchString) {
  return async (dispatch) => {
    let response = await getReq(
      `Users/get-clients?${searchString ?? ""}pageNumber=${pageNum}&pageSize=10`
    );
    if (response.data.isSuccess) {
      let data = prepareDataWithPagination(response);
      dispatch(setClients(data));
    }
  };
}
function getClientOrdersAction(userId, pageNum, pageSize) {
  return async (dispatch) => {
    let response = await getReq(
      `Users/get-client-orders?userId=${userId}&pageNumber=${pageNum}&pageSize=${pageSize}`
    );
    if (response.data.isSuccess) {
      let data = prepareDataWithPagination(response);
      dispatch(setClientOrders(data));
    }
  };
}
function getClientsOrdersAction(pageNum, searchString) {
  return async (dispatch) => {
    dispatch(setClientsOrders([]));
    let response = await getReq(
      `Orders/admin-get-clients-orders?${
        searchString ?? ""
      }pageNumber=${pageNum}&pageSize=10`
    );
    if (response.data.isSuccess) {
      let data = prepareDataWithPagination(response);

      dispatch(setClientsOrders(data));
    }
  };
}
function getCompaniesOrdersAction(pageNum, searchString) {
  return async (dispatch) => {
    let response = await getReq(
      `Orders/admin-get-companies-orders?${
        searchString ?? ""
      }pageNumber=${pageNum}&pageSize=10`
    );
    if (response.data.isSuccess) {
      let data = prepareDataWithPagination(response);
      dispatch(setCompaniesOrders(data));
    }
  };
}
function getCompaniesAction(pageNum, searchString) {
  return async (dispatch) => {
    let response = await getReq(
      `Companies/admin-get-companies?${
        searchString ?? ""
      }pageNum=${pageNum}&pageSize=10`
    );
    if (response.data.isSuccess) {
      let data = prepareDataWithPagination(response);
      dispatch(setCompanies(data));
    }
  };
}
function getSystemAdminsAction(pageNum) {
  return async (dispatch) => {
    let response = await getReq(
      `Users/admin/get-system-admins?pageNum=${pageNum}&pageSize=10`
    );
    if (response.data.isSuccess) {
      let data = prepareDataWithPagination(response);
      dispatch(setSystemAdmins(data));
    }
  };
}
function getAdminPermissionsAction(id) {
  return async (dispatch) => {
    let response = await getReq(
      `Users/admin/get-admin-permisions?adminId=${id}`
    );
    if (response.data.isSuccess) {
      dispatch(setAdminPermissions(response.data.data.permissions));
    }
  };
}
async function editAdminPermissionsAction(params) {
  let response = await postReq(`Users/admin/edit-admin`, params);
  return response;
}
function getTranslatorsAction(pageNum, searchString, fields) {
  searchString = searchString?.replace("searchKey=", "");
  searchString = searchString?.slice(0, -1);

  let params = {
    pageSize: 10,
    pageNum: pageNum,
    searchKeyWord: searchString?.trim() ?? "",
    ...fields,
  };

  return async (dispatch) => {
    let response = await postReq(
      `Translators/admin-get-translators`,
      params
      // params ?? {}
    );
    if (response.data.isSuccess) {
      // let data = prepareDataWithPagination(response);

      let data = prepareDataWithPagination(response);
      data.data = data.data.map((item) => ({
        ...item,
        label: { ar: item.fullName, en: item.fullName },
        value: item.id,
      }));

      dispatch(setTranslatorsList(data));
    }
  };
}
function getTranslatorInfoAction(id) {
  return async (dispatch) => {
    let response = await getReq(
      `Translators/admin/get-translator-info?translatorId=${id}`
    );
    if (response.data.isSuccess) {
      // let data = prepareDataWithPagination(response);

      dispatch(setTranslatorInfo(response.data.data));
    }
  };
}
function getTranslatorOrdersAction(id, pageNum) {
  return async (dispatch) => {
    let response = await getReq(
      `Translators/admin/get-translator-orders?translatorId=${id}&pageNumber=${pageNum}&pageSize=10`
    );
    if (response.data.isSuccess) {
      // let data = prepareDataWithPagination(response);

      dispatch(setTranslatorOrders(response.data.data));
    }
  };
}
async function editTranslatorInfoAction(params) {
  let response = await putReq(
    `Translators/admin/update-translator-info`,
    params
  );
  return response;
}
async function changeOrderStatusAction(orderId, statusId, orderSource) {
  let response = await putReq(
    `Orders/admin/update-order-status?orderId=${orderId}&statusId=${statusId}&orderSource=${orderSource}`,
    {}
  );
  return response;
}
async function assignOrderToTranslatorAction(
  orderId,
  translatorId,
  orderSource
) {
  let response = await putReq(
    `Orders/admin/assign-order-to-translator?orderId=${orderId}&translatorId=${translatorId}&orderSource=${orderSource}`,
    {}
  );
  return response;
}
function getLangPricingAction(pageNum, searchString) {
  return async (dispatch) => {
    let response = await getReq(
      `Languages/admin/get-wordprice-languages?${
        searchString ?? ""
      }pageNumber=${pageNum}&pageSize=10`
    );
    if (response.data.isSuccess) {
      let data = prepareDataWithPagination(response);

      dispatch(setLangPricing(data));
    }
  };
}
function getDeliveryOrdersAction(pageNum, searchString) {
  return async (dispatch) => {
    dispatch(setDeliveryOrdersList([]));
    let response = await getReq(
      `Orders/admin/get-delivery-orders?${
        searchString ?? ""
      }pageNumber=${pageNum}&pageSize=10`
    );
    if (response.data.isSuccess) {
      let data = prepareDataWithPagination(response);

      dispatch(setDeliveryOrdersList(data));
    }
  };
}
function getCompanyOrderAction(pageNum, companyId) {
  return async (dispatch) => {
    let response = await getReq(
      `Companies/admin/get-company-order?companyId=${companyId}pageNumber=${pageNum}&pageSize=10`
    );
    if (response.data.isSuccess) {
      let data = prepareDataWithPagination(response);

      dispatch(setCompanyOrders(data));
    }
  };
}
function getAdminUserPermissionsAction() {
  return async (dispatch) => {
    dispatch(setDeliveryOrdersList([]));
    let response = await getReq(`Users/get-user-permissions`);

    if (response.data.isSuccess) {
      dispatch(setAdminUserPermissions(response.data.data.permissions));
    }
  };
}
async function getWordPricingAction(langFrom, langTo) {
  let response = await getReq(
    `WordPrices/get-word-prices?fromLang=${langFrom}&toLang=${langTo}`
  );
  return response;
}

const prepareDataWithPagination = (response) => {
  return {
    pagination: JSON.parse(response.headers?.["x-pagination"]),
    data: response.data.data,
  };
};
function getTransactionsAdmin(pageNum, searchString) {
  return async (dispatch) => {
    dispatch(setTransactions([]));
    let response = await getReq(
      `Transactions/admin-get-transactions?${
        searchString ?? ""
      }pageNumber=${pageNum}&pageSize=10`
    );
    if (response.data.isSuccess) {
      let data = prepareDataWithPagination(response);

      dispatch(setTransactions(data));
    }
  };
}
