/** @format */

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "../../../Components/Shared/Select";
import CustomNavbar from "../../../Components/Shared/CustomNavbar";
import Sidebar from "../../../Components/Shared/Sidebar";
import { languages } from "../../../Constants/Languages";
import { prepareDashboardSideBar } from "../../../Constants/PrepareData";
import WightArrow from "../../../Assets/Images/Icon ionic-ios-arrow-up white.svg";
import DownloadImg from "../../../Assets/Images/Icon feather-download-cloud.svg";
import DownloadImgAfter from "../../../Assets/Images/Icon ionic-ios-cloud-download.svg";
import "./index.scss";
import CancelModal from "../../../Components/Modals/CancelModal";
import OrderDetail from "../../../Components/Shared/OrderDetails";
import NavbarAndSidebarCont from "../../../Components/Shared/NavbarAndSidebarCont";
import { adminActions } from "../../../actions/adminActions";
import { commonActions } from "../../../actions/commonActions";
import { bindActionCreators } from "@reduxjs/toolkit";
import Status from "../../../Components/Shared/Status";
import { prepareDate } from "../../../Constants/commonFunc";
export default function Order(props) {
  const [loading, setLoading] = useState(true);
  const [isOpen, setOpen] = useState({
    successModal: false,
    failModal: false,
    cancelModal: false,
  });
  const [userType, setUserType] = useState("");
  const [modalMsg, setModalMsg] = useState("");
  const [orderData, setOrderData] = useState({});
  const [updatesKeys, addUpdatesKeys] = useState([]);
  const [changes, setChangedValues] = useState({
    status: null,
    translator: null,
  });
  let dispatch = useDispatch();
  let { getTranslatorsAction } = bindActionCreators(adminActions, dispatch);
  let { lang, dir } = useSelector((state) => state.lang);
  let { orderStatuses } = useSelector((state) => state.commonData);
  let { translatorsList, adminUserPermissions } = useSelector(
    (state) => state.admin
  );
  console.log(translatorsList);
  useEffect(() => {
    let userData = JSON.parse(localStorage.getItem("userRole"));
    if (userData.roles[0] === "User") {
      setUserType("customer");
    } else if (userData.roles[0] === "Translator") {
      setUserType("translator");
    } else if (userData.roles[0] === "CompanyAdmin") {
      setUserType("company");
    }

    let order = JSON.parse(localStorage.getItem("orderDetails"));

    let params = {
      languages: [order?.fromLanguageId, order?.toLanguageId],
      fields: [order?.translationFieldId],
    };
    getTranslatorsAction(1, null, params);
    setOrderData(order);
  }, []);
  useEffect(() => {
    if (orderStatuses.length) {
      let changedDataClone = { ...changes };
      setLoading(false);

      let selectedStatus = orderStatuses.find(
        (status) => status.value === orderData.statusId
      );

      changedDataClone = { ...changedDataClone, status: selectedStatus };
      if (translatorsList.data?.length) {
        let selectedTranslator = translatorsList.data.find(
          (item) => item.fullName === orderData.translatorName
        );
        changedDataClone = {
          ...changedDataClone,
          translator: selectedTranslator,
        };
      }
      setChangedValues(changedDataClone);
    }

    // setOrderData(orderDataClone);
  }, [orderStatuses, translatorsList.data]);
  const getChanges = (key, value) => {
    addUpdatesKeys([...updatesKeys, key]);
    setChangedValues({ ...changes, [key]: value });
  };
  const toggleModal = (stateName) => {
    setOpen({
      ...isOpen,
      successModal: false,
      failModal: false,
      cancelModal: false,
      [stateName]: !isOpen[stateName],
    });
    if (stateName === "successModal" && isOpen.successModal) {
      isOpen({ successModal: false, failModal: false, cancelModal: false });
    }
  };
  const changeSessionData = (statusId) => {
    let order = { ...orderData };
    let selectedStatus = orderStatuses.find((status) => status.id === statusId);
    getChanges({ ...changes, status: selectedStatus });
    order.statusId = selectedStatus.id;
    order.status = selectedStatus[lang];
    localStorage.setItem("orderDetails", JSON.stringify(order));
  };
  const handelAPIRes = (res, modalName) => {
    toggleModal(modalName);
    setModalMsg(
      res?.message || modalName === "successModal"
        ? res?.message[lang]
        : languages[lang].wentWrong
    );
  };
  const cancelOrder = async () => {
    setLoading(true);
    setLoading(true);
    let response = await adminActions.changeOrderStatusAction(
      orderData.id,
      "36b3787e-7a7e-4ca1-a3c2-0e549c277c32",
      orderData.companyName ? "Company" : "User"
    );

    if (response.data?.isSuccess) {
      changeSessionData("36b3787e-7a7e-4ca1-a3c2-0e549c277c32");
      handelAPIRes(response.data, "successModal");
      window.location.reload();
    } else {
      handelAPIRes(response.data, "failModal");
    }

    setLoading(false);
  };
  const saveChanges = async () => {
    setLoading(true);
    let statusRes = {};
    let translatorRes = {};
    if (updatesKeys.includes("status")) {
      let response = await adminActions.changeOrderStatusAction(
        orderData.id,
        changes.status.id,
        orderData.companyName ? "Company" : "User"
      );
      statusRes = response;
      if (response.data?.isSuccess) {
        changeSessionData(changes.status.id);
      }
    }
    if (updatesKeys.includes("translator")) {
      let response = await adminActions.assignOrderToTranslatorAction(
        orderData.id,
        changes.translator.id,
        orderData.companyName ? "Company" : "User"
      );
      translatorRes = response;
      // if (response.data?.isSuccess) {
      //   handelAPIRes(response.data, "successModal");
      // } else {
      //   handelAPIRes(response.data, "failModal");
      // }
    }

    if (
      (updatesKeys.includes("translator")
        ? translatorRes.data?.isSuccess
        : true) &&
      (updatesKeys.includes("status") ? statusRes.data?.isSuccess : true)
    ) {
      handelAPIRes(
        updatesKeys.data
          ? updatesKeys.data
          : translatorRes.data
          ? translatorRes.data
          : statusRes.data,
        "successModal"
      );
    } else {
      handelAPIRes(statusRes.data, "failModal");
    }
    setLoading(false);
  };
  const downloadFile = async (id) => {
    setLoading(true);
    let res = await commonActions.cancelOrderAction(id);
    if (res.isSuccess) {
      const element = document.createElement("a");
      element.href = res.data.url;
      element.target = "_blank";
      element.download = "SamplePDF.pdf";
      element.click();
      // const element = document.createElement("a");
      // element.href = URL.createObjectURL(new Blob([res.data.url]));
      // element.setAttribute("download", "file.pdf");
      // document.body.appendChild(element);
      // element.click();
    } else {
      toggleModal("failModal");
      setModalMsg(
        res?.message ? res?.message[lang] : languages[lang].wentWrong
      );
    }
    setLoading(false);
  };

  const getOptions = () => {
    if (!orderData.withDelivery) {
      return orderStatuses && orderStatuses.length > 0
        ? orderStatuses.filter(
            (res) =>
              res.id !== "fe6c21ec-ee70-46d2-9789-105428ef93ec" &&
              res.id !== "75943a58-8719-4750-bf47-eab38fc33265"
          )
        : [];
    } else {
      return orderStatuses;
    }
  };
  return (
    <div>
      <CancelModal
        toggleModal={toggleModal}
        modalName="cancelModal"
        open={isOpen.cancelModal}
        deleteAction={cancelOrder}
      />
      <NavbarAndSidebarCont
        userSidebar={userType}
        isOpen={isOpen}
        loading={loading}
        toggleModal={toggleModal}
        modalMsg={modalMsg} /* userSidebar='translator' */
      >
        <div dir={dir} lang={lang} className={`order-cont__info-cont`}>
          <div className="order-cont__info-cont__header">
            <h4>
              {languages[lang].orderInfo}{" "}
              <span dir={dir} lang={lang}>
                (#{orderData.id})
              </span>
            </h4>
            {props.hideSelect ? (
              <div className="order-cont__info-cont__header__status-cont">
                <Status data={orderData.status} removeArrow showMsg />
              </div>
            ) : null}
            <div className="order-cont__info-cont__header__actions">
              {props.hideSelect ||
              !adminUserPermissions.includes("PermissionOrdersEdit") ? null : (
                <>
                  {" "}
                  <Select
                    value={changes.status}
                    getChanges={getChanges}
                    stateName="status"
                    options={getOptions()}
                    disabled={
                      orderData.statusId ==
                      "36b3787e-7a7e-4ca1-a3c2-0e549c277c32"
                    }
                    icon={WightArrow}
                    customStyle="orderCustomStyle"
                    placeholder={languages[lang].chooseTranslatorName}
                  />
                  <Select
                    value={changes.translator}
                    getChanges={getChanges}
                    disabled={
                      orderData.statusId ==
                      "36b3787e-7a7e-4ca1-a3c2-0e549c277c32"
                    }
                    stateName="translator"
                    options={translatorsList.data}
                    placeholder={languages[lang].chooseTranslatorName}
                    icon={WightArrow}
                    translators
                    customStyle="orderCustomStyle"
                  />
                </>
              )}
              {adminUserPermissions.includes("PermissionOrdersDelete") &&
              orderData.statusId !== "3f9fa7c7-12ab-4291-b1cd-865e8e12abd7" ? (
                <button
                  disabled={
                    orderData.statusId == "36b3787e-7a7e-4ca1-a3c2-0e549c277c32"
                  }
                  onClick={() => toggleModal("cancelModal")}
                >
                  {languages[lang].cancelOrder}
                </button>
              ) : null}
            </div>
          </div>
          <div className="order-cont__info-cont__res-status-cont">
            <Status data={orderData.status} removeArrow showMsg />
          </div>
          <OrderDetail orderData={orderData} /* userSidebar */ />
        </div>
        <div className="order-cont__footer" dir={dir} lang={lang}>
          {props.hideSelect ||
          !adminUserPermissions.includes("PermissionOrdersEdit") ? null : (
            <button onClick={saveChanges}>{languages[lang].saveChanges}</button>
          )}
          <div
            className="order-cont__footer__downloadFile"
            onClick={() => downloadFile(orderData.documentBeforeId)}
          >
            <img src={DownloadImg} />
            <span>{languages[lang].downloadFile}</span>
          </div>
          {orderData.documentAfterId ? (
            <div
              className="order-cont__footer__downloadِAndUploadFile__downloadFile order-cont__footer__downloadِAndUploadFile__downloadFile--afterFile"
              onClick={() => downloadFile(orderData.documentAfterId)}
            >
              <img src={DownloadImgAfter} />
              <span>{languages[lang].downloadFileAfterTranslation}</span>
              <span>{prepareDate(orderData.fininshedAt)}</span>
            </div>
          ) : null}
        </div>
      </NavbarAndSidebarCont>
    </div>
  );
}
