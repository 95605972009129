/** @format */

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
  orders: {},
  bundleInfo: {},
  companyUsers: {},
};

export const companySlice = createSlice({
  name: "company",
  initialState,
  reducers: {
    setCompanyOrders: (state, action) => {
      state.orders = action.payload;
    },
    setCompanyBundleInfo: (state, action) => {
      state.bundleInfo = action.payload;
    },
    setCompanyUsers: (state, action) => {
      state.companyUsers = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setCompanyOrders, setCompanyBundleInfo, setCompanyUsers } =
  companySlice.actions;

export default companySlice.reducer;
