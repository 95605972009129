/** @format */

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import CustomerForm from "../../../../Components/AdminComponents/CutomerForm";
import CustomNavbar from "../../../../Components/Shared/CustomNavbar";
import Sidebar from "../../../../Components/Shared/Sidebar";
import { languages } from "../../../../Constants/Languages";
import { prepareDashboardSideBar } from "../../../../Constants/PrepareData";
import UserIcon from "../../../../Assets/Images/Icon awesome-user-alt.svg";
import phoneIcon from "../../../../Assets/Images/Icon ionic-md-phone-portrait.svg";
import EyeIcon from "../../../../Assets/Images/Icon ionic-ios-eye.svg";
import EmailIcon from "../../../../Assets/Images/Icon ionic-ios-mail.svg";
import NavbarAndSidebarCont from "../../../../Components/Shared/NavbarAndSidebarCont";
export default function AddNewCustomer() {
  const [loading, setLoading] = useState(false);
  const [isOpen, setOpen] = useState({ successModal: false, failModal: false });
  const [modalMsg, setModalMsg] = useState("");
  const [userFields, setUserFields] = useState([]);
  const [emailField, setEmailField] = useState({});
  const [userData, setUserData] = useState({
    name: "",
    phone: "",
    email: "",
  });
  const [errors, setErrors] = useState({
    name: null,
    mobile: null,
    email: null,
  });
  let { lang, dir } = useSelector((state) => state.lang);
  useEffect(() => {
    let userFieldsConst = [
      {
        title: languages[lang].name,
        key: "name",
        icon: UserIcon,
        placeholder: languages[lang].pleaseWriteName,
      },
      {
        title: languages[lang].phoneNum,
        key: "phone",
        icon: phoneIcon,
        placeholder: languages[lang].pleaseWritePhone,
        type: "number",
      },
    ];

    setEmailField({
      title: languages[lang].email,
      key: "email",
      icon: EmailIcon,
      placeholder: languages[lang].pleaseWriteEmail,
    });

    setUserFields(userFieldsConst);
  }, [lang]);
  const toggleLoading = (flag) => {
    setLoading(flag);
  };
  const getModalMsg = (msg) => {
    setModalMsg(msg);
  };
  const toggleModal = (stateName) => {
    setOpen({ ...isOpen, [stateName]: !isOpen[stateName] });
  };
  const getChanges = (value, stateName) => {
    setErrors({ ...errors, [stateName]: null });
    setUserData({ ...userData, [stateName]: value });
  };
  return (
    <NavbarAndSidebarCont
      isOpen={isOpen}
      loading={loading}
      toggleModal={toggleModal}
      modalMsg={modalMsg}>
      <div
        className={`cutomer-details ${
          isOpen && "cutomer-details--apply-margin"
        }`}
        lang={lang}
        dir={dir}>
        <h4>{languages[lang].addNewCustomer}</h4>
        <CustomerForm
          data={userData}
          userFieldsConst={userFields}
          emailField={emailField}
          getChanges={getChanges}
        />
      </div>
    </NavbarAndSidebarCont>
  );
}
