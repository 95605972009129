/** @format */

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import addIcon from "../../../../Assets/Images/Group 2784.svg";
import wightAdd from "../../../../Assets/Images/Group 2784.png";
import { languages } from "../../../../Constants/Languages";
import { prepareOptions } from "../../../../Constants/commonFunc";
import NavbarAndSidebarCont from "../../../../Components/Shared/NavbarAndSidebarCont";
import RequestsSearchbar from "../../../../Components/AdminComponents/RequestsSearchbar";
import CustomersTable from "../../../../Components/Shared/CustomersTable";
import Pagination from "../../../../Components/Shared/Pagination";
import { getLanguages } from "../../../../Services/commonServices";
import { setLanguages } from "../../../../Redux/commonData";
import { deleteLanguageService } from "../../../../Services/adminServices";
import { bindActionCreators } from "@reduxjs/toolkit";
import { adminActions } from "../../../../actions/adminActions";

export default function LangPricing(props) {
  const [id, setId] = useState();
  const [searchValue, setSearch] = useState("");
  const [isModalOpen, setModalOpen] = useState({ cancelModal: false });
  const [isHoveringBtn1, toggleHoverBtn1] = useState(false);
  const [isHoveringBtn2, toggleHoverBtn2] = useState(false);
  const [languagesList, setLanguagesList] = useState([]);
  const [pagination, setPaginationData] = useState({});
  const [tableHeaderConstatnt, setTableHeader] = useState([]);
  let { lang, dir } = useSelector((state) => state.lang);
  let { languagesData } = useSelector((state) => state.commonData);
  let { langPricingList } = useSelector((state) => state.admin);

  const dispatch = useDispatch();
  let { getLangPricingAction } = bindActionCreators(adminActions, dispatch);
  useEffect(() => {
    props.toggleLoading(true);
    getLangPricingAction(1);
  }, []);
  useEffect(() => {
    if (langPricingList.data?.length) {
      props.toggleLoading(false);
      let languagesListClone = languagesData.map((language) => ({
        ...language,
        value: language.id,
        name: language.label.ar,
      }));
      let tableHeader = [
        { title: languages[lang].translateFrom, key: "fromLanguageName" },
        { title: languages[lang].translateTo, key: "toLanguageName" },
      ];

      setTableHeader(tableHeader);
      setPaginationData(langPricingList.pagination);
      setLanguagesList(langPricingList.data);
    }
  }, [langPricingList]);
  const toggleCancelModal = (modalName, event, id) => {
    setId(id);

    setModalOpen({ ...isModalOpen, [modalName]: !isModalOpen[modalName] });
    if (event) {
      event.preventDefault();
    }
  };
  const getPageData = async (text) => {
    props.toggleLoading(true);
    let res = await getLangPricingAction(text, searchValue);
    props.toggleLoading(false);
  };
  const getSearchValues = async (searchString) => {
    props.toggleLoading(true);
    setSearch(searchString);
    let res = await getLangPricingAction(1, searchString);
    props.toggleLoading(false);
  };
  const deleteLanguage = async (id) => {
    props.toggleLoading(true);
    let response = await deleteLanguageService(id);
    if (response.data?.isSuccess) {
      toggleCancelModal("cancelModal");
      let newLanguagesDataList = languagesData.filter(
        (language) => language.id !== id
      );
      dispatch(setLanguages(newLanguagesDataList));
      props.toggleModal("successModal");
      props.getModalMsg(response.data.message[lang]);
    } else {
      props.toggleModal("failModal");
      props.getModalMsg(
        response.data?.message
          ? response.data?.message[lang]
          : languages[lang].wentWrong
      );
    }
    props.toggleLoading(false);
  };
  return (
    <div>
      <div className='customers-cont__body__info' dir={dir} lang={lang}>
        <h4>{languages[lang].languagePricing}</h4>
        <div className='customers-cont__body__info__langBtns'>
          {props.canEdit ? (
            <>
              {" "}
              <Link to='/dashboard/settings/langPrice/add'>
                <button
                  onMouseEnter={() => toggleHoverBtn1(true)}
                  onMouseLeave={() => toggleHoverBtn1(false)}>
                  {" "}
                  <img src={isHoveringBtn1 ? wightAdd : addIcon} />{" "}
                  {languages[lang].addLangPricing}{" "}
                </button>
              </Link>{" "}
              &nbsp;&nbsp;
              <Link to='/dashboard/settings/langPrice/add-lang'>
                <button
                  onMouseEnter={() => toggleHoverBtn2(true)}
                  onMouseLeave={() => toggleHoverBtn2(false)}>
                  <img src={isHoveringBtn2 ? wightAdd : addIcon} />
                  {languages[lang].addLang}
                </button>
              </Link>{" "}
            </>
          ) : null}
        </div>
      </div>
      <RequestsSearchbar searchOnly getSearchValues={getSearchValues} />
      <CustomersTable
        toggleModal={toggleCancelModal}
        isOpen={isModalOpen.cancelModal}
        id={id}
        agents={languagesList}
        url='settings/langPrice'
        tableHeaderConstatnt={tableHeaderConstatnt}
        msg={languages[lang].deletePricing}
        loading={props.loading}
        deleteAction={deleteLanguage}
        saveToSessionStorege='langData'
        disableRouting={!props.canEdit}
        removeDelete
      />

      {pagination.TotalPages < 2 ? null : (
        <Pagination pagination={pagination} getPageData={getPageData} />
      )}
    </div>
  );
}
