/** @format */

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { languages } from "../../../Constants/Languages";
import NavbarAndSidebarCont from "../../../Components/Shared/NavbarAndSidebarCont";
import LangPricing from "./LangPricing";
import LangFieldsPricing from "./LangFieldsPricing";
import DeliveryPricing from "./DeliveryPricing";
import TranslationAreas from "./TranslationAreas";
import AdminUsers from "./AdminUsers";
import {
  getAddOns,
  getCities,
  getOrderTypes,
  getTranslationFields,
  getUserPerissions,
} from "../../../Services/commonServices";
import {
  setAddOns,
  setCities,
  setOrderTypes,
  setTranslationFields,
} from "../../../Redux/commonData";
import Loader from "../../../Components/Shared/Loader";
import AddOns from "./AddOns";
import SuccessAndFailModal from "../../../Components/Modals/SuccessAndFailModal";
import { prepareOptions } from "../../../Constants/commonFunc";
export default function Settings(props) {
  const [loading, setLoading] = useState(false);
  const [isOpen, setOpen] = useState({ successModal: false, failModal: false });
  const [modalMsg, setModalMsg] = useState("");
  let { lang, dir, selectedOption } = useSelector((state) => state.lang);
  let { adminUserPermissions } = useSelector((state) => state.admin);
  let dispatch = useDispatch();
  useEffect(() => {
    (async () => {
      getOrderTypes().then((typesRes) => {
        if (typesRes.data.isSuccess) {
          let options = prepareOptions(typesRes.data.data);
          dispatch(setOrderTypes(options));
        }
      });
      getAddOns().then((res) => {
        if (res.data.isSuccess) {
          let options = prepareOptions(res.data.data);
          dispatch(setAddOns(options));
        }
      });
      getTranslationFields().then((res) => {
        if (res.data.isSuccess) {
          let options = prepareOptions(res.data.data, "fields");
          dispatch(setTranslationFields(options));
        }
      });
      getCities().then((res) => {
        if (res.data.isSuccess) {
          let options = prepareOptions(res.data.data);
          dispatch(setCities(options));
        }
      });
      getUserPerissions().then((res) => {});
    })();
  }, []);
  const toggleLoading = (flag) => {
    setLoading(flag);
  };
  const getModalMsg = (msg) => {
    setModalMsg(msg);
  };
  const toggleModal = (stateName) => {
    setOpen({ ...isOpen, [stateName]: !isOpen[stateName] });
  };
  // const prepareOptions = (data, fields) => {
  //   let options = [];
  //   data.forEach((item) => {
  //     if (item.additinalCost >= 0) {
  //       item.additionalCost = item.additinalCost;
  //     }
  //     let option = {
  //       label: { ar: item.ar, en: item.en },
  //       value: item.id,
  //       [fields ? "additionalCostPerWord" : "additionalCost"]:
  //         item[fields ? "additionalCostPerWord" : "additionalCost"],
  //     };
  //     options = [...options, option];
  //   });
  //   return options;
  // };
  const renderBody = () => {
    switch (languages[lang][selectedOption]) {
      case languages[lang].languagePricing:
        return (
          <LangPricing
            toggleModal={toggleModal}
            toggleLoading={toggleLoading}
            loading={loading}
            getModalMsg={getModalMsg}
            canEdit={adminUserPermissions.includes("PermissionSystemEdit")}
          />
        );
        break;
      case languages[lang].translationTypePricing:
        return (
          <LangFieldsPricing
            prepareOptions={prepareOptions}
            toggleModal={toggleModal}
            toggleLoading={toggleLoading}
            loading={loading}
            getModalMsg={getModalMsg}
            canEdit={adminUserPermissions.includes("PermissionSystemEdit")}
          />
        );
        break;
      case languages[lang].pricingForTypesOfTranslationServices:
        return (
          <AddOns
            toggleModal={toggleModal}
            toggleLoading={toggleLoading}
            loading={loading}
            getModalMsg={getModalMsg}
            canEdit={adminUserPermissions.includes("PermissionSystemEdit")}
          />
        );
        break;
      case languages[lang].deliveryPricing:
        return (
          <DeliveryPricing
            toggleModal={toggleModal}
            toggleLoading={toggleLoading}
            loading={loading}
            getModalMsg={getModalMsg}
            canEdit={adminUserPermissions.includes("PermissionSystemEdit")}
          />
        );
        break;
      case languages[lang].translationAreas:
        return (
          <TranslationAreas
            toggleModal={toggleModal}
            toggleLoading={toggleLoading}
            loading={loading}
            getModalMsg={getModalMsg}
            canEdit={adminUserPermissions.includes("PermissionSystemEdit")}
          />
        );
        break;
      case languages[lang].adminUsers:
        return (
          <AdminUsers
            toggleModal={toggleModal}
            toggleLoading={toggleLoading}
            loading={loading}
            getModalMsg={getModalMsg}
            canEdit={adminUserPermissions.includes("PermissionSystemEdit")}
          />
        );
        break;
    }
  };
  return (
    <div className='customers-cont'>
      <NavbarAndSidebarCont
        isOpen={isOpen}
        loading={loading}
        toggleModal={toggleModal}
        modalMsg={modalMsg}>
        <div className={`customers-cont__body `}>{renderBody()}</div>
      </NavbarAndSidebarCont>
    </div>
  );
}
