/** @format */

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import CustomerForm from "../../../Components/AdminComponents/CutomerForm";

import { languages } from "../../../Constants/Languages";
import UserIcon from "../../../Assets/Images/Icon awesome-user-alt.svg";
import phoneIcon from "../../../Assets/Images/Icon ionic-md-phone-portrait.svg";
import EyeIcon from "../../../Assets/Images/Icon ionic-ios-eye.svg";
import EmailIcon from "../../../Assets/Images/Icon ionic-ios-mail.svg";
import NavbarAndSidebarCont from "../../../Components/Shared/NavbarAndSidebarCont";
import { clintActions } from "../../../actions/clintActions";
export default function TranslatorProfile() {
  const [loading, setLoading] = useState(false);
  const [isOpen, setOpen] = useState({ successModal: false, failModal: false });
  const [redirect, setRedireact] = useState(false);
  const [modalMsg, setModalMsg] = useState("");
  const [passwordFields, setPasswordFields] = useState([]);
  const [userFields, setUserFields] = useState([]);
  const [emailField, setEmailField] = useState({});
  const [userData, setUserData] = useState({
    name: "",
    phone: "",
    email: "",
  });
  const [errors, setErrors] = useState({
    name: null,
    mobile: null,
    email: null,
  });
  let { lang, dir } = useSelector((state) => state.lang);
  useEffect(() => {
    let userData = JSON.parse(localStorage.getItem("userRole"));

    setUserData({
      fullName: userData.name,
      email: userData.email,
      phoneNumber: userData.phoneNumber,
    });
  }, []);
  useEffect(() => {
    let userFieldsConst = [
      {
        title: languages[lang].name,
        key: "fullName",
        icon: UserIcon,
        placeholder: languages[lang].pleaseWriteName,
      },
      {
        title: languages[lang].phoneNum,
        key: "phoneNumber",
        icon: phoneIcon,
        placeholder: languages[lang].pleaseWritePhone,
        type: "phoneNumber",
      },
    ];

    setEmailField({
      title: languages[lang].email,
      key: "email",
      icon: EmailIcon,
      placeholder: languages[lang].pleaseWriteEmail,
      disabled: true,
    });
    // let passwordFields = [
    //   {
    //     title: languages[lang].password,
    //     key: "password",
    //     icon: EyeIcon,
    //     placeholder: languages[lang].pleaseWritePass,
    //   },
    //   {
    //     title: languages[lang].confirmPass,
    //     key: "confirmPass",
    //     icon: EyeIcon,
    //     placeholder: languages[lang].pleaseWritePass,
    //   },
    // ];
    setUserFields(userFieldsConst);
    // setPasswordFields(passwordFields);
  }, [lang]);
  const toggleModal = (stateName) => {
    setOpen({ ...isOpen, [stateName]: !isOpen[stateName] });
    if (stateName === "successModal" && isOpen.successModal) {
      setRedireact(!redirect);
    }
  };
  const getChanges = (value, stateName) => {
    setErrors({ ...errors, [stateName]: null });
    setUserData({ ...userData, [stateName]: value });
  };
  const editClintInfo = async (params) => {
    setLoading(true);
    let res = await clintActions.editClintInfoAction(params);
    if (res.data.isSuccess) {
      toggleModal("successModal");
      setModalMsg(res.data?.message?.[lang] ?? "Done");
    } else {
      toggleModal("failModal");
      setModalMsg(res.data?.message?.[lang]);
    }
    setLoading(false);
  };
  return (
    <div>
      <NavbarAndSidebarCont
        userSidebar='translator'
        isOpen={isOpen}
        loading={loading}
        toggleModal={toggleModal}
        modalMsg={modalMsg}>
        <div className={`cutomer-details `} lang={lang} dir={dir}>
          <h4>{languages[lang].editProfile}</h4>

          <CustomerForm
            data={userData}
            userFieldsConst={userFields}
            emailField={emailField}
            passFields={passwordFields}
            getChanges={getChanges}
            clintProfile
            action={editClintInfo}
            btnTitle='editProfile'
          />
        </div>
      </NavbarAndSidebarCont>
    </div>
  );
}
