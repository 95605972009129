/** @format */

import Modal from "@mui/material/Modal";
import { useSelector } from "react-redux";
import { forgetPassService } from "../../../Services/commonServices";
import closeIcon from "../../../Assets/Images/Icon ionic-ios-close-circle-outline.svg";

export default function TermsConditionsModal(props) {
  let { lang, dir } = useSelector((state) => state.lang);
  return (
    <Modal open={props.open} onClose={() => props.toggleModal(props.modalName)}>
      <div className="signup-cont new-65 signup-cont--decrease-padding">
        <img
          className="closeIcon"
          src={closeIcon}
          onClick={() => props.toggleModal(props.modalName)}
        />

        {lang == "ar" ? (
          <div style={{ textAlign: "right", direction: "rtl" }}>
            <h1>شروط وأحكام الاستخدام</h1>
            <p><strong>آخر تحديث: 22/7/2024</strong></p>

            <p>مرحبًا بكم في <a href="https://q8qamous.com/">https://q8qamous.com/</a> . المنصة مملوكة ومدارة من قبل كويت قاموس. باستخدام خدماتنا، فإنك توافق على الالتزام بالشروط والأحكام التالية. يرجى قراءتها بعناية.</p>

            <h3>1. مسؤوليات المستخدم</h3>
            <p>1.1. <strong>تقديم الملفات</strong>: يجب على المستخدمين والشركات التأكد من أن الملفات المقدمة واضحة من حيث النصوص. يجب تحديد اللغات المراد الترجمة منها و اليها بشكل صحيح لمنع التقييم الخاطئ للطلب ولتسهيل الترجمة الدقيقة.</p>
            <p>1.2. <strong>قيود المحتوى</strong>:<br />
              - أي ملفات تحتوي على محتوى غير قانوني مثل العري ستؤدي إلى إلغاء الطلب وحظر دائم من المنصة.<br />
              - سيتم ترجمة الملفات ذات المحتوى غير القانوني مثل المواد المسروقة أو الوثائق الاحتيالية، ولكن المنصة ليست مسؤولة عن هذا المحتوى.</p>

            <h3>2. سياسة الاسترداد</h3>
            <p>2.1. <strong>إلغاء الطلب</strong>: يمكن للمستخدمين إلغاء الطلب عن طريق الاتصال بالدعم على <a href="mailto:info@q8qamous.com">info@q8qamous.com</a>. يمكن إلغاء الطلبات التي لم يتم تعيينها لمترجم واسترداد الأموال من خلال طريقة الدفع الأصلية. لا يمكن إلغاء الطلبات التي تم تعيينها لمترجم.</p>

            <h3>3. سياسة الخصوصية</h3>
            <p>3.1. <strong>معلومات المستخدم</strong>: نحن نجمع معلومات عامة مثل عناوين البريد الإلكتروني وأرقام الهواتف لتقديم خدماتنا. يتم الحفاظ على هذه المعلومات بأمان وسرية.</p>

            <h3>4. إدارة الحساب</h3>
            <p>4.1. <strong>حسابات المستخدمين</strong>: يجب على المستخدمين إنشاء حساب لاستخدام خدمات المنصة.</p>
            <p>4.2. <strong>حسابات الشركات</strong>: يجب على الشركات تقديم طلب عبر <a href="https://q8qamous.com/company-application">https://q8qamous.com/company-application</a>. بعد الموافقة، سيتم إنشاء حساب بواسطة مسؤول، وستتلقى الشركة بريدًا ترحيبيًا مع رابط إعادة تعيين كلمة المرور.</p>
            <p>4.3. <strong>انتهاكات المحتوى</strong>: سيتم حظر الحسابات التي تقدم محتوى غير مناسب من المنصة.</p>

            <h3>5. حل النزاعات</h3>
            <p>5.1. سيتم حل النزاعات وفقًا للمعايير المتبعة في الشركات في الكويت. سيتم توفير إجراءات محددة لحل النزاعات حسب الحاجة.</p>

            <h3>6. الملكية الفكرية</h3>
            <p>6.1. <strong>الملكية</strong>: لا تحتفظ المنصة بأي حقوق على المحتوى المترجم. يحتفظ المستخدمون والشركات بالملكية الكاملة لموادهم المترجمة.</p>

            <h3>7. توفر الخدمة</h3>
            <p>7.1. <strong>ساعات العمل</strong>: لا يعمل مترجمونا في أيام الجمعة، ويتم أخذ هذا في الاعتبار في الوقت المقدر للطلب. قد يختلف توفر الخدمة، ونحن لسنا مسؤولين عن أي انقطاعات.</p>

            <h3>8. الدفع والرسوم</h3>
            <p>8.1. <strong>الطلبات الفردية</strong>: يتم تحصيل الرسوم من المستخدمين لكل طلب.</p>
            <p>8.2. <strong>حزم الشركات</strong>: تشتري الشركات حزمًا تحتوي على عدد محدد من الكلمات. لكل حزمة تاريخ انتهاء. إذا تم شراء حزمة جديدة قبل انتهاء صلاحية الحزمة الحالية، يتم تحويل أي كلمات متبقية إلى الحزمة الجديدة. ورسوم التوصيل غير مشمولة في حزم الشركات.</p>

            <h3>9. خدمات التوصيل</h3>
            <p>9.1. <strong>التوصيل الاختياري</strong>: التوصيل الورقي اختياري لكل من الترجمات المعتمدة وغير المعتمدة. يتم تضمين وقت التسليم في الوقت المقدر للطلب.</p>

            <h3>10. الولاية القضائية والقانون المعمول به</h3>
            <p>10.1. تخضع هذه الشروط والأحكام للحكم وفقًا للقوانين المطبقة في دولة الكويت.</p>

            <h3>11. معلومات الاتصال</h3>
            <p>للدعم أو الاستفسارات، يرجى الاتصال بنا على:<br />
              البريد الإلكتروني: <a href="mailto:info@q8qamous.com">info@q8qamous.com</a></p>

            <p>باستخدامك المنصة، فإنك تقر بأنك قد قرأت وفهمت وتوافق على الالتزام بهذه الشروط والأحكام.</p>

          </div>
        ) : (
          <div>
            <h1>Terms and Conditions of Use</h1>
            <p><strong>Last Updated: 22/7/2024</strong></p>

            <p>Welcome to <a href="https://q8qamous.com/">https://q8qamous.com/</a>. The Platform is owned and operated by Kuwait Qamous. By using our services, you agree to comply with the following terms and conditions. Please read them carefully.</p>

            <h3>1. User Responsibilities</h3>
            <p>1.1. <strong>File Submission</strong>: Users and companies must ensure that submitted files are clear in terms of text. The source and target languages must be correctly specified to prevent overcharging or undercharging and to facilitate accurate translation.</p>
            <p>1.2. <strong>Content Restrictions</strong>:<br />
              - Any files containing illegal content such as nudity will result in order cancellation and permanent banning from the Platform.<br />
              - Files with illegal content such as stolen materials or fraudulent documents will be translated, but the Platform is not accountable for this content.</p>

            <h3>2. Refund Policy</h3>
            <p>2.1. <strong>Order Cancellation</strong>: Users can cancel an order by contacting support at <a href="mailto:info@q8qamous.com">info@q8qamous.com</a>. Orders not yet assigned to a translator can be canceled and refunded via the original payment method. Orders assigned to a translator cannot be canceled.</p>

            <h3>3. Privacy Policy</h3>
            <p>3.1. <strong>User Information</strong>: We collect general information such as email addresses and phone numbers to provide our services. This information is kept secure and confidential.</p>

            <h3>4. Account Management</h3>
            <p>4.1. <strong>User Accounts</strong>: Users are required to create an account to use the Platform's services.</p>
            <p>4.2. <strong>Company Accounts</strong>: Companies must submit a request via <a href="https://q8qamous.com/company-application">https://q8qamous.com/company-application</a>. Upon approval, an account will be created by an admin, and the company will receive a welcome email with a password reset link.</p>
            <p>4.3. <strong>Content Violations</strong>: Accounts submitting inappropriate content will be banned from the Platform.</p>

            <h3>5. Dispute Resolution</h3>
            <p>5.1. Disputes will be resolved according to the norms followed by companies in Kuwait. Specific procedures for dispute resolution will be provided as needed.</p>

            <h3>6. Intellectual Property</h3>
            <p>6.1. <strong>Ownership</strong>: The Platform retains no rights to the translated content. Users and companies retain full ownership of their translated materials.</p>

            <h3>7. Service Availability</h3>
            <p>7.1. <strong>Working Hours</strong>: Our translators do not work on Fridays, which is accounted for in the estimated order time. Service availability may vary, and we are not responsible for any interruptions.</p>

            <h3>8. Payment and Fees</h3>
            <p>8.1. <strong>Individual Orders</strong>: Users are charged per order.</p>
            <p>8.2. <strong>Company Bundles</strong>: Companies purchase bundles with a set number of words. Each bundle has an expiration date. If a new bundle is purchased before the current bundle expires, any remaining words are transferred to the new bundle. Delivery fees are not included in company bundles.</p>

            <h3>9. Delivery Services</h3>
            <p>9.1. <strong>Optional Delivery</strong>: Paper delivery is optional for both certified and uncertified translations. Delivery time is included in the estimated order time.</p>

            <h3>10. Jurisdiction and Governing Law</h3>
            <p>10.1. These terms and conditions are governed by and construed in accordance with the laws of Kuwait.</p>

            <h3>11. Contact Information</h3>
            <p>For support or inquiries, please contact us at:<br />
              Email: <a href="mailto:info@q8qamous.com">info@q8qamous.com</a></p>

            <p>By using the Platform, you acknowledge that you have read, understood, and agree to be bound by these terms and conditions.</p>

          </div>
        )}
      </div>
    </Modal>
  );
}
