/** @format */

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { languages } from "../../../Constants/Languages";
import UserIcon from "../../../Assets/Images/Icon awesome-user-alt.svg";
import phoneIcon from "../../../Assets/Images/Icon ionic-md-phone-portrait.svg";
import EyeIcon from "../../../Assets/Images/Icon ionic-ios-eye.svg";
import Checkbox from "@mui/material/Checkbox";
import EmailIcon from "../../../Assets/Images/Icon ionic-ios-mail.svg";
import InputField from "../../InputField";
import "./index.scss";
export default function CustomerForm(props) {
  const [langSelected, selectLang] = useState([]);
  const [disableSubmitt, toggleDisable] = useState(false);
  const [selectedFields, selectedField] = useState([]);
  const [areFieldsSelected, checkFieldsSelection] = useState(false);
  const [userFieldsData, setFieldsData] = useState({
    fullName: "",
    phoneNumber: "",
    email: "",
  });
  const [errors, setErrors] = useState({
    fullName: null,
    phoneNumber: null,
    email: null,
  });
  let { lang, dir } = useSelector((state) => state.lang);
  useEffect(() => {
    let data = {
      fullName: props.data.fullName,
      phoneNumber: props.data.phoneNumber,
      email: props.data.email,
    };
    if (props.companyUser) {
      data = { ...data, isAdmin: props.data.isAdmin ?? false };
    }
    setFieldsData(data);
    if (props.data.languages) {
      selectLang(props.data.languages);
      selectedField(props.data.translationFields);
    }
  }, [props.data]);
  useEffect(() => {
    // let passwordFields = [
    //   {
    //     title: languages[lang].password,
    //     key: "password",
    //     icon: EyeIcon,
    //     placeholder: languages[lang].pleaseWritePass,
    //   },
    //   {
    //     title: languages[lang].confirmPass,
    //     key: "confirmPass",
    //     icon: EyeIcon,
    //     placeholder: languages[lang].pleaseWritePass,
    //   },
    // ];
    if (props.cutomerInfo) {
      let data = {
        name: props.cutomerInfo.name,
        email: props.cutomerInfo.email,
        phone: props.cutomerInfo.phone,
      };
      setFieldsData({ ...userFieldsData, ...data });
    }
  }, [lang, props.cutomerInfo]);

  const getChanges = (key, value) => {
    let errorsClone = { ...errors };
    errorsClone = { ...errorsClone, [key]: null };
    let flag = Object.values(errorsClone).every((error) => error === null);
    toggleDisable(!flag);
    setErrors(errorsClone);
    setFieldsData({ ...userFieldsData, [key]: value });
  };
  const getLangCheckListValue = (value) => {
    let selectedLangClone = [...langSelected];
    if (selectedLangClone.includes(value)) {
      selectLang(selectedLangClone.filter((langId) => langId != value));
    } else {
      selectedLangClone = [...selectedLangClone, value];
      selectLang(selectedLangClone);
    }
  };
  const getSelectedFields = (value) => {
    let selectedFieldsClone = [...selectedFields];
    if (selectedFieldsClone.includes(value)) {
      selectedField(selectedFieldsClone.filter((langId) => langId != value));
    } else {
      selectedFieldsClone = [...selectedFieldsClone, value];
      selectedField(selectedFieldsClone);
    }
  };
  const validatData = (key, value) => {
    let errorsClone = { ...errors };
    let flag = false;
    errorsClone = { ...errorsClone, [key]: null };
    if (key === "email") {
      let regex = new RegExp("[a-z0-9]+@[a-z]+.[a-z]{2,3}");
      if (!regex.test(value)) {
        errorsClone = { ...errorsClone, email: languages[lang].validEmail };
        flag = true;
      }
    }
    toggleDisable(flag);
    setErrors(errorsClone);
  };
  const renderCheckBox = (value, label, action, list) => {
    return (
      <div
        key={label}
        dir={dir}
        lang={lang}
        className="customer-form__checkBox-cont__checkBox__field"
        onClick={() => (props.disabled ? null : action(value))}
      >
        <Checkbox
          label="تذكرني"
          // checked={value === selectedValues}
          sx={{
            color: "#1E8AB2",
            "&.Mui-checked": {
              color: "#1E8AB2",
            },
          }}
          disabled={props.disabled}
          checked={list?.includes(value)}
        />{" "}
        <span>{label}</span>
      </div>
    );
  };
  const renderPermission = (title, key, constant) => {
    return (
      <div
        key={key}
        className="customer-form__checkBox-cont__permissions__permission"
      >
        <span>{title}</span>
        <div>
          <Checkbox
            onClick={() => props.getPermissions("view", key, constant.view)}
            checked={props.permissionsList.includes(constant.view)}
            disabled={!constant.view}
          />
          <Checkbox
            onClick={() => props.getPermissions("add", key, constant.add)}
            checked={props.permissionsList.includes(constant.add)}
            disabled={!constant.add}
          />
          <Checkbox
            onClick={() => props.getPermissions("edit", key, constant.edit)}
            checked={props.permissionsList.includes(constant.edit)}
            disabled={!constant.edit}
          />
          <Checkbox
            onClick={() => props.getPermissions("delete", key, constant.delete)}
            checked={props.permissionsList.includes(constant.delete)}
            disabled={!constant.delete}
          />
        </div>
      </div>
    );
  };
  const renderHeader = () => {
    return (
      <div className="customer-form__checkBox-cont__permissions__header">
        <span>عرض</span>
        <span>إضافة</span>
        <span>تعديل</span>
        <span>حذف</span>
      </div>
    );
  };
  const renderPermissions = () => {
    return (
      <div className="customer-form__checkBox-cont">
        <div className="customer-form__checkBox-cont__title">
          <span>{languages[lang].permissions}</span>
          <hr />
        </div>
        <div className="customer-form__checkBox-cont__permissions">
          <div>
            {renderHeader()}

            {props.permissionsFieldsPart1?.map((constant) =>
              renderPermission(constant.title, constant.key, constant)
            )}
          </div>

          <div>
            {renderHeader()}
            {props.permissionsFieldsPart2?.map((constant) =>
              renderPermission(constant.title, constant.key, constant)
            )}
          </div>
        </div>
        {areFieldsSelected && props.permissionsList.length === 0 ? (
          <span className="input-cont__error-msg">
            {languages[lang].select1Field}
          </span>
        ) : null}
      </div>
    );
  };
  const submit = async () => {
    checkFieldsSelection(true);
    let errorsClone = {};
    let fields = {};

    Object.keys(fields).forEach((field) => {
      let value = fields[field]?.value ?? fields[field];
      if (!value?.length && field !== "isAdmin") {
        errorsClone = {
          ...errorsClone,
          [field]: languages[lang].requiredField,
        };
      }
    });
    Object.keys(userFieldsData).forEach((field) => {
      let value = userFieldsData[field]?.label?.ar ?? userFieldsData[field];
      if (!value?.length && field !== "isAdmin") {
        errorsClone = {
          ...errorsClone,
          [field]: languages[lang].requiredField,
        };
      }
    });
    if (
      props.preferredLanguage &&
      !Object.keys(userFieldsData).includes("preferredLanguageId")
    ) {
      fields = { ...userFieldsData, preferredLanguageId: "" };
    } else {
      fields = {
        ...userFieldsData,
        preferredLanguageId: String(
          userFieldsData.preferredLanguageId?.value ?? ""
        ),
      };
    }

    toggleDisable(Object.keys(errorsClone).length > 0);

    setErrors(errorsClone);
    if (Object.keys(errorsClone).length === 0) {
      if (
        props.addNewTranslator &&
        langSelected.length &&
        selectedFields.length
      ) {
        let params = {
          ...userFieldsData,
          languages: langSelected,
          translationFields: selectedFields,
        };
        props.action(params);
      } else if (props.clintProfile) {
        props.action({ ...userFieldsData });
      } else if (props.companyUser) {
        let params = {
          ...fields,
        };
        props.action(params);
      } else {
        let params = {
          ...fields,
          permissions: props.permissionsList,
        };
        props.action(params);
      }
    }
  };
  return (
    <div className="customer-form" dir={dir} lang={lang}>
      <div className="customer-form__userFields">
        {props.userFieldsConst.map((field) => (
          <InputField
            title={field.title}
            placeholder={field.placeholder}
            value={userFieldsData[field.key]}
            getChanges={getChanges}
            stateName={field.key}
            errors={errors}
            icon={field.icon}
            disabled={field.disabled}
            type={field.type}
            select={field.type === "select"}
            options={field.options}
            validatData={validatData}
            key={field.key}
          />
        ))}
      </div>
      {props.emailField ? (
        <div className="customer-form__emailFields">
          <InputField
            title={languages[lang].email + " *"}
            placeholder={languages[lang].pleaseWriteEmail}
            value={userFieldsData.email}
            getChanges={getChanges}
            stateName={"email"}
            errors={errors}
            icon={EmailIcon}
            disabled={props.emailField.disabled}
            validatData={validatData}
          />
        </div>
      ) : null}

      {props.passFields ? (
        <div className="customer-form__userFields">
          {props.passFields.map((field) => (
            <InputField
              title={field.title + " *"}
              placeholder={field.placeholder}
              value={userFieldsData[field.key]}
              getChanges={getChanges}
              stateName={field.key}
              errors={errors}
              icon={field.icon}
              key={field.key}
            />
          ))}
        </div>
      ) : null}
      {props.langConst ? (
        <div className="customer-form__checkBox-cont">
          <div className="customer-form__checkBox-cont__title">
            <span>{languages[lang].translatorLang}</span>
            <hr />
          </div>
          <div className="customer-form__checkBox-cont__checkBox">
            {props.langConst.map((constant) =>
              renderCheckBox(
                constant.value,
                constant.label,
                getLangCheckListValue,
                langSelected
              )
            )}
          </div>
          {areFieldsSelected && langSelected.length === 0 ? (
            <span className="input-cont__error-msg">
              {languages[lang].select1Field}
            </span>
          ) : null}
        </div>
      ) : null}
      {props.fieldsConst ? (
        <div className="customer-form__checkBox-cont">
          <div className="customer-form__checkBox-cont__title">
            <span>{languages[lang].translationField}</span>
            <hr />
          </div>
          <div className="customer-form__checkBox-cont__checkBox">
            {props.fieldsConst.map((constant) =>
              renderCheckBox(
                constant.value,
                constant.label,
                getSelectedFields,
                selectedFields
              )
            )}
          </div>

          {areFieldsSelected && selectedFields.length === 0 ? (
            <span className="input-cont__error-msg">
              {languages[lang].select1Field}
            </span>
          ) : null}
        </div>
      ) : null}
      {props.permissionsFieldsPart1 ? renderPermissions() : null}
      {props.hideFooter || props.disabled ? null : (
        <div className="customer-form__userFields">
          <button disabled={disableSubmitt} onClick={submit}>
            {
              languages[lang][
                props.btnTitle
                  ? props.btnTitle
                  : props.cutomerInfo
                  ? "saveChanges"
                  : props.editAdmin
                  ? "editAdmin"
                  : "addNewCustomer"
              ]
            }
          </button>
        </div>
      )}
    </div>
  );
}
