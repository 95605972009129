/** @format */

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import addIcon from "../../../../Assets/Images/Group 2784.svg";
import wightAdd from "../../../../Assets/Images/Group 2784.png";
import { languages } from "../../../../Constants/Languages";

import RequestsSearchbar from "../../../../Components/AdminComponents/RequestsSearchbar";
import CustomersTable from "../../../../Components/Shared/CustomersTable";
import { deleteAddOnService } from "../../../../Services/adminServices";
import { setAddOns } from "../../../../Redux/commonData";

export default function AddOns(props) {
  const [id, setId] = useState();
  const [isModalOpen, setModalOpen] = useState({ cancelModal: false });
  const [isHovering, toggleHover] = useState(false);
  const [addOnsData, setAddOnsData] = useState([]);
  const [tableHeaderConstatnt, setTableHeader] = useState([]);
  let { lang, dir } = useSelector((state) => state.lang);
  let { addOns } = useSelector((state) => state.commonData);
  let dispatch = useDispatch();
  useEffect(() => {
    let addOnsDataClone = addOns.map((addOn) => ({
      ...addOn,
      label: addOn.label[lang],
    }));
    let tableHeader = [
      { title: languages[lang].serviceType, key: "label" },
      { title: languages[lang].price, key: "additionalCost" },
      // { title: languages[lang].deleviryTime, key: "deleviryTime" },
    ];
    setTableHeader(tableHeader);
    setAddOnsData(addOnsDataClone);
  }, [lang, addOns.length]);
  const toggleCancelModal = (modalName, event, id) => {
    setId(id);
    setModalOpen({ ...isModalOpen, [modalName]: !isModalOpen[modalName] });
    if (event) {
      event.preventDefault();
    }
  };
  const deleteAddon = async (id) => {
    props.toggleLoading(true);
    let response = await deleteAddOnService(id);
    if (response.data?.isSuccess) {
      toggleCancelModal("cancelModal");
      let addOnsNewList = addOns.filter((addOn) => addOn.value !== id);
      dispatch(setAddOns(addOnsNewList));
      props.toggleModal("successModal");
      props.getModalMsg(response.data.message[lang]);
    } else {
      props.toggleModal("failModal");
      props.getModalMsg(
        response.data?.message
          ? response.data?.message[lang]
          : languages[lang].wentWrong
      );
    }
    props.toggleLoading(false);
  };
  return (
    <div>
      <div className='customers-cont__body__info' dir={dir} lang={lang}>
        <h4>{languages[lang].pricingForTypesOfTranslationServices}</h4>
        {props.canEdit ? (
          <Link to='/dashboard/settings/translation-service/add'>
            <button
              onMouseEnter={() => toggleHover(true)}
              onMouseLeave={() => toggleHover(false)}>
              <img src={isHovering ? wightAdd : addIcon} />{" "}
              {languages[lang].add}
            </button>
          </Link>
        ) : null}
      </div>
      {/* <RequestsSearchbar searchOnly /> */}

      <CustomersTable
        toggleModal={toggleCancelModal}
        isOpen={isModalOpen.cancelModal}
        id={id}
        agents={addOnsData}
        url='settings/translation-service'
        tableHeaderConstatnt={tableHeaderConstatnt}
        msg={languages[lang].deleteService}
        deleteAction={deleteAddon}
        loading={props.loading}
        disableRouting
        removeDelete={!props.canEdit}
      />
    </div>
  );
}
