/** @format */

import { configureStore } from "@reduxjs/toolkit";
import admin from "./admin";
import clintSlice from "./clint";
import commonData from "./commonData";
import companySlice from "./company";
import languageSlice from "./lang";
export const store = configureStore({
  reducer: {
    lang: languageSlice,
    commonData: commonData,
    admin: admin,
    clint: clintSlice,
    company: companySlice,
  },
});
