/** @format */

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import NavbarAndSidebarCont from "../../../../Components/Shared/NavbarAndSidebarCont";
import { languages } from "../../../../Constants/Languages";
import CustomerForm from "../../../../Components/AdminComponents/CutomerForm";
import UserIcon from "../../../../Assets/Images/Icon awesome-user-alt.svg";
import phoneIcon from "../../../../Assets/Images/Icon ionic-md-phone-portrait.svg";
import EmailIcon from "../../../../Assets/Images/Icon ionic-ios-mail.svg";
import { Navigate } from "react-router-dom";
import { adminActions } from "../../../../actions/adminActions";
import { bindActionCreators } from "@reduxjs/toolkit";
import { clintActions } from "../../../../actions/clintActions";

export default function AddCompanyUser() {
  const params = useParams();
  const [loading, setLoading] = useState(params.id ? true : false);
  const [isOpen, setOpen] = useState({ successModal: false, failModal: false });
  const [modalMsg, setModalMsg] = useState("");
  const [redirect, setRedireact] = useState(false);
  const [adminData, setAdminData] = useState({
    name: "",
    phone: "",
    email: "",
    password: "",
    confirmPass: "",
  });
  const [errors, setErrors] = useState({
    name: null,
    mobile: null,
    email: null,
    password: null,
    confirmPass: null,
  });
  const [permissionsData, setPermissionsData] = useState({
    requests: [],
    companies: [],
    payments: [],
    customers: [],
    translators: [],
    settings: [],
  });
  const [userFields, setUserFields] = useState([]);

  let { lang, dir } = useSelector((state) => state.lang);
  let { adminPermissions } = useSelector((state) => state.admin);
  let dispatch = useDispatch();
  let { getAdminPermissionsAction } = bindActionCreators(
    adminActions,
    dispatch
  );

  useEffect(() => {
    let adminUserData = JSON.parse(localStorage.getItem("adminData"));
    if (params.id) {
      getAdminPermissionsAction(adminUserData.id);
      setAdminData(adminUserData);
    }
    let userFieldsConst = [
      {
        title: languages[lang].name,
        key: "fullName",
        icon: UserIcon,
        placeholder: languages[lang].pleaseWriteName,
        disabled: false,
      },
      {
        title: languages[lang].phoneNum,
        key: "phoneNumber",
        icon: phoneIcon,
        placeholder: languages[lang].pleaseWritePhone,
        type: "phoneNumber",
        disabled: false,
      },
      {
        title: languages[lang].email,
        key: "email",
        icon: EmailIcon,
        placeholder: languages[lang].pleaseWriteEmail,
        disabled: false,
      },
      {
        title: languages[lang].preferredLanguage,
        key: "preferredLanguageId",
        type: "select",
        options: [
          { label: { ar: "العربية", en: "العربية" }, value: 1 },
          { label: { ar: "English", en: "English" }, value: 2 },
        ],
        placeholder: languages[lang].selectPreferredLang,
        disabled: false,
      },
      {
        title: languages[lang].adminHeader,
        key: "isAdmin",
        type: "checkbox",
        placeholder: languages[lang].selectPreferredLang,
        disabled: false,
      },
    ];

    setUserFields(userFieldsConst);
  }, []);
  useEffect(() => {
    setLoading(false);
  }, [adminPermissions]);
  // const getChanges = (value, stateName) => {
  //   setErrors({ ...errors, [stateName]: null });
  //   setAdminData({ ...adminData, [stateName]: value });
  // };

  const toggleModal = (stateName) => {
    setOpen({ ...isOpen, [stateName]: !isOpen[stateName] });
    if (stateName === "successModal" && isOpen.successModal) {
      setRedireact(!redirect);
    }
  };
  const createNewUser = async (adminData) => {
    setLoading(true);

    let res = await clintActions.addNewCompanyUser(adminData);

    if (res.data?.isSuccess) {
      toggleModal("successModal");
      setModalMsg(res.data?.message?.[lang] ?? "Done");
    } else {
      toggleModal("failModal");
      setModalMsg(res.data?.message?.[lang]);
    }
    setLoading(false);
  };

  return (
    <NavbarAndSidebarCont
      isOpen={isOpen}
      loading={loading}
      toggleModal={toggleModal}
      modalMsg={modalMsg}>
      <h4 className='lang-header-title' lang={lang} dir={dir}>
        {languages[lang].addNewCustomer}
      </h4>
      <div className='Lang-price'>
        <CustomerForm
          userFieldsConst={userFields}
          // emailField={emailField}
          //   passFields={passwordFields}
          // getChanges={getChanges}
          data={adminData}
          action={createNewUser}
          editAdmin={params.id}
          preferredLanguage
          companyUser
        />
      </div>
      {redirect ? <Navigate to='/company/users' /> : null}
    </NavbarAndSidebarCont>
  );
}
