/** @format */

import { setOrderStatuses } from "../Redux/commonData";
import { deleteReq, getReq, postReq, putReq } from "../Services/APIMethods";

export const commonActions = {
  getOrderStatusesActions,
  resetPassword,
  cancelOrderAction,
  uploadFileAction,
};
function getOrderStatusesActions() {
  return async (dispatch) => {
    let response = await getReq(`Orders/get-order-statuses`);
    if (response.data.isSuccess) {
      dispatch(
        setOrderStatuses(
          response.data.data.map((type) => ({
            ...type,
            value: type.id,
            label: { ar: type.ar, en: type.en },
          }))
        )
      );
    }
  };
}
async function cancelOrderAction(fileId) {
  let response = await getReq(
    `Files/get-download-link?documentId=${fileId}&expireAfterDays=1`
  );
  return response.data;
}
async function uploadFileAction(orderId, orderSource, params) {
  let response = await putReq(
    `Translators/upload-translation-file?orderId=${orderId}&orderSource=${orderSource}`,
    params
  );
  return response.data;
}
async function resetPassword(params) {
  let response = await postReq(`Auth/user-reset-password`, params);

  return response.data;
}
const prepareDataWithPagination = (response) => {
  return {
    pagination: JSON.parse(response.headers["x-pagination"]),
    data: response.data.data,
  };
};
