/** @format */
import { useSelector } from "react-redux";
import Info from "../../Assets/Images/Icon feather-alert-circle.svg";
import { languages } from "../../Constants/Languages";
import "./index.scss";
export default function Bill(props) {
  let { lang, dir } = useSelector((state) => state.lang);
  const { bundleInfo } = useSelector((state) => state.company);
  // const prepareTotalPrice = () => {
  //   let translationFieldCost = props.fieldsData.translationField
  //     ?.additionalCostPerWord
  //     ? props.wordCount *
  //       props.fieldsData.translationField.additionalCostPerWord
  //     : 0;
  //   let translationTypeCost = props.fieldsData.translationType?.additionalCost
  //     ? props.fieldsData.translationType.additionalCost
  //     : 0;
  //   let serviceTypeCost = props.fieldsData.serviceType?.additionalCost
  //     ? props.fieldsData.serviceType.additionalCost
  //     : 0;
  //   let cityCost = props.addressData.city?.additionalCost
  //     ? props.addressData.city?.additionalCost
  //     : 0;
  //   return (
  //     props.wordCount * props.wordPrice +
  //     translationFieldCost +
  //     translationTypeCost +
  //     serviceTypeCost +
  //     cityCost
  //   ).toFixed(3);
  // };
  const additionalCost = (title, value, style) => {
    return (
      <div className={style}>
        <span>{title}</span>
        <span>{value}</span>
      </div>
    );
  };
  return (
    <div className="bill-cont" dir={dir} lang={lang}>
      <h3>{languages[lang].orderSummary}</h3>
      <div className="bill-cont__body" dir={dir} lang={lang}>
        {additionalCost(
          languages[lang].translateFrom,
          props.selectedLanguages.formLangId.label[lang],
          "bill-cont__body__extraStyle"
        )}
        {additionalCost(
          languages[lang].translateTo,
          props.selectedLanguages.toLangId.label[lang],
          "bill-cont__body__extraStyle"
        )}
        {additionalCost(
          languages[lang].wordsNum,
          `${props.wordCount} ${languages[lang].word}`,
          "bill-cont__body__extraStyle"
        )}
        {props.userType === "company" ? (
          <>
          </>
        ) : (
          <>
            {additionalCost(
              languages[lang].price,
              `${(props.wordCount * props.wordPrice).toFixed(3)} ${
                languages[lang].DK
              }`
            )}
            {props.fieldsData.translationField?.additionalCostPerWord
              ? additionalCost(
                  props.fieldsData.translationField.label[lang],
                  `${(
                    props.wordCount *
                    props.fieldsData.translationField.additionalCostPerWord
                  ).toFixed(3)} ${languages[lang].DK}`
                )
              : null}
            {props.fieldsData.translationType?.additionalCost
              ? additionalCost(
                  props.fieldsData.translationType.label[lang],
                  `${(props.fieldsData.translationType?.additionalCost * props.wordCount).toFixed(3)} ${languages[lang].DK}`
                )
              : null}
            {props.fieldsData.serviceType?.additionalCost
              ? additionalCost(
                  props.fieldsData.serviceType.label[lang],
                  `${
                    (props.fieldsData.serviceType?.additionalCost *
                    props.wordCount).toFixed(3)
                  }  ${languages[lang].DK}`
                )
              : null}
          </>
        )}

        {props.addressData.city?.additionalCost && props.showAddressField && props.userType !== "company"
          ? additionalCost(
              languages[lang].sendTranslation,
              `${props.addressData.city?.additionalCost} ${languages[lang].DK}`
            )
          : null}

        <div className="bill-cont__body__extraStyle">
          <span className="bill-cont__body__extraStyle__total">
            {languages[lang].totalPrice}
          </span>
          <span>
            {props.prepareTotalPrice()} {languages[lang].DK}
          </span>
        </div>
      </div>
      {props.removeFooter ? null : (
        <span className="bill-cont__info">
          {languages[lang].priceMaybeChange} <img src={Info} />
        </span>
      )}

      <div>
        <button
          onClick={() => {
            localStorage.removeItem("firstPhaseData");
            props.setPhaseNum(1);
          }}
          className="btn btn-danger mt-2"
        >
          {" "}
          {languages[lang].cancelOrder}
        </button>
      </div>
    </div>
  );
}
