/** @format */
import { useSelector } from "react-redux";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { languages } from "../../Constants/Languages";
import Select from "../Shared/Select";
import Checkbox from "@mui/material/Checkbox";
import "./index.scss";
import Input from "./Input";

export default function InputField(props) {
  let { lang, dir } = useSelector((state) => state.lang);
  const getPhoneNumber = (event) => {
    props.getChanges(props.stateName, event);
  };
  return (
    <div className={`input-cont `} dir={dir} lang={lang}>
      {props.type === "checkbox" ? null : (
        <span className={`input-cont__title `} dir={dir} lang={lang}>
          {props.title}{" "}
        </span>
      )}

      {props.select ? (
        <Select
          placeholder={props.placeholder}
          value={props.value}
          getChanges={props.getChanges}
          stateName={props.stateName}
          options={props.options}
          errors={props.errors?.[props.stateName]}
          disabled={props.disabled}
        />
      ) : props.type === "phoneNumber" ? (
        <div lang='en' dir='ltr'>
          <PhoneInput
            className={`input-cont__phone-num ${
              props.errors?.[props.stateName] ? "PhoneInput--error" : ""
            }`}
            placeholder={props.placeholder}
            value={props.value}
            onChange={getPhoneNumber}
            disabled={props.disabled}
          />
        </div>
      ) : props.type === "checkbox" ? (
        <div
          className='seconed-phase__inputFields__primary-fields__address-checkbox'
          onClick={() => {
            return props.getChanges(props.stateName, !props.value);
          }}>
          <Checkbox
            label='تذكرني'
            checked={props.value}
            sx={{
              color: "#1E8AB2",
              "&.Mui-checked": {
                color: "#1E8AB2",
              },
            }}
          />{" "}
          <span>{props.title}</span>
        </div>
      ) : (
        <Input
          placeholder={props.placeholder}
          value={props.value}
          getChanges={props.getChanges}
          stateName={props.stateName}
          errors={props.errors?.[props.stateName]}
          icon={props.icon}
          disabled={props.disabled}
          id={props.id}
          rowKey={props.rowKey}
          type={props.type}
          showPassword={props.showPassword}
          togglePassViability={props.togglePassViability}
          validatData={props.validatData}
        />
      )}

      {props.errors?.[props.stateName] ? (
        <span className='input-cont__error-msg'>
          {props.errors?.[props.stateName]}
        </span>
      ) : null}
      {(props.stateName === "password" || props.stateName === "newPassword") &&
      !props.errors?.[props.stateName] ? (
        <span className='input-cont__password-rule'>
          {languages[lang].passwordRule}
        </span>
      ) : null}

      {/* <span className='input-cont__error-msg'>هذه الخانة مطلوبة</span> */}
    </div>
  );
}
