/** @format */

import React, { useEffect, useState } from "react";
import { Link, Navigate } from "react-router-dom";
import Drawer from "@mui/material/Drawer";
import { useSelector, useDispatch } from "react-redux";
import "./index.scss";
import Menu from "../../../Assets/Images/Icon feather-menu.png";
import Logo from "../../../Assets/Images/Group 39808.png";
import cartImg from "../../../Assets/Images/Group 39860.svg";
import CloseIcon from "../../../Assets/Images/Path 11470.svg";
import Logout from "../../../Assets/Images/Icon open-account-logout.png";
import Lock from "../../../Assets/Images/Icon awesome-lock.png";
import radioBtn from "../../../Assets/Images/Ellipse 191.svg";
import { changeSelectedOption, toggleSideMenu } from "../../../Redux/lang";
import selectedRadioBtn from "../../../Assets/Images/Ellipse 190.svg";
import Arrow from "../../../Assets/Images/Icon ionic-ios-arrow-up.svg";
import { languages } from "../../../Constants/Languages";
import ChangePass from "../../Modals/ChangePass";
export default function Sidebar(props) {
  const [selectedPage, selectPage] = useState({});
  const [openChangePass, toggleChangePass] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [windowSize, setWindowSize] = useState(getWindowSize());
  let { lang, dir, selectedOption, isOpen } = useSelector(
    (state) => state.lang
  );
  const { bundleInfo } = useSelector((state) => state.company);
  const dispatch = useDispatch();
  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }
  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  useEffect(() => {
    if (props.sideMenu.length) {
      let selectedPageClone = props.sideMenu.find((page) =>
        window.location.pathname.includes(page.link)
      );
      selectPage(selectedPageClone);
      if (selectedPageClone?.subMenu) {
        let option = localStorage.getItem("selectedOption").length
          ? localStorage.getItem("selectedOption")
          : selectedOption.length
          ? selectedOption
          : selectedPageClone.options[0];

        dispatch(changeSelectedOption(option));
      }
    }
  }, [props.sideMenu]);
  const getSelectedOption = (option) => {
    localStorage.setItem("selectedOption", option);
    dispatch(changeSelectedOption(option));

    props.getSelectedOption(option);
  };
  const logOut = () => {
    localStorage.removeItem("userRole");
    setRedirect(true);
  };
  const renderMenuItem = () => {
    return (
      <div className="sidebar-cont__menu-items">
        {props.sideMenu.map((item, index) =>
          item.hide ? null : (
            <>
              <Link
                onClick={() =>
                  selectedPage?.link === item?.link
                    ? null
                    : dispatch(changeSelectedOption(""))
                }
                className={`sidebar-cont__menu-items__link ${
                  selectedPage?.link === item?.link &&
                  "sidebar-cont__menu-items__link--selected"
                }`}
                key={item.title + index}
                dir={dir}
                lang={lang}
                to={item?.link}
              >
                <span dir={dir} lang={lang}>
                  <img
                    className="sidebar-cont__menu-items__link__icon"
                    src={
                      selectedPage?.link === item?.link
                        ? item.selectedImg
                        : item.img
                    }
                  />
                  &nbsp;
                  {isOpen ? item.title : null}{" "}
                </span>
                {item.subMenu && isOpen ? <img src={Arrow} /> : null}
              </Link>
              {selectedPage?.link === item?.link &&
              selectedPage.subMenu &&
              isOpen ? (
                <div className="sidebar-cont__menu-items__dropdown">
                  {selectedPage.options.map((option, index) => (
                    <Link
                      key={new Date() + index}
                      dir={dir}
                      lang={lang}
                      to={item.link}
                    >
                      <img
                        src={
                          languages[lang][selectedOption] ===
                          languages[lang][option]
                            ? selectedRadioBtn
                            : radioBtn
                        }
                      />{" "}
                      <span onClick={() => getSelectedOption(option)}>
                        {languages[lang][option]}
                      </span>
                    </Link>
                  ))}
                </div>
              ) : null}
            </>
          )
        )}
      </div>
    );
  };
  return (
    <div>
      {windowSize.innerWidth > 950 ? (
        <div
          className={`sidebar-cont ${!isOpen && "sidebar-cont--closed"} ${
            lang === "en" && "sidebar-cont--left"
          }`}
        >
          <div
            className={`sidebar-cont__burger-menu ${
              !isOpen && "sidebar-cont__burger-menu--closed"
            }`}
            onClick={() => props.toggleSideMenu(!isOpen)}
          >
            <img src={Menu} className="sidebar-cont__burger-menu__menu" />
            {isOpen ? (
              <Link to="/">
                <img src={Logo} className="sidebar-cont__burger-menu__logo" />
              </Link>
            ) : null}
          </div>
          {renderMenuItem()}
        </div>
      ) : (
        <Drawer
          anchor={lang === "ar" ? "right" : "left"}
          open={isOpen}
          onClose={() => props.toggleSideMenu(!isOpen)}
        >
          <div className="mobile-sideMenu" dir={dir} lang={lang}>
            <div className="mobile-sideMenu__userInfo">
              <div className="mobile-sideMenu__userInfo__nameAndBadge">
                <div className="mobile-sideMenu__userInfo__nameAndBadge__badge">
                  <h1>
                    {" "}
                    {JSON.parse(localStorage.getItem("userRole"))?.name[0]}
                  </h1>
                </div>
                <span lang={lang} dir={dir} className={``}>
                  {languages[lang].hello}:
                  {JSON.parse(localStorage.getItem("userRole"))?.name}
                </span>
              </div>

              {props.userSidebar === "company" ? (
                <div
                  className="customNav-cont__reminder mobile-sideMenu__userInfo__reminder"
                  lang={lang}
                  dir={dir}
                >
                  <span className="customNav-cont__reminder__title">
                    {languages[lang].reminderWords}
                  </span>{" "}
                  <span className="customNav-cont__reminder__count">
                    {bundleInfo?.reminder} {languages[lang].word}
                  </span>{" "}
                </div>
              ) : null}
            </div>

            {renderMenuItem()}
            <div
              className="sidebar-cont__menu-items"
              style={{ border: "none" }}
            >
              <a
                className="sidebar-cont__menu-items__link"
                onClick={() => toggleChangePass(true)}
              >
                <span>
                  <img src={Lock} /> &nbsp;{languages[lang].changePass}
                </span>
              </a>
              <a className="sidebar-cont__menu-items__link" onClick={logOut}>
                <span>
                  <img src={Logout} /> &nbsp; {languages[lang].logout}
                </span>
              </a>
              <ChangePass
                open={openChangePass}
                toggleModal={() => toggleChangePass(false)}
              />
              {redirect ? <Navigate to="/" /> : null}
            </div>
          </div>
        </Drawer>
      )}

      <img
        onClick={() => props.toggleSideMenu(!isOpen)}
        src={isOpen ? CloseIcon : Menu}
        className={"Drawer-burger-menu " + dir}
      />
    </div>
  );
}
