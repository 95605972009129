/** @format */

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import RequestsSearchbar from "../../../Components/AdminComponents/RequestsSearchbar";
import Pagination from "../../../Components/Shared/Pagination";
import addIcon from "../../../Assets/Images/Group 2784.svg";
import wightAdd from "../../../Assets/Images/Group 2784.png";
import { languages } from "../../../Constants/Languages";
import CustomersTable from "../../../Components/Shared/CustomersTable";
import { Link } from "react-router-dom";
import NavbarAndSidebarCont from "../../../Components/Shared/NavbarAndSidebarCont";
import "./index.scss";
import { bindActionCreators } from "@reduxjs/toolkit";
import { adminActions } from "../../../actions/adminActions";
let paginationData = {
  TotalPages: 1,
  PageSize: 10,
  CurrentPage: 1,
  HasNext: false,
  HasPrev: false,
};
export default function Customers(props) {
  const [loading, setLoading] = useState(true);
  const [isOpen, setOpen] = useState({ successModal: false, failModal: false });
  const [modalMsg, setModalMsg] = useState("");
  const [searchValue, setSearch] = useState("");
  const [agents, setAgents] = useState([]);
  const [pagination, setPaginationData] = useState({});
  const [tableHeaderConstatnt, setTableHeader] = useState([]);
  let { lang, dir } = useSelector((state) => state.lang);
  let { clients } = useSelector((state) => state.admin);
  let dispatch = useDispatch();
  let { getClientsAction } = bindActionCreators(adminActions, dispatch);
  useEffect(() => {
    getClientsAction(1);
  }, []);
  useEffect(() => {
    if (clients.data?.length) {
      setLoading(false);

      setPaginationData(clients.pagination);
      let CustomersClone = clients.data;
      let tableHeader = [
        { title: languages[lang].customerName, key: "fullName" },
        { title: languages[lang].email, key: "email" },
        { title: languages[lang].phoneNum, key: "phoneNumber" },
        { title: languages[lang].ordersNum, key: "ordersNum" },
      ];
      setTableHeader(tableHeader);
      setAgents(CustomersClone);
    }
  }, [lang, clients]);
  const toggleModal = (stateName) => {
    setOpen({ ...isOpen, [stateName]: !isOpen[stateName] });
  };
  const getPageData = async (num) => {
    setLoading(true);
    await getClientsAction(num, searchValue);
    setLoading(false);
  };
  const getSearchValues = async (searchString) => {
    setLoading(true);
    setSearch(searchString);
    let res = await getClientsAction(1, searchString);
    setLoading(false);
  };
  return (
    <div className='customers-cont'>
      <NavbarAndSidebarCont
        isOpen={isOpen}
        loading={loading}
        toggleModal={toggleModal}
        modalMsg={modalMsg}>
        <div className={`customers-cont__body `}>
          <div className='customers-cont__body__info' dir={dir} lang={lang}>
            <h4>{languages[lang].allRequests}</h4>
            {/* <Link to='/dashboard/customers/add'>
              <button
                onMouseEnter={() => toggleHover(true)}
                onMouseLeave={() => toggleHover(false)}>
                <img src={isHovering ? wightAdd : addIcon} />{" "}
                {languages[lang].add}
              </button>
            </Link> */}
          </div>
          <RequestsSearchbar searchOnly getSearchValues={getSearchValues} />
          <CustomersTable
            agents={agents}
            url='customers'
            tableHeaderConstatnt={tableHeaderConstatnt}
            saveToSessionStorege='clintData'
            removeDelete
          />
        </div>
        {pagination.TotalPages === 1 ? null : (
          <Pagination pagination={pagination} getPageData={getPageData} />
        )}
      </NavbarAndSidebarCont>
    </div>
  );
}
