/** @format */

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomerForm from "../../../../Components/AdminComponents/CutomerForm";
import EditCompanyForm from "../../../../Components/AdminComponents/EditComapnyForm";
import CustomNavbar from "../../../../Components/Shared/CustomNavbar";
import CustomerHeader from "../../../../Components/Shared/CutomerHeader";
import OrderTable from "../../../../Components/Shared/OrderTable";
import Sidebar from "../../../../Components/Shared/Sidebar";
import UserIcon from "../../../../Assets/Images/Icon awesome-user-alt.svg";
import phoneIcon from "../../../../Assets/Images/Icon ionic-md-phone-portrait.svg";
import CompanyIcon from "../../../../Assets/Images/Path 11449.svg";
import EmailIcon from "../../../../Assets/Images/Icon ionic-ios-mail.svg";
import { languages } from "../../../../Constants/Languages";
import { prepareDashboardSideBar } from "../../../../Constants/PrepareData";
import NavbarAndSidebarCont from "../../../../Components/Shared/NavbarAndSidebarCont";
import { bindActionCreators } from "@reduxjs/toolkit";
import { adminActions } from "../../../../actions/adminActions";

export default function CompanyDetails() {
  const [sidebar, setSidebar] = useState([]);
  const [orders, setOrders] = useState([]);
  const [isOpen, toggleSideMenu] = useState(true);
  const [customerInfo, setCustomerInfo] = useState({});
  const [selectedpage, setPage] = useState("info");
  const [userFields, setUserFields] = useState([]);
  const [selectedOption, selectOption] = useState();
  let { lang, dir } = useSelector((state) => state.lang);
  let { companyOrders } = useSelector((state) => state.admin);
  let dispatch = useDispatch();
  let { getCompanyOrderAction } = bindActionCreators(adminActions, dispatch);
  useEffect(() => {
    let companyData = JSON.parse(localStorage.getItem("companyData"));
    getCompanyOrderAction(1, companyData.id);
  }, []);
  useEffect(() => {
    let companyData = JSON.parse(localStorage.getItem("companyData"));

    setCustomerInfo(companyData);

    let userFieldsConst = [
      {
        title: languages[lang].companyName,
        key: "name",
        icon: CompanyIcon,
        placeholder: languages[lang].pleaseWriteName,
        disabled: true,
      },
      {
        title: languages[lang].email,
        key: "email",
        icon: EmailIcon,
        placeholder: languages[lang].pleaseWriteEmail,
        disabled: true,
      },
      {
        title: languages[lang].responsibleName,
        key: "representativeName",
        icon: UserIcon,
        placeholder: languages[lang].pleaseWriteResponsibleName,
        disabled: true,
      },
      {
        title: languages[lang].phoneNum,
        key: "phone",
        icon: phoneIcon,
        placeholder: languages[lang].pleaseWritePhone,
        disabled: true,
      },
      // {
      //   title: languages[lang].companyBusiness,
      //   key: "companyBusiness",

      //   placeholder: languages[lang].chooseBusiness,
      //   disabled: true,
      //   type: "select",
      // },
      {
        title: languages[lang].package,
        key: "bundle",
        icon: phoneIcon,
        placeholder: languages[lang].pleaseWritePhone,
        disabled: true,
        // type: "select",
      },
      // {
      //   title: languages[lang].password,
      //   key: "password",
      //   icon: EyeIcon,
      //   placeholder: languages[lang].pleaseWritePass,
      // },
      // {
      //   title: languages[lang].confirmPass,
      //   key: "confirmPass",
      //   icon: EyeIcon,
      //   placeholder: languages[lang].pleaseWritePass,
      // },
    ];
    setUserFields(userFieldsConst);
    setOrders(companyOrders.data);
  }, [lang, companyOrders.data]);

  return (
    <div>
      <NavbarAndSidebarCont>
        <div className={`cutomer-details `}>
          <CustomerHeader
            customerInfo={customerInfo}
            selectedpage={selectedpage}
            changePage={(page) => setPage(page)}
            dataBtnTitle={languages[lang].companyInfo}
            hideList={orders?.length == 0}
          />
          {selectedpage === "info" ? (
            <EditCompanyForm
              customerInfo={customerInfo}
              userFields={userFields}
              companyDetails={true}
            />
          ) : orders.length ? (
            <div className='cutomer-details__table-cont'>
              <OrderTable
                orders={orders}
                style='remove-border'
                removeName
                url='dashboard/companies/request'
              />
            </div>
          ) : null}
        </div>
      </NavbarAndSidebarCont>
    </div>
  );
}
