/** @format */

export const prepareOptions = (data, fields) => {
  let options = [];
  data.forEach((item) => {
    if (item.additinalCost >= 0) {
      item.additionalCost = item.additinalCost;
    }
    let option = {
      label: { ar: item.ar, en: item.en },
      value: item.id,
      [fields ? "additionalCostPerWord" : "additionalCost"]:
        item[fields ? "additionalCostPerWord" : "additionalCost"],
    };
    options = [...options, option];
  });
  return options;
};
export const prepareDate = (data) => {
  const options = { year: "numeric", month: "numeric", day: "numeric" };
  const date = data
    ? new Date(data).toLocaleDateString("en-EN", options)
    : data;
  let time =
    date +
    " | " +
    new Date(data).toLocaleTimeString("en", {
      timeStyle: "short",
      hour12: true,
    });
  return data ? time : "-";
};
