/** @format */

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import NavbarAndSidebarCont from "../../../Components/Shared/NavbarAndSidebarCont";
import { languages } from "../../../Constants/Languages";
import ClientsOrders from "./ClientsOrders";
import CompaniesOrders from "./CompaniesOrders";

import Shipments from "./Shipping";

export default function OrdersAndShipping() {
  const [loading, setLoading] = useState();
  const [isOpen, setOpen] = useState({ successModal: false, failModal: false });
  const [modalMsg, setModalMsg] = useState("");
  let { lang, dir, selectedOption } = useSelector((state) => state.lang);
  const toggleLoading = (flag) => {
    setLoading(flag);
  };
  const getModalMsg = (msg) => {
    setModalMsg(msg);
  };
  const toggleModal = (stateName) => {
    setOpen({ ...isOpen, [stateName]: !isOpen[stateName] });
  };
  return (
    <div>
      <NavbarAndSidebarCont
        isOpen={isOpen}
        loading={loading}
        toggleModal={toggleModal}
        modalMsg={modalMsg}>
        {languages[lang][selectedOption] === languages[lang].clientOrders ? (
          <ClientsOrders
            toggleModal={toggleModal}
            toggleLoading={toggleLoading}
            loading={loading}
            getModalMsg={getModalMsg}
            title={languages[lang][selectedOption]}
          />
        ) : languages[lang][selectedOption] ===
          languages[lang].companiesRequests ? (
          <CompaniesOrders
            toggleModal={toggleModal}
            toggleLoading={toggleLoading}
            loading={loading}
            getModalMsg={getModalMsg}
          />
        ) : (
          <Shipments
            toggleModal={toggleModal}
            toggleLoading={toggleLoading}
            loading={loading}
            getModalMsg={getModalMsg}
          />
        )}
      </NavbarAndSidebarCont>
    </div>
  );
}
