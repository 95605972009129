/** @format */
import "./index.scss";
import RightArrow from "../../../Assets/Images/Path 32.svg";
import LeftArrow from "../../../Assets/Images/Path 33.png";

import image from "../../../Assets/Images/Path 34.svg";
import { languages } from "../../../Constants/Languages";
import { useSelector } from "react-redux";
import { useState } from "react";

export default function Opinions() {
  let { lang, dir } = useSelector((state) => state.lang);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const opinions = languages[lang].opinionsObj
  const goToNextSlide = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % opinions.length);
  };


  const goToPreviousSlide = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? opinions.length - 1 : prevIndex - 1
    );
  };
  return (
    <section className="opinions-cont" id="opinions">
      <h3>{languages[lang].opinions}</h3>
      <div className="slider-container-NEW">
        <div className="slider-NEW">
          {opinions.map((opinion, index) => (
            <div
              className={`opinions-cont__shape-cont ${
                index === currentImageIndex
                  ? "slide-NEW active-NEW"
                  : "slide-NEW inactive"
              }`}
            >
              <div className="opinions-cont__shape-cont__main-div">
                <h5>{opinion.name}</h5>

                <span>
                  {opinion.opinion}
                </span>
              </div>
              <div className="opinions-cont__shape-cont__sec-div" />
              <div className="opinions-cont__shape-cont__third-div" />
            </div>
          ))}
        </div>
      </div>

      <div className="opinions-cont__arrows-cont">
        <img src={RightArrow} onClick={goToNextSlide} />
        <img src={RightArrow} onClick={goToPreviousSlide} />
      </div>
    </section>
  );
}
