/** @format */
import Section1Img from "../../../Assets/Images/Group 39816.png";
import BtnPorder from "../../../Assets/Images/Group 138.png";
import BtnPorder2 from "../../../Assets/Images/Group 139.png";
import "./index.scss";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { languages } from "../../../Constants/Languages";
import { useEffect, useState } from "react";
export default function AboutUsPart1() {
  let { lang, dir } = useSelector((state) => state.lang);
  const [first, setfirst] = useState("");
  useEffect(() => {
    let userData = JSON.parse(localStorage.getItem("userRole"));
    if (
      userData?.roles?.[0] === "CompanyAdmin" ||
      userData?.roles?.[0] === "CompanyUser"
    ) {
      setfirst("/company/requests/add");
    } else {
      setfirst("/translate");
    }
  }, []);

  return (
    <section
      className="section1-cont"
      lang={lang}
      dir={dir}
      id="about-us"
      style={{ position: "relative" }}
    >
      <div className="background-sec"></div>
      <div className="section1-cont__body">
        <img src={Section1Img} className="section1-cont__body__coverImg" />

        <div className="section1-cont__body__info-cont">
          <h3>{languages[lang].officeInYourHand}</h3>
          <span>{languages[lang].officeInYourHandBody}</span>
          <div className="section1-cont__body__info-cont__btn-cont">
            <Link to={first}>
              <button className="section1-cont__body__info-cont__btn-cont__firstBtn">
                <span>{languages[lang].startNow}</span>
                <img src={BtnPorder} />
              </button>
            </Link>
            <Link to="/company-application">
              <button className="section1-cont__body__info-cont__btn-cont__secBtn">
                <span>{languages[lang].companyNeeds}</span>
                <img src={BtnPorder2} />
              </button>
            </Link>
          </div>
        </div>
        {/* <div
          style={{
            background:
              "linear-gradient(90deg, rgba(252,252,252,0) 0%, rgba(229,229,229,1) 100%)",

            borderRadius: "34px",
            transform: " rotate(142deg)",
            width: "48%",
            height: "104%",
            position: "absolute",
            left: " 2%",

            top: "-47px",
          }}
        ></div> */}
      </div>
    </section>
  );
}
