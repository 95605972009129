/** @format */

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { languages } from "../../../Constants/Languages";

import InputField from "../../InputField";
let intialData = {
  ar: "",
  en: "",
  bundleClassId: "",
  bundleTypeId: "",
  wordCount: "",
  price: "",
};
let errorData = {
  ar: null,
  en: null,
  bundleClassId: null,
  bundleTypeId: null,
  wordCount: null,
  price: null,
};
export default function PackageForm(props) {
  const [disableSubmitt, toggleDisable] = useState(false);
  const [packageData, setFieldsData] = useState(intialData);
  const [errors, setErrors] = useState(errorData);
  let { lang, dir } = useSelector((state) => state.lang);

  useEffect(() => {
    if (props.packageInfo) {
      setFieldsData({ ...packageData, ...props.packageInfo });
    }
  }, [lang, props.packageInfo]);

  const getChanges = (key, value) => {
    let errorsClone = { ...errors };
    errorsClone = { ...errorsClone, [key]: null };
    let flag = Object.values(errorsClone).every((error) => error === null);
    toggleDisable(!flag);
    setErrors(errorsClone);
    setFieldsData({ ...packageData, [key]: value });
  };
  const submit = async () => {
    let errorsClone = {};

    Object.keys(packageData).forEach((field) => {
      let value =
        field === "bundleClassId" || field === "bundleTypeId"
          ? packageData[field]?.value ?? ""
          : packageData[field];

      if (!String(value)?.length) {
        errorsClone = {
          ...errorsClone,
          [field]: languages[lang].requiredField,
        };
      }
    });

    toggleDisable(Object.keys(errorsClone).length > 0);

    setErrors(errorsClone);
    if (Object.keys(errorsClone).length === 0) {
      let params = {
        ...packageData,
        bundleClassId: packageData.bundleClassId.value,
        bundleTypeId: packageData.bundleTypeId.value,
      };
      let response = await props.addPackage(params);
    }
  };
  return (
    <div className="customer-form" dir={dir} lang={lang}>
      <div className="customer-form__userFields">
        {props.packageFields.map((field) => (
          <InputField
            title={field.title + "*"}
            placeholder={field.placeholder}
            value={packageData[field.key]}
            getChanges={getChanges}
            stateName={field.key}
            errors={errors}
            icon={field.icon}
            type={field.type}
            select={field.type === "select" ? true : false}
            options={field.options}
          />
        ))}
      </div>

      {!props.isEdit ? (
        <div className="customer-form__userFields">
          <button onClick={submit} disabled={disableSubmitt}>
            {
              languages[lang][
                props.packageInfo ? "saveChanges" : "addNewPackage"
              ]
            }
          </button>
        </div>
      ) : null}
    </div>
  );
}
