/** @format */

import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import requestSentImg from "../../Assets/Images/Group 39796.svg";
import Footer from "../../Components/Footer";
import Navbar from "../../Components/Navbar";
import { languages } from "../../Constants/Languages";
import "./index.scss";
export default function RequestSent() {
  let { lang, dir } = useSelector((state) => state.lang);
  return (
    <div>
      <Navbar />
      <div className='page-cont'>
        <img src={requestSentImg} />
        <h3>{languages[lang].requestSent}</h3>
        <span>{languages[lang].ourTeamWillcontactYou}</span>
        <Link to='/'>
          <button>{languages[lang].backToHome}</button>
        </Link>
      </div>
      <Footer />
    </div>
  );
}
