/** @format */

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomerForm from "../../../../Components/AdminComponents/CutomerForm";
import CustomerHeader from "../../../../Components/Shared/CutomerHeader";
import NavbarAndSidebarCont from "../../../../Components/Shared/NavbarAndSidebarCont";
import OrderTable from "../../../../Components/Shared/OrderTable";
import { languages } from "../../../../Constants/Languages";
import UserIcon from "../../../../Assets/Images/Icon awesome-user-alt.svg";
import phoneIcon from "../../../../Assets/Images/Icon ionic-md-phone-portrait.svg";
import EyeIcon from "../../../../Assets/Images/Icon ionic-ios-eye.svg";
import EmailIcon from "../../../../Assets/Images/Icon ionic-ios-mail.svg";
import "./index.scss";
import { adminActions } from "../../../../actions/adminActions";
import { bindActionCreators } from "@reduxjs/toolkit";
let styleObject = {
  New: "yellow",
  Done: "green",
  "In Translation": "blue",
  Translated: "green",
  "Waiting Delivery": "yellow",
  "In Delivery": "blue",
  Cancelled: "red",
};
export default function CutomerDetails() {
  const [userFields, setUserFields] = useState([]);
  const [orders, setOrders] = useState([]);
  const [customerInfo, setCustomerInfo] = useState({});
  const [fields, setFields] = useState([]);
  const [selectedpage, setPage] = useState("info");
  const [emailField, setEmailField] = useState({});
  let { lang, dir } = useSelector((state) => state.lang);
  let { clientOrders } = useSelector((state) => state.admin);
  let { orderStatuses } = useSelector((state) => state.commonData);
  let dispatch = useDispatch();
  let { getClientOrdersAction } = bindActionCreators(adminActions, dispatch);

  useEffect(() => {
    let userData = JSON.parse(localStorage.getItem("clintData"));
    setCustomerInfo(userData);

    if (userData.ordersNum > 0) {
      getClientOrdersAction(userData.id, 1, 10);
    }
  }, []);

  useEffect(() => {
    if (clientOrders.data?.length) {
      let tableHeaderConstant = [
        { label: "#", key: "id" },
        { label: languages[lang].requestData, key: "createdAt" },

        { label: languages[lang].translateFrom, key: "fromLang" },
        { label: languages[lang].translateTo, key: "toLang" },
        { label: languages[lang].translationField, key: "translationField" },
        { label: languages[lang].translationType, key: "orderType" },
        { label: languages[lang].serviceType, key: "addOn" },
        { label: languages[lang].price, key: "price" },
        { label: languages[lang].translator, key: "translatorName" },
        { label: languages[lang].deleviryData, key: "fininshedAt" },
        { label: languages[lang].status, key: "status" },
      ];
      let ordersClone = [...clientOrders.data];
      ordersClone = ordersClone.map((order) => ({
        ...order,
        addOn: order.addOn[lang],
        orderType: order.orderType[lang],
        translationField: order.translationField[lang],
      }));
      let orderStatusesClone = orderStatuses.map((status) => ({
        ...status,
        num: 0,
        style: styleObject[status.en],
        label: { ar: status.ar, en: status.en },
        value: status.id,
      }));
      let modifiedOrders = [];
      ordersClone.forEach((order) => {
        let statusIndex = orderStatuses.findIndex(
          (status) => status.id === order.statusId
        );
        if (statusIndex) {
          orderStatusesClone[statusIndex].num =
            orderStatusesClone[statusIndex].num + 1;
          order = {
            ...order,
            status: orderStatuses[statusIndex][lang],
          };
        }
        modifiedOrders = [...modifiedOrders, order];
      });
      setFields(tableHeaderConstant);
      setOrders(modifiedOrders);
    }

    let userFieldsConst = [
      {
        title: languages[lang].name,
        key: "fullName",
        icon: UserIcon,
        placeholder: languages[lang].pleaseWriteName,
        disabled: true,
      },
      {
        title: languages[lang].phoneNum,
        key: "phoneNumber",
        icon: phoneIcon,
        placeholder: languages[lang].pleaseWritePhone,
        type: "phoneNumber",
        disabled: true,
      },
    ];

    setEmailField({
      title: languages[lang].email,
      key: "email",
      icon: EmailIcon,
      placeholder: languages[lang].pleaseWriteEmail,
      disabled: true,
    });
    setUserFields(userFieldsConst);
  }, [lang, clientOrders.data?.length]);

  return (
    <div>
      <NavbarAndSidebarCont>
        <div className={`cutomer-details`}>
          <CustomerHeader
            customerInfo={customerInfo}
            selectedpage={selectedpage}
            changePage={(page) => setPage(page)}
            dataBtnTitle={languages[lang].customerInfo}
            hideList={
              customerInfo.ordersNum === 0 || clientOrders.data?.length === 0
            }
          />
          {selectedpage === "info" ? (
            <CustomerForm
              userFieldsConst={userFields}
              emailField={emailField}
              data={customerInfo}
              hideFooter
            />
          ) : (
            <div
              className="cutomer-details__table-cont"
              style={{ height: "60vh" }}
            >
              <OrderTable
                orders={orders}
                removeHeaderBorder
                removeName
                constants={fields}
                saveToSessionStorege="orderDetails"
                url="dashboard/customers/request"
              />
            </div>
          )}
        </div>
      </NavbarAndSidebarCont>
    </div>
  );
}
