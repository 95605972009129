/** @format */

import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { languages } from "../../Constants/Languages";
import checked from "../../Assets/Images/Path 11461.svg";
import cartImg from "../../Assets/Images/Group 39860.svg";
import "./indes.scss";
export default function BundleCard(props) {
  let { lang, dir } = useSelector((state) => state.lang);
  const { bundleInfo } = useSelector((state) => state.company);

  return (
    <div className="bundle-card">
      <h3
        className={`bundle-card__title  ${
          props.bundle.id === bundleInfo.bundleId
            ? "bundle-card__title--selected"
            : null
        }`}
      >
        {props.bundle[lang]}
      </h3>
      <span className="bundle-card__price">
        {" "}
        {props.bundle.price} {languages[lang].DK}
      </span>

      <span className="bundle-card__info">{props.bundle.wordCount} {languages[lang].word}</span>
      <span className="bundle-card__info">
        {languages[lang].subscriptionPeriod}:{props.bundle?.bundleType[lang]}
      </span>
      <button
        className={`bundle-card__btn ${
          props.bundle.id === bundleInfo.bundleId
            ? "bundle-card__btn--selected"
            : null
        }`}
        onClick={(e) => props.buyBundle(props.bundle)}
      >
        {props.bundle.id === bundleInfo.bundleId ? (
          <>
            {" "}
            <img src={checked} /> {languages[lang].currentBundle}
          </>
        ) : (
          <>
            {" "}
            <img src={cartImg} /> {languages[lang].buyTheBundle}
          </>
        )}
      </button>
    </div>
  );
}
