/** @format */

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { languages } from "../../../Constants/Languages";
import translators from "../../../Assets/Images/Layer 2.svg";
import comapnies from "../../../Assets/Images/Group 39840.svg";
import customers from "../../../Assets/Images/Path 11439.svg";
import canceled from "../../../Assets/Images/Group 39839.svg";
import fulfilled from "../../../Assets/Images/Group 39838.svg";
import underway from "../../../Assets/Images/Layer_1.svg";
import graph1 from "../../../Assets/Images/6G2iUx01 (1).svg";
import graph2 from "../../../Assets/Images/wh9DH501.svg";
import Eye from "../../../Assets/Images/Icon awesome-eye.svg";
import "./index.scss";
import { Link } from "react-router-dom";
import OrderTable from "../../Shared/OrderTable";
export default function Statistics(props) {
  const [statistics, setStatistics] = useState([]);
  let { lang, dir } = useSelector((state) => state.lang);

  useEffect(() => {
    let statisticsClone = [
      {
        title: languages[lang].numOfOrders,
        num: 246,
        img: graph1,
        customWidth: true,
      },
      { title: languages[lang].fulfilledOrders, num: 246, img: fulfilled },
      { title: languages[lang].underway, num: 246, img: underway },
      { title: languages[lang].canceldOrders, num: 246, img: canceled },
      {
        title: languages[lang].monthIncome,
        num: "3500 د.ك",
        img: graph2,
        customWidth: true,
      },
      { title: languages[lang].customerNum, num: 2765, img: customers },
      { title: languages[lang].companiesNum, num: 67, img: comapnies },
      { title: languages[lang].translatorsNum, num: 6, img: translators },
    ];

    setStatistics(statisticsClone);
  }, [lang]);
  return (
    <section dir={dir} lang={lang} className={`statistics-cont`}>
      <div className="statistics-cont__statistics">
        {statistics.map((statistic) => (
          <div
            className={`statistics-cont__statistics__single-card ${
              statistic.customWidth &&
              "statistics-cont__statistics__single-card--customWidth"
            }`}
          >
            <label>{statistic.title} </label>
            <div
              className="statistics-cont__statistics__single-card__info"
              dir={dir}
              lang={lang}
            >
              <span>{statistic.num} </span>
              <img src={statistic.img} />
            </div>
          </div>
        ))}
      </div>
      <div className="statistics-cont__table-cont">
        <div
          className="statistics-cont__table-cont__header"
          dir={dir}
          lang={lang}
        >
          <h4>{languages[lang].requests}</h4>
          <Link to="/dashboard/requests&shipping">
            <button>
              <img src={Eye} />
              {"  "}
              {languages[lang].seeAll}
            </button>
          </Link>
        </div>

        <OrderTable
          orders={props.orders}
          saveToSessionStorege={"orderDetails"}
          url="admin-dashboard/requests"
        />
      </div>
    </section>
  );
}
