/** @format */

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import InputField from "../../../../../Components/InputField";
import NavbarAndSidebarCont from "../../../../../Components/Shared/NavbarAndSidebarCont";
import Add from "../../../../../Assets/Images/Icon ionic-ios-add-circle.svg";
import Remove from "../../../../../Assets/Images/Icon material-delete.svg";
import { languages } from "../../../../../Constants/Languages";
import { setLanguages } from "../../../../../Redux/commonData";
import { getLanguages } from "../../../../../Services/commonServices";
import priceIcon from "../../../../../Assets/Images/Capture.PNG";
import { adminActions } from "../../../../../actions/adminActions";

import "./index.scss";
import { Navigate } from "react-router-dom";
let countForm = { min: null, max: null, price: null, rowKey: 0 };
export default function EditLangPricing() {
  const [loading, setLoading] = useState(true);
  const [isOpen, setOpen] = useState({ successModal: false, failModal: false });
  const [modalMsg, setModalMsg] = useState("");
  const [languagesList, setList] = useState([]);
  const [redirect, setRedireact] = useState(false);
  const [language, setLang] = useState({
    fromLanguageId: null,
    toLanguageId: null,
  });
  const [errors, setErrors] = useState({
    fromLanguageId: null,
    toLanguageId: null,
  });
  const [disableSubmitt, toggleDisable] = useState(false);
  const [countArray, setCount] = useState([countForm]);
  let { lang, dir } = useSelector((state) => state.lang);
  let { languagesData } = useSelector((state) => state.commonData);
  let dispatch = useDispatch();

  useEffect(() => {
    if (languagesData.length) {
      setLoading(false);
      if (!window.location.pathname.includes("langPrice/add")) {
        let data = JSON.parse(localStorage.getItem("langData"));

        let langData = {
          fromLanguageId: {
            label: { ar: data.fromLanguageName, en: data.fromLanguageId },
            value: data.fromLanguageId,
          },
          toLanguageId: {
            label: { ar: data.toLanguageName, en: data.toLanguageName },
            value: data.toLanguageId,
          },
        };
        let tolangList = languagesData.filter(
          (language) => language.value !== langData.toLanguageId.value
        );
        setList(tolangList);

        setLang(langData);
        adminActions
          .getWordPricingAction(data.fromLanguageId, data.toLanguageId)
          .then((res) => {
            setCount(
              res.data.data.length
                ? res.data.data.map((row, index) => ({ ...row, rowKey: index }))
                : [countForm]
            );
          });
      }
    }
  }, [languagesData]);
  const getChanges = (key, value) => {
    if (key === "fromLanguageId") {
      let tolangList = languagesData.filter(
        (language) => language.value !== value.value
      );
      setList(tolangList);
    }
    let errorsClone = { ...errors };
    errorsClone = { ...errorsClone, [key]: null };
    let flag = Object.values(errorsClone).every((error) => error === null);
    toggleDisable(!flag);
    setErrors(errorsClone);
    setLang({ ...language, [key]: value });
  };
  const getCount = (stateName, value, id, rowKey) => {
    let countArrayClone = [...countArray];
    let selectedFormIndex = countArrayClone.findIndex(
      (form) => form.rowKey === rowKey
    );
    countArrayClone[selectedFormIndex] = {
      ...countArrayClone[selectedFormIndex],
      [stateName]: value,
    };
    setCount(countArrayClone);
  };
  const addForm = () => {
    let newForm = {
      ...countForm,
      rowKey: countArray[countArray.length - 1].rowKey + 1,
    };
    setCount([...countArray, newForm]);
  };
  const removeForm = (rowKey) => {
    setCount(countArray.filter((form) => form.rowKey !== rowKey));
  };
  const toggleModal = (stateName) => {
    setOpen({ ...isOpen, [stateName]: !isOpen[stateName] });
    if (stateName === "successModal" && isOpen.successModal) {
      setRedireact(!redirect);
    }
  };
  const addLangPrice = async () => {
    let errorsClone = {};

    Object.keys(language).forEach((field) => {
      if (!language[field]?.value.length) {
        errorsClone = {
          ...errorsClone,
          [field]: languages[lang].requiredField,
        };
      }
    });
    toggleDisable(Object.keys(errorsClone).length > 0);
    setErrors(errorsClone);
    if (Object.keys(errorsClone).length === 0) {
      setLoading(true);
      let params = {
        fromLanguageId: language.fromLanguageId?.value,
        toLanguageId: language.toLanguageId?.value,
        wordPrices: countArray,
      };
      let res = await adminActions.addLangPriceAction(params);
      if (res.data.isSuccess) {
        toggleModal("successModal");
        setModalMsg(res.data?.message?.[lang] ?? "Done");
      } else {
        toggleModal("failModal");
        setModalMsg(res.data?.message?.[lang]);
      }
    }
    setLoading(false);
  };
  return (
    <NavbarAndSidebarCont
      isOpen={isOpen}
      loading={loading}
      toggleModal={toggleModal}
      modalMsg={modalMsg}>
      <h4 className='lang-header-title' lang={lang} dir={dir}>
        {languages[lang].editLangPricing}
      </h4>
      <div className='Lang-price'>
        <div className='Lang-price__body' lang={lang} dir={dir}>
          <div className='Lang-price__body__select-cont'>
            <InputField
              title={languages[lang].translateFrom + " *"}
              placeholder={languages[lang].chooseLang}
              value={language.fromLanguageId}
              getChanges={getChanges}
              stateName={"fromLanguageId"}
              options={languagesData}
              select
              errors={errors}
            />
            &nbsp;&nbsp;
            <InputField
              title={languages[lang].translateTo + " *"}
              placeholder={languages[lang].chooseLang}
              value={language.toLanguageId}
              getChanges={getChanges}
              stateName={"toLanguageId"}
              options={languagesList}
              select
              errors={errors}
            />
          </div>
          <div>
            <div className='customer-form__checkBox-cont__title'>
              <span>{languages[lang].wordPrice}</span>
              <hr />
            </div>
            <br />
            {countArray.map((form, index) => (
              <div className='Lang-price__body__priceForm' key={form.rowKey}>
                <InputField
                  title={languages[lang].countFrom}
                  placeholder={"0"}
                  value={form.min}
                  getChanges={getCount}
                  stateName={"min"}
                  id={form.id}
                  rowKey={form.rowKey}
                  type='number'
                  disabled={form.id}
                />
                &nbsp;
                <InputField
                  title={languages[lang].countTo}
                  placeholder={"0"}
                  value={form.max}
                  getChanges={getCount}
                  stateName={"max"}
                  id={form.id}
                  rowKey={form.rowKey}
                  type='number'
                  disabled={form.id}
                />
                &nbsp;
                <InputField
                  title={languages[lang].wordPrice}
                  placeholder={"0"}
                  value={form.price}
                  getChanges={getCount}
                  stateName={"price"}
                  id={form.id}
                  rowKey={form.rowKey}
                  type='number'
                  icon={priceIcon}
                  disabled={form.id}
                />
                {index === countArray.length - 1 && (
                  <img src={Add} onClick={addForm} />
                )}
                <img src={Remove} onClick={() => removeForm(form.rowKey)} />
              </div>
            ))}
          </div>
          <button disabled={disableSubmitt} onClick={addLangPrice}>
            {languages[lang].add}
          </button>
          {redirect ? <Navigate to='/dashboard/settings' /> : null}
        </div>
      </div>
    </NavbarAndSidebarCont>
  );
}
