/** @format */
/** @format */
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Navigate } from "react-router-dom";
import { languages } from "../../../Constants/Languages";
import { setLanguages } from "../../../Redux/commonData";
import { getLanguages } from "../../../Services/commonServices";
import {
  sendTranslationData,
  sendtranslationReq,
  sendCompanyTranslationReq,
} from "../../../Services/companyServices";
import FirstPhaseTranslation from "../../TraslateComponets/FirstPhaseTranslation";
import ProgressBar from "../../TraslateComponets/ProgressBar";
import SeconedPhase from "../../TraslateComponets/SeconedPhase";
import ThiredPhase from "../../TraslateComponets/ThiredPhase";
import ErrorSVG from "../../../Assets/Images/Layer 5.svg";
import cartImg from "../../../Assets/Images/Group 39860.svg";
import Loader from "../Loader";
import SuccessAndFailModal from "../../Modals/SuccessAndFailModal";
const addressDataInit = {
  city: null,
  streetAddress: null,
  mailNum: null,
};
export default function TranslateForm(props) {
  const [files, setFiles] = useState([]);
  const [percentCompleted, setPercentCompleted] = useState(0);
  const [errors, setErrors] = useState();
  const [isOpen, setOpen] = useState({
    successModal: false,
    failModal: false,
    PaymentModal: false,
  });
  const [modalMsg, setModalMsg] = useState("");
  const [loading, toggleLoading] = useState(false);
  const [phaseNum, setPhaseNum] = useState(1);
  const [postFileRes, setPostFileRes] = useState({});
  const [renavigate, setRenavigate] = useState(false);
  const { bundleInfo } = useSelector((state) => state.company);
  let { lang, dir } = useSelector((state) => state.lang);

  const [addressData, setAddressData] = useState(addressDataInit);
  const [showAddressField, toggleAddressFields] = useState(false);
  const [fieldsData, setData] = useState([]);

  let dispatch = useDispatch();
  useEffect(() => {
    getLanguages().then((res) => {
      if (res.data.isSuccess) {
        let options = res.data.data.map((language) => {
          return {
            label: { ar: language.name, en: language.name },
            value: language.id,
          };
        });
        dispatch(setLanguages(options));
      }
    });
    let fileRes = JSON.parse(window.localStorage.getItem("firstPhaseData"));

    setPhaseNum(fileRes?.phaseNum ? fileRes?.phaseNum : 1);

    setPostFileRes(fileRes);
  }, []);
  const toggleModal = (stateName) => {
    setOpen({ ...isOpen, [stateName]: !isOpen[stateName] });
  };
  const toggleLoadingState = (flag) => {
    toggleLoading(flag);
  };
  const changePhaseNum = (num) => {
    setPhaseNum(num);
  };
  const postFiles = async (files, selectedLanguagesIds, selectedLanguages) => {
    setFiles(files);
    let config = {};
    let params = {};
    let form = new FormData();
    if (typeof files === "string") {
      params = { ...selectedLanguagesIds, text: files, type: "TXT" };
      form.append("text", files);
      form.append("type", "TXT");
      toggleLoading(true);
    } else {
      params = { ...selectedLanguagesIds, file: files[0], type: "FILE" };
      form.append("file", files[0]);
      form.append("type", "FILE");
      config = {
        onUploadProgress: function (progressEvent) {
          var percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setPercentCompleted(percentCompleted);
          if (percentCompleted == 100) {
            toggleLoading(true);
          }
        },
      };
    }

    // form.append("file", files[0]);

    form.append("formLangId", params.formLangId);
    form.append("toLangId", params.toLangId);

    let response = await sendTranslationData(form, params.type, config);
    if (!response.data.isSuccess) {
      if (response.data.errors) {
        let errorObj = {};
        Object.keys(response.data.errors).forEach((errorKey) => {
          errorObj = {
            ...errorObj,
            [errorKey]: response.data.errors[errorKey].join(", "),
          };
        });
        setErrors(errorObj);
      } else if (response.data.message.ar.length) {
        toggleModal("failModal");
        setModalMsg(response.data.message[lang]);
      }
    } else {
      let phaseOneData = { ...selectedLanguages, ...response.data.data };
      phaseOneData = {
        ...phaseOneData,
        phaseNum: 2,
        selectedLanguages: selectedLanguages,
      };

      setPostFileRes(phaseOneData);
      window.localStorage.setItem(
        "firstPhaseData",
        JSON.stringify(phaseOneData)
      );
      setPhaseNum(2);
    }
    toggleLoading(false);
  };
  const sendReq = async (params) => {
    let userData = JSON.parse(localStorage.getItem("userRole"));

    if (userData?.token?.length) {
      toggleLoading(true);
      let response = {};

      if (props.userType === "company") {
        delete params.addOnId;
        response = await sendCompanyTranslationReq(params);
      } else {
        response = await sendtranslationReq(params);
      }

      if (response.data.isSuccess) {
        // setRenavigate(true);

        window.localStorage.removeItem("firstPhaseData");

        window.location.href = response?.data?.data?.data?.paymentURL;
      } else {
        toggleModal("failModal");
        setModalMsg(response.data.message[lang]);
      }
      toggleLoading(false);
    } else {
      props.openLogin();
    }
  };
  const [dataParams, setDataParams] = useState({});

  return (
    <section id="translat-cont" className="translat-cont" lang={lang} dir={dir}>
      {props.userType === "company" &&
      bundleInfo.reminder < postFileRes?.wordCount ? (
        <div className="translat-cont__errorMsg">
          <img src={ErrorSVG} />
          {languages[lang].noEnoughWords}
          <Link to="/company/packages">
            <span className="customNav-cont__reminder__buy-bundle">
              <img src={cartImg} />
              {languages[lang].buyBundle}
            </span>
          </Link>
        </div>
      ) : null}

      <h3>{languages[lang].startTranslateNow}</h3>
      <ProgressBar
        phaseNum={phaseNum}
        changePhaseNum={changePhaseNum}
        userType={props.userType}
      />
      {phaseNum === 1 ? (
        <FirstPhaseTranslation
          percentCompleted={percentCompleted}
          postFiles={postFiles}
          errors={errors}
        />
      ) : phaseNum === 2 ? (
        <SeconedPhase
          selectedLanguages={postFileRes.selectedLanguages}
          wordCount={postFileRes.wordCount}
          wordPrice={postFileRes.wordPrice}
          toggleLoading={toggleLoadingState}
          postFileRes={postFileRes}
          setPhaseNum={setPhaseNum}
          userType={props.userType}
          setDataParams={setDataParams}
          openLogin={props.openLogin}
          setAddressData={setAddressData}
          toggleAddressFields={toggleAddressFields}
          addressData={addressData}
          showAddressField={showAddressField}
          setData={setData}
          fieldsData={fieldsData}
        />
      ) : (
        <ThiredPhase
          selectedLanguages={postFileRes.selectedLanguages}
          dataParams={dataParams}
          sendReq={sendReq}
          wordCount={postFileRes.wordCount}
          wordPrice={postFileRes.wordPrice}
          setAddressData={setAddressData}
          toggleAddressFields={toggleAddressFields}
          addressData={addressData}
          showAddressField={showAddressField}
          setData={setData}
          setPhaseNum={setPhaseNum}
          openLogin={props.openLogin}
          fieldsData={fieldsData}
        />
      )}
      <Loader loading={loading} />
      <SuccessAndFailModal
        toggleModal={toggleModal}
        modalName={"failModal"}
        open={isOpen.failModal}
        msg={modalMsg}
        buttonTitle={languages[lang].ok}
      />

      {renavigate ? <Navigate to="/request-sent" /> : null}
    </section>
  );
}
