/** @format */

import { bindActionCreators } from "@reduxjs/toolkit";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { adminActions } from "../../../../actions/adminActions";
import { languages } from "../../../../Constants/Languages";
import Orders from "../Orders";

export default function CompaniesOrders(props) {
  const [orders, setOrders] = useState();
  const [searchValue, setSearch] = useState("");
  const [headerConstant, setHeaderConstant] = useState([]);
  let { companiesOrders } = useSelector((state) => state.admin);
  let { lang, dir, selectedOption } = useSelector((state) => state.lang);
  let dispatch = useDispatch();
  let { getCompaniesOrdersAction } = bindActionCreators(adminActions, dispatch);
  useEffect(() => {
    getCompaniesOrdersAction(1);
  }, []);
  useEffect(() => {
    if (companiesOrders.data) {
      let ordersClone = [...companiesOrders.data];
      ordersClone = ordersClone.map((order) => ({
        ...order,

        orderType: order.orderType[lang],
        translationField: order.translationField[lang],
      }));

      setOrders(ordersClone);
    }
  }, [companiesOrders.data?.length, lang]);
  useEffect(() => {
    let tableHeaderConstant = [
      { label: "#", key: "id" },
      { label: languages[lang].requestData, key: "createdAt" },
      { label: languages[lang].name, key: "companyName" },
      { label: languages[lang].translateFrom, key: "fromLang" },
      { label: languages[lang].translateTo, key: "toLang" },
      { label: languages[lang].translationField, key: "translationField" },
      { label: languages[lang].translationType, key: "orderType" },
      // { label: languages[lang].serviceType, key: "addOn" },
      { label: languages[lang].price, key: "price" },
      { label: languages[lang].translator, key: "translatorName" },
      { label: languages[lang].deleviryData, key: "fininshedAt" },
      { label: languages[lang].status, key: "status" },
    ];
    setHeaderConstant(tableHeaderConstant);
  }, [lang]);
  const getSearchValues = async (searchString) => {
    props.toggleLoading(true);
    setSearch(searchString);
    let res = await getCompaniesOrdersAction(1, searchString);
    props.toggleLoading(false);
  };
  const getPageData = async (pageNum) => {
    if (pageNum !== companiesOrders.pagination.CurrentPage) {
      props.toggleLoading(true);
      await getCompaniesOrdersAction(pageNum, searchValue);
      props.toggleLoading(false);
    }
  };
  return (
    <Orders
      saveToSessionStorege='orderDetails'
      constants={headerConstant}
      title={languages[lang][selectedOption]}
      orders={orders}
      toggleLoading={props.toggleLoading}
      loading={props.loading}
      pagination={companiesOrders?.pagination}
      getSearchValues={getSearchValues}
      style='remove-name'
      getPageData={getPageData}
    />
  );
}
