/** @format */

import { useEffect } from "react";
import Footer from "../../Components/Footer";
import AboutUsPart1 from "../../Components/HomeCompoents/AboutUsPart1";
import AboutUsPart2 from "../../Components/HomeCompoents/AboutUsPart2";
import ContactUs from "../../Components/HomeCompoents/ContactUs";

import HowToUse from "../../Components/HomeCompoents/HowToUse";

import Opinions from "../../Components/HomeCompoents/Opinions";
import OurTranslator from "../../Components/HomeCompoents/OurTranslator";
import Partners from "../../Components/HomeCompoents/Partners";
import WhatWeOfferPart1 from "../../Components/HomeCompoents/WhatWeOfferPart1";
import WhatWeOfferPart2 from "../../Components/HomeCompoents/WhatWeOfferPart2";
import Navbar from "../../Components/Navbar";

export default function Home() {
  useEffect(() => {
    localStorage.removeItem("firstPhaseData");
    // localStorage.removeItem("userRole");
  }, []);
  return (
    <div>
      <Navbar />
      <AboutUsPart1 />
      <AboutUsPart2 />
      <WhatWeOfferPart1 />
      <WhatWeOfferPart2 />
      <HowToUse />
      <OurTranslator />
      {/* <Partners /> */}
      <Opinions />
      {/* <ContactUs /> */}
      <Footer />
    </div>
  );
}
