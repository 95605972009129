/** @format */
import { useState, useEffect } from "react";
import Checkbox from "@mui/material/Checkbox";
import Bill from "../../Bill";
import InputField from "../../InputField";
import ArrowIcon from "../../../Assets/Images/Icon ionic-ios-arrow-up.svg";
import "./index.scss";
import { useDispatch, useSelector } from "react-redux";
import { languages } from "../../../Constants/Languages";
import {
  getAddOns,
  getCities,
  getOrderTypes,
  getTranslationFields,
} from "../../../Services/commonServices";
import {
  setAddOns,
  setCities,
  setOrderTypes,
  setTranslationFields,
} from "../../../Redux/commonData";
import {
  getEstimateDue,
  sendCompanyTranslationReq,
} from "../../../Services/companyServices";

const fieldsDataInit = {
  formLangId: null,
  toLangId: null,
  translationField: null,
  translationType: null,
  serviceType: null,
};

const addressErrorInit = {
  city: null,
  streetAddress: null,
  mailNum: null,
};
const errorsInit = {
  formLangId: null,
  toLangId: null,
  translationField: null,
  translationType: null,
  serviceType: null,
};
export default function SeconedPhase(props) {
  const {
    setAddressData,
    toggleAddressFields,
    addressData,
    showAddressField,
    fieldsData,
    setData,
  } = props;
  const [disableNext, toggleDisable] = useState(false);
  const [fieldsConstant, setFields] = useState([]);
  const [isOpen, setOpen] = useState({
    successModal: false,
    failModal: false,
    PaymentModal: false,
  });
  const toggleModal = (stateName) => {
    setOpen({ ...isOpen, [stateName]: !isOpen[stateName] });
  };
  const [addressConstant1, setAddressConstant1] = useState([]);
  const [addressConstant2, setAddressConstant2] = useState([]);
  const [addressError, setAddressError] = useState(addressErrorInit);
  const [errors, setErrors] = useState([]);
  const { bundleInfo } = useSelector((state) => state.company);
  // const [addressData, setAddressData] = useState(addressDataInit);
  // const [showAddressField, toggleAddressFields] = useState(false);

  let { lang, dir } = useSelector((state) => state.lang);
  let { orderTypes, addOns, translationFields, cities, languagesData } =
    useSelector((state) => state.commonData);
  let dispatch = useDispatch();
  useEffect(() => {
    (async () => {
      props.toggleLoading(true);
      getOrderTypes().then((typesRes) => {
        if (typesRes.data.isSuccess) {
          let options = prepareOptions(typesRes.data.data);
          dispatch(setOrderTypes(options));
        }
      });
      getAddOns().then((res) => {
        if (res.data.isSuccess) {
          let options = prepareOptions(res.data.data);
          dispatch(setAddOns(options));
        }
      });
      getTranslationFields().then((res) => {
        if (res.data.isSuccess) {
          let options = prepareOptions(res.data.data, "fields");
          dispatch(setTranslationFields(options));
        }
      });
      getCities().then((res) => {
        if (res.data.isSuccess) {
          let options = prepareOptions(res.data.data);
          dispatch(setCities(options));
        }
      });
    })();
  }, []);
  useEffect(() => {
    if (props.userType === "company") {
      let initialData = { ...fieldsDataInit };
      delete initialData.serviceType;

      setData(initialData);
      setErrors(initialData);
    }
  }, []);
  useEffect(() => {
    let requestData = JSON.parse(localStorage.getItem("firstPhaseData"));
    if (requestData.seconedPhaseData) {
      setData(requestData.seconedPhaseData);
      toggleAddressFields(requestData.showAddressField);
      setAddressData(requestData.addressData);
    } else {
      setData({ ...fieldsData, ...props.selectedLanguages });
    }
  }, [props.selectedLanguages]);
  useEffect(() => {
    if (
      orderTypes.length &&
      addOns.length &&
      translationFields.length &&
      cities.length &&
      languagesData.length
    ) {
      props.toggleLoading(false);
      let {
        chooseLang,
        langOfTheText,
        translateLang,
        translateLangTo,
        translationField,
        chooseTranslationField,
        chooseType,
        translationType,
        serviceType,
        chooseServiesType,
        city,
        chooseCity,
        address,
        writeTheAddress,
        writeBuildingNum,
        buildingNum,
        floor,
        writeFloorNum,
        automatedFigure,
        writeZipCode,
      } = languages[lang];
      let fields = [
        {
          title: langOfTheText,
          placeholder: chooseLang,
          key: "formLangId",
          type: "select",
          options: languagesData,
          disabled: true,
        },
        {
          title: translateLang,
          placeholder: translateLangTo,
          key: "toLangId",
          type: "select",
          options: languagesData,
          disabled: true,
        },

        {
          title: translationField + " *",
          placeholder: chooseTranslationField,
          key: "translationField",
          type: "select",
          options: translationFields,
        },
        {
          title: translationType + " *",
          placeholder: chooseType,
          key: "translationType",
          type: "select",
          options: orderTypes,
        },
        {
          title: serviceType + " *",
          placeholder: chooseServiesType,
          key: "serviceType",
          type: "select",
          options: addOns,
        },
      ];
      if (props.userType === "company") {
        fields = fields.filter((field) => field.key !== "serviceType");
      }

      const addressFirstRow = [
        {
          title: city,
          placeholder: chooseCity,
          key: "city",
          type: "select",
          icon: ArrowIcon,
          options: cities,
        },
        {
          title: address + " *",
          placeholder: writeTheAddress,
          key: "streetAddress",
          type: "input",
        },
      ];
      const addressSeconedRow = [
        {
          title: automatedFigure,
          placeholder: writeZipCode,
          key: "mailNum",
          type: "input",
        },
      ];
      setFields(fields);
      setAddressConstant1(addressFirstRow);
      setAddressConstant2(addressSeconedRow);
    }
  }, [lang, orderTypes, addOns, translationFields, cities, languagesData]);
  const prepareOptions = (data, fields) => {
    let options = [];
    data.forEach((item) => {
      if (item.additinalCost >= 0) {
        item.additionalCost = item.additinalCost;
      }
      let option = {
        label: { ar: item.ar, en: item.en },
        value: item.id,
        [fields ? "additionalCostPerWord" : "additionalCost"]:
          item[fields ? "additionalCostPerWord" : "additionalCost"],
      };
      options = [...options, option];
    });
    return options;
  };

  const getChanges = (key, value) => {
    let errorClone = { ...errors };
    errorClone[key] = null;
    let validErrors = Object.values(errorClone).filter(
      (errorValue) => errorValue
    );
    if (validErrors.length === 0) {
      toggleDisable(false);
    }
    setErrors(errorClone);
    setData({ ...fieldsData, [key]: value });
    value = key === "translationType" ? value : fieldsData.translationType;
    if (value.value === "old-logic-that-I-dont-have-the-power-to-tackle-rn") {
      toggleAddressFields(true);
    } 
  };
  const getAddressData = (key, value) => {
    let errorClone = { ...addressError };
    errorClone[key] = null;
    let validErrors = Object.values(errorClone).filter(
      (errorValue) => errorValue
    );
    if (validErrors.length === 0) {
      toggleDisable(false);
    }
    setAddressError(errorClone);
    setAddressData({ ...addressData, [key]: value });
  };
  const prepareTotalPrice = () => {
    let translationFieldCost = fieldsData.translationField
      ?.additionalCostPerWord
      ? props.wordCount * fieldsData.translationField.additionalCostPerWord
      : 0;
    let translationTypeCost = fieldsData.translationType?.additionalCost
      ? fieldsData.translationType.additionalCost * props.wordCount
      : 0;
    let serviceTypeCost = fieldsData.serviceType?.additionalCost
      ? fieldsData.serviceType.additionalCost * props.wordCount
      : 0;
    let cityCost = addressData.city?.additionalCost
      ? addressData.city?.additionalCost
      : 0;
    let wordsPrice =
      props.userType === "company" ? 0 : props.wordCount * props.wordPrice;
    if (props.userType === "company") {
      return "0.00";
    } else {
      return (
        wordsPrice +
        translationFieldCost +
        translationTypeCost +
        serviceTypeCost +
        (showAddressField ? cityCost : 0)
      ).toFixed(3);
    }
  };
  const sendRequest = async () => {
    let errorsClone = {};
    let flag = false;
    Object.keys(fieldsData).forEach((field) => {
      if (!fieldsData[field]?.value.length) {
        flag = true;
        errorsClone = {
          ...errorsClone,
          [field]: languages[lang].requiredField,
        };
      }
    });
    if (showAddressField) {
      let addressErrorClone = {};
      Object.keys(addressData).forEach((field) => {
        if (field === "mailNum") {
          
        } else if (
          field === "city"
            ? !addressData[field]?.value.length
            : false || !addressData[field]?.length
        ) {
          flag = true;
          addressErrorClone = {
            ...addressErrorClone,
            [field]: languages[lang].requiredField,
          };
        }
      });
      setAddressError(addressErrorClone);
    }

    setErrors(errorsClone);
    if (flag) {
      toggleDisable(true);
    } else {
      let firstPhaseData = JSON.parse(localStorage.getItem("firstPhaseData"));
      firstPhaseData = {
        ...firstPhaseData,
        seconedPhaseData: fieldsData,
        showAddressField: showAddressField,
        addressData: addressData,
      };
      localStorage.setItem("firstPhaseData", JSON.stringify(firstPhaseData));
      let params = {
        wordpriceId: props.postFileRes.wordPriceId,
        translationFieldId: fieldsData.translationField.value,
        orderTypeId: fieldsData.translationType.value,
        addOnId: fieldsData?.serviceType?.value,
        documentId: props.postFileRes.documentId,
        withDelivery: showAddressField,
        totalPrice: prepareTotalPrice(),
      };
      if (showAddressField) {
        let addressParams = {
          cityId: addressData.city.value,
          details: addressData.streetAddress,
          autoNumber: addressData.mailNum,
        };
        params = { ...params, address: addressParams };
      }
      let userData = JSON.parse(localStorage.getItem("userRole"));

      if (userData?.token?.length) {
        if (
          props.userType === "company" &&
          bundleInfo.reminder < firstPhaseData.wordCount
        ) {
          window.location.href = "/company/packages";
          return false;
        }
        if (props.userType === "company" && params.totalPrice === "0.00") {
          delete params.addOnId;
          let response = await sendCompanyTranslationReq(params);

          window.location.href = "/company/requests";
          return false;
        }

        let response = await getEstimateDue(params);
        if (response.status === 200) {
          params = { ...params, estimateDue: response.data.data };
        }

        props.setDataParams(params);
        // toggleModal("PaymentModal");
        props.setPhaseNum(3);
      } else {
        props.openLogin();
      }
      // props.sendReq(params);
    }
  };
  return (
    <section className="seconed-phase" dir={dir} lang={lang}>
      <Bill
        selectedLanguages={props.selectedLanguages}
        wordCount={props.wordCount}
        wordPrice={props.wordPrice}
        fieldsData={fieldsData}
        addressData={addressData}
        prepareTotalPrice={prepareTotalPrice}
        userType={props.userType}
        showAddressField={showAddressField}
        setPhaseNum={props.setPhaseNum}
      />
      <div
        className="seconed-phase__inputFields"
        style={{ textAlign: "start" }}
        dir={dir}
        lang={lang}
      >
        {" "}
        <div className="seconed-phase__inputFields__primary-fields">
          {fieldsConstant.map((field) => (
            <InputField
              key={field.key}
              title={field.title}
              placeholder={field.placeholder}
              value={fieldsData[field.key]}
              getChanges={getChanges}
              stateName={field.key}
              errors={errors}
              select
              options={field.options}
              icon={ArrowIcon}
              disabled={field.disabled}
            />
          ))}

          <div
            className="seconed-phase__inputFields__primary-fields__address-checkbox"
            onClick={() => {
              return toggleAddressFields(!showAddressField);
            }}
          >
            <Checkbox
              label="تذكرني"
              checked={showAddressField}
              sx={{
                color: "#1E8AB2",
                "&.Mui-checked": {
                  color: "#1E8AB2",
                },
              }}
            />{" "}
            <span>{languages[lang].sendTranslationToMyAddress}</span>
          </div>
        </div>
        {showAddressField ? (
          <>
            <div className="seconed-phase__inputFields__address-firstRow">
              {addressConstant1.map((field) => (
                <InputField
                  key={field.key}
                  title={field.title}
                  placeholder={field.placeholder}
                  value={addressData[field.key]}
                  getChanges={getAddressData}
                  stateName={field.key}
                  errors={addressError}
                  select={field.type === "select"}
                  options={field.options}
                  icon={field.icon}
                />
              ))}
            </div>
            <div className="seconed-phase__inputFields__address-seconedRow">
              {addressConstant2.map((field) => (
                <InputField
                  key={field.key}
                  title={field.title}
                  placeholder={field.placeholder}
                  value={addressData[field.key]}
                  getChanges={getAddressData}
                  stateName={field.key}
                  errors={addressError}
                  select={field.type === "select"}
                  options={field.options}
                />
              ))}
            </div>
          </>
        ) : null}
        <button
          onClick={sendRequest}
          disabled={
            disableNext ||
            fieldsData?.translationField?.value == null ||
            fieldsData?.translationType?.value == null
          }
        >
          {languages[lang].next}
        </button>
      </div>

      {/* {isOpen.PaymentModal ? (
        <PaymentModal
          toggleModal={toggleModal}
          modalName={"PaymentModal"}
          open={isOpen.PaymentModal}
          sendReq={props.sendReq}
          dataParams={dataParams}
          msg={modalMsg}
        />
      ) : null} */}
    </section>
  );
}
