/** @format */

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import addIcon from "../../../../Assets/Images/Group 2784.svg";
import wightAdd from "../../../../Assets/Images/Group 2784.png";
import { languages } from "../../../../Constants/Languages";
import NavbarAndSidebarCont from "../../../../Components/Shared/NavbarAndSidebarCont";
import RequestsSearchbar from "../../../../Components/AdminComponents/RequestsSearchbar";
import CustomersTable from "../../../../Components/Shared/CustomersTable";
import Pagination from "../../../../Components/Shared/Pagination";
import { deleteCityService } from "../../../../Services/adminServices";
import { setCities } from "../../../../Redux/commonData";

export default function DeliveryPricing(props) {
  const [isHovering, toggleHover] = useState(false);
  const [id, setId] = useState();
  const [isModalOpen, setModalOpen] = useState({ cancelModal: false });
  const [citesData, setCitesData] = useState([]);
  const [pagination, setPaginationData] = useState({});
  const [tableHeaderConstatnt, setTableHeader] = useState([]);
  let { lang, dir } = useSelector((state) => state.lang);
  const { cities } = useSelector((state) => state.commonData);
  let dispatch = useDispatch();
  useEffect(() => {
    let paginationData = {
      totalCount: 10,
      tageSize: 9,
      currentPage: 7,
      hasNext: true,
      hasPrevious: true,
    };
    setPaginationData(paginationData);
    let citiesListClone = cities.map((city) => ({
      ...city,
      label: city.label[lang],
    }));
    let tableHeader = [
      { title: languages[lang].location, key: "label" },
      { title: languages[lang].price, key: "additionalCost" },
    ];
    setTableHeader(tableHeader);
    setCitesData(citiesListClone);
  }, [lang, cities]);
  const toggleCancelModal = (modalName, event, id) => {
    setId(id);
    setModalOpen({ ...isModalOpen, [modalName]: !isModalOpen[modalName] });
    if (event) {
      event.preventDefault();
    }
  };
  const getPageData = (text) => {};
  const deleteCity = async (id) => {
    props.toggleLoading(true);
    let response = await deleteCityService(id);
    if (response.data?.isSuccess) {
      toggleCancelModal("cancelModal");
      let newCitiesList = cities.filter((city) => city.value !== id);
      dispatch(setCities(newCitiesList));
      props.toggleModal("successModal");
      props.getModalMsg(response.data.message[lang]);
    } else {
      props.toggleModal("failModal");
      props.getModalMsg(
        response.data?.message
          ? response.data?.message[lang]
          : languages[lang].wentWrong
      );
    }
    props.toggleLoading(false);
  };
  return (
    <div>
      <div className='customers-cont__body__info' dir={dir} lang={lang}>
        <h4>{languages[lang].deliveryPricing}</h4>
        {props.canEdit ? (
          <Link to='/dashboard/settings/delivery-location/add'>
            <button
              onMouseEnter={() => toggleHover(true)}
              onMouseLeave={() => toggleHover(false)}>
              <img src={isHovering ? wightAdd : addIcon} />{" "}
              {languages[lang].add}
            </button>
          </Link>
        ) : null}
      </div>
      {/* <RequestsSearchbar searchOnly /> */}
      <CustomersTable
        toggleModal={toggleCancelModal}
        isOpen={isModalOpen.cancelModal}
        agents={citesData}
        url='settings/delivery-location'
        tableHeaderConstatnt={tableHeaderConstatnt}
        msg={languages[lang].deletelocation}
        deleteAction={deleteCity}
        loading={props.loading}
        id={id}
        disableRouting
        removeDelete={!props.canEdit}
      />
    </div>
  );
}
