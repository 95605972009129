/** @format */

import React, { useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import notificationIcon from "../../../Assets/Images/Group 39851.png";
import seenNotification from "../../../Assets/Images/Group 39848.png";
import "./index.scss";
import { languages } from "../../../Constants/Languages";
import { Link } from "react-router-dom";
import { getDetailedServices } from "../../../Services/commonServices";
const notifications = [];
export default function NotificationModal(props) {
  const { lang } = useSelector((state) => state.lang);
  let ref = useRef(null);
  let { orderStatuses } = useSelector((state) => state.commonData);

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      props.toggleModal();
      props.onClickOutside && props.onClickOutside();
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  });

  const goNotifications = async (e) => {
    let x = e.url.split("/");
    let userRole = JSON.parse(localStorage.getItem("userRole"));
    if (userRole?.roles[0] === "Admin" || userRole?.roles[0] === "Translator") {
      window.location.href = e.url;
    }

    let response = await getDetailedServices(x[x.length - 1]);
    if (response) {
      let newD = response.data.data;
      let statusIndex = orderStatuses.findIndex(
        (status) => status.id === newD.statusId
      );
      let data = {
        ...newD,
        addOn: newD?.addOn?.[lang],
        orderType: newD?.orderType[lang],
        translationField: newD?.translationField[lang],
        status: orderStatuses[statusIndex][lang],
      };
      localStorage.setItem("orderDetails", JSON.stringify(data));
      window.location.href = e.url;
    }
  };

  return (
    <div
      ref={ref}
      className={`notifications-cont ${props.showModal && "notifications-cont--show"
        }`}
    >
      {props.notifications?.map((notification) => (
        <div
          key={notification.id}
          onClick={() => {
            goNotifications(notification);
          }}
        >
          {/* to={notification.url} */}
          <div
            className={`notifications-cont__notification ${notification.isSeen && "notifications-cont__notification--seen"
              }`}
          >
            <img
              src={notification.isSeen ? seenNotification : notificationIcon}
            />
            <div className="notifications-cont__notification__info"
              style={{
                textAlign: lang === "ar" ? "right" : "left",
              }}
            >
              <span className="notifications-cont__notification__info__msg" dir={lang === "ar" ? "rtl" : undefined}>
                {notification?.[lang]}
              </span>
              <div className="notifications-cont__notification__info__date">
                <span>
                  {new Date(notification.createdAt).toLocaleDateString("en-US")}
                </span>
                <span>
                  {new Date(notification.createdAt).toLocaleTimeString()}
                </span>
              </div>
            </div>
          </div>
        </div>
      ))}
      <Link to="/notification">
        <button>{languages[lang].showAllNotification}</button>
      </Link>
    </div>
  );
}
