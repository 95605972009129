/** @format */
import { useEffect, useState } from "react";
import Footer from "../../Components/Footer";
import Navbar from "../../Components/Navbar";
import TranslateForm from "../../Components/Shared/TranslateForm";

import "./index.scss";
export default function TranslatePage() {
  const [openLoginFlag, setLoginFlag] = useState(null);
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
      document.getElementById("translat-cont").scrollTo({
        top: 0,
      });
    }, 50);
  }, []);

  return (
    <div>
      <Navbar openLoginFlag={openLoginFlag} />
      <TranslateForm
        openLogin={() =>
          setLoginFlag(openLoginFlag === null ? true : !openLoginFlag)
        }
      />
      <Footer />
    </div>
  );
}
