/** @format */

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomNavbar from "../../../Components/Shared/CustomNavbar";
import Sidebar from "../../../Components/Shared/Sidebar";
import dashboard from "../../../Assets/Images/Group 398116.svg";
import selectedDashboard from "../../../Assets/Images/Group 39811.svg";
import requests from "../../../Assets/Images/layer1.svg";
import customers from "../../../Assets/Images/Group 39814.svg";
import companies from "../../../Assets/Images/Path 1673.svg";
import translators from "../../../Assets/Images/Language.svg";
import transactions from "../../../Assets/Images/Group 39815.svg";
import settings from "../../../Assets/Images/Group 39836.svg";

import { languages } from "../../../Constants/Languages";
import Statistics from "../../../Components/AdminComponents/Statistics";
import "./index.scss";
import NavbarAndSidebarCont from "../../../Components/Shared/NavbarAndSidebarCont";
import { bindActionCreators } from "@reduxjs/toolkit";
import { adminActions } from "../../../actions/adminActions";
export default function Dashboard() {
  const [orders, setOrders] = useState([]);
  let styleObject = {
    New: "yellow",
    Done: "green",
    "In Translation": "blue",
    Translated: "green",
    "Waiting Delivery": "yellow",
    "In Delivery": "blue",
    Cancelled: "red",
  };
  let { clientsOrders } = useSelector((state) => state.admin);
  let { lang } = useSelector((state) => state.lang);
  let { orderStatuses } = useSelector((state) => state.commonData);

  let dispatch = useDispatch();
  let { getClientsOrdersAction } = bindActionCreators(adminActions, dispatch);
  useEffect(() => {
    getClientsOrdersAction(1, 3);
  }, []);
  useEffect(() => {
    if (clientsOrders.data && orderStatuses && orderStatuses.length > 0) {
      let ordersClone = [...clientsOrders.data];
      ordersClone = ordersClone.map((order) => ({
        ...order,
        addOn: order.addOn[lang],
        orderType: order.orderType[lang],
        translationField: order.translationField[lang],
      }));

      let orderStatusesClone = orderStatuses.map((status) => ({
        ...status,
        num: 0,
        style: styleObject[status.en],
        label: { ar: status.ar, en: status.en },
        value: status.id,
      }));
      ordersClone = ordersClone.map((order) => {
        let statusIndex = orderStatuses.findIndex(
          (status) => status.id === order.statusId
        );
        orderStatusesClone[statusIndex].num =
          orderStatusesClone[statusIndex].num + 1;
        return {
          ...order,
          status: orderStatuses[statusIndex][lang],
        };
      });
      setOrders(ordersClone);
    }
  }, [clientsOrders?.data?.length, lang, orderStatuses]);

  return (
    <div className="dashboard-cont">
      <NavbarAndSidebarCont>
        <Statistics orders={orders} />
      </NavbarAndSidebarCont>
    </div>
  );
}
