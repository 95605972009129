/** @format */

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import NavbarAndSidebarCont from "../../../../../Components/Shared/NavbarAndSidebarCont";
import { languages } from "../../../../../Constants/Languages";
import CustomerForm from "../../../../../Components/AdminComponents/CutomerForm";
import UserIcon from "../../../../../Assets/Images/Icon awesome-user-alt.svg";
import phoneIcon from "../../../../../Assets/Images/Icon ionic-md-phone-portrait.svg";
import EyeIcon from "../../../../../Assets/Images/Icon ionic-ios-eye.svg";
import EmailIcon from "../../../../../Assets/Images/Icon ionic-ios-mail.svg";
import { Navigate } from "react-router-dom";
import { adminActions } from "../../../../../actions/adminActions";
import { bindActionCreators } from "@reduxjs/toolkit";

export default function AddAdminUser() {
  const params = useParams();
  const [loading, setLoading] = useState(params.id ? true : false);
  const [isOpen, setOpen] = useState({ successModal: false, failModal: false });
  const [modalMsg, setModalMsg] = useState("");
  const [redirect, setRedireact] = useState(false);
  const [adminData, setAdminData] = useState({
    name: "",
    phone: "",
    email: "",
    password: "",
    confirmPass: "",
  });
  const [errors, setErrors] = useState({
    name: null,
    mobile: null,
    email: null,
    password: null,
    confirmPass: null,
  });
  const [permissionsData, setPermissionsData] = useState({
    requests: [],
    companies: [],
    payments: [],
    customers: [],
    translators: [],
    settings: [],
  });
  const [userFields, setUserFields] = useState([]);
  const [passwordFields, setPasswordFields] = useState([]);
  const [permissionsFieldsPart1, setPermissionsFieldsPart1] = useState([]);
  const [permissionsFieldsPart2, setPermissionsFieldsPart2] = useState([]);
  const [permissionsList, setPermissionsList] = useState([]);
  const [emailField, setEmailField] = useState({});
  let { lang, dir } = useSelector((state) => state.lang);
  let { adminPermissions } = useSelector((state) => state.admin);
  let dispatch = useDispatch();
  let { getAdminPermissionsAction } = bindActionCreators(
    adminActions,
    dispatch
  );

  useEffect(() => {
    let adminUserData = JSON.parse(localStorage.getItem("adminData"));
    if (params.id) {
      getAdminPermissionsAction(adminUserData.id);
      setAdminData(adminUserData);
    }
    let userFieldsConst = [
      {
        title: languages[lang].name,
        key: "fullName",
        icon: UserIcon,
        placeholder: languages[lang].pleaseWriteName,
        disabled: params.id ? true : false,
      },
      {
        title: languages[lang].phoneNum,
        key: "phoneNumber",
        icon: phoneIcon,
        placeholder: languages[lang].pleaseWritePhone,
        type: "phoneNumber",
        disabled: params.id ? true : false,
      },
      {
        title: languages[lang].email,
        key: "email",
        icon: EmailIcon,
        placeholder: languages[lang].pleaseWriteEmail,
        disabled: params.id ? true : false,
      },
      {
        title: languages[lang].preferredLanguage,
        key: "preferredLanguageId",
        type: "select",
        options: [
          { label: { ar: "العربية", en: "العربية" }, value: 1 },
          { label: { ar: "English", en: "English" }, value: 2 },
        ],
        placeholder: languages[lang].selectPreferredLang,
        disabled: params.id ? true : false,
      },
    ];

    // let passwordFields = [
    //   {
    //     title: languages[lang].password,
    //     key: "password",
    //     icon: EyeIcon,
    //     placeholder: languages[lang].pleaseWritePass,
    //   },
    //   {
    //     title: languages[lang].confirmPass,
    //     key: "confirmPass",
    //     icon: EyeIcon,
    //     placeholder: languages[lang].pleaseWritePass,
    //   },
    // ];
    let permissionsPart1 = [
      {
        title: languages[lang].requests,
        key: "requests",
        view: "PermissionOrdersView",
        add: false,
        edit: "PermissionOrdersEdit",
        delete: "PermissionOrdersDelete",
      },

      {
        title: languages[lang].companies,
        key: "companies",
        view: "PermissionCompaniesView",
        add: "PermissionCompaniesAdd",
        edit: "PermissionCompaniesEdit",
        delete: "PermissionCompaniesDelete",
      },

      {
        title: languages[lang].payments,
        key: "payments",
        view: "PermissionTransactionsView",
        add: false,
        edit: false,
        delete: false,
      },
    ];
    let permissionsPart2 = [
      {
        title: languages[lang].customers,
        key: "customers",
        view: "PermissionClientsView",
        add: false,
        edit: false,
        delete: "PermissionClientsDelete",
      },
      {
        title: languages[lang].translators,
        key: "translators",
        view: "PermissionTranslatorsView",
        add: "PermissionTranslatorsAdd",
        edit: "PermissionTranslatorsEdit",
        delete: "PermissionTranslatorsDelete",
      },
      {
        title: languages[lang].settings,
        key: "settings",
        view: "PermissionSystemView",
        add: false,
        edit: "PermissionSystemEdit",
        delete: false,
      },
    ];
    setPermissionsFieldsPart1(permissionsPart1);
    setPermissionsFieldsPart2(permissionsPart2);
    // setPasswordFields(passwordFields);
    setUserFields(userFieldsConst);
  }, []);
  useEffect(() => {
    setPermissionsList(adminPermissions);
    setLoading(false);
  }, [adminPermissions]);
  // const getChanges = (value, stateName) => {
  //   setErrors({ ...errors, [stateName]: null });
  //   setAdminData({ ...adminData, [stateName]: value });
  // };
  const getPermissions = (value, stateName, permissionName) => {
    let permissionsListClone = [...permissionsList];
    if (permissionsListClone.includes(permissionName)) {
      permissionsListClone = permissionsListClone.filter(
        (item) => item !== permissionName
      );
    } else {
      permissionsListClone = [...permissionsListClone, permissionName];
    }
    setPermissionsList(permissionsListClone);
    let permissionsDataClone = { ...permissionsData };

    if (permissionsDataClone[stateName].includes(value)) {
      permissionsDataClone[stateName] = permissionsDataClone[stateName].filter(
        (data) => data !== value
      );
    } else {
      permissionsDataClone[stateName] = [
        ...permissionsDataClone[stateName],
        value,
      ];
    }
    setPermissionsData(permissionsDataClone);
  };
  const toggleModal = (stateName) => {
    setOpen({ ...isOpen, [stateName]: !isOpen[stateName] });
    if (stateName === "successModal" && isOpen.successModal) {
      setRedireact(!redirect);
    }
  };
  const createSuperAdmin = async (adminData) => {
    setLoading(true);
    let res = {};
    if (params.id) {
      adminData = { permissions: adminData.permissions, id: params.id };
      res = await adminActions.editAdminPermissionsAction(adminData);
    } else {
      res = await adminActions.addSuperAdminAction(adminData);
    }

    if (res.data.isSuccess) {
      toggleModal("successModal");
      setModalMsg(res.data?.message?.[lang] ?? "Done");
    } else {
      toggleModal("failModal");
      setModalMsg(res.data?.message?.[lang]);
    }
    setLoading(false);
  };

  return (
    <NavbarAndSidebarCont
      isOpen={isOpen}
      loading={loading}
      toggleModal={toggleModal}
      modalMsg={modalMsg}
    >
      <h4 className="lang-header-title" lang={lang} dir={dir}>
        {languages[lang].addAdminUser}
      </h4>
      <div className="Lang-price">
        <CustomerForm
          userFieldsConst={userFields}
          // emailField={emailField}
          passFields={passwordFields}
          // getChanges={getChanges}
          data={adminData}
          permissionsData={permissionsData}
          getPermissions={getPermissions}
          permissionsFieldsPart1={permissionsFieldsPart1}
          permissionsFieldsPart2={permissionsFieldsPart2}
          permissionsList={permissionsList}
          action={createSuperAdmin}
          editAdmin={params.id}
          preferredLanguage
        />
      </div>
      {redirect ? <Navigate to="/dashboard/settings" /> : null}
    </NavbarAndSidebarCont>
  );
}
