/** @format */
import { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import Arrow from "../../../Assets/Images/Icon ionic-ios-arrow-up.svg";
import "./index.scss";
export default function Select(props) {
  const [open, setOpen] = useState(false);
  let { lang, dir } = useSelector((state) => state.lang);
  let ref = useRef(null);
  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setOpen(false);
      props.onClickOutside && props.onClickOutside();
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  });
  const getSelectedItem = (value) => {
    props.getChanges(props.stateName, value);
    setOpen(false);
  };

  const renderOption = (option) => {
    // if(props.translators){

    // }else{}

    return (
      <span
        key={option.value}
        className={`${
          props.value?.value === option.value &&
          "select-cont__list__selectedOption"
        } ${
          props.value?.value === option.value &&
          props.customStyle &&
          `select-cont__list__selectedOption--${props.customStyle}`
        }`}
        onClick={() => getSelectedItem(option)}>
        {option.label[lang]}
      </span>
    );
  };

  return (
    <div
      className={`select-cont ${props.errors && "select-cont--error"} ${
        props.customStyle && `select-cont--${props.customStyle}`
      } ${props.disabled && "select-cont--disabled"}`}
      onClick={() => setOpen(!open)}
      dir={dir}
      lang={lang}>
      {}
      <span>{props.value?.label?.[lang] ?? props.placeholder} </span>
      <img
        className={`select-cont__arrow-icon--${lang}`}
        src={props.icon ?? Arrow}
      />

      {!props.disabled && open ? (
        <div
          className={`select-cont__list ${
            props.customStyle && `select-cont__list--${props.customStyle}`
          }`}
          ref={ref}>
          {props.options.map((option) => renderOption(option))}
        </div>
      ) : null}
    </div>
  );
}
