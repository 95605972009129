/** @format */

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { languages } from "../../../Constants/Languages";
import RightArrow from "../../../Assets/Images/Icon ionic-ios-arrow-right.svg";
import "./index.scss";
import { Link } from "react-router-dom";
import Status from "../Status";

export default function OrderTable(props) {
  const [headerConstant, setHeaderConstant] = useState([]);
  let { lang, dir } = useSelector((state) => state.lang);

  useEffect(() => {
    if (props.constants) {
      setHeaderConstant(props.constants);
    } else {
      let tableHeaderConstant = [
        { label: "#", key: "id" },
        { label: languages[lang].requestData, key: "createdAt" },
        { label: languages[lang].name, key: "userName" },
        { label: languages[lang].translateFrom, key: "fromLang" },
        { label: languages[lang].translateTo, key: "toLang" },
        { label: languages[lang].translationField, key: "translationField" },
        { label: languages[lang].translationType, key: "orderType" },
        { label: languages[lang].serviceType, key: "addOn" },
        { label: languages[lang].price, key: "price" },
        { label: languages[lang].translator, key: "translatorName" },
        { label: languages[lang].deleviryData, key: "fininshedAt" },
        { label: languages[lang].status, key: "status" },
      ];
      if (props.removeName) {
        tableHeaderConstant = tableHeaderConstant.filter(
          (constant) => constant.key !== "userName"
        );
      }
      setHeaderConstant(tableHeaderConstant);
    }
  }, [lang, props.constants, props.orders]);

  const prepareDate = (data) => {
    const options = { year: "numeric", month: "numeric", day: "numeric" };
    const date = data
      ? new Date(data).toLocaleDateString("en-EN", options)
      : data;

    return (
      <span>
        {date}
        <br />{" "}
        {date
          ? new Date(data).toLocaleTimeString("en", {
              timeStyle: "short",
              hour12: true,
            })
          : "-"}
      </span>
    );
  };

  const renderRow = (order) => {
    return (
      <div
        onClick={() => {
          return props.saveToSessionStorege
            ? localStorage.setItem(
                props.saveToSessionStorege,
                JSON.stringify(order)
              )
            : null;
        }}
        key={order.id}
        className={`table__body table__body--${props.style} ${
          props.removeName && "table__body--remove-name"
        }`}
      >
        {headerConstant.map((constant) => {
          if (constant.key?.includes("At")) {
            return prepareDate(order[constant.key]);
          } else if (constant.key === "status") {
            return <Status data={order.status} />;
          } else {
            return <span>{order[constant.key] ?? "-"}</span>;
          }
        })}
      </div>
    );
  };

  return (
    <div className="table" dir={dir} lang={lang}>
      {props.orders?.length ? (
        <>
          <div
            className={`table__header table__header--${props.style} ${
              props.removeName && "table__header--remove-name"
            }`}
          >
            {headerConstant.map((constant) => (
              <span>{constant.label}</span>
            ))}
          </div>
          {props.orders?.map((order) =>
            props.disableRouting ? (
              renderRow(order)
            ) : (
              <Link to={`/${props.url}/${order.id}`} key={order.key}>
                {renderRow(order)}
              </Link>
            )
          )}
        </>
      ) : (
        <h3>{languages[lang].noRequests}</h3>
      )}
    </div>
  );
}
