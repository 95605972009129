/** @format */

import { languages } from "./Languages";
import dashboard from "../Assets/Images/Group 398116.svg";
import selectedDashboard from "../Assets/Images/Group 39811.svg";
import requests from "../Assets/Images/layer1.svg";
import selectedRequests from "../Assets/Images/selectedlayer1.svg";
import customers from "../Assets/Images/Group 39814.svg";
import selectedCustomers from "../Assets/Images/Group 398143.svg";
import companies from "../Assets/Images/Path 1673.svg";
import SelectedCompany from "../Assets/Images/Path 1673SelectedCompany.svg";
import translators from "../Assets/Images/Language.svg";
import SelectedTranslators from "../Assets/Images/SelectedLanguage.svg";
import transactions from "../Assets/Images/Group 39815.svg";
import selectedTransactions from "../Assets/Images/selectedTransactions.svg";
import settings from "../Assets/Images/Group 39836.svg";
import selectedSetting from "../Assets/Images/selectedSetting.svg";
import profileIcon from "../Assets/Images/profile.svg";
import selectedProfileIcon from "../Assets/Images/selectedProfile.svg";
import packageIcon from "../Assets/Images/Page-1.svg";
export const prepareDashboardSideBar = (
  lang,
  selectedItem,
  userSidebar,
  permissions
) => {
  let sidebarData = [];

  if (userSidebar === "customer") {
    sidebarData = [
      {
        title: languages[lang].requests,
        link: "/customer/requests",
        subMenu: false,
        img: requests,
        selectedImg: selectedRequests,
      },
      {
        title: languages[lang].profile,
        link: "/customer/profile",
        subMenu: false,
        img: profileIcon,
        selectedImg: selectedProfileIcon,
      },
    ];
  } else if (userSidebar === "translator") {
    sidebarData = [
      {
        title: languages[lang].requests,
        link: "/translator/requests",
        subMenu: false,
        img: requests,
        selectedImg: selectedRequests,
      },
      {
        title: languages[lang].profile,
        link: "/translator/profile",
        subMenu: false,
        img: profileIcon,
        selectedImg: selectedProfileIcon,
      },
    ];
  } else if (userSidebar === "company") {
    sidebarData = [
      {
        title: languages[lang].requests,
        link: "/company/requests",
        subMenu: false,
        img: requests,
        selectedImg: selectedRequests,
      },
      {
        title: languages[lang].transaction,
        link: "/dashboard/transactions",
        subMenu: false,
        img: transactions,
        selectedImg: selectedTransactions,
      },
      {
        title: languages[lang].packages,
        link: "/company/packages",
        subMenu: false,
        img: packageIcon,
        selectedImg: packageIcon,
      },
      {
        title: languages[lang].customers,
        link: "/company/users",
        subMenu: false,
        img: customers,
        selectedImg: selectedCustomers,
      },
      {
        title: languages[lang].profile,
        link: "/company/profile",
        subMenu: false,
        img: profileIcon,
        selectedImg: selectedProfileIcon,
      },
    ];
  } else if (userSidebar === "CompanyUser") {
    sidebarData = [
      {
        title: languages[lang].requests,
        link: "/company/requests",
        subMenu: false,
        img: requests,
        selectedImg: selectedRequests,
      },

      {
        title: languages[lang].profile,
        link: "/company/profile",
        subMenu: false,
        img: profileIcon,
        selectedImg: selectedProfileIcon,
      },
    ];
  } else {
    sidebarData = [
      {
        title: languages[lang].dashboard,
        link: "/admin-dashboard",
        subMenu: false,
        img: dashboard,
        selectedImg: selectedDashboard,
        hide: false,
      },
      {
        title: languages[lang].requests,
        link: "/dashboard/requests&shipping",
        subMenu: true,
        img: requests,
        selectedImg: selectedRequests,
        options: ["clientOrders", "companiesRequests", "shippingRequests"],
        hide: !permissions.includes("PermissionOrdersView"),
      },
      {
        title: languages[lang].customers,
        link: "/dashboard/customers",
        subMenu: false,
        img: customers,
        selectedImg: selectedCustomers,
        hide: !permissions.includes("PermissionClientsView"),
      },
      {
        title: languages[lang].companies,
        link: "/dashboard/companies",
        subMenu: true,
        options: ["allCompanies", "packages", "companiesRequests"],
        img: companies,
        selectedImg: SelectedCompany,
        hide: !permissions.includes("PermissionCompaniesView"),
      },
      {
        title: languages[lang].translators,
        link: "/dashboard/translators",
        subMenu: false,
        img: translators,
        selectedImg: SelectedTranslators,
        hide: !permissions.includes("PermissionTranslatorsView"),
      },
      {
        title: languages[lang].transaction,
        link: "/dashboard/transactions",
        subMenu: false,
        img: transactions,
        selectedImg: selectedTransactions,
        hide: !permissions.includes("PermissionTransactionsView"),
      },
      {
        title: languages[lang].settings,
        link: "/dashboard/settings",
        subMenu: true,
        img: settings,
        selectedImg: selectedSetting,
        options: [
          // "generalSettings",
          // "sendingData",
          "languagePricing",
          "translationTypePricing",
          "pricingForTypesOfTranslationServices",
          "deliveryPricing",
          "translationAreas",
          "adminUsers",
        ],
        hide: !permissions.includes("PermissionSystemView"),
      },
    ];
  }

  return sidebarData;
};
export const prepareOrderDetails = (lang, companyOrder) => {
  let orderDetailsConstant = [];

  if (window.location.pathname.includes("company/requests")) {
    orderDetailsConstant = [
      { label: "#", key: "id" },
      { label: languages[lang].requestData, key: "createdAt" },

      { label: languages[lang].translateFrom, key: "fromLang" },
      { label: languages[lang].translateTo, key: "toLang" },
      { label: languages[lang].translationField, key: "translationField" },
      { label: languages[lang].translationType, key: "orderType" },
      { label: languages[lang].serviceType, key: "addOn" },
      { label: languages[lang].price, key: "price" },

      { label: languages[lang].deleviryData, key: "fininshedAt" },
      { label: languages[lang].status, key: "status" },
    ];
  } else if (window.location.pathname.includes("translator/requests")) {
    orderDetailsConstant = [
      { label: "#", key: "id" },
      { label: languages[lang].requestData, key: "createdAt" },
      { label: languages[lang].deleviryData, key: "fininshedAt" },
      { label: languages[lang].translateFrom, key: "fromLang" },
      { label: languages[lang].translateTo, key: "toLang" },
      { label: languages[lang].translationField, key: "translationField" },
      { label: languages[lang].translationType, key: "orderType" },
    ];
  } else if (companyOrder) {
    orderDetailsConstant = [
      { label: languages[lang].orderNum, key: "id" },
      { label: languages[lang].requestData, key: "createdAt" },
      { label: languages[lang].deleviryData, key: "fininshedAt" },
      // { label: languages[lang].paymentMethod, key: "paymentMethod" },
      // { label: languages[lang].transactionNumber, key: "transactionNumber" },
      // { label: null, key: "" },
      { label: languages[lang].translateFrom, key: "fromLang" },
      { label: languages[lang].translateTo, key: "toLang" },
      { label: languages[lang].translationField, key: "translationField" },
      { label: languages[lang].translationType, key: "orderType" },

      { label: languages[lang].translationDelivery, key: "withDelivery" },
      { label: languages[lang].customerName, key: "userName" },
    ];
  } else {
    orderDetailsConstant = [
      { label: languages[lang].orderNum, key: "id" },
      { label: languages[lang].requestData, key: "createdAt" },
      { label: languages[lang].deleviryData, key: "fininshedAt" },
      // { label: languages[lang].paymentMethod, key: "paymentMethod" },
      // { label: languages[lang].transactionNumber, key: "transactionNumber" },
      // { label: null, key: "" },
      { label: languages[lang].translateFrom, key: "fromLang" },
      { label: languages[lang].translateTo, key: "toLang" },
      { label: languages[lang].translationField, key: "translationField" },
      { label: languages[lang].translationType, key: "orderType" },
      { label: languages[lang].serviceType, key: "addOn" },
      { label: languages[lang].translationDelivery, key: "withDelivery" },
      { label: languages[lang].customerName, key: "userName" },
    ];
  }
  return orderDetailsConstant;
};
