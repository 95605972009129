/** @format */
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Navigate } from "react-router-dom";
import Footer from "../../Components/Footer";
import InputField from "../../Components/InputField";
import Navbar from "../../Components/Navbar";
import Loader from "../../Components/Shared/Loader";
import { languages } from "../../Constants/Languages";
import { setIndustries } from "../../Redux/commonData";
import { getIndustries } from "../../Services/commonServices";
import { sendCompanyReq } from "../../Services/companyServices";
import "./index.scss";
let requiredField = ["companyName", "phone", "email", "representativeName"];
export default function CompanyForm() {
  const [loading, toggleLoading] = useState(false);
  const [disableSubmitt, toggleDisable] = useState(false);
  const [reqSent, toggleReqSent] = useState(false);
  const [options, setOptions] = useState([]);
  const [fieldsData, setData] = useState({
    companyName: null,
    representativeName: null,
    phone: null,
    email: null,
    industryId: null,
  });
  const [errors, setErrors] = useState({
    companyName: null,
    phone: null,
    email: null,
    representativeName: null,
  });
  const dispatch = useDispatch();
  let { lang, dir } = useSelector((state) => state.lang);
  let { industries } = useSelector((state) => state.commonData);
  useEffect(() => {
    if (industries.length === 0) {
      getIndustries().then((res) => {
        dispatch(setIndustries(res.data.data));
      });
    }
  }, []);
  useEffect(() => {
    let industryOptions = industries.map((industry) => {
      let option = {
        label: { ar: industry.ar, en: industry.en },
        value: industry.id,
      };
      return option;
    });
    setOptions(industryOptions);
  }, [industries]);
  const getChanges = (key, value) => {
    let errorsClone = { ...errors };
    errorsClone = { ...errorsClone, [key]: null };
    setErrors(errorsClone);

    toggleDisable(
      Object.values(errorsClone).filter((errorValue) => errorValue).length > 0
    );

    setData({ ...fieldsData, [key]: value });
  };
  const switchLoading = (flag) => {
    let value = flag ?? !loading;
    toggleLoading(value);
  };
  const validatData = (key, value) => {
    let errorsClone = { ...errors };
    errorsClone = { ...errorsClone, [key]: null };

    let regex = new RegExp("[a-z0-9]+@[a-z]+.[a-z]{2,3}");
    if (!regex.test(value)) {
      errorsClone = { ...errorsClone, email: languages[lang].validEmail };
    }

    toggleDisable(errorsClone.email !== null);
    setErrors(errorsClone);
  };
  const sendRequest = async () => {
    let errorsClone = {};
    requiredField.forEach((field) => {
      if (!fieldsData[field]?.length) {
        errorsClone = {
          ...errorsClone,
          [field]: languages[lang].requiredField,
        };
      }
    });
    toggleDisable(Object.keys(errorsClone).length > 0);
    setErrors(errorsClone);
    if (Object.keys(errorsClone).length === 0) {
      switchLoading(true);
      let fieldsDataClone = { ...fieldsData };
      fieldsDataClone.industryId = fieldsDataClone.industryId?.value;
      let response = await sendCompanyReq(fieldsDataClone);
      if (response.data.isSuccess) {
        toggleReqSent(true);
      }

      switchLoading(false);
    }
  };
  return (
    <div>
      <Navbar />
      <section className='compnay-form-cont' dir={dir} lang={lang}>
        <div dir={dir} lang={lang}>
          <h3>{languages[lang].fillCompanyForm}</h3>
          <span>{languages[lang].contactYou}</span>
        </div>
        <br />
        <InputField
          title={languages[lang].companyName + " *"}
          placeholder={languages[lang].pleaseEnterCompanyName}
          value={fieldsData.companyName}
          getChanges={getChanges}
          stateName={"companyName"}
          errors={errors}
        />
        <div className='compnay-form-cont__inputs-cont'>
          <InputField
            title={languages[lang].companyActivity}
            placeholder={languages[lang].chooseCompanyActivity}
            value={fieldsData.industryId}
            select
            getChanges={getChanges}
            options={options}
            stateName={"industryId"}
          />
          <InputField
            title={languages[lang].responsibleName}
            placeholder={languages[lang].pleaseWriteResponName}
            value={fieldsData.representativeName}
            getChanges={getChanges}
            stateName='representativeName'
            errors={errors}
          />
          <InputField
            title={languages[lang].phoneNum + " *"}
            placeholder={languages[lang].pleaseWritePhone}
            value={fieldsData.phone}
            getChanges={getChanges}
            stateName='phone'
            errors={errors}
            type='phoneNumber'
          />
          <InputField
            title={languages[lang].email + " *"}
            placeholder={languages[lang].pleaseWriteEmail}
            value={fieldsData.email}
            getChanges={getChanges}
            stateName='email'
            errors={errors}
            validatData={validatData}
          />
        </div>

        <button onClick={sendRequest} disabled={disableSubmitt}>
          {languages[lang].sendRequest}
        </button>
      </section>
      {reqSent ? <Navigate to='/request-sent' /> : null}
      <Loader loading={loading} />
      <Footer />
    </div>
  );
}
