/** @format */

import { bindActionCreators } from "@reduxjs/toolkit";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { adminActions } from "../../../../actions/adminActions";
import { languages } from "../../../../Constants/Languages";
import Orders from "../Orders";

export default function ClientsOrders(props) {
  const [orders, setOrders] = useState([]);
  const [searchValue, setSearch] = useState("");
  let { clientsOrders } = useSelector((state) => state.admin);
  let { lang, dir, selectedOption } = useSelector((state) => state.lang);
  let dispatch = useDispatch();
  let { getClientsOrdersAction } = bindActionCreators(adminActions, dispatch);
  useEffect(() => {
    props.toggleLoading(true);
    getClientsOrdersAction(1, 10);
  }, []);
  useEffect(() => {
    if (clientsOrders.data) {
      let ordersClone = [...clientsOrders.data];
      ordersClone = ordersClone.map((order) => ({
        ...order,
        addOn: order.addOn[lang],
        orderType: order.orderType[lang],
        translationField: order.translationField[lang],
      }));

      setOrders(ordersClone);
    }
  }, [clientsOrders.data?.length, lang]);
  const getSearchValues = async (searchString) => {
    setSearch(searchString);
    props.toggleLoading(true);
    let res = await getClientsOrdersAction(1, searchString);
    props.toggleLoading(false);
  };
  const getPageData = async (pageNum) => {
    if (pageNum !== clientsOrders.pagination.CurrentPage) {
      props.toggleLoading(true);
      await getClientsOrdersAction(pageNum, searchValue);
      props.toggleLoading(false);
    }
  };
  return (
    <Orders
      title={languages[lang][selectedOption]}
      orders={orders}
      pagination={clientsOrders?.pagination}
      toggleLoading={props.toggleLoading}
      loading={props.loading}
      getSearchValues={getSearchValues}
      saveToSessionStorege='orderDetails'
      getPageData={getPageData}
    />
  );
}
