/** @format */

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import RequestsSearchbar from "../../../Components/AdminComponents/RequestsSearchbar";
import NavbarAndSidebarCont from "../../../Components/Shared/NavbarAndSidebarCont";
import OrderTable from "../../../Components/Shared/OrderTable";
import Pagination from "../../../Components/Shared/Pagination";
import { languages } from "../../../Constants/Languages";
import addIcon from "../../../Assets/Images/Group 2784.svg";
import wightAdd from "../../../Assets/Images/Group 2784.png";
import filter from "../../../Assets/Images/Path 11471.svg";
import { clintActions } from "../../../actions/clintActions";
import { bindActionCreators } from "@reduxjs/toolkit";
import ResponsiveTable from "../../../Components/Shared/ResponsiveOrderTable";
import CustomersTable from "../../../Components/Shared/CustomersTable";

export default function CompanyUsers(props) {
  const [openFilter, setOpenFilter] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isOpen, setOpen] = useState({ successModal: false, failModal: false });
  const [modalMsg, setModalMsg] = useState("");
  const [tableHeaderConstatnt, setTableHeader] = useState([]);
  const [companyUsersList, setCompanyUsersList] = useState([]);
  const [pagination, setPaginationData] = useState({});
  const [isHovering, toggleHover] = useState(false);
  const [headerConstant, setHeaderConstant] = useState([]);
  let { lang, dir } = useSelector((state) => state.lang);
  let { orders } = useSelector((state) => state.clint);
  let { companyUsers } = useSelector((state) => state.company);
  let { orderStatuses } = useSelector((state) => state.commonData);
  let dispatch = useDispatch();
  let { getCompanyUsersAction } = bindActionCreators(clintActions, dispatch);

  useEffect(() => {
    // let userData = JSON.parse(localStorage.getItem("userRole"));
    getCompanyUsersAction(1);
  }, []);

  useEffect(() => {
    if (companyUsers.pagination) {
      setLoading(false);
      setPaginationData(companyUsers.pagination);
      setCompanyUsersList(
        companyUsers.data.map((user) => ({
          ...user,
          isAdmin: user.isAdmin ? languages[lang].adminHeader : "-",
        }))
      );
    }
  }, [companyUsers]);
  useEffect(() => {
    let tableHeader = [
      { title: languages[lang].name, key: "fullName" },
      { title: languages[lang].email, key: "email" },
      { title: languages[lang].phoneNum, key: "phoneNumber" },
      {
        title: languages[lang].admin,
        key: "isAdmin",
      },
    ];
    setTableHeader(tableHeader);
  }, [lang]);

  const toggleModal = (stateName) => {
    setOpen({ ...isOpen, [stateName]: !isOpen[stateName] });
  };
  const toggleFilterModal = () => {
    setOpenFilter(!openFilter);
  };
  const getPageData = async (text) => {
    setLoading(true);
    let res = await getCompanyUsersAction(text);
    setLoading(false);
  };
  const getSearchValues = async (searchString) => {
    setLoading(true);
    let res = await getCompanyUsersAction(1, searchString);
    setLoading(false);
  };

  return (
    <NavbarAndSidebarCont
      userSidebar='company'
      isOpen={isOpen}
      loading={loading}
      toggleModal={toggleModal}
      modalMsg={modalMsg}>
      <div className='orders-cont'>
        <div className={`orders-cont__body`}>
          <div className='orders-cont__body__info' dir={dir} lang={lang}>
            <h4>{languages[lang].allUsers}</h4>
            <div className='orders-cont__body__info__types orders-cont__body__info__types--normalView'></div>
            <span
              className='orders-cont__body__info__filter'
              onClick={toggleFilterModal}>
              <img src={filter} /> {languages[lang].filter}
            </span>

            <Link to='/company/users/add'>
              <button
                onMouseEnter={() => toggleHover(true)}
                onMouseLeave={() => toggleHover(false)}>
                <img src={isHovering ? wightAdd : addIcon} />{" "}
                {languages[lang].add}
              </button>
            </Link>
          </div>
          <div className='orders-cont__body__info__types--responsiveView'></div>
          <RequestsSearchbar
            getSearchValues={getSearchValues}
            openFilter={openFilter}
            toggleFilterModal={toggleFilterModal}
            searchOnly
          />

          <CustomersTable
            saveToSessionStorege='adminData'
            agents={companyUsersList}
            url='settings/admin-user'
            tableHeaderConstatnt={tableHeaderConstatnt}
            msg={languages[lang].deleteService}
            disableRouting
            removeDelete
          />
        </div>

        {/* <ResponsiveTable
          url='company/requests'
          orders={ordersList}
          constants={headerConstant}
          style='customer-requests'
          saveToSessionStorege='orderDetails'
        /> */}

        {pagination.TotalPages < 2 ? null : (
          <Pagination pagination={pagination} getPageData={getPageData} />
        )}
      </div>
    </NavbarAndSidebarCont>
  );
}
