/** @format */

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
  industries: [],
  orderTypes: [],
  orderStatuses: [],
  addOns: [],
  translationFields: [],
  cities: [],
  languagesData: [],
  packages: [],
  bundleTypes: [],
};

export const commonDataSlice = createSlice({
  name: "commonData",
  initialState,
  reducers: {
    setIndustries: (state, action) => {
      state.industries = action.payload;
    },
    setOrderTypes: (state, action) => {
      state.orderTypes = action.payload;
    },
    setOrderStatuses: (state, action) => {
      state.orderStatuses = action.payload;
    },
    setAddOns: (state, action) => {
      state.addOns = action.payload;
    },
    setTranslationFields: (state, action) => {
      state.translationFields = action.payload;
    },
    setCities: (state, action) => {
      state.cities = action.payload;
    },
    setLanguages: (state, action) => {
      state.languagesData = action.payload;
    },
    setPackages: (state, action) => {
      state.packages = action.payload;
    },
    setBundleTypes: (state, action) => {
      state.bundleTypes = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setIndustries,
  setOrderTypes,
  setAddOns,
  setTranslationFields,
  setCities,
  setLanguages,
  setPackages,
  setBundleTypes,
  setOrderStatuses,
} = commonDataSlice.actions;

export default commonDataSlice.reducer;
