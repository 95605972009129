/** @format */

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import RequestsSearchbar from "../../../Components/AdminComponents/RequestsSearchbar";
import CustomNavbar from "../../../Components/Shared/CustomNavbar";
import Sidebar from "../../../Components/Shared/Sidebar";
import Pagination from "../../../Components/Shared/Pagination";
import addIcon from "../../../Assets/Images/Group 2784.svg";
import wightAdd from "../../../Assets/Images/Group 2784.png";
import { languages } from "../../../Constants/Languages";
import CustomersTable from "../../../Components/Shared/CustomersTable";
import NavbarAndSidebarCont from "../../../Components/Shared/NavbarAndSidebarCont";
import { bindActionCreators } from "@reduxjs/toolkit";
import { adminActions } from "../../../actions/adminActions";
export default function Translators(props) {
  const [loading, setLoading] = useState(true);
  const [isOpen, setOpen] = useState({ successModal: false, failModal: false });
  const [modalMsg, setModalMsg] = useState("");
  const [searchValue, setSearch] = useState("");
  const [isHovering, toggleHover] = useState(false);
  const [translators, setTranslators] = useState([]);
  const [pagination, setPaginationData] = useState({});

  const [tableHeaderConstatnt, setTableHeader] = useState([]);
  let { lang, dir } = useSelector((state) => state.lang);
  let { translatorsList, adminUserPermissions } = useSelector(
    (state) => state.admin
  );
  const dispatch = useDispatch();
  let { getTranslatorsAction } = bindActionCreators(adminActions, dispatch);
  const toggleLoading = (flag) => {
    setLoading(flag);
  };
  const getModalMsg = (msg) => {
    setModalMsg(msg);
  };
  const toggleModal = (stateName) => {
    setOpen({ ...isOpen, [stateName]: !isOpen[stateName] });
  };
  useEffect(() => {
    getTranslatorsAction(1);
  }, []);
  useEffect(() => {
    // let paginationData = {
    //   totalCount: 10,
    //   tageSize: 9,
    //   currentPage: 7,
    //   hasNext: true,
    //   hasPrevious: true,
    // };
    // setPaginationData(paginationData);

    if (translatorsList.data?.length) {
      toggleLoading(false);
      let tableHeader = [
        { title: languages[lang].customerName, key: "fullName" },
        { title: languages[lang].email, key: "email" },
        { title: languages[lang].phoneNum, key: "phoneNumber" },
        // { title: languages[lang].translationField, key: "fields" },
        {
          title: languages[lang].fulfilledOrders,
          key: "translatedOrdersCount",
        },
        {
          title: languages[lang].orderInProgress,
          key: "inTranslationOrdersCount",
        },
      ];
      setPaginationData(translatorsList.pagination);
      setTableHeader(tableHeader);
      setTranslators(translatorsList.data);
    }
  }, [lang, translatorsList]);
  const getPageData = async (pageNum) => {
    if (pageNum !== pagination.CurrentPage) {
      toggleLoading(true);
      await getTranslatorsAction(pageNum, searchValue);
      toggleLoading(false);
    }
  };
  const getSearchValues = async (searchString) => {
    toggleLoading(true);
    setSearch(searchString);
    let res = await getTranslatorsAction(1, searchString);
    toggleLoading(false);
  };
  const renderAddTranslatorBtn = () => {
    return (
      <button
        onMouseEnter={() => toggleHover(true)}
        onMouseLeave={() => toggleHover(false)}>
        <img src={isHovering ? wightAdd : addIcon} /> {languages[lang].add}
      </button>
    );
  };
  return (
    <div className='customers-cont'>
      <NavbarAndSidebarCont
        isOpen={isOpen}
        loading={loading}
        toggleModal={toggleModal}
        modalMsg={modalMsg}>
        <div className={`customers-cont__body `}>
          <div className='customers-cont__body__info' dir={dir} lang={lang}>
            <h4>{languages[lang].allRequests}</h4>
            {adminUserPermissions.includes("PermissionTranslatorsAdd") ? (
              <Link to='/dashboard/translators/add'>
                {renderAddTranslatorBtn()}
              </Link>
            ) : null}
          </div>
          <RequestsSearchbar searchOnly getSearchValues={getSearchValues} />
          <CustomersTable
            agents={translators}
            url='translators'
            tableHeaderConstatnt={tableHeaderConstatnt}
            msg={languages[lang].deleteTranslator}
            saveToSessionStorege='translatorData'
            removeDelete
          />
        </div>
        {console.log(">>>>>>>>pagination", pagination)}
        {pagination.TotalPages < 2 ? null : (
          <Pagination pagination={pagination} getPageData={getPageData} />
        )}
      </NavbarAndSidebarCont>
    </div>
  );
}
