/** @format */
import Img1 from "../../../Assets/Images/Image 18.png";
import Img2 from "../../../Assets/Images/Image 17.png";
import Img3 from "../../../Assets/Images/Image 16.png";
import Img4 from "../../../Assets/Images/Image 20.png";
import Img5 from "../../../Assets/Images/Image 19.png";
import "./index.scss";
import { languages } from "../../../Constants/Languages";
import { useSelector } from "react-redux";

export default function OurTranslator() {
  let { lang, dir } = useSelector((state) => state.lang);
  const row1 = [
    {
      img: Img3,
      msg: "howToPickBody1",
      number: "01",
    },
    {
      img: Img2,
      msg: "howToPickBody2",
      number: "02",
    },
    {
      img: Img1,
      msg: "howToPickBody3",
      number: "03",
    },
  ];
  const row2 = [
    {
      img: Img5,
      msg: "howToPickBody4",
      number: "04",
    },
    {
      img: Img4,
      msg: "howToPickBody5",
      number: "05",
    },
  ];
  const renderCard = (card) => {
    return (
      <div className='translator-cont__cards-cont__card'>
        <div className='translator-cont__cards-cont__card__img-cont'>
          <img src={card.img} />
        </div>
        <p className='translator-cont__cards-cont__card__number'>
          {card.number}
        </p>
        <span>{languages[lang][card.msg]}</span>
      </div>
    );
  };
  return (
    <section className='translator-cont'>
      <h3>{languages[lang].howToPick}</h3>
      <div className='translator-cont__cards-cont' lang={lang} dir={dir}>
        {row1.map((card) => {
          return renderCard(card);
        })}
      </div>
      <div
        className='translator-cont__cards-cont translator-cont__cards-cont--phoneWindow'
        lang={lang}
        dir={dir}>
        {row2.map((card) => {
          return renderCard(card);
        })}
      </div>
      <div
        className='translator-cont__cards-cont translator-cont__cards-cont--secRow'
        lang={lang}
        dir={dir}>
        {row2.map((card) => {
          return renderCard(card);
        })}
      </div>
    </section>
  );
}
