/** @format */

import { bindActionCreators } from "@reduxjs/toolkit";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { commonActions } from "../../../../actions/commonActions";
import RequestsSearchbar from "../../../../Components/AdminComponents/RequestsSearchbar";

import OrderTable from "../../../../Components/Shared/OrderTable";
import Pagination from "../../../../Components/Shared/Pagination";

import { languages } from "../../../../Constants/Languages";

import "./index.scss";
const OrdersTypes = [
  { langKey: "waitingForApproval", style: "yellow" },
  { langKey: "underTranslation", style: "blue" },
  { langKey: "translationSent", style: "green" },
  { langKey: "canceled", style: "red" },
];
let styleObject = {
  New: "yellow",
  Done: "green",
  "In Translation": "blue",
  Translated: "green",
  "Waiting Delivery": "yellow",
  "In Delivery": "blue",
  Cancelled: "red",
};

export default function Orders(props) {
  const [orders, setOrders] = useState([]);
  const [pagination, setPaginationData] = useState({});
  const [headerStatuses, setHeaderStatuses] = useState([]);
  let { lang, dir } = useSelector((state) => state.lang);
  let { orderStatuses } = useSelector((state) => state.commonData);
  let dispatch = useDispatch();
  let { getOrderStatusesActions } = bindActionCreators(commonActions, dispatch);
  useEffect(() => {
    props.toggleLoading(true);

    getOrderStatusesActions();
  }, []);
  useEffect(() => {
    if (orderStatuses.length && props.orders && props.pagination) {
      props.toggleLoading(false);

      let orderStatusesClone = orderStatuses.map((status) => {
        return {
          ...status,
          num: 0,
          style: styleObject[status.en],
          label: { ar: status.ar, en: status.en },
          value: status.id,
        };
      });
      let ordersClone = [];

      props.orders.forEach((order) => {
        let statusIndex = orderStatuses.findIndex(
          (status) => status.id === order.statusId
        );
        let x = orderStatuses[statusIndex]["en"];
        orderStatusesClone[statusIndex].num =
          orderStatusesClone[statusIndex].num + 1;
        order = {
          ...order,
          status: orderStatuses[statusIndex][lang],
          style: styleObject[x],
        };

        ordersClone = [...ordersClone, order];
      });

      setHeaderStatuses(orderStatusesClone);
      setOrders(ordersClone);
      setPaginationData(props.pagination);
    }
  }, [props.orders, props.pagination, orderStatuses]);

  const renderOrdersTypes = (type, index) => {
    return (
      <div className="orders-cont__body__info__types__type" key={index}>
        <div
          className={`orders-cont__body__info__types__type__dot orders-cont__body__info__types__type__dot--${type.style}`}
        />
        <div>
          <span>{type.num}</span>
          <span>{type[lang]}</span>
        </div>
      </div>
    );
  };
  return (
    <div className="orders-cont">
      <div className={`orders-cont__body`}>
        <div className="orders-cont__body__info" dir={dir} lang={lang}>
          <h4>{props.title}</h4>
          <div className="orders-cont__body__info__types">
            {headerStatuses.map((type, index) =>
              renderOrdersTypes(type, index)
            )}
          </div>
        </div>
        <RequestsSearchbar
          statusOptions={headerStatuses}
          getSearchValues={props.getSearchValues}
        />

        <OrderTable
          url="dashboard/requests&shipping/request"
          orders={orders}
          constants={props.constants}
          saveToSessionStorege={props.saveToSessionStorege}
          style={props.style}
        />
      </div>
      {pagination.TotalPages === 1 ? null : (
        <Pagination pagination={pagination} getPageData={props.getPageData} />
      )}
    </div>
  );
}
