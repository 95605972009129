/** @format */

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomerForm from "../../../../Components/AdminComponents/CutomerForm";
import CustomerHeader from "../../../../Components/Shared/CutomerHeader";
import NavbarAndSidebarCont from "../../../../Components/Shared/NavbarAndSidebarCont";
import OrderTable from "../../../../Components/Shared/OrderTable";
import UserIcon from "../../../../Assets/Images/Icon awesome-user-alt.svg";
import phoneIcon from "../../../../Assets/Images/Icon ionic-md-phone-portrait.svg";

import EmailIcon from "../../../../Assets/Images/Icon ionic-ios-mail.svg";
import { languages } from "../../../../Constants/Languages";
import { getLanguages } from "../../../../Services/commonServices";
import { setLanguages } from "../../../../Redux/commonData";
import { bindActionCreators } from "@reduxjs/toolkit";
import { adminActions } from "../../../../actions/adminActions";
import { Navigate } from "react-router-dom";

export default function AddTranslator() {
  const [loading, setLoading] = useState(true);
  const [isOpen, setOpen] = useState({ successModal: false, failModal: false });
  const [modalMsg, setModalMsg] = useState("");
  const [redirect, setRedireact] = useState(false);
  const [orders, setOrders] = useState([]);
  const [translatorFields, setTranslatorFields] = useState([]);
  const [emailField, setEmailField] = useState({});
  const [customerInfo, setCustomerInfo] = useState({
    fullName: "",
    phoneNumber: "",
    email: "",
  });
  const [selectedpage, setPage] = useState("info");
  const [langConst, setLangCont] = useState([]);
  const [fieldsConst, setFieldsConst] = useState([]);
  const [orderConst, setOrderConst] = useState([]);
  let { lang, dir } = useSelector((state) => state.lang);
  let { languagesData } = useSelector((state) => state.commonData);
  let { translationFields } = useSelector((state) => state.commonData);
  let dispatch = useDispatch();
  let { getTranslationFieldsAction, getLanguagesAction } = bindActionCreators(
    adminActions,
    dispatch
  );
  useEffect(() => {
    if (languagesData.length === 0) {
      getLanguagesAction();
    }
    if (translationFields.length === 0) {
      getTranslationFieldsAction();
    }
  }, []);
  useEffect(() => {
    if (languagesData.length && translationFields.length) {
      let userFieldsConst = [
        {
          title: languages[lang].name,
          key: "fullName",
          icon: UserIcon,
          placeholder: languages[lang].pleaseWriteName,
          disabled: false,
        },
        {
          title: languages[lang].phoneNum,
          key: "phoneNumber",
          icon: phoneIcon,
          placeholder: languages[lang].pleaseWritePhone,
          disabled: false,
          type: "phoneNumber",
        },
      ];
      let translatorLang = languagesData.map((language) => ({
        ...language,
        label: language.label.ar,
        checked: false,
      }));
      let fieldsConst = translationFields.map((field) => ({
        ...field,
        label: field.label[lang],
        checked: false,
      }));

      setFieldsConst(fieldsConst);
      setLangCont(translatorLang);
      setEmailField({
        title: languages[lang].email,
        key: "email",
        icon: EmailIcon,
        placeholder: languages[lang].pleaseWriteEmail,
        disabled: false,
      });
      setTranslatorFields(userFieldsConst);
      setLoading(false);
    }
  }, [lang, languagesData, translationFields]);
  const toggleModal = (stateName) => {
    setOpen({ ...isOpen, [stateName]: !isOpen[stateName] });
    if (stateName === "successModal" && isOpen.successModal) {
      setRedireact(!redirect);
    }
  };
  const addTranslator = async (params) => {
    setLoading(true);
    let res = await adminActions.addTranslatorAction(params);
    if (res.data.isSuccess) {
      toggleModal("successModal");
      setModalMsg(res.data?.message?.[lang] ?? "Done");
    } else {
      toggleModal("failModal");
      setModalMsg(res.data?.message?.[lang]);
    }
    setLoading(false);
  };
  return (
    <div>
      <NavbarAndSidebarCont
        isOpen={isOpen}
        loading={loading}
        toggleModal={toggleModal}
        modalMsg={modalMsg}>
        <div className={`cutomer-details`}>
          <h4>{languages[lang].addTranslator}</h4>
          <CustomerForm
            data={customerInfo}
            userFieldsConst={translatorFields}
            emailField={emailField}
            langConst={langConst}
            fieldsConst={fieldsConst}
            addNewTranslator
            action={addTranslator}
            setLoading={() => setLoading(!loading)}
          />
        </div>
        {redirect ? <Navigate to='/dashboard/translators' /> : null}
      </NavbarAndSidebarCont>
    </div>
  );
}
