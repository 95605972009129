/** @format */

import React from "react";
import { useSelector } from "react-redux";

export default function Date(props) {
  let { lang, dir } = useSelector((state) => state.lang);
  return (
    <div
      className={`input-cont__field-cont ${
        props.errors && "input-cont__field-cont--error"
      }`}>
      <input
        value={props.value}
        placeholder='MM/DD/YYYY'
        dir={dir}
        lang={lang}
        type='date'
        onChange={(e) => props.getChanges(props.stateName, e.target.value)}
      />
      {props.icon ? <img src={props.icon} /> : null}
    </div>
  );
}
