/** @format */

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import RightArrow from "../../../Assets/Images/Icon ionic-ios-arrow-right.svg";
import { languages } from "../../../Constants/Languages";
const statusData = [
  { data: "في إنتظار الموافقة", style: "yellow" },
  { data: "جاري الترجمة", style: "blue" },
  { data: "In Translation", style: "blue" },
  { data: "تم إرسال الترجمة", style: "green" },
  { data: "مدفوع", style: "green" },
  { data: "Paid", style: "green" },
  { data: "تم الإلغاء", style: "red" },
  { data: "مٌلغى", style: "red" },
  { data: "تم الشحن", style: "green" },
  { data: "منتظر التوصيل", style: "yellow" },
  { data: "Waiting Delivery", style: "yellow" },
  { data: "جديد", style: "yellow" },
  { data: "منتهي", style: "green" },
  { data: "Done", style: "green" },
  { data: "New", style: "yellow" },
  { data: "Pending", style: "yellow" },
  { data: "قيد الانتظار", style: "yellow" },
  { data: "تم ترجمته", style: "green" },
  { data: "Translated", style: "green" },
  { data: "جاري التوصيل", style: "blue" },
  { data: "In Delivery", style: "blue" },
  { data: "Rejected", style: "red" },
  { data: "Joined", style: "green" },
  { data: "Under Review", style: "blue" },
  { data: "مرفوض", style: "red" },
  { data: "انضم", style: "green" },
  { data: "تحت المراجعة", style: "blue" },
];
export default function Status(props) {
  let [statusStyle, setStyle] = useState("");
  let { lang, dir } = useSelector((state) => state.lang);
  useEffect(() => {
    setStyle(statusData.find((status) => status.data === props.data)?.style);
  }, [props.data]);

  return (
    <div style={{ display: `${props.showMsg ? "flex" : "block"}` }}>
      {props.showMsg ? (
        <span style={{ margin: "0px 5px" }}>
          {" "}
          {languages[lang].orderStatus}:{" "}
        </span>
      ) : null}
      <div
        className={`table__body__status table__body__status--${statusStyle}`}
      >
        <span>{props.data}</span>
        {props.removeArrow ? null : (
          <img
            className={`${
              lang === "en" &&
              "table__body__status table__body__status__flibImg"
            }`}
            src={RightArrow}
          />
        )}
      </div>
    </div>
  );
}
