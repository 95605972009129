/** @format */

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { languages } from "../../../Constants/Languages";
import NavbarAndSidebarCont from "../../../Components/Shared/NavbarAndSidebarCont";
import { clintActions } from "../../../actions/clintActions";
import { bindActionCreators } from "@reduxjs/toolkit";
import { adminActions } from "../../../actions/adminActions";
import BundleCard from "../../../Components/BundleCard";
import { createInitiatePayment } from "../../../Services/PaymentServices";
import RadioBtn from "../../../Assets/Images/Icon ionic-ios-radio-button-off.png";
import selectedRadioBtn from "../../../Assets/Images/Icon ionic-ios-radio-button-on.png";
import { Checkbox } from "@mui/material";
import {
  buyBundleReq,
  sendtranslationReq,
} from "../../../Services/companyServices";
export default function CompanyPackages() {
  const [loading, setLoading] = useState(true);
  const [selectedBundle, setSelectedBundle] = useState({});
  const [isOpen, setOpen] = useState({ successModal: false, failModal: false });
  const [redirect, setRedireact] = useState(false);
  const [payModel, setPayModel] = useState(false);

  const [modalMsg, setModalMsg] = useState("");

  let { lang, dir } = useSelector((state) => state.lang);
  let dispatch = useDispatch();
  let { getPackages } = bindActionCreators(adminActions, dispatch);
  let { packages } = useSelector((state) => state.commonData);
  useEffect(() => {
    getPackages();
  }, []);
  useEffect(() => {
    if (packages.length) {
      setLoading(false);
    }
  }, [packages]);
  useEffect(() => { }, [lang]);
  const toggleModal = (stateName) => {
    setOpen({ ...isOpen, [stateName]: !isOpen[stateName] });
    if (stateName === "successModal" && isOpen.successModal) {
      setRedireact(!redirect);
    }
  };

  const [agreeOnTerms, toggleAgreement] = useState(true);
  const [selectedMethodId, setMethodId] = useState(1);
  const [cards, setCards] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      let response = await createInitiatePayment({
        InvoiceAmount: selectedBundle.price,
        CurrencyIso: "KWD",
      }).then((res) => {
        if (res.data.isSuccess) {
          setCards(
            res?.data?.data?.paymentMethods.filter(
              (res) => res.paymentMethodId == 1 || res.paymentMethodId == 2
            )
          );
        } else if (res.status === 401) {
          // props.setPhaseNum(2);
          // props.openLogin();
        }
      });
      console.log(response);
    };
    if (payModel && selectedBundle.price) {
      fetchData();
    }
  }, [payModel, selectedBundle.price]);
  // const toggleCancelModal = (modalName, event, id) => {
  //   setId(id);
  //   setModalOpen({ ...isModalOpen, [modalName]: !isModalOpen[modalName] });
  //   if (event) {
  //     event.preventDefault();
  //   }
  // };
  const [isModalOpen, setModalOpen] = useState({ cancelModal: false });
  const editClintInfo = async (params) => {
    setLoading(true);
    let res = await clintActions.editClintInfoAction(params);
    if (res.data.isSuccess) {
      toggleModal("successModal");
      setModalMsg(res.data?.message?.[lang] ?? "Done");
    } else {
      toggleModal("failModal");
      setModalMsg(res.data?.message?.[lang]);
    }
    setLoading(false);
  };

  const renderCard = (img, id, title) => {
    return (
      <div
        className={`text-center itemPay border-1 ${id === selectedMethodId &&
          "thired-phase-cont__cards-cont__card--selected"
          }`}
      >
        <div
          onClick={() => setMethodId(id)}
          style={{
            flexWrap: "flex-wrap",
            flexDirection: "row",
            margin: "4px",
            padding: "0 10px",
          }}
          className={`thired-phase-cont__cards-cont__card `}
        >
          <img
            src={img}
            className="thired-phase-cont__cards-cont__card__logo"
            alt="paymentLogo"
          />
          <img
            src={selectedMethodId === id ? selectedRadioBtn : RadioBtn}
            className="thired-phase-cont__cards-cont__card__radio"
            alt="radio"
          />
        </div>
        <p className="text-center m-0">{title}</p>
      </div>
    );
  };

  const buyBundle = (e) => {
    setSelectedBundle(e);
    setPayModel(true);
  };

  const sendReq = async (data) => {
    const response = await buyBundleReq({
      bundleId: selectedBundle.id,
      paymentMethodId: selectedMethodId,
      displayCurrencyIso: data.DisplayCurrencyIso,
    });

    if (response.data.isSuccess) {
      window.location.href = response?.data?.data?.data?.paymentURL;
    }
  };

  return (
    <div>
      <NavbarAndSidebarCont
        isOpen={isOpen}
        loading={loading}
        toggleModal={toggleModal}
        modalMsg={modalMsg}
        userSidebar="company"
      >
        {!payModel ? (
          <div className={`cutomer-details `} lang={lang} dir={dir}>
            <div className={`cutomer-details__bundles `}>
              <div className={`cutomer-details__bundles__cards-container `}>
                {packages?.map((bundle) => {
                  return bundle.bundleClassId === 1 ? (
                    <BundleCard
                      bundle={bundle}
                      key={bundle.id}
                      buyBundle={buyBundle}
                    />
                  ) : null;
                })}
              </div>
              <div className="customer-form__checkBox-cont__title cutomer-details__bundles__divider ">
                {
                  packages?.some((bundle) => bundle.bundleClassId === 2) ?
                    <span style={{ fontSize: "20px" }}>
                      {languages[lang].extraBundle}
                    </span> : null
                }
                <hr />
              </div>
              <div className={`cutomer-details__bundles__cards-container `}>
                {packages?.map((bundle) => {
                  return bundle.bundleClassId === 2 ? (
                    <BundleCard
                      bundle={bundle}
                      key={bundle.id}
                      buyBundle={buyBundle}
                    />
                  ) : null;
                })}
              </div>
            </div>
          </div>
        ) : null}
        {/* <CancelModal
        toggleModal={props.toggleModal}
        modalName='cancelModal'
        open={props.isOpen}
        msg={props.msg ?? languages[lang].deleteCustomers}
        id={props.id}
        deleteAction={props.deleteAction}
        loading={props.loading}
      /> */}
        {payModel === true ? (
          <div
            style={{
              margin: "4% auto",
              width: "80%",
              overflowY: "scroll",
              height: "80vh",
            }}
          >
            <div style={{ width: "100%", textAlign: "right" }}>
              <button className="btn-back" onClick={() => setPayModel(false)}>
                {languages[lang].back}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="#fff"
                  width="24"
                  height="24"
                >
                  <path d="m11.707 3.293-1.414 1.414L17.586 12l-7.293 7.293 1.414 1.414L20.414 12l-8.707-8.707z" />
                  <path d="M5.707 3.293 4.293 4.707 11.586 12l-7.293 7.293 1.414 1.414L14.414 12 5.707 3.293z" />
                </svg>
              </button>
            </div>
            <div className="thired-phase-cont">
              <div style={{ width: "-webkit-fill-available" }}>
                <span>إجمالي المبلغ</span>

                <h2 dir="rtl" lang="ar">
                  {selectedBundle.price} {languages[lang].DK}
                </h2>
                <h4>أختر طريقة الدفع</h4>
                <div
                  className="thired-phase-cont__cards-cont"
                  style={{ flexWrap: "wrap", justifyContent: "flex-end" }}
                >
                  {cards.map((card) =>
                    renderCard(
                      card.imageUrl,
                      card.paymentMethodId,
                      lang == "ar" ? card.paymentMethodAr : card.paymentMethodEn
                    )
                  )}
                </div>
                <div
                  dir="rtl"
                  lang="ar"
                  className="thired-phase-cont__agreement-checkbox"
                  onClick={() => toggleAgreement(!agreeOnTerms)}
                >
                  <Checkbox
                    label="تذكرني"
                    checked={agreeOnTerms}
                    sx={{
                      color: "#1E8AB2",
                      "&.Mui-checked": {
                        color: "#1E8AB2",
                      },
                    }}
                  />{" "}
                  <span dir="rtl" lang="ar">
                    اوافق على <a>الشروط والاحكام</a>
                  </span>
                </div>
                <button
                  disabled={!agreeOnTerms}
                  onClick={() =>
                    sendReq({
                      paymentMethodId: selectedMethodId,
                      DisplayCurrencyIso: "KWD",
                    })
                  }
                >
                  {" "}
                  {languages[lang].next}
                </button>
              </div>
            </div>
          </div>
        ) : null}
      </NavbarAndSidebarCont>
    </div>
  );
}
