/** @format */
import { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import Checkbox from "@mui/material/Checkbox";
import Logo from "../../../Assets/Images/Group 39808.png";
import InputField from "../../InputField";
import EmailIcon from "../../../Assets/Images/Group 39800.svg";
import EyeIcon from "../../../Assets/Images/Icon ionic-ios-eye.svg";
import closeIcon from "../../../Assets/Images/Icon ionic-ios-close-circle-outline.svg";
import "./index.scss";
import { languages } from "../../../Constants/Languages";
import { useSelector } from "react-redux";
import { commonActions } from "../../../actions/commonActions";
import SuccessAndFailModal from "../SuccessAndFailModal";
const fieldsDataInit = {
  newPassword: null,
  confirmPassword: null,
};
const errorsInit = {
  newPassword: null,
  confirmPassword: null,
};
export default function ChangePass(props) {
  const [fieldsData, setData] = useState(fieldsDataInit);
  const [errors, setErrors] = useState(errorsInit);
  const [isOpen, setOpen] = useState({ successModal: false, failModal: false });
  const [modalMsg, setModalMsg] = useState("");
  const [disableSubmitt, toggleDisable] = useState(false);
  const { lang } = useSelector((state) => state.lang);
  const getChanges = (key, value) => {
    setErrors({ ...errors, [key]: null });
    setData({ ...fieldsData, [key]: value });
  };
  useEffect(() => {
    setData(fieldsDataInit);
    setErrors(errorsInit);
  }, [props.open]);
  const toggleModal = (stateName) => {
    setOpen({ ...isOpen, [stateName]: !isOpen[stateName] });
  };
  const changePass = async () => {
    let errorsClone = {};
    Object.keys(fieldsData).forEach((field) => {
      if (!fieldsData[field]?.length) {
        errorsClone = {
          ...errorsClone,
          [field]: languages[lang].requiredField,
        };
      }
    });
    setErrors(errorsClone);
    if (Object.keys(errorsClone).length === 0) {
      let userData = JSON.parse(localStorage.getItem("userRole"));
      let params = {
        ...fieldsData,
        token: userData.token,
        email: userData.email,
      };
      let response = await commonActions.resetPassword(params);

      if (!response.isSuccess) {
        if (response.errors) {
          let errorObj = {};
          Object.keys(response.errors).forEach((errorKey) => {
            errorObj = {
              ...errorObj,
              [errorKey]: response.errors[errorKey].join(", "),
            };
          });
          setErrors(errorObj);
        } else if (response.message.ar.length) {
          setModalMsg(response.message[lang]);
          toggleModal("failModal");
        }
      } else {
        setModalMsg(languages[lang].userCreated);
        toggleModal("successModal");
        props.toggleModal(props.modalName);
      }
      props.toggleLoading(false);
    }
  };
  const validatData = (key, value) => {
    let errorsClone = { ...errors };
    errorsClone = { ...errorsClone, [key]: null };
    if (fieldsData.confirmPassword !== fieldsData.newPassword) {
      errorsClone = {
        ...errorsClone,
        confirmPassword: languages[lang].passwordConfirmationError,
      };
      toggleDisable(true);
    } else {
      toggleDisable(false);
    }

    setErrors(errorsClone);
  };
  return (
    <>
      <Modal
        open={props.open}
        onClose={() => props.toggleModal(props.modalName)}
      >
        <div className="login-cont">
          <img
            src={closeIcon}
            className="closeIcon"
            onClick={() => props.toggleModal(props.modalName)}
          />
          <img className="logo" src={Logo} />
          <h5 className="login-cont__title">{languages[lang].completeApp}</h5>
          <div style={{ textAlign: "start" }}>
            <InputField
              title={languages[lang].oldPassword}
              placeholder={languages[lang].pleaseWritePass}
              value={fieldsData.oldPassword}
              getChanges={getChanges}
              stateName={"oldPassword"}
              errors={errors}
              icon={EyeIcon}
            />
            <InputField
              title={languages[lang].newPassword}
              placeholder={languages[lang].pleaseWritePass}
              value={fieldsData.newPassword}
              getChanges={getChanges}
              stateName={"newPassword"}
              errors={errors}
              icon={EyeIcon}
            />
            <InputField
              title={languages[lang].confirmPass}
              placeholder={languages[lang].pleaseWritePass}
              value={fieldsData.confirmPassword}
              getChanges={getChanges}
              stateName={"confirmPassword"}
              errors={errors}
              icon={EyeIcon}
              validatData={validatData}
            />

            <button onClick={changePass} disabled={disableSubmitt}>
              {languages[lang].changePass}
            </button>
          </div>
        </div>
      </Modal>
      <SuccessAndFailModal
        toggleModal={toggleModal}
        modalName={isOpen.successModal ? "successModal" : "failModal"}
        open={isOpen.successModal || isOpen.failModal}
        msg={modalMsg}
        buttonTitle={languages[lang].ok}
      />
    </>
  );
}
