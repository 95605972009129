/** @format */

import { getReq, postReq, postReqConfig } from "./APIMethods";

export const sendCompanyReq = async (params) => {
  let response = await postReq(
    "CompanyRequests/create-company-request",
    params
  );
  return response;
};
export const sendtranslationReq = async (params) => {
  let response = await postReq("Orders/place-order", params);
  return response;
};
export const sendCompanyTranslationReq = async (params) => {
  let response = await postReq("Orders/place-company-order", params);
  return response;
};
export const buyBundleReq = async (params) => {
  let response = await postReq("Bundles/company/buy-bundle", params);
  return response;
};
export const sendTranslationData = async (params, type, config) => {
  let response = await postReqConfig(`Files?type=${type}`, params, config);
  return response;
};

export const getEstimateDue = async (params) => {
  let response = await getReq(
    `Orders/estimate-due-date?documentId=${params.documentId}&withDelivery=${
      params.withDelivery
    }&addOnId =${params.addOnId ? params.addOnId : null}`
  );
  return response;
};
