/** @format */
import { useSelector } from "react-redux";
import Section2Img from "../../../Assets/Images/Image 1.png";
import { languages } from "../../../Constants/Languages";
import "./index.scss";
export default function AboutUsPart2() {
  let { lang, dir } = useSelector((state) => state.lang);
  return (
    <section className='sec2-cont' id='main' lang={lang} dir={dir}>
      <div className='sec2-cont__info'>
        <h3> {languages[lang].kuwaitQamous}</h3>
        <span>{languages[lang].kuwaitQamousBody1}</span>
        <span>{languages[lang].kuwaitQamousBody2}</span>
      </div>
      <div className='sec2-cont__img-cont'>
        <img src={Section2Img} />
        <div className='sec2-cont__img-cont__border-div' />
      </div>
    </section>
  );
}
