/** @format */

import { getReq, postReq } from "./APIMethods";

export const registerUser = async (params) => {
  let response = await postReq("Auth/Register", params);
  return response;
};
export const userLogin = async (params) => {
  let response = await postReq("Auth/Login", params);
  return response;
};
export const forgetPassService = async (params) => {
  let response = await postReq("Auth/ForgetPassword", params);
  return response;
};
export const resetPassService = async (params) => {
  let response = await postReq("Auth/ResetPassword", params);
  return response;
};
export const getIndustries = async () => {
  let response = await getReq("CompanyRequests/get-company-Industries");
  return response;
};
export const getLanguages = async () => {
  let response = await getReq("Languages/get-languages");
  return response;
};
export const getOrderTypes = async () => {
  let response = await getReq("OrderTypes/get-order-types");
  return response;
};
export const getAddOns = async () => {
  let response = await getReq("AddOns/get-add-ons");
  return response;
};
export const getTranslationFields = async () => {
  let response = await getReq("TranslationFields/get-translation-fields");
  return response;
};
export const getCities = async () => {
  let response = await getReq("Cities/get-cites");
  return response;
};
export const getUserPerissions = async () => {
  let response = await getReq("Users/get-user-permissions");

  return response;
};
export const getPackagesServices = async () => {
  let response = await getReq("Bundles/get-bundles?page=1&pageSize=10");
  return response;
};

export const getDetailedServices = async (orderId) => {
  let response = await getReq(
    "Notifications/detailed-order?orderId=" + orderId
  );
  return response;
};
