/** @format */

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import CustomNavbar from "../../../../Components/Shared/CustomNavbar";
import Sidebar from "../../../../Components/Shared/Sidebar";

import { prepareDashboardSideBar } from "../../../../Constants/PrepareData";
import { languages } from "../../../../Constants/Languages";
import PackageForm from "../../../../Components/AdminComponents/PackageForm";
import NavbarAndSidebarCont from "../../../../Components/Shared/NavbarAndSidebarCont";
import { bindActionCreators } from "@reduxjs/toolkit";
import { adminActions } from "../../../../actions/adminActions";
import Loader from "../../../../Components/Shared/Loader";
import SuccessAndFailModal from "../../../../Components/Modals/SuccessAndFailModal";
import { Navigate } from "react-router-dom";

export default function AddNewPackage(props) {
  const [packageFields, setPackageFields] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isOpen, setOpen] = useState({ successModal: false, failModal: false });
  const [modalMsg, setModalMsg] = useState("");
  const [redirect, setRedireact] = useState(false);
  let { lang, dir } = useSelector((state) => state.lang);
  let { bundleTypes } = useSelector((state) => state.commonData);
  const dispatch = useDispatch();
  const { getBundleTypes, addPackageAction } = bindActionCreators(
    adminActions,
    dispatch
  );
  useEffect(() => {
    (async () => {
      setLoading(true);
      let response = await getBundleTypes();

      setLoading(false);
    })();
  }, []);
  useEffect(() => {
    if (bundleTypes.length) {
      let packageFieldsConst = [
        {
          title: languages[lang].packageName,
          key: "ar",
          placeholder: languages[lang].enterInAr,
          type: "text",
        },
        {
          title: languages[lang].packageName,
          key: "en",
          placeholder: languages[lang].enterInE,
          type: "text",
        },

        {
          title: languages[lang].serviceType,
          key: "bundleClassId",
          placeholder: languages[lang].chooseServiceType,
          type: "select",
          options: [
            { label: { ar: "أساسية", en: "Basic" }, value: 1 },
            { label: { ar: "إضافية", en: "Extra" }, value: 2 },
          ],
        },
        {
          title: languages[lang].subscriptionPeriod,
          key: "bundleTypeId",
          placeholder: languages[lang].chooseSubscriptionPeriod,
          type: "select",
          options: bundleTypes,
        },
        {
          title: languages[lang].wordsNum,
          key: "wordCount",
          type: "number",
          placeholder: languages[lang].writeNumOffWords,
        },
        {
          title: languages[lang].price,
          key: "price",
          placeholder: languages[lang].writePrice,
          type: "number",
        },
      ];

      setPackageFields(packageFieldsConst);
    }
  }, [lang, bundleTypes]);
  const addPackage = async (params) => {
    setLoading(true);
    let response = await addPackageAction(params);
    if (response.data.isSuccess) {
      toggleModal("successModal");
      setModalMsg(response.data.message[lang]);
    } else {
      toggleModal("failModal");
      setModalMsg(response.data.message[lang]);
    }
    setLoading(false);
    return response.data.isSuccess;
  };

  const toggleModal = (stateName) => {
    setOpen({ ...isOpen, [stateName]: !isOpen[stateName] });
    if (stateName === "successModal" && isOpen.successModal) {
      setRedireact(!redirect);
    }
  };
  return (
    <div className='customers-cont '>
      <NavbarAndSidebarCont>
        <div>
          <h4>{languages[lang].addNewPackage}</h4>
          <PackageForm
            packageFields={packageFields}
            addPackage={addPackage}
            redirect={redirect}
          />
        </div>
        <SuccessAndFailModal
          toggleModal={toggleModal}
          modalName={isOpen.successModal ? "successModal" : "failModal"}
          open={isOpen.successModal || isOpen.failModal}
          msg={modalMsg}
          buttonTitle={languages[lang].ok}
        />
        {redirect ? <Navigate to='/dashboard/companies' /> : null}

        <Loader loading={loading} />
      </NavbarAndSidebarCont>
    </div>
  );
}
