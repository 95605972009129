/** @format */

import React from "react";
import NavbarAndSidebarCont from "../../../../Components/Shared/NavbarAndSidebarCont";
import TranslateForm from "../../../../Components/Shared/TranslateForm";

export default function AddRequest() {
  return (
    <NavbarAndSidebarCont userSidebar='customer'>
      <TranslateForm />
    </NavbarAndSidebarCont>
  );
}
