/** @format */
import Icon1 from "../../../Assets/Images/Image 12.png";
import Icon2 from "../../../Assets/Images/Image 10.png";
import Icon3 from "../../../Assets/Images/Image 6.png";
import Icon4 from "../../../Assets/Images/Image 8.png";
import Icon5 from "../../../Assets/Images/Image 11.png";
import Icon6 from "../../../Assets/Images/Image 9.png";
import Card from "../Card";
import "./index.scss";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useState } from "react";
import { languages } from "../../../Constants/Languages";
export default function WhatWeOfferPart1() {
  let { lang, dir } = useSelector((state) => state.lang);
  const [cardsList, setCards] = useState([]);
  useEffect(() => {
    const cards = [
      {
        icon: Icon1,
        title: languages[lang].timeQuality,
        body: languages[lang].timeQualityBody,
      },
      {
        icon: Icon2,
        title: languages[lang].quality,
        body: languages[lang].qualityBody,
      },
      {
        icon: Icon3,
        title: languages[lang].ourTranslators,
        body: languages[lang].ourTranslatorsBody,
      },

      {
        icon: Icon4,
        title: languages[lang].corporateServices,
        body: languages[lang].corporateServicesBody,
      },
      {
        icon: Icon5,
        title: languages[lang].deliveryService,
        body: languages[lang].deliveryServiceBody,
      },
      {
        icon: Icon6,
        title: languages[lang].certifiedTranslation,
        body: languages[lang].certifiedTranslationBody,
      },
    ];
    setCards(cards);
  }, [lang]);
  return (
    <section className='offerSec1-cont' id='services' lang={lang} dir={dir}>
      <div className='offerSec1-cont__cards-cont'>
        {cardsList.map((card) => (
          <Card icon={card.icon} title={card.title} body={card.body} />
        ))}
      </div>
    </section>
  );
}
