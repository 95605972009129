/** @format */
import PointIcon from "../../../Assets/Images/Path 1619.svg";
import CurrentPonit from "../../../Assets/Images/Group 39798.png";
import WightPoints from "../../../Assets/Images/Icon feather-more-horizontal.svg";
import GrayPoints from "../../../Assets/Images/Icon feather-more-horizontal (1).svg";
import CheckedMark from "../../../Assets/Images/Icon metro-checkmark.svg";
import "./index.scss";
import { useSelector } from "react-redux";
import { languages } from "../../../Constants/Languages";
export default function ProgressBar(props) {
  let { lang, dir } = useSelector((state) => state.lang);
  const renderPonit = (
    contImg,
    pointImg,
    msg,
    donePoint,
    checked,
    changePhase,
    phaseNum
  ) => {
    return (
      <div className="progress__point">
        <img
          src={contImg}
          className={`${!donePoint && "progress__point__mainImg"} `}
        />
        {checked ? (
          <img src={CheckedMark} className={`progress__point__checked`} />
        ) : (
          <img
            src={pointImg}
            className={`progress__point__innerPoints ${
              donePoint && "progress__point__innerPoints--extraStyle"
            } `}
          />
        )}

        <span className={`${!donePoint && "progress__point__msg"} `}>
          {msg}
        </span>
      </div>
    );
  };
  return (
    <div className="progress" dir={dir} lang={lang}>
      <div className="progress__bar" />
      {renderPonit(
        CurrentPonit,
        WightPoints,
        languages[lang].translationData,
        "donePoint",
        props.phaseNum === 2 ? true : false,
        props.changePhaseNum,
        1
      )}
      {props.phaseNum === 1
        ? renderPonit(PointIcon, GrayPoints, languages[lang].translationDetails)
        : renderPonit(
            CurrentPonit,
            WightPoints,
            languages[lang].translationDetails,
            "donePoint",
            props.phaseNum === 3 ? true : false,
            props.changePhaseNum,
            2
          )}

      {props.userType === "company"
        ? null
        : props.phaseNum === 3
        ? renderPonit(
            CurrentPonit,
            WightPoints,
            languages[lang].paymentMethod,
            "donePoint"
          )
        : renderPonit(PointIcon, GrayPoints, languages[lang].paymentMethod)}
    </div>
  );
}
