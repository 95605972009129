/** @format */

import Modal from "@mui/material/Modal";
import { useSelector } from "react-redux";

import Logo from "../../../Assets/Images/Group 39808.png";

import closeIcon from "../../../Assets/Images/Icon ionic-ios-close-circle-outline.svg";
import { languages } from "../../../Constants/Languages";
import Loader from "../../Shared/Loader";
// import "./index.scss";

export default function CancelModal(props) {
  let { lang, dir } = useSelector((state) => state.lang);
  return (
    <Modal open={props.open} onClose={() => props.toggleModal(props.modalName)}>
      <div className='login-cont'>
        <img
          src={closeIcon}
          className='closeIcon'
          onClick={() => props.toggleModal(props.modalName)}
        />
        <img className='logo' src={Logo} />
        <h5 className='login-cont__title'>
          {props.msg ?? languages[lang].cancelOrderQ}
        </h5>
        <div className='login-cont__cancelModal-footer'>
          <button onClick={() => props.toggleModal(props.modalName)}>
            {languages[lang].no}
          </button>
          <button onClick={() => props.deleteAction(props.id)}>
            {languages[lang].yes}
          </button>
        </div>
        <Loader loading={props.loading} />
      </div>
    </Modal>
  );
}
