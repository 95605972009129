/** @format */

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomerForm from "../../../../Components/AdminComponents/CutomerForm";
import CustomerHeader from "../../../../Components/Shared/CutomerHeader";
import NavbarAndSidebarCont from "../../../../Components/Shared/NavbarAndSidebarCont";
import OrderTable from "../../../../Components/Shared/OrderTable";
import UserIcon from "../../../../Assets/Images/Icon awesome-user-alt.svg";
import phoneIcon from "../../../../Assets/Images/Icon ionic-md-phone-portrait.svg";
import EmailIcon from "../../../../Assets/Images/Icon ionic-ios-mail.svg";
import { languages } from "../../../../Constants/Languages";
import { Navigate, useParams } from "react-router-dom";
import { bindActionCreators } from "@reduxjs/toolkit";
import { adminActions } from "../../../../actions/adminActions";
export default function TranslatorDetails() {
  const [loading, setLoading] = useState(true);
  const [isOpen, setOpen] = useState({ successModal: false, failModal: false });
  const [redirect, setRedireact] = useState(false);
  const [modalMsg, setModalMsg] = useState("");
  const [orders, setOrders] = useState([]);
  const [translatorFields, setTranslatorFields] = useState([]);
  const [emailField, setEmailField] = useState({});
  const [customerInfo, setCustomerInfo] = useState({});
  const [selectedpage, setPage] = useState("info");
  const [langConst, setLangCont] = useState([]);
  const [fieldsConst, setFieldsConst] = useState([]);
  const [orderConst, setOrderConst] = useState([]);
  let { lang, dir } = useSelector((state) => state.lang);
  let { translationFields, languagesData } = useSelector(
    (state) => state.commonData
  );
  let { translatorInfo, translatorOrders } = useSelector(
    (state) => state.admin
  );
  let { adminUserPermissions } = useSelector((state) => state.admin);
  let dispatch = useDispatch();
  let { getTranslatorInfoAction, getTranslatorOrdersAction } =
    bindActionCreators(adminActions, dispatch);

  let params = useParams();
  useEffect(() => {
    setCustomerInfo(JSON.parse(localStorage.getItem("translatorData")));
    getTranslatorInfoAction(params.id);
    getTranslatorOrdersAction(params.id, 1);
  }, []);
  useEffect(() => {
    if (
      languagesData.length &&
      translationFields.length &&
      translatorInfo.languages?.length
    ) {
      setLoading(false);
      setCustomerInfo({ ...customerInfo, ...translatorInfo });
      let userFieldsConst = [
        {
          title: languages[lang].name,
          key: "fullName",
          icon: UserIcon,
          placeholder: languages[lang].pleaseWriteName,
          disabled: true,
        },
        {
          title: languages[lang].phoneNum,
          key: "phoneNumber",
          icon: phoneIcon,
          placeholder: languages[lang].pleaseWritePhone,
          disabled: true,
          type: "phoneNumber",
        },
      ];
      let orderConst = [
        { label: "#", key: "id" },
        { label: languages[lang].requestData, key: "createdAt" },

        { label: languages[lang].translateFrom, key: "fromLang" },
        { label: languages[lang].translateTo, key: "toLang" },
        { label: languages[lang].translationField, key: "translationField" },
        { label: languages[lang].translationType, key: "orderType" },
        // { label: languages[lang].serviceType, key: "addOns" },

        { label: languages[lang].deleviryData, key: "fininshedAt" },
        // { label: languages[lang].status, key: "status" },
      ];
      setOrderConst(orderConst);
      setFieldsConst(
        translationFields.map((field) => ({
          ...field,
          label: field.label[lang],
        }))
      );
      setLangCont(
        languagesData.map((item) => ({ ...item, label: item.label.ar }))
      );
      setEmailField({
        title: languages[lang].email,
        key: "email",
        icon: EmailIcon,
        placeholder: languages[lang].pleaseWriteEmail,
        disabled: true,
      });
      setTranslatorFields(userFieldsConst);
      setOrders(
        translatorOrders.map((order) => ({
          ...order,
          orderType: order.orderType[lang],
          translationField: order.translationField[lang],
        }))
      );
    }
  }, [
    lang,
    translatorInfo,
    translatorOrders,
    languagesData,
    translationFields,
  ]);
  const toggleModal = (stateName) => {
    setOpen({ ...isOpen, [stateName]: !isOpen[stateName] });
    if (stateName === "successModal" && isOpen.successModal) {
      setRedireact(!redirect);
    }
  };
  const editTranslator = async (data) => {
    data = { ...data, id: params.id };
    setLoading(true);
    let res = await adminActions.editTranslatorInfoAction(data);
    if (res.data.isSuccess) {
      toggleModal("successModal");
      setModalMsg(res.data?.message?.[lang] ?? "Done");
    } else {
      toggleModal("failModal");
      setModalMsg(res.data?.message?.[lang]);
    }
    setLoading(false);
  };
  return (
    <div>
      <NavbarAndSidebarCont
        isOpen={isOpen}
        loading={loading}
        toggleModal={toggleModal}
        modalMsg={modalMsg}>
        <div className={`cutomer-details`}>
          <CustomerHeader
            customerInfo={customerInfo}
            selectedpage={selectedpage}
            changePage={(page) => setPage(page)}
            dataBtnTitle={languages[lang].customerInfo}
            hideList={translatorOrders.length === 0}
          />
          {selectedpage === "info" ? (
            <CustomerForm
              data={customerInfo}
              userFieldsConst={translatorFields}
              emailField={emailField}
              langConst={langConst}
              fieldsConst={fieldsConst}
              addNewTranslator
              action={editTranslator}
              btnTitle='edittranslator'
              disabled={
                !adminUserPermissions.includes("PermissionTranslatorsEdit")
              }
            />
          ) : (
            <div className='cutomer-details__table-cont'>
              <OrderTable
                orders={orders}
                constants={orderConst}
                style='translatorTable'
                removeHeaderBorder
                disableRouting
                // url='dashboard/translators/request'
              />
            </div>
          )}
        </div>
        {redirect ? <Navigate to='/dashboard/translators' /> : null}
      </NavbarAndSidebarCont>
    </div>
  );
}
