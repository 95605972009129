/** @format */

import { bindActionCreators } from "@reduxjs/toolkit";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { adminActions } from "../../../../actions/adminActions";
import RequestsSearchbar from "../../../../Components/AdminComponents/RequestsSearchbar";
import ResponsiveTable from "../../../../Components/Shared/ResponsiveOrderTable";
import OrderTable from "../../../../Components/Shared/OrderTable";
import Pagination from "../../../../Components/Shared/Pagination";

import { languages } from "../../../../Constants/Languages";

import "./index.scss";
const OrdersTypes = [
  { langKey: "waitingForShipment", style: "yellow" },
  { langKey: "shipped", style: "blue" },
];
let styleObject = {
  New: "yellow",
  Done: "green",
  "In Translation": "blue",
  Translated: "green",
  "Waiting Delivery": "yellow",
  "In Delivery": "blue",
  Cancelled: "red",
};
export default function Shipments(props) {
  const [orders, setOrders] = useState([]);
  const [headerConstant, setHeaderConstant] = useState([]);
  const [searchValue, setSearch] = useState("");
  const [pagination, setPaginationData] = useState({});
  let { lang, dir } = useSelector((state) => state.lang);
  let { deliveryOrdersList } = useSelector((state) => state.admin);
  let { orderStatuses } = useSelector((state) => state.commonData);
  const [headerStatuses, setHeaderStatuses] = useState([]);
  let dispatch = useDispatch();
  let { getDeliveryOrdersAction } = bindActionCreators(adminActions, dispatch);
  useEffect(() => {
    props.toggleLoading(true);
    getDeliveryOrdersAction(1, 10);
  }, []);
  useEffect(() => {
    let tableHeaderConstant = [
      { label: "#", key: "id" },
      { label: languages[lang].requestData, key: "createdAt" },
      { label: languages[lang].translateFrom, key: "fromLang" },
      { label: languages[lang].translateTo, key: "toLang" },
      { label: languages[lang].translationField, key: "translationField" },
      { label: languages[lang].translationType, key: "orderType" },

      { label: languages[lang].deleviryData, key: "fininshedAt" },
      { label: languages[lang].status, key: "status" },
    ];
    setHeaderConstant(tableHeaderConstant);
  }, [lang]);
  useEffect(() => {
    if (deliveryOrdersList.data && orderStatuses.length) {
      props.toggleLoading(false);
      let ordersClone = [...deliveryOrdersList.data];

      ordersClone = ordersClone.map((order) => ({
        ...order,
        addOn: order.addOn?.[lang],
        orderType: order.orderType?.[lang],
        translationField: order.translationField?.[lang],
      }));
      let orderStatusesClone = orderStatuses.map((status) => ({
        ...status,
        num: 0,
        style: styleObject[status.en],
        label: { ar: status.ar, en: status.en },
        value: status.id,
      }));

      let modifiedOrders = [];
      ordersClone.forEach((order) => {
        let statusIndex = orderStatuses.findIndex(
          (status) => status.id === order.statusId
        );
        orderStatusesClone[statusIndex].num =
          orderStatusesClone[statusIndex].num + 1;
        order = {
          ...order,
          status: orderStatuses[statusIndex][lang],
        };
        modifiedOrders = [...modifiedOrders, order];
      });

      orderStatusesClone = orderStatusesClone.filter((order) =>
        [
          "75943a58-8719-4750-bf47-eab38fc33265",
          "fe6c21ec-ee70-46d2-9789-105428ef93ec",
        ].includes(order.value)
      );
      setHeaderStatuses(orderStatusesClone);
      setPaginationData(deliveryOrdersList.pagination);
      setOrders(modifiedOrders);
    }
  }, [deliveryOrdersList.data?.length, lang, orderStatuses.length]);
  const getSearchValues = async (searchString) => {
    setSearch(searchString);
    props.toggleLoading(true);

    let res = await getDeliveryOrdersAction(1, searchString);
    props.toggleLoading(false);
  };

  const getPageData = async (pageNum) => {
    if (pageNum !== pagination.CurrentPage) {
      props.toggleLoading(true);
      await getDeliveryOrdersAction(pageNum, searchValue);
      props.toggleLoading(false);
    }
  };
  const renderOrdersTypes = (type, index) => {
    return (
      <div
        className="orders-cont__body__info__types__type"
        key={type.langKey + index}
      >
        <div
          className={`orders-cont__body__info__types__type__dot orders-cont__body__info__types__type__dot--${type.style}`}
        />
        <div>
          <span>{type.num}</span>
          <span>{type[lang]}</span>
        </div>
      </div>
    );
  };

  return (
    <div className="orders-cont">
      <div
        className={`orders-cont__body ${
          props.isOpen && "orders-cont__body--apply-margin"
        }`}
      >
        <div className="orders-cont__body__info" dir={dir} lang={lang}>
          <h4>{languages[lang].allRequests}</h4>
          <div className="orders-cont__body__info__types orders-cont__body__info__types--normalView">
            {headerStatuses.map((type, index) =>
              renderOrdersTypes(type, index)
            )}
          </div>
        </div>

        <RequestsSearchbar
          statusOptions={headerStatuses}
          getSearchValues={getSearchValues}
          hideFieldSearch
        />

        <OrderTable
          constants={headerConstant}
          url="dashboard/requests&shipping/request"
          orders={orders}
          style="translator-requests"
          saveToSessionStorege="orderDetails"
        />

        {orders.length ? (
          <ResponsiveTable
            url="company/requests"
            orders={orders}
            constants={headerConstant}
            style="customer-requests"
            saveToSessionStorege="orderDetails"
          />
        ) : (
          <h3>{languages[lang].noRequests}</h3>
        )}
      </div>

      {pagination.TotalPages === 1 ? null : (
        <Pagination pagination={pagination} getPageData={getPageData} />
      )}
    </div>
  );
}
