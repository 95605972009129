/** @format */
import { useEffect, useState } from "react";
import Drawer from "@mui/material/Drawer";
import EngFlag from "../../Assets/Images/Image 14@2x.png";
import Logo from "../../Assets/Images/Group 39808.png";
import Dots from "../../Assets/Images/Group 39774.png";
import LoginModal from "../Modals/LoginModal";
import SignupModal from "../Modals/SignupModal";
import ForgetPass from "../Modals/ForgetPassModal";
import BurgerMenu from "../../Assets/Images/Icon feather-menu.svg";
import msgIcon from "../../Assets/Images/Icon material-message.png";
import groupIcon from "../../Assets/Images/Icon material-group.png";
import services from "../../Assets/Images/Group 39869.png";
import infoIcon from "../../Assets/Images/Icon awesome-info-circle.png";
import HomeIcon from "../../Assets/Images/Icon awesome-home-black.png";
import CloseIcon from "../../Assets/Images/Path 11470.png";
import ArbFlag from "../../Assets/Images/Flag_of_Kuwait.svg.webp";
import { changeLang } from "../../Redux/lang";
import { useSelector, useDispatch } from "react-redux";
import "./index.scss";
import { Link } from "react-router-dom";
import EmailSentModal from "../Modals/EmailSentModal";
import Loader from "../Shared/Loader";
import { languages } from "../../Constants/Languages";
const modalsCost = {
  login: false,
  signup: false,
  forgetPass: false,
  emailSent: false,
  successModal: false,
  failModal: false,
};
export default function Navbar(props) {
  const [userRole, setUserRole] = useState([]);
  const [isOpen, setOpen] = useState(modalsCost);
  const [showSideMenu, toggleMenu] = useState(false);
  const [forgetEmail, setEmail] = useState("");
  const [loading, toggleLoading] = useState(false);
  const { lang, dir } = useSelector((state) => state.lang);

  const dispatch = useDispatch();
  useEffect(() => {
    let userData = JSON.parse(localStorage.getItem("userRole"));
    let role = userData?.roles?.[0];
    userData = {
      ...userData,
      link:
        role === "Admin"
          ? "/admin-dashboard"
          : role === "User"
            ? "/customer/requests"
            : role === "Translator"
              ? "/translator/requests"
              : (role = "CompanyAdmin" ? "/company/requests" : "/"),
    };
    setUserRole(userData);
  }, []);
  useEffect(() => {
    if (props.openLoginFlag === true || props.openLoginFlag === false) {
      toggleModal("login");
    }
  }, [props.openLoginFlag]);

  const navList = [
    { title: "main", icon: HomeIcon, id: "main" },
    { title: "aboutUs", icon: infoIcon, id: "about-us" },
    { title: "services", icon: services, id: "services" },
    { title: "opinionsLink", icon: groupIcon, id: "opinions" },
    // { title: "اتصل بنا", icon: msgIcon, id: "contactUs" },
  ];

  const toggleModal = (modalName) => {
    setOpen({ ...modalsCost, [modalName]: !isOpen[modalName] });
  };

  const switchLoading = (flag) => {
    let value = flag ?? !loading;
    toggleLoading(value);
  };
  const sendEmail = (email) => {
    let modalsFlags = { ...isOpen };
    modalsFlags = { ...modalsFlags, forgetPass: false, emailSent: true };
    setOpen(modalsFlags);
    setEmail(email);
  };
  return (
    <section style={{ position: "relative", zIndex: "2" }}>
      <nav className="navbar-container">
        <div className="navbar-container__loginAndlang">
          {userRole?.token?.length ? (
            <Link to={userRole.link}>
              <button>{languages[lang].dashboard}</button>
            </Link>
          ) : (
            <button onClick={() => toggleModal("login")}>
              {languages[lang].login}
            </button>
          )}
          <span
            lang={lang}
            dir={"ltr"}
            className="customNav-cont__options__lang"
            onClick={() => dispatch(changeLang(lang === "ar" ? "en" : "ar"))}
          >
            {" "}
            {lang === "ar" ? "English" : "العربية"}{" "}
            <img src={lang === "ar" ? EngFlag : ArbFlag} />
          </span>
        </div>
        <div>
          {/^https?:\/\/[^\/]+(\/([?#].*)?)?$/.test(window.location.href) &&

            navList.map((link) => (
              <a
                className="navbar-container__link"
                key={link.title}
                href={"#" + link.id}
              >
                {languages[lang][link.title]} <img src={Dots} />
              </a>
            ))
          }
        </div>
        <Link to="/">
          <img src={Logo} className="navbar-container__logo" />
        </Link>
      </nav>
      <nav className="navbar-container-mobile">
        <div
          className={`navbar-container-mobile__body ${showSideMenu && "navbar-container-mobile__body--customeStyle"
            }`}
        >
          <Link to="/">
            <img src={Logo} className="navbar-container-mobile__body__logo" />
          </Link>
          <span
            lang={lang}
            dir={"ltr"}
            className="customNav-cont__options__lang"
            onClick={() => dispatch(changeLang(lang === "ar" ? "en" : "ar"))}
          >
            {" "}
            {lang === "ar" ? "English" : "العربية"}{" "}
            <img src={lang === "ar" ? EngFlag : ArbFlag} />
          </span>
          <img
            src={showSideMenu ? CloseIcon : BurgerMenu}
            onClick={() => toggleMenu(!showSideMenu)}
          />
        </div>
        <Drawer
          anchor={"right"}
          open={showSideMenu}
          onClose={() => toggleMenu(false)}
        >
          <div className="navbar-container-mobile__drawer-cont">

            {userRole?.token?.length ? (
              <Link to={userRole.link}>
                <button>{languages[lang].dashboard}</button>
              </Link>
            ) : (
              <button onClick={() => toggleModal("login")}>
                {languages[lang].login}
              </button>
            )}
          </div>
        </Drawer>
      </nav>
      <LoginModal
        toggleModal={toggleModal}
        modalName="login"
        open={isOpen.login}
        loading={loading}
        toggleLoading={switchLoading}
      />
      <SignupModal
        toggleModal={toggleModal}
        modalName="signup"
        open={isOpen.signup}
        loading={loading}
        toggleLoading={switchLoading}
      />
      <ForgetPass
        toggleModal={toggleModal}
        modalName="forgetPass"
        open={isOpen.forgetPass}
        sendEmail={sendEmail}
        loading={loading}
        toggleLoading={switchLoading}
      />
      <EmailSentModal
        toggleModal={toggleModal}
        modalName="emailSent"
        open={isOpen.emailSent}
        forgetEmail={forgetEmail}
        loading={loading}
        toggleLoading={switchLoading}
      />

      <Loader loading={loading} />
    </section>
  );
}
