/** @format */

import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import InputField from "../../../../../Components/InputField";
import NavbarAndSidebarCont from "../../../../../Components/Shared/NavbarAndSidebarCont";

import { languages } from "../../../../../Constants/Languages";
import priceIcon from "../../../../../Assets/Images/Capture.PNG";
import { createOrderType } from "../../../../../Services/adminServices";
import Loader from "../../../../../Components/Shared/Loader";
import SuccessAndFailModal from "../../../../../Components/Modals/SuccessAndFailModal";
const errorsInit = {
  ar: null,
  en: null,
  additionalCost: null,
};
const intialData = {
  ar: "",
  en: "",
  additionalCost: "",
};
export default function TranslationField() {
  const [fieldsData, setFieldData] = useState(intialData);
  const [disableSubmitt, toggleDisable] = useState(false);
  const [isOpen, setOpen] = useState({ successModal: false, failModal: false });
  const [modalMsg, setModalMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(errorsInit);
  let { lang, dir } = useSelector((state) => state.lang);
  const dispatch = useDispatch();
  const getChanges = (key, value) => {
    let errorsClone = { ...errors };
    errorsClone = { ...errorsClone, [key]: null };
    let flag = Object.values(errorsClone).every((error) => error === null);
    toggleDisable(!flag);
    setErrors(errorsClone);
    setFieldData({ ...fieldsData, [key]: value });
  };
  const toggleModal = (stateName) => {
    setOpen({ ...isOpen, [stateName]: !isOpen[stateName] });
    if (stateName === "successModal" && isOpen.successModal) {
      // getAddOns().then((res) => {
      //   if (res.data.isSuccess) {
      //     let options = prepareOptions(res.data.data);
      //     dispatch(setAddOns(options));
      //   }
      // });
    }
  };

  const addOrdertype = async () => {
    let errorsClone = {};

    Object.keys(fieldsData).forEach((field) => {
      if (!fieldsData[field]?.length) {
        errorsClone = {
          ...errorsClone,
          [field]: languages[lang].requiredField,
        };
      }
    });
    toggleDisable(Object.keys(errorsClone).length > 0);
    setErrors(errorsClone);
    if (Object.keys(errorsClone).length === 0) {
      setLoading(true);
      let params = {
        ...fieldsData,
        additionalCost: parseInt(fieldsData.additionalCost),
      };
      let response = await createOrderType(params);

      if (!response.data.isSuccess) {
        if (response.data.errors) {
          let errorObj = {};
          Object.keys(response.data.errors).forEach((errorKey) => {
            errorObj = {
              ...errorObj,
              [errorKey]: response.data.errors[errorKey].join(", "),
            };
          });
          setErrors(errorObj);
        } else if (response.data.message.ar.length) {
          setModalMsg(response.data.message[lang]);
          toggleModal("failModal");
        }
      } else {
        setFieldData(intialData);
        setModalMsg(response.data.message[lang]);
        toggleModal("successModal");
      }
      setLoading(false);
    }
  };
  return (
    <NavbarAndSidebarCont>
      <h4 className='lang-header-title' lang={lang} dir={dir}>
        {languages[lang].addLangFieldPricing}
      </h4>
      <div className='Lang-price'>
        <div className='Lang-price__body' lang={lang} dir={dir}>
          <div className='Lang-price__body__select-cont'>
            <InputField
              title={languages[lang].translationType}
              placeholder={languages[lang].enterInAr}
              value={fieldsData.ar}
              getChanges={getChanges}
              stateName={"ar"}
            />
            &nbsp;&nbsp;
            <InputField
              title={languages[lang].translationType}
              placeholder={languages[lang].enterInE}
              value={fieldsData.en}
              getChanges={getChanges}
              stateName={"en"}
            />
            <InputField
              title={languages[lang].price}
              placeholder={"0"}
              value={fieldsData.additionalCost}
              getChanges={getChanges}
              stateName={"additionalCost"}
              type='number'
              icon={priceIcon}
            />
          </div>
          <Loader loading={loading} />
          <SuccessAndFailModal
            toggleModal={toggleModal}
            modalName={isOpen.successModal ? "successModal" : "failModal"}
            open={isOpen.successModal || isOpen.failModal}
            msg={modalMsg}
            buttonTitle={languages[lang].ok}
          />
          <button disabled={disableSubmitt} onClick={addOrdertype}>
            {languages[lang].add}
          </button>
        </div>
      </div>
    </NavbarAndSidebarCont>
  );
}
