/** @format */

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
  companiesAppliedReq: [],
  reqStatus: [],
  clients: {},
  clientOrders: {},
  companies: {},
  companiesOrders: {},
  clientsOrders: {},
  systemAdmins: {},
  adminPermissions: [],
  translatorsList: [],
  translatorInfo: [],
  translatorOrders: [],
  langPricingList: {},
  deliveryOrdersList: {},
  adminUserPermissions: [],
  companyOrders: {},
};

export const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    setCompaniesAppliedReq: (state, action) => {
      state.companiesAppliedReq = action.payload;
    },
    setReqStatus: (state, action) => {
      state.reqStatus = action.payload;
    },
    setClients: (state, action) => {
      state.clients = action.payload;
    },
    setClientOrders: (state, action) => {
      state.clientOrders = action.payload;
    },
    setCompanies: (state, action) => {
      state.companies = action.payload;
    },
    setCompaniesOrders: (state, action) => {
      state.companiesOrders = action.payload;
    },
    setClientsOrders: (state, action) => {
      state.clientsOrders = action.payload;
    },
    setSystemAdmins: (state, action) => {
      state.systemAdmins = action.payload;
    },
    setAdminPermissions: (state, action) => {
      state.adminPermissions = action.payload;
    },
    setTranslatorsList: (state, action) => {
      state.translatorsList = action.payload;
    },
    setTranslatorInfo: (state, action) => {
      state.translatorInfo = action.payload;
    },
    setTranslatorOrders: (state, action) => {
      state.translatorOrders = action.payload;
    },
    setLangPricing: (state, action) => {
      state.langPricingList = action.payload;
    },
    setDeliveryOrdersList: (state, action) => {
      state.deliveryOrdersList = action.payload;
    },
    setAdminUserPermissions: (state, action) => {
      state.adminUserPermissions = action.payload;
    },
    setCompanyOrders: (state, action) => {
      state.companyOrders = action.payload;
    },
    setTransactions: (state, action) => {
      state.Transactions = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setAdminUserPermissions,
  setCompaniesAppliedReq,
  setReqStatus,
  setClients,
  setCompanies,
  setClientOrders,
  setCompaniesOrders,
  setClientsOrders,
  setSystemAdmins,
  setAdminPermissions,
  setTranslatorsList,
  setTranslatorInfo,
  setTranslatorOrders,
  setLangPricing,
  setDeliveryOrdersList,
  setCompanyOrders,
  setTransactions,
} = adminSlice.actions;

export default adminSlice.reducer;
