/** @format */

import React, { useState } from "react";
import { useSelector } from "react-redux";
import deleteIcon from "../../../Assets/Images/Icon material-delete.svg";
import { Link } from "react-router-dom";
import { languages } from "../../../Constants/Languages";
import "./index.scss";
import CancelModal from "../../Modals/CancelModal";
import Status from "../Status";
const statusData = [
  { data: "في إنتظار الموافقة", style: "yellow" },
  { data: "تحت المراجعة", style: "blue" },
  { data: "تم إرسال الترجمة", style: "green" },
  { data: "تم الإلغاء", style: "red" },
  { data: "مرفوض", style: "red" },
  { data: "تم الشحن", style: "green" },
  { data: "في إنتظار الشحن", style: "yellow" },
  { data: "جديد", style: "yellow" },
  { data: "انضم", style: "green" },
];
export default function CustomersTable(props) {
  let { lang, dir } = useSelector((state) => state.lang);

  const getStatus = (data) => {
    let statusStyle = statusData.find((status) => status.data === data)?.style;
    return (
      <div
        key={new Date()}
        className={`table__body__status table__body__status--${statusStyle}`}>
        <span>{data}</span>
      </div>
    );
  };
  const renderRow = (agent) => {
    return (
      <>
        <div
          className='customer-table__body'
          onClick={() =>
            props.saveToSessionStorege
              ? localStorage.setItem(
                  props.saveToSessionStorege,
                  JSON.stringify(agent)
                )
              : null
          }>
          <div className='customer-table__body__info'>
            {props.tableHeaderConstatnt.map((constant) => {
              if (constant.key === "requestStatus") {
                return <Status data={agent.requestStatus} />;
              } else {
                return (
                  <span
                    dir={constant.key === "phoneNum" ? "ltr" : dir}
                    lang={lang}
                    style={
                      constant.key === "phoneNum" && lang === "ar"
                        ? { textAlign: "right" }
                        : {}
                    }
                    key={constant.key}>
                    {agent[constant.key] ?? "-"}
                  </span>
                );
              }
            })}
          </div>
          {props.removeDelete ? null : (
            <div className='customer-table__body__settings'>
              {/* <img src={editIcon} />
              <img src={lockIcon} /> */}

              <img
                src={deleteIcon}
                onClick={(event) =>
                  props.toggleModal("cancelModal", event, agent.value)
                }
              />
            </div>
          )}
        </div>
      </>
    );
  };
  return (
    <div className='customer-table' dir={dir} lang={lang}>
      <div className='customer-table__header'>
        <div className='customer-table__header__constants'>
          {props.tableHeaderConstatnt.map((constant) => (
            <span key={constant.key}>{constant.title}</span>
          ))}
        </div>
        {props.removeDelete ? null : <span>{languages[lang].settings}</span>}
      </div>
      {props.agents.map((agent) => (
        <>
          {props.disableRouting ? (
            renderRow(agent)
          ) : (
            <Link to={`/dashboard/${props.url}/${agent.id}`} key={agent.id}>
              {renderRow(agent)}
            </Link>
          )}
        </>
      ))}

      <CancelModal
        toggleModal={props.toggleModal}
        modalName='cancelModal'
        open={props.isOpen}
        msg={props.msg ?? languages[lang].deleteCustomers}
        id={props.id}
        deleteAction={props.deleteAction}
        loading={props.loading}
      />
    </div>
  );
}
