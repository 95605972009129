/** @format */

export const languages = {
  ar: {
    adminHeader: "أدمن",
    admin: "الادمن",
    showAllNotification: "عرض جميع الأشعارات",
    changePass: "تغيير كلمة المرور",
    logout: "تسجيل خروج",
    /////////////////////// side menu
    dashboard: "حسابي",
    requests: "الطلبات",
    customers: "العملاء",
    companies: "الشركات",
    translators: "المترجمين",
    transaction: "سجل المعاملات",
    settings: "الإعدادات",
    allRequests: "جميع الطلبات",
    shippingRequests: "طلبات الشحن",
    allCompanies: "جميع الشركات",
    packages: "الباقات",
    companiesRequests: "طلبات الشركات",
    generalSettings: "الإعدادات العامة",
    sendingData: "بيانات الإرسال",
    languagePricing: "تسعير لغات الترجمة",
    tralnsationTypePricing: "تسعير انواع الترجمة",
    pricingForTypesOfTranslationServices: "تسعير انواع خدمات الترجمة",
    deliveryPricing: "تسعير التوصيل",
    translationAreas: "مجالات الترجمة",
    translationTypePricing: "تسعير انواع الترجمة",
    adminUsers: "يوزرات الادمن",
    ///////////////// dashboard
    numOfOrders: "إجمالي عدد الطلبات",
    fulfilledOrders: "الطلبات المنفذة",
    underway: "قيد التنفيذ",
    canceldOrders: "الطلبات الملغاة",
    monthIncome: "إجمالي الارباح لهذا الشهر",
    customerNum: "عدد العملاء",
    companiesNum: "عدد الشركات",
    translatorsNum: "عدد المترجمين",
    seeAll: "عرض الجميع",
    pleaseEnterCompanyName: "برجاء كتابة اسم الشركة …",
    ////////////////// table
    requestData: "تاريخ الطلب",
    name: "الاسم",
    translateFrom: "الترجمة من",
    translateTo: "الترجمة اإلي",
    translationType: "نوع الترجمة",
    serviceType: "نوع الخدمة",
    price: "السعر",
    translator: "المترجم",
    deleviryData: "تاريخ التسليم",
    status: "الحالة",
    selectStatus: "الرجاء اختيار الحالة",
    translationField: "مجال الترجمة",
    waitingForApproval: "في إنتظار الموافقة",
    underTranslation: "قيد الترجمة",
    translationSent: "تم إرسال الترجمة",
    canceled: "تم الإلغاء",
    search: "بحث",
    clear: "تصفيه البحث",
    /////////////////////////
    orderInfo: "بيانات الطلب",
    cancelOrder: "إلغاء الطلب",
    idle: "في إنتظار الموافقة",
    waitingTranslation: "في انتظار الترجمة",
    inTranslation: "في الترجمة",
    waitingDelivery: "انتظار التسليم",
    inDelivery: "جاري الارسال",
    done: "انتهى",
    chooseTranslatorName: "أختر اسم المترجم",
    cancelOrderQ: "هل تريد الغاء الطلب؟",
    yes: "نعم",
    cancel: "الغاء",
    no: "لا",
    orderNum: "رقم الطلب",
    paymentMethod: "طريقة الدفع",
    transactionNumber: "رقم المعاملة",
    translationDelivery: "توصيل الترجمة",
    customerName: "اسم العميل",
    phoneNum: "رقم الجوال",
    email: "البريد الإلكتروني",
    city: "المدينة",
    autoNum: "الرقم الآلي",
    address: "العنوان",
    saveChanges: "حفظ التعديلات",
    downloadFile: "تحميل ملف الترجمة",
    waitingForShipment: "في إنتظار الشحن",
    shipped: "تم الشحن",
    ///////////// Customers
    ordersNum: "عدد الطلبات",
    settings: "الإعدادات",
    deleteCustomers: "هل تريد مسح العميل؟",
    add: "إضافة",
    customerInfo: "بيانات العميل",
    password: "كلمة المرور",
    confirmPass: "تأكيد كلمة المرور",
    pleaseWriteName: "برجاء كتابة الاسم …",
    pleaseWritePhone: "برجاء كتابة رقم الجوال …",
    pleaseWriteEmail: "برجاء كتابة البريد الالكتروني …",
    addNewCustomer: "إضافة عميل جديد",
    pleaseWritePass: "برجاء كتابة كلمة المرور …",
    //////////////// comapnies
    companyName: "اسم الشركة",
    responsibleNameHeader: "اسم المسؤول",
    package: "الباقة",
    rest: "الباقي",
    companyInfo: "بيانات الشركة",
    pleaseWriteResponsibleName: "برجاء كتابة أسم المسؤول …",
    companyBusiness: "نشاط الشركة",
    chooseBusiness: "أختر النشاط",
    choosePaymentMethod: "أختر طريقة الدفع",
    addNewCompany: "إضافة شركة جديدة",
    packageName: "اسم الباقة",
    wordsNum: "عدد الكلمات",
    subscriptionPeriod: "مدة الاشتراك",
    deletePackage: "هل تريد مسح الباقة؟",
    writePackageName: "برجاء أختر اسم الباقة …",
    writeNumOffWords: "برجاء كتابة عدد الكلمات …",
    chooseSubscriptionPeriod: "أختر مدة الاشتراك",
    writePrice: "برجاء كتابة السعر …",
    chooseServiceType: "أختر نوع الخدمة",
    addNewPackage: "إضافة باقة جديدة",
    //////////////translators
    orderInProgress: "الطلبات قيد التنفيذ",
    translatorLang: "اللغات التي يجيدها",
    arabic: "اللغة العربية",
    english: "اللغة الإنجليزية",
    spain: "اللغة الأسبانية",
    german: "اللغة الألمانية",
    french: "اللغة الفرنسية",
    chinese: "اللغة الصينية",
    legalTran: "ترجمة قانونية",
    tourismTran: "ترجمة القطاع السياحي",
    telecommunicationsTran: "ترجمة قطاع الإتصالات",
    medicalTran: "ترجمة طبية",
    sportTran: "ترجمة القطاع الرياضي",
    marketingTran: "ترجمة قطاع التسويق",
    deleteTranslator: "هل تريد مسح المترجم؟",
    payments: "المدفوعات",
    operationNum: "رقم العملية",
    operationDate: "تاريخ العملية",
    transactionType: "نوع المعاملة",
    addTranslator: "إضافة مترجم",
    /////////// setting
    deletePricing: "هل تريد مسح هذا التسعير؟",
    editLangPricing: "تعديل تسعير للغات الترجمة",
    addLangPricing: " إضافة تسعير للغات الترجمة ",
    addLang: "إضافة لغة",
    langName: "اللغة",
    writelangName: "أدخل اللغة",
    writelangCode: "أدخل كود اللغة",
    code: "كود اللغة",
    chooseLang: "أختر اللغة",
    wordPrice: "سعر الكلمة",
    word: "كلمة",
    countFrom: "عدد الكلمات من",
    countTo: "عدد الكلمات إلي",
    add: "إضافة",
    deleteType: "هل تريد مسح هذا النوع؟",
    chooseField: "أختر النوع",
    addLangFieldPricing: "إضافة تسعير لانواع الترجمة",
    deleviryTime: "وقت التسليم",
    location: "المنطقة",
    chooseLocation: "أختر المنطقة",
    addingDeliveryPrice: "إضافة تسعير للتوصيل",
    deletelocation: "هل تريد مسح هذه المنطقة؟",
    deleteService: "هل تريد مسح هذه الخدمه؟",
    translationArea: "مجال الترجمة",
    addTranslationArea: "إضافة مجال للترجمة",
    ////////////// admin user
    permissions: "الصلاحيات",
    payments: "المدفوعات",
    ////////////////////
    profile: "البروفايل",
    editProfile: "تعديل بيانات البروفايل",
    editAdmin: "تعديل بيانات الادمن",
    addAdminUser: "إضافه ادمن جديد",
    edittranslator: "تعديل بيانات المترجم",
    /////////////////// modals
    phoneOrEmail: "البريد الإلكتروني *",
    writeEmailOrPhone: "برجاء كتابة البريد الإلكتروني …",
    rememberMe: "تذكرني",
    forgetPass: "نسيت كلمة المرور",
    login: "تسجيل الدخول",
    completeApp: "تسجيل الدخول",
    registerNewAcc: "تسجيل حساب جديد",
    donotHaveAcc: "ليس لديك حساب لدينا؟",
    requiredField: "هذه الخانة مطلوبة",
    validEmail: "قم بإدخال بريد إلكتروني صحيح",
    passwordConfirmationError: "خطأ تأكيد كلمة المرور",
    haveAcc: "لديك حساب؟",
    name: "الاسم",
    passwordRule:
      "يجب أن تحتوي كلمة المرور على حرف واحد كبير وحرف صغير واحد على الأقل ولا يقل طوله عن ٦  حروف",
    restorePass: "إستعادة كلمة المرور",
    changePassword: "تغيير كلمة المرور",
    fillCompanyForm: "قم بملئ استمارة احتياجات الشركات",
    contactYou: "وسيقوم فريقنا بالتواصل معك في أسرع وقت",
    chooseCompanyActivity: "أختر نشاط الشركة",
    companyActivity: "نشاط الشركة (اختياري)",
    responsibleName: "اسم المسؤول ",
    pleaseWriteResponName: "برجاء كتابة اسم المسؤول …",
    sendRequest: "إرسال الطلب",
    requestSent: "تم إرسال طلبك بنجاح",
    requestError: "حدث خطا اثناء اتمام المعاملة",
    ourTeamWillcontactYou: "وسيقوم فريقنا بالتواصل معك في أسرع وقت",
    backToHome: "الرجوع للرئيسية",
    back: "الرجوع",
    emailNotReceived: "البريد الإلكتروني لم يصل ؟",
    emailSent: "تم إرسال البريد الإلكتروني الخاص بإعادة تعيين كلمة المرور",
    reSend: " إعادة الإرسال",
    emailSentTo: "تم إرسال البريد الإلكتروني إلي ",
    followTheInstructions:
      "اتبع الإرشادات الموجودة في البريد الإلكتروني لإعادة كلمة المرور",
    startTranslateNow: "ابدأ بترجمة مستنداتك الآن",
    attachDoc: "إرفاق المستند",
    textInput: "إدخال النص",
    or: "أو",
    writeTheText: "'برجاء كتابة النص …",
    chooseFile: "أختر الملف",
    dragFile: "أو اسحب الملف هنا",
    next: "التالي",
    langOfTheText: "لغة النص المراد ترجمته *",
    translateLang: "اللغة المطلوب الترجمة إليها *",
    chooseLang: "أختر اللغة",
    translateLangTo: "أختر اللغة المطلوب الترجمة إليها",
    chooseTranslationField: "أختر مجال الترجمة",
    chooseType: "أختر نوع الترجمة",
    chooseServiesType: "أختر نوع الخدمة",
    sendTranslationToMyAddress: "أريد إرسال الترجمة إلى عنواني",
    city: "المدينة *",
    chooseCity: "أختر المدينة",
    writeTheAddress: "برجاء كتابة العنوان بالتفصيل …",
    buildingNum: "رقم المبنى *",
    writeBuildingNum: "برجاء كتابة رقم المبنى …",
    floor: "الدور *",
    writeFloorNum: "برجاء كتابة الدور …",
    totalPrice: "السعر الإجمالي",
    priceMaybeChange: "الأسعار قابلة للتغيير عند طلب التعديل ",
    DK: "د.ك",
    orderSummary: "ملخص الطلب",
    sendTranslation: "إرسال الترجمة",
    automatedFigure: "الرقم الآلي",
    writeZipCode: "برجاء كتابة الرقم الآلي …",
    ok: "حسنا",
    userCreated: "تم إنشاء المستخدم بنجاح",
    passReseted: "تم إعادة تعيين كلمة المرور بنجاح",
    translationDetails: "تفاصيل الترجمة",
    translationData: "بيانات الترجمة",
    enterInE: "أدخل الاسم باللغة الإنجليزية",
    enterInAr: "أدخل الاسم باللغة العربية",
    costPerWord: "التكلفة لكل كلمة",
    wentWrong: "هناك خطأ ما",
    select1Field: "الرجاء تحديد حقل واحد على الأقل",
    clientOrders: "طلبات العملاء",
    noRequests: "لا يوجد طلبات للعرض",
    hello: "مرحباً",
    reminderWords: "الباقي من الباقة",
    buyBundle: "شراء باقة اضافية",
    subscriptionPeriod: "مدة الإشتراك",
    buyTheBundle: "شراء الباقة",
    extraBundle: "باقات اضافية",
    currentBundle: "الباقة الحالية",
    noEnoughWords: "ليس لديك رصيد كافي من الكلمات لاتمام عملية الترجمة",
    orderStatus: "حالة الطلب",
    translate: "الترجمة",
    filter: "تصفية البحث",
    downloadFileAfterTranslation: "تحميل الملف بعد الترجمة",
    preferredLanguage: "اللغة المفضله",
    selectPreferredLang: "برجاء أختيار اللغة المفضله …",
    allUsers: "كل العملاء",
    allNotification: "جميع الاشعارات",
    officeInYourHand: "مكتب ترجمة كامل في متناول يديك …",
    officeInYourHandBody:
      "كويت قاموس، منصة تقدم خدمة الترجمة الاحترافية عن طريق الإنترنت، تضم فريق لأكثر من 60 مترجم متخصص وإداري وخدمة عملاء. لست بحاجة من الآن للبحث عن مكاتب الترجمة الاحترافية لمستنداتك، ولست بحاجة للتنقل بينها لمعرفة الأجدر. ففريق كويت قاموس جاهر لتلبية احتياجاتك للترجمة 24 ساعة / 7 أيام في الأسبوع مع فريق من الخبراء في مجالات الوثائق الأكاديمية والقانونية والطبية وغيرها يحافظون على مستوى عال من الجودة من خلال تنفيذ عملية مراقبة الجودة لكل وثيقة مترجمة قبل التسليم .",
    startNow: "ابدأ بترجمة مستنداتك الان",
    companyNeeds: "احتياجات الشركات",
    kuwaitQamous: "كويت قاموس",
    kuwaitQamousBody1:
      "منصة تقدم خدمة الترجمة الاحترافية عن طريق الإنترنت، تضم فريق لأكثر من 60 مترجم متخصص وإداري وخدمة عملاء. لست بحاجة من الآن للبحث عن مكاتب الترجمة الاحترافية لمستنداتك، ولست بحاجة للتنقل بينها لمعرفة الأجدر. ففريق كويت قاموس جاهر لتلبية احتياجاتك للترجمة 24 ساعة / 7 أيام في الأسبوع مع فريق من الخبراء في مجالات الوثائق الأكاديمية والقانونية والطبية وغيرها يحافظون على مستوى عال من الجودة من خلال تنفيذ عملية مراقبة الجودة لكل وثيقة مترجمة قبل التسليم .",
    kuwaitQamousBody2:
      "يتكوّن فريق كويت قاموس والذي يمكنك التواصل معه أسرع مما تتخيل عن طريق الدردشة الموجودة يسار الشاشة عبر الموقع من خبراء تكمن أهدافهم في تقديم خدمة ذات جودة عالية بسرعة أعلى. حمّل مستنداتك الآن وقم بتوفير الوقت والجهد واحصل على خدمة ترجمة احترافية بشرية من أفضل شركات الترجمة اون لاين بالطريقة الأكثر ملاءمة لاحتياجاتك.",
    ourTranslators: "مترجمونا محترفون لأنهم متخصصون",
    ourTranslatorsBody:
      "نضم أكثر من 60 مترجم من الذين تم اختبارهم واختيارهم بعناية فائقة، و يتمتّعون بخبرات متعدّدة ضمن مختلف المجالات حيث أن فريقنا مستعد دائماً لخدمتك",
    quality: "الجودة هي الأولوية",
    qualityBody:
      "بهدف ضمان تحقيق أعلى معايير الجودة، فإنّ جميع الترجمات التي يعمل عليها مترجمو كويت قاموس تمرّ عبر عملية متعدّدة المراحل من المراجعة والتحرير",
    timeQuality: "الوقت لدينا بالغ الأهمية",
    timeQualityBody:
      "بهدف ضمان تحقيق أعلى معايير الجودة، فإنّ جميع الترجمات التي يعمل عليها مترجمو كويت قاموس تمرّ عبر عملية متعدّدة المراحل من المراجعة والتحرير",
    corporateServices: "خدمات الشركات",
    corporateServicesBody:
      "لأول مرة خدمة التعاقد مع الشركات لتقديم باقات الترجمة المثالية من خلال حساب الشركات على الموقع. ارسل بيانات الشركة وسنتواصل معك في الحال",

    certifiedTranslation: "الترجمة المعتمدة",
    certifiedTranslationBody:
      "فريق كويت قاموس يدرك أهمية الوقت بالنسبة لك ومن أولوياتنا أن يتم تسليم مستنداتك المترجمة لك في الوقت المحدد لتحقيق اهدافك",
    deliveryService: "خدمة توصيل الترجمة المعتمدة",
    deliveryServiceBody:
      "في حالة رغبتك في استلام ترجمتك المعتمدة فإن فريق كويت قاموس يصلك بالترجمة المعتمدة أينما كنت، بمقابل أقل بكثير مما تتوقع",
    coverAllLang: "نغطي جميع اللغات للترجمة",
    coverAllLangBody:
      "تقدم كويت قاموس خدمات ترجمة معتمدة احترافية لمعظم لغات العالم بمساعدة فريق من المترجمين المعتمدين. نحن نغطي أكثر من 99٪ من اللغات المستخدمة حول العالم، ترجمة من الانجليزي الى العربي وترجمة من الفرنسي الى العربي والاسبانية الى العربية وترجمة من الفارسي الى العربي وترجمة من العربية الى الصينية وترجمة من الروسية الى العربية وترجمة من البرتغالية الى العربية وغيرها الكثير.",
    getCertifiedTranslation: "أحصل على ترجمة معتمدة للعديد من المجالات",

    legalTranslation: "ترجمة قانونية معتمدة",
    financialTranslation: "ترجمة مالية معتمدة",
    officialGovernmental: " ترجمة الاوراق الرسمية للحكومات",
    manufacturingTranslation: "ترجمة ما يخص الصناعات التحويلية",
    travelTourism: "ترجمة قطاع السياحة والسفر",
    mediaEntertainment: " ترجمة لوسائل الإعلام والترفيه",
    MedicalTranslation: "ترجمة طبية معتمدة",
    sectorTranslation: "ترجمة قطاع المواصلات",
    telecomSectorTranslation: " ترجمة قطاع الإتصالات",
    sportsSectorTranslation: " ترجمة القطاع الرياضي",
    energyMining: "ترجمة الطاقة والتعدين ",
    marketingTranslation: "ترجمة معتمدة لمجال التسويق",
    translateYourDocuments: "ابدأ بترجمه مستنداتك الان",
    howToUse: "كيف تستخدم كويت قاموس ؟",
    howToUseBody:
      "تتمثل رؤية كويت قاموس في أن تصبح المزود الأكثر ابتكارًا وإبداعًا وزهدًا في السعر، والأسرع لخدمات الترجمة المعتمدة في العالم",
    selectFile: "اختار الملف المراد ترجمته",
    selectDelivery: "حدد موعد التسليم ونوع الترجمة واللغة",
    paymentProcess: "اتمام عملية الدفع  ",
    overTime: "استلام الترجمة في الموعد",
    howToPick: "كيف نختار مترجمينا المعتمدين؟",
    howToPickBody1:
      "نحن نتعاقد مع المترجمين الأكثر كفاءةً في هذا المجال، والمتخصصين في مجالٍ معرفيٍ محدد، والذين يمتلكون الخلفية التّعليمية والخبرات والمهارات التي تتماشى مع متطلّباتنا.",
    howToPickBody2:
      "نقوم بدراسة السير الذاتية للمترجمين المتقدمين بعناية. والتحقق من خبراتهم وجودتهم المهنية ومقارنتها مع احتياجات كويت قاموس.",
    howToPickBody3:
      "يخضع المترجم المتقدم إلى اختبار يتكون من عشرة نقاط، لكل نقطة درجة منها تُمنح لسياق اللغة والأسلوب والإخلاص للنّص المصدر ودقّة قواعد اللغة واستخدام المصطلحات اللغوية المناسبة.",
    howToPickBody4:
      "يتم إجراء مقابلات شخصية مع المترجمين الذين يحصلون على تقييم “جيد جداً” أو “ممتاز” (9 أو 10 من أصل 10) لمعرفة نقاط قوتهم ومهاراتهم فيما يتعلّق باستخدام أدوات الترجمة الخاصة بفريق كويت قاموس.",
    howToPickBody5:
      "إذا اجتاز المترجم المرشح هذه العملية بنجاح، يتم انضمامه ضمن قاعدة بيانات نظام كويت قاموس مع علامة “مترجم جديد” ليصبح بذلك في خدمتك وقتما تحتاج.",
    parteners: "شركاء النجاح",
    opinions: "بعض من أراء عملائنا",
    links: "روابط سريعة",
    main: "الرئيسية",
    aboutUs: "من نحن",
    services: "خدمتنا",
    opinionsLink: "أراء العملاء",
    callUs: "اتصل بنا",
    Failed: "فشل",
    Paid: "مدفوع",
    Refunded: "مستردة",
    Bundle: "باقة",
    CompanyOrder: "طلب الشركة",
    UserOrder: "طلب المستخدم",
    Pending: "قيد الانتظار",
    oldPassword: "كلمة المرور الحالية",
    newPassword: "كلمة المرور الجديد",
    TermsConditions: "   الشروط والاحكام",
    accept: "اوافق على",
    EstimateTimeDelivery: " الوقت المتوقع للتسليم",
    opinionsObj : [
      {
        name: "مبارك الشمري",
        opinion: "أسعار مناسبة جداً مقارنة مع المجهود المبذول للحصول على مثيلها بالشركات، أنصح بها دائما لكل من يبحثون عن الراحة والثقة"
      },
      {
        name: "فاطمة العلي",
        opinion: "خدمة ممتازة وسرعة في الإنجاز، دائماً ألجأ لهم عند حاجتي للترجمة وأوصي بها لكل من يبحث عن الدقة والجودة"
      },
      {
        name: "خالد المطيري",
        opinion: "التعامل راقي والترجمات دقيقة جداً، أعتبرهم الخيار الأمثل لكل من يرغب في خدمة ترجمة احترافية ومضمونة"
      }
    ],
    q8qAddress: "الكويت- القبلة- شارع فهد السالم- عمارة الكويت- الدور١٣"
  },
  en: {
    allNotification: "All notification",
    allUsers: "all users",
    adminHeader: "Admin",
    admin: "Admin",
    showAllNotification: "Show all notification",
    changePass: "Change password",
    logout: "Logout",
    ///////////////////// dashboard
    dashboard: "My Account",
    requests: "Requests",
    customers: "Customers",
    companies: "Companies",
    translators: "Translators",
    transaction: "Transaction history",
    settings: "Settings",
    allRequests: "All requests",
    shippingRequests: "Shipping requests",
    allCompanies: "All companies",
    packages: "Packages",
    companiesRequests: "Companies requests",
    generalSettings: "General settings",
    sendingData: "Sending data",
    languagePricing: "Language Pricing",
    translationTypePricing: "Translation field pricing",
    pricingForTypesOfTranslationServices:
      "Pricing for types of translation services",
    deliveryPricing: "Delivery pricing",
    translationAreas: "Translation areas",
    adminUsers: "Admin users",
    //////////////////// dashboard
    numOfOrders: "Number of orders",
    fulfilledOrders: "Fulfilled orders",
    underway: "Underway",
    canceldOrders: "Canceld orders",
    monthIncome: "Total earnings for this month",
    customerNum: "Customer number",
    companiesNum: "Companies number",
    translatorsNum: "Number of translators",
    seeAll: "See all",
    //////////////// table
    requestData: "Request date",
    name: "Name",
    translateFrom: "Translate from",
    translateTo: "Translate to",
    translationType: "Translation field",
    serviceType: "Service type",
    price: "Price",
    translator: "Translator",
    deleviryData: "Delivery date",
    status: "Status",
    selectStatus: "Please select status",
    translationField: "Translation field",
    waitingForApproval: "Waiting for approval",
    underTranslation: "Under translation",
    translationSent: "Translation has been sent",
    canceled: "Canceled",
    search: "Search",
    clear: "Clear search",
    // ///////////// order page
    orderInfo: "Order info",
    cancelOrder: "Cancel order",
    idle: "Idle",
    waitingTranslation: "Waiting translation",
    inTranslation: "In translation",
    waitingDelivery: "Waiting Delivery",
    inDelivery: "In Delivery",
    done: "Done",
    chooseTranslatorName: "Choose translator name",
    cancelOrderQ: "Do you want to cancel the order?",
    yes: "Yes",
    cancel: "cancel",

    no: "No",
    orderNum: "Order number",
    paymentMethod: "Payment method",
    transactionNumber: "Transaction number",
    translationDelivery: "Translation delivery",
    customerName: "Customer name",
    phoneNum: "Phone number",
    email: "Email",
    city: "City",
    autoNum: "Automated number",
    address: "Address",
    saveChanges: "Save changes",
    downloadFile: "Download file",
    waitingForShipment: "Waiting for shipment",
    shipped: "Shipped",
    ///////////// Customers
    ordersNum: "Order number",
    settings: "Settings",
    deleteCustomers: "Do you want to delete this user?",
    add: "Add",
    customerInfo: "Cutomer info",
    password: "Password *",
    confirmPass: "Confirm password",
    pleaseWriteName: "Please write the name...",
    pleaseWritePhone: "Please write phone number...",
    pleaseWritePass: "Please write password...",
    pleaseWriteEmail: "Please write email...",
    addNewCustomer: "Add new customer",
    // //////////// companies
    companyName: "Company name",
    responsibleNameHeader: "Responsible name",
    package: "Package",
    rest: "The rest",
    companyInfo: "Company info",
    pleaseWriteResponsibleName: "Please write responsible name...",
    companyBusiness: "Company business",
    chooseBusiness: "Choose business",
    choosePaymentMethod: "Choose payment method",
    addNewCompany: "Add new company",
    packageName: "Package name",
    wordsNum: "Words number",
    subscriptionPeriod: "Subscription period",
    deletePackage: "Do you want to delete this package?",
    writePackageName: "Please choose package name...",
    writeNumOffWords: "Please write number of words...",
    chooseSubscriptionPeriod: "Choose subscription period",
    writePrice: "Please write price...",
    chooseServiceType: "Choose service type",
    addNewPackage: "Add new package",
    orderInProgress: "Orders in progress",
    translatorLang: "The languages he speaks",
    arabic: "Arabic",
    english: "English",
    spain: "Spain",
    german: "German",
    french: "French",
    chinese: "Chinese",
    legalTran: "Legal translation",
    tourismTran: "Tourism translation",
    telecommunicationsTran: "Telecommunications translation",
    medicalTran: "Medical translation",
    sportTran: "Sport translation",
    marketingTran: "Marketing sector translation",
    deleteTranslator: "Do you want to delete this translator?",
    payments: "Payments",
    operationNum: "Operation number",
    operationDate: "Operation date",
    transactionType: "Transaction field",
    addTranslator: "Add translator",
    //////////////////// setting
    deletePricing: "Do you want to delete this pricing?",
    editLangPricing: "pricing for translation languages",
    addLangPricing: "Add pricing for translation languages",
    addLang: "Add language",
    writelangName: "Write language name",
    code: "Code",
    writelangCode: "Write language code",
    langName: "Language",
    chooseLang: "Choose lang",
    wordPrice: "Word price",
    countFrom: "Words number from",
    countTo: "Words number to",
    add: "Add",
    deleteType: "Do you want to delete this field?",
    chooseField: "Choose Field",
    addLangFieldPricing: "Add language field pricing",
    deleviryTime: "Deleviry time",
    location: "Location",
    chooseLocation: "Choose location",
    addingDeliveryPrice: "Add delivery price",
    deletelocation: "Do you want to delete this location?",
    deleteService: "Do you want to delete this service?",
    translationArea: "Translation area",
    addTranslationArea: "Add translation area",
    ///////////// admin user
    permissions: "Permissions",
    payments: "Payments",
    // ///////////// customer
    profile: "Profile",
    editProfile: "Edit profile information",
    editAdmin: "Edit admin information",
    addAdminUser: "Add admin user",
    edittranslator: "Edit translator information",
    // ////////// modals
    phoneOrEmail: "email *",
    writeEmailOrPhone: "Please enter your e-mail...",
    rememberMe: "Remember me",
    forgetPass: "Forget password",
    login: "Login",
    completeApp: "Login",
    registerNewAcc: "Register a new account",
    donotHaveAcc: "You do not have an account here?",
    requiredField: "This field is required",
    validEmail: "Enter a valid email",
    passwordConfirmationError: "Password confirmation error",
    haveAcc: "Do you have an account?",
    name: "Name *",
    passwordRule:
      "Password must contain at least 1 uppercase and 1 lowercase letter and must be at least 6 characters long",
    restorePass: "Restore password",
    changePassword: "Change password",
    fillCompanyForm: "Fill out the corporate needs form",
    contactYou: "Our team will contact you as soon as possible",
    pleaseEnterCompanyName: "Please write the name of the company...",
    chooseCompanyActivity: "Choose the company's activity",
    companyActivity: "Company activity (optional)",
    responsibleName: "responsible name ",
    pleaseWriteResponName: "Please write the name of the administrator...",
    sendRequest: "Send request",
    requestSent: "Your request has been submitted successfully",
    requestError: "An error occurred while completing the transaction",
    ourTeamWillcontactYou: "Our team will contact you as soon as possible",
    backToHome: "Back to Home",
    back: "back",
    emailNotReceived: "Email not received ?",
    emailSent: "Password reset email has been sent",
    reSend: "Resend",
    emailSentTo: "Email has been sent to",
    followTheInstructions:
      "Follow the instructions in the email to reset your password",
    startTranslateNow: "Start translating your documents now",
    attachDoc: "Attach document",
    textInput: "Text input",
    or: "Or",
    writeTheText: "Please type the text...",
    chooseFile: "Choose file",
    dragFile: "Or drag the file here",
    next: "Next",
    langOfTheText: "The language of the text to be translated *",
    translateLang: "Language to be translated into *",
    chooseLang: "Choose the language",
    translateLangTo: "Choose the language to translate to",
    certifiedTranslation: "Certified legal translation",
    chooseType: "Choose translation type",
    chooseTranslationField: "Choose translation field",
    chooseServiesType: "Choose servies type",
    sendTranslationToMyAddress: "I want to send the translation to my address",
    city: "City *",
    chooseCity: "Choose city",
    writeTheAddress: "Please write the address in detail...",
    buildingNum: "Building number *",
    writeBuildingNum: "Please write the building number...",
    floor: "Floor *",
    writeFloorNum: "“Please write floor number…",
    word: "Word",
    totalPrice: "Total price",
    priceMaybeChange: "Prices are subject to change upon request ",
    DK: "K.D",
    orderSummary: "Order Summary",
    sendTranslation: "Send translation",
    automatedFigure: "Automated figure",
    writeZipCode: "Please type the auto code...",
    ok: "Ok",
    userCreated: "User created successfully",
    passReseted: "Password has been reset successfully",
    translationDetails: "Translation details",
    translationData: "Translation Data",
    enterInE: "Enter the name in english",
    enterInAr: "Enter the name in arabic",
    costPerWord: "Cost per word",
    wentWrong: "Something went wrong",
    select1Field: "Please select 1 field at least ",
    clientOrders: "Client orders",
    noRequests: "No requests to show",
    hello: "Hello",
    reminderWords: "The rest of the package",
    buyBundle: "Buy extra bundle",
    subscriptionPeriod: "Subscription period",
    buyTheBundle: "Buy bundle",
    extraBundle: "Extra bundles",
    currentBundle: "Current bundle",
    noEnoughWords:
      "You do not have enough words to complete the translation process",
    orderStatus: "Order status",
    translate: "Translate",
    filter: "search filter",
    downloadFileAfterTranslation: "Download file after translation",
    preferredLanguage: "Preferred language",
    selectPreferredLang: "Select Preferred language ...",
    officeInYourHand:
      "A complete translation office/agency at your fingertips….",
    officeInYourHandBody:
      "Kuwait Qamous,  a platform that provides professional online translation service, has a team of more than 60 specialized translators, administrators and customer service. You don’t looking for professional translation offices for your documents, neither navigate between them finding out what’s the best .Kuwait Qamous team will be on hand to cater you own translation needs 24 hours / 7 days a week with a team of experts in the fields of academic, legal, medical and others, who maintain a high level of quality by implementing a quality control process for each translated document pre-delivery/before delivery/ pre-handover/ prior to delivery.",
    startNow: "Start translation",
    companyNeeds: "Corporate needs",
    kuwaitQamous: "Kuwait Qamous",
    kuwaitQamousBody1:
      "A platform that provides professional online translation service, has a team of more than 60 specialized translators, administrators and customer service. You don’t looking for professional translation offices for your documents, neither navigate between them finding out what’s the best .Kuwait Qamous team will be on hand to cater you own translation needs 24 hours / 7 days a week with a team of experts in the fields of academic, legal, medical and others, who maintain a high level of quality by implementing a quality control process for each translated document pre-delivery/before delivery/ pre-handover/ prior to delivery.",
    kuwaitQamousBody2:
      "Kuwait Qamous team you can connect with in a flash by chatting on the left via site, consists of experts whose goals rendering high quality service at a higher speed. To save your time, effort, and get a professional human translation in the most way that meets your requires from the best online translation agencies then download your documentaries now. ",

    ourTranslators: "Professional specialized translators",
    ourTranslatorsBody:
      "More than 60 translators are fussily chosen with multiple experiences in multiple fields , here our team is always ready  to give an assistance .",
    quality: "Quality",
    qualityBody:
      "The highest quality standards are demanding a multistage process of reviewing and editing that Kuwait Qamous translators are working on .",
    timeQuality: "Time Quality ",
    timeQualityBody:
      "Translated documents on time for our time quality and your goals achieving.",
    corporateServices: "Corporate Services",
    corporateServicesBody:
      "For the first time, the service of contracting with corporations to get your indefectible translation through corporations accounts on site. Send your corporation details & we’re back in touch. ",
    certifiedTranslation: "Certified Translation ",
    certifiedTranslationBody:
      "If you want to submit your documents to a government agency, the Kuwait Qamous team provides you a special accreditation for your translation in all disciplines that officially approved inside and outside the State of Kuwait.",

    deliveryService: "Certified Translation Delivery Service",
    deliveryServiceBody:
      "In case you need to receive your certified translation, Kuwait Qamous delivers it to you wherever you are,  for much less than you expect.",
    coverAllLang: "Translation coverage for all languages",
    coverAllLangBody:
      "Kuwait Qamous provides professional certified translation services for most of the world's languages with the help of a team of certified translators. We cover more than 99% of the languages spoken around the world, English to Arabic, French to Arabic, Spanish to Arabic, Persian to Arabic, Arabic to Chinese, Russian to Arabic, Portuguese to Arabic translation and many more.",
    getCertifiedTranslation: "Get certified translation for many fields",
    legalTranslation: "Certified Legal Translation",
    financialTranslation: "Certified Financial Translation",
    officialGovernmental: "Translation of official governmental documents",
    manufacturingTranslation: "Manufacturing Translation",
    travelTourism: "Travel & Tourism",
    mediaEntertainment: "Media & Entertainment",
    MedicalTranslation: "Certified Medical Translation",
    sectorTranslation: "Transportation Sector Translation",
    telecomSectorTranslation: "Telecom Sector Translation",
    sportsSectorTranslation: "Sports Sector Translation",
    energyMining: "Energy & Mining ",
    marketingTranslation: "Certified Marketing Translation",
    translateYourDocuments: "Translate your documents",
    howToUse: "How to use Kuwait Qamous?",
    howToUseBody:
      " Kuwait Dictionary vision is to become the most innovative, creative, affordable and fastest provider of certified translation services in the world.",
    selectFile: "Select the file to be translated",
    selectDelivery: "Select delivery time, type of translation and language",
    paymentProcess: "Payment  process completion",
    overTime: "Taking over on time",
    howToPickBody1:
      "We contract the most qualified translators this field, who are specialized in specific cognitive sector and possess the educational background, experience, and skills that are in line with our demands.",
    howToPickBody2:
      "We assay  the resumes of applicants very carefully, Verify their expertise and professional quality and compare it to the needs of Kuwait Qamous.",
    howToPickBody3:
      "The submitted translator undergoes a ten-point test, each of which is awarded for the context of the language, style, fidelity to the source text, accuracy of grammar and use of appropriate linguistic terminology.",
    howToPickBody4:
      "Translators who possess a 'Very Good' or 'Excellent' rating (9 or 10 out of 10) are interviewed to find out their strengths and skills regarding the use of the Kuwait Qamous team's translation tools.",
    howToPickBody5:
      "If the nominated translator successfully passes this process, he will be joined to the Kuwait Qamous System database with the 'New Translator' tag to be at your service whenever you need.",
    parteners: "Success Partners",
    opinions: "Some of our customers' opinions",
    links: "Links",
    main: "Main",
    aboutUs: "About Us",
    services: "Our services",
    opinionsLink: "Reviews",
    callUs: "Call us",
    Failed: "Failed",
    Paid: "Paid",
    Refunded: "Refunded",
    Bundle: "Bundle",
    CompanyOrder: "Company Order",
    UserOrder: "User Order",
    Pending: "Pending",
    oldPassword: "Current Password",
    newPassword: "New Password",
    acceptTermsConditions: " terms and conditions",
    TermsConditions: "terms and conditions",
    accept: "Accept the",
    EstimateTimeDelivery: "estimate time delivery ",
    opinionsObj : [
      {
        name: "Mubarak Al-Shammari",
        opinion: "Very reasonable prices compared to the effort required to get similar services from companies. I always recommend them to anyone looking for comfort and trust"
      },
      {
        name: "Fatima Al-Ali",
        opinion: "Excellent service and speedy completion. I always turn to them when I need translation, and I recommend them to anyone looking for accuracy and quality"
      },
      {
        name: "Khaled Al-Mutairi",
        opinion: "Professional interaction and very accurate translations. I consider them the ideal choice for anyone seeking professional and reliable translation services"
      }
    ],
    q8qAddress: "Kuwait - Qibla - Fahad Al-Salem Street - Kuwait Building - 13th Floor"
  },
};
