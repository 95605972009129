/** @format */

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import addIcon from "../../../../Assets/Images/Group 2784.svg";
import wightAdd from "../../../../Assets/Images/Group 2784.png";
import { languages } from "../../../../Constants/Languages";
import NavbarAndSidebarCont from "../../../../Components/Shared/NavbarAndSidebarCont";
import RequestsSearchbar from "../../../../Components/AdminComponents/RequestsSearchbar";
import CustomersTable from "../../../../Components/Shared/CustomersTable";
import Pagination from "../../../../Components/Shared/Pagination";
import { deleteTranslationField } from "../../../../Services/adminServices";
import { setTranslationFields } from "../../../../Redux/commonData";

export default function TranslationAreas(props) {
  const [id, setId] = useState();
  const [isModalOpen, setModalOpen] = useState({ cancelModal: false });
  const [isHovering, toggleHover] = useState(false);
  const [agents, setAgents] = useState([]);
  const [tableHeaderConstatnt, setTableHeader] = useState([]);
  let { lang, dir } = useSelector((state) => state.lang);
  let { translationFields } = useSelector((state) => state.commonData);
  let dispatch = useDispatch();
  useEffect(() => {
    let translationFieldsClone = translationFields;
    translationFieldsClone = translationFieldsClone.map((field) => ({
      label: field.label[lang],
      value: field.value,
    }));
    let tableHeader = [
      { title: languages[lang].translationArea, key: "label" },
    ];
    setTableHeader(tableHeader);
    setAgents(translationFieldsClone);
  }, [lang, translationFields]);
  const getPageData = (text) => {};
  const toggleCancelModal = (modalName, event, id) => {
    setId(id);
    setModalOpen({ ...isModalOpen, [modalName]: !isModalOpen[modalName] });
    if (event) {
      event.preventDefault();
    }
  };
  const deleteField = async (id) => {
    props.toggleLoading(true);
    let response = await deleteTranslationField(id);
    if (response.data?.isSuccess) {
      toggleCancelModal("cancelModal");
      let newTranslationFieldsList = translationFields.filter(
        (addOn) => addOn.value !== id
      );
      dispatch(setTranslationFields(newTranslationFieldsList));
      props.toggleModal("successModal");
      props.getModalMsg(response.data.message[lang]);
    } else {
      props.toggleModal("failModal");
      props.getModalMsg(
        response.data?.message
          ? response.data?.message[lang]
          : languages[lang].wentWrong
      );
    }
    props.toggleLoading(false);
  };
  return (
    <div>
      <div className='customers-cont__body__info' dir={dir} lang={lang}>
        <h4>{languages[lang].translationAreas}</h4>
        {props.canEdit ? (
          <Link to='/dashboard/settings/transaction-area/add'>
            <button
              onMouseEnter={() => toggleHover(true)}
              onMouseLeave={() => toggleHover(false)}>
              <img src={isHovering ? wightAdd : addIcon} />{" "}
              {languages[lang].add}
            </button>
          </Link>
        ) : null}
      </div>
      {/* <RequestsSearchbar searchOnly /> */}
      <CustomersTable
        toggleModal={toggleCancelModal}
        isOpen={isModalOpen.cancelModal}
        id={id}
        agents={agents}
        url='settings/transaction-area'
        tableHeaderConstatnt={tableHeaderConstatnt}
        msg={languages[lang].deleteType}
        deleteAction={deleteField}
        loading={props.loading}
        disableRouting
        removeDelete={!props.canEdit}
      />
    </div>
  );
}
