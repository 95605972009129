/** @format */

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "@mui/material/Modal";
import Date from "../../InputField/Date";
import Input from "../../InputField/Input";
import ReloadIcon from "../../../Assets/Images/Icon open-reload.svg";
import calenderIcon from "../../../Assets/Images/Icon awesome-calendar-alt.svg";
import searchIcon from "../../../Assets/Images/Icon feather-search.svg";
import Select from "../../Shared/Select";
import { languages } from "../../../Constants/Languages";
import "./index.scss";
import { bindActionCreators } from "@reduxjs/toolkit";
import { adminActions } from "../../../actions/adminActions";
import { clear } from "@testing-library/user-event/dist/clear";
export default function RequestsSearchbar(props) {
  const [searchValues, setSearchValues] = useState({
    name: "",
    date: null,
    type: null,
    status: null,
  });

  let { lang, dir } = useSelector((state) => state.lang);
  let { orderTypes, translationFields, orderStatuses } = useSelector(
    (state) => state.commonData
  );
  let dispatch = useDispatch();
  let { getOrderTypesAction, getTranslationFieldsAction } = bindActionCreators(
    adminActions,
    dispatch
  );
  useEffect(() => {
    getOrderTypesAction();
    getTranslationFieldsAction();
  }, []);
  const getChanges = (key, value) => {
    setSearchValues({ ...searchValues, [key]: value });
  };
  const getSearchResult = () => {
    let searchString = `${
      searchValues.name.length ? "searchKey=" + searchValues.name : ""
    }${searchValues.date?.length ? "&searchDate=" + searchValues.date : ""}${
      searchValues.status ? "&statusId=" + searchValues.status.value : ""
    }${
      searchValues.type ? "&translationFieldId=" + searchValues.type.value : ""
    }
    ${
      searchValues?.paymentMethodId?.paymentMethodId
        ? "&paymentMethodId=" + searchValues?.paymentMethodId?.paymentMethodId
        : ""
    }&`;
    if (searchString[0] === "&") {
      searchString = searchString.replace("&", "");
    }
    props.getSearchValues(searchString);
  };
  const clearSearch = () => {
    setSearchValues({ name: "", date: "", type: null, status: null });
    props.getSearchValues("");
  };
  const renderInputFields = () => {
    return (
      <>
        <Input
          value={searchValues.name}
          stateName="name"
          getChanges={getChanges}
          placeholder={languages[lang].search}
          icon={searchIcon}
        />
        {props.searchOnly ? null : (
          <>
            {" "}
            <Date
              value={searchValues.date}
              stateName="date"
              getChanges={getChanges}
              icon={calenderIcon}
            />
            {props.hideFieldSearch ? null : (
              <Select
                placeholder={languages[lang].translationField}
                value={searchValues.type}
                getChanges={getChanges}
                stateName="type"
                options={translationFields}
              />
            )}
            <Select
              placeholder={languages[lang].status}
              value={searchValues.status}
              getChanges={getChanges}
              stateName={"status"}
              options={props.statusOptions}
            />
            {props.hasSelect ? (
              <Select
                placeholder={languages[lang]?.[props.selectName]}
                value={searchValues?.[props.selectKey]}
                getChanges={getChanges}
                stateName={props.selectKey}
                options={props.selectOptions}
              />
            ) : null}
          </>
        )}{" "}
        <div dir={dir} lang={lang} className="request-search__clear">
          <img src={ReloadIcon} onClick={() => clearSearch()} />
          <span onClick={() => clearSearch()}>{languages[lang].clear}</span>
          <button onClick={getSearchResult}>{languages[lang].search}</button>
        </div>
      </>
    );
  };
  return (
    <div>
      <div className="request-search" dir={dir} lang={lang}>
        {renderInputFields()}
      </div>
      <div>
        <Modal
          className="responsive-search-modal"
          open={props.openFilter}
          onClose={() => props.toggleFilterModal(false)}
        >
          <div className="responsive-search-modal__container">
            {renderInputFields()}
          </div>
        </Modal>
      </div>
    </div>
  );
}
