/** @format */
import "./index.scss";
import Logo from "../../Assets/Images/Group 39809.png";
import HomeImg from "../../Assets/Images/Icon awesome-home.png";
import PhoneImg from "../../Assets/Images/Icon awesome-phone-alt.png";
import EmailImg from "../../Assets/Images/Icon material-email.png";
import FaceImg from "../../Assets/Images/facebook (1).png";
import TwitterImg from "../../Assets/Images/twitter (1).png";
import linkedImg from "../../Assets/Images/Icon awesome-linkedin-in.png";
import InstaImg from "../../Assets/Images/Icon metro-instagram.png";
import { languages } from "../../Constants/Languages";
import { useSelector } from "react-redux";
export default function Footer() {
  let { lang, dir } = useSelector((state) => state.lang);
  return (
    <footer className='footer' lang={lang} dir={dir}>
      <div className='footer-cont' lang={lang} dir={dir}>
        <div className='footer-cont__sect-cont'>
          <h5> {languages[lang].callUs}</h5>
          <div className='footer-cont__sect-cont__info'>
            <img src={HomeImg} />{" "}
            <span>{languages[lang].q8qAddress}</span>
          </div>
          <div className='footer-cont__sect-cont__info'>
            <img src={PhoneImg} />
            <span dir="ltr">
              <a href="tel:+96596715605">+965 96715605</a>
            </span>
          </div>
          <div className='footer-cont__sect-cont__info'>
            <img src={EmailImg} />
            <span><a href="mailto:info@q8qamous.com">info@q8qamous.com</a></span>
          </div>
        </div>


        {/^https?:\/\/[^\/]+(\/([?#].*)?)?$/.test(window.location.href) &&
          <div className='footer-cont__links'>
            <h5>{languages[lang].links}</h5>
            <div className='footer-cont__links'>
              <a href='#main'>{languages[lang].main}</a>
              <a href='#about-us'>{languages[lang].aboutUs}</a>
              <a href='#services'>{languages[lang].services}</a>
              <a href='#opinions'>{languages[lang].opinionsLink}</a>
            </div>
          </div>}

        {/* <a href='#contactUs'>اتصل بنا</a> */}


        <div className='footer-cont__about-us'>
          <img src={Logo} />
          <span>{languages[lang].kuwaitQamousBody2}</span>
          <br />
          <a href="https://www.instagram.com/" target="_blank" style={{ display: "inline" }}><img src={InstaImg} className='socialMediaImg' /></a>
          <a href="https://www.linkedin.com/feed/" target="_blank" style={{ display: "inline" }}><img src={linkedImg} className='socialMediaImg' /></a>
          <a href="https://x.com/" target="_blank" style={{ display: "inline" }}><img src={TwitterImg} className='socialMediaImg' /></a>
          <a href="https://www.facebook.com/" target="_blank" style={{ display: "inline" }}><img
            src={FaceImg}
            className='socialMediaImg'
            style={{ width: "10px" }}
          /></a>
        </div>
      </div>
      {/* <div className='copyright'>
        <span>جميع الحقوق محفوظة © 2022</span>
      </div> */}
    </footer>
  );
}
