/** @format */

import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import InputField from "../../../../../Components/InputField";
import NavbarAndSidebarCont from "../../../../../Components/Shared/NavbarAndSidebarCont";
import Add from "../../../../../Assets/Images/Icon ionic-ios-add-circle.svg";
import Remove from "../../../../../Assets/Images/Icon material-delete.svg";
import { languages } from "../../../../../Constants/Languages";
import priceIcon from "../../../../../Assets/Images/Capture.PNG";
import { bindActionCreators } from "@reduxjs/toolkit";
import { adminActions } from "../../../../../actions/adminActions";
import { Navigate } from "react-router-dom";

let initData = { name: null, code: null };
export default function AddLang() {
  const [navigate, setNavigate] = useState(false);
  const [isOpen, setOpen] = useState({ successModal: false, failModal: false });
  const [modalMsg, setModalMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const [language, setLang] = useState(initData);
  const [disableSubmitt, toggleDisable] = useState(false);
  const [errors, setErrors] = useState(initData);
  let { lang, dir } = useSelector((state) => state.lang);
  let dispatch = useDispatch();
  let { addLangAction } = bindActionCreators(adminActions, dispatch);
  const getChanges = (key, value) => {
    let errorsClone = { ...errors };
    errorsClone = { ...errorsClone, [key]: null };
    let flag = Object.values(errorsClone).every((error) => error === null);
    toggleDisable(!flag);
    setErrors(errorsClone);
    setLang({ ...language, [key]: value });
  };
  const toggleModal = (stateName) => {
    setOpen({ ...isOpen, [stateName]: !isOpen[stateName] });
    if (stateName === "successModal" && isOpen.successModal) {
      setNavigate(true);
    }
  };
  const AddLang = async () => {
    let errorsClone = {};

    Object.keys(language).forEach((field) => {
      if (!language[field]?.length) {
        errorsClone = {
          ...errorsClone,
          [field]: languages[lang].requiredField,
        };
      }
    });
    toggleDisable(Object.keys(errorsClone).length > 0);
    setErrors(errorsClone);
    if (Object.keys(errorsClone).length === 0) {
      setLoading(true);
      let params = { ...language };
      let response = await addLangAction(params);

      if (!response.data.isSuccess) {
        if (response.data.errors) {
          let errorObj = {};
          Object.keys(response.data.errors).forEach((errorKey) => {
            errorObj = {
              ...errorObj,
              [errorKey]: response.data.errors[errorKey].join(", "),
            };
          });
          setErrors(errorObj);
        } else if (response.data.message?.ar?.length) {
          setModalMsg(response.data?.message[lang]);
          toggleModal("failModal");
        }
      } else {
        setModalMsg(response.data?.message?.[lang]);
        toggleModal("successModal");
      }
      setLoading(false);
    }
  };
  return (
    <NavbarAndSidebarCont
      isOpen={isOpen}
      loading={loading}
      toggleModal={toggleModal}
      modalMsg={modalMsg}>
      <h4 className='lang-header-title' lang={lang} dir={dir}>
        {languages[lang].editLangPricing}
      </h4>
      <div className='Lang-price'>
        <div className='Lang-price__body' lang={lang} dir={dir}>
          <div className='Lang-price__body__select-cont'>
            <InputField
              title={languages[lang].langName}
              placeholder={languages[lang].writelangName}
              value={language.name}
              getChanges={getChanges}
              stateName={"name"}
              errors={errors}
            />
            &nbsp;&nbsp;
            <InputField
              title={languages[lang].code}
              placeholder={languages[lang].writelangCode}
              value={language.code}
              getChanges={getChanges}
              stateName={"code"}
              errors={errors}
            />
          </div>

          <button disableSubmitt={disableSubmitt} onClick={AddLang}>
            {languages[lang].add}
          </button>
          {navigate ? <Navigate to='/dashboard/settings' /> : null}
        </div>
      </div>
    </NavbarAndSidebarCont>
  );
}
