/** @format */

import React from "react";
import { useSelector } from "react-redux";
import hidePass from "../../../Assets/Images/images (5).png";
export default function Input(props) {
  let { lang, dir } = useSelector((state) => state.lang);
  return (
    <div
      className={`input-cont__field-cont ${
        props.errors && "input-cont__field-cont--error"
      } ${props.disabled && "input-cont__field-cont--disabled"}`}
      dir={dir}
      lang={lang}>
      <input
        value={props.value}
        placeholder={props.placeholder}
        dir={dir}
        lang={
          props.stateName === "en" || props.stateName === "email" ? "en" : lang
        }
        disabled={props.disabled}
        type={props.showPassword ? "text" : props.type}
        onChange={(e) =>
          props.getChanges(
            props.stateName,
            e.target.value,
            props.id,
            props.rowKey
          )
        }
        onBlur={(e) =>
          props.validatData &&
          props.validatData(props.stateName, e.target.value)
        }
      />
      {props.icon ? (
        <img
          className={`
            input-cont__field-cont__icon--${lang}
          `}
          style={props.type === "password" ? { cursor: "pointer" } : {}}
          onClick={() => props.togglePassViability(props.stateName)}
          src={props.showPassword ? hidePass : props.icon}
        />
      ) : null}
    </div>
  );
}
