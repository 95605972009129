/** @format */

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
  orders: {},
};

export const clintSlice = createSlice({
  name: "clint",
  initialState,
  reducers: {
    setClintOrders: (state, action) => {
      state.orders = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setClintOrders } = clintSlice.actions;

export default clintSlice.reducer;
