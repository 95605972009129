/** @format */

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDropzone } from "react-dropzone";

import UploadImg from "../../../../Assets/Images/Icon feather-upload-cloud.svg";

import { languages } from "../../../../Constants/Languages";
import CloseIcon from "../../../../Assets/Images/Icon ionic-ios-close.svg";
import DownloadImg from "../../../../Assets/Images/Icon feather-download-cloud.svg";
import DownloadImgAfter from "../../../../Assets/Images/Icon ionic-ios-cloud-download.svg";

import fileIcon from "../../../../Assets/Images/Icon awesome-file-alt.svg";
import CancelModal from "../../../../Components/Modals/CancelModal";
import OrderDetail from "../../../../Components/Shared/OrderDetails";
import NavbarAndSidebarCont from "../../../../Components/Shared/NavbarAndSidebarCont";
import { commonActions } from "../../../../actions/commonActions";
import Status from "../../../../Components/Shared/Status";
import { prepareDate } from "../../../../Constants/commonFunc";

export default function TranslatorRequest() {
  const [loading, setLoading] = useState(false);
  const [isOpen, setOpen] = useState({
    successModal: false,
    failModal: false,
    cancelModal: false,
  });
  const [modalMsg, setModalMsg] = useState("");
  const [files, setFiles] = useState([]);
  const { acceptedFiles, fileRejections, getRootProps, getInputProps } =
    useDropzone({
      maxFiles: 1,
    });

  const [orderData, setOrderData] = useState({});
  const [isModalOpen, setModalOpen] = useState({
    failModal: false,
    successModal: false,
    cancelModal: false,
  });
  const [changes, setChangedValues] = useState({
    status: null,
    translator: null,
  });
  let { orderStatuses } = useSelector((state) => state.commonData);
  let { lang, dir } = useSelector((state) => state.lang);
  useEffect(() => {
    setChangedValues({ ...changes, status: languages[lang].idle });
    let order = JSON.parse(localStorage.getItem("orderDetails"));
    setOrderData(order);
  }, [lang]);
  useEffect(() => {
    setFiles(acceptedFiles);
  }, [acceptedFiles]);
  const deleteFile = (selectedFile) => {
    let filesClone = files.filter(
      (file) =>
        file.lastModified !== selectedFile.lastModified &&
        file.name !== selectedFile.name
    );
    setFiles(filesClone);
  };
  const toggleModal = (modalName) => {
    setOpen({ ...isOpen, [modalName]: !isOpen[modalName] });
  };
  const downloadFile = async (id) => {
    setLoading(true);
    let res = await commonActions.cancelOrderAction(id);
    if (res.isSuccess) {
      const element = document.createElement("a");
      element.href = res.data.url;
      element.target = "_blank";
      element.download = "SamplePDF.pdf";
      element.click();
      // element.href = URL.createObjectURL(new Blob([res.data.url]));
      // element.setAttribute("download", "file.pdf");
      // document.body.appendChild(element);
      // element.click();
    } else {
      toggleModal("failModal");
      setModalMsg(
        res?.message ? res?.message[lang] : languages[lang].wentWrong
      );
    }
    setLoading(false);
  };
  const uploadFile = async () => {
    setLoading(true);
    let form = new FormData();
    form.append("afterFile", acceptedFiles[0]);
    let res = await commonActions.uploadFileAction(
      orderData.id,
      orderData.orderSource ?? "User",
      form
    );

    if (res.isSuccess) {
      let data = { ...orderData };
      data.documentAfterId = res.data.documentAfterId;
      data.statusId = res.data.statusId;
      let statusIndex = orderStatuses.findIndex(
        (status) => status.id === res.data.statusId
      );
      data.status = orderStatuses[statusIndex][lang];
      setFiles([]);
      localStorage.setItem("orderDetails", JSON.stringify(data));
      setOrderData(data);
      toggleModal("successModal");
      setModalMsg(res.message[lang]);
    } else {
      toggleModal("failModal");

      setModalMsg(
        res?.message ? res?.message[lang] : languages[lang].wentWrong
      );
    }
    setLoading(false);
  };
  const isBtnDisable = () => {
    let ids = [
      "36b3787e-7a7e-4ca1-a3c2-0e549c277c32",
      "3f9fa7c7-12ab-4291-b1cd-865e8e12abd7",
      "808cf190-87b8-4cff-8b18-ebe8de58e30f",
      "fe6c21ec-ee70-46d2-9789-105428ef93ec",
    ];
    return ids.includes(orderData.statusId) || acceptedFiles.length === 0;
  };
  return (
    <div>
      {/* <CancelModal
        toggleModal={toggleModal}
        modalName='cancelModal'
        open={isModalOpen.cancelModal}
      /> */}

      <NavbarAndSidebarCont
        userSidebar="translator"
        isOpen={isOpen}
        loading={loading}
        toggleModal={toggleModal}
        modalMsg={modalMsg}
      >
        <div dir={dir} lang={lang} className={`order-cont__info-cont`}>
          <div className="order-cont__info-cont__header">
            <h4>
              {languages[lang].orderInfo}{" "}
              <span dir={dir} lang={lang}>
                (#{orderData.id})
              </span>
            </h4>
            <div className="order-cont__info-cont__header__actions">
              <span className="order-cont__info-cont__header__actions__orderStatus">
                {languages[lang].orderStatus}:{" "}
              </span>
              <Status removeArrow data={orderData.status} />
            </div>
          </div>
          <OrderDetail orderData={orderData} userSidebar translator />
        </div>
        <div className="order-cont__footer" dir={dir} lang={lang}>
          <div>
            <div className="order-cont__footer__downloadِAndUploadFile">
              <div
                className="order-cont__footer__downloadِAndUploadFile__downloadFile"
                onClick={() => downloadFile(orderData.documentBeforeId)}
              >
                <img src={DownloadImg} />
                <span>{languages[lang].downloadFile}</span>
              </div>

              {orderData.documentAfterId ? (
                <div
                  className="order-cont__footer__downloadِAndUploadFile__downloadFile order-cont__footer__downloadِAndUploadFile__downloadFile--afterFile"
                  onClick={() => downloadFile(orderData.documentAfterId)}
                >
                  <img src={DownloadImgAfter} />
                  <span>{languages[lang].downloadFileAfterTranslation}</span>
                  <span>{prepareDate(orderData.fininshedAt)}</span>
                </div>
              ) : null}
              <div
                {...getRootProps({
                  className:
                    "first-phase__upload-files__section-cont__dropzone  order-cont__footer__downloadِAndUploadFile__uploadFile",
                })}
                dir="rtl"
                lang="ar"
              >
                <input {...getInputProps()} />
                <img src={UploadImg} />
                <span
                  dir="rtl"
                  lang="ar"
                  className="first-phase__upload-files__section-cont__dropzone__choose-file"
                >
                  {languages[lang].chooseFile}
                </span>{" "}
                <span dir="rtl" lang="ar">
                  {languages[lang].dragFile}
                </span>
              </div>
            </div>

            <div className="first-phase__upload-files__section-cont__file-cont">
              {files.map((file) => (
                <div
                  className="first-phase__upload-files__section-cont__file-cont__file"
                  key={file.lastModified}
                >
                  <img src={CloseIcon} onClick={() => deleteFile(file)} />
                  <span>{file.size / 1000} KB</span>
                  <span className="first-phase__upload-files__section-cont__file-cont__file__fileName">
                    {file.name} <img src={fileIcon} />
                  </span>
                </div>
              ))}
            </div>
          </div>

          {false ? null : (
            <button disabled={isBtnDisable()} onClick={uploadFile}>
              {languages[lang].sendTranslation}
            </button>
          )}
        </div>
      </NavbarAndSidebarCont>
    </div>
  );
}
