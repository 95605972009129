/** @format */

import Modal from "@mui/material/Modal";
import { useSelector } from "react-redux";

import Logo from "../../../Assets/Images/Group 39808.png";

import closeIcon from "../../../Assets/Images/Icon ionic-ios-close-circle-outline.svg";
import { languages } from "../../../Constants/Languages";
import { forgetPassService } from "../../../Services/commonServices";

export default function EmailSentModal(props) {
  const reSendEmail = () => {
    forgetPassService({ email: props.forgetEmail });
  };
  let { lang, dir } = useSelector((state) => state.lang);
  return (
    <Modal open={props.open} onClose={() => props.toggleModal(props.modalName)}>
      <div className='signup-cont signup-cont--decrease-padding'>
        <img
          src={closeIcon}
          className='closeIcon'
          onClick={() => props.toggleModal(props.modalName)}
        />
        <img className='logo' src={Logo} />
        <h5 className='signup-cont__title' style={{ textAlign: "center" }}>
          {languages[lang].emailSent}
        </h5>
        <div style={{ textAlign: "center" }}>
          <span>
            {languages[lang].emailSentTo}
            <br />
            {props.forgetEmail} <br /> {languages[lang].followTheInstructions}
          </span>

          <div
            className='signup-cont__forget-cont--resendEmail'
            dir='rtl'
            lang='ar'>
            <span className='signup-cont__forget-cont--resendEmail__reSendMsg '>
              {languages[lang].emailNotReceived}
            </span>
            <span
              className='signup-cont__forget-cont--resendEmail__reSend'
              onClick={reSendEmail}>
              {languages[lang].reSend}
            </span>
          </div>
        </div>
      </div>
    </Modal>
  );
}
