/** @format */

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import RequestsSearchbar from "../../../../Components/AdminComponents/RequestsSearchbar";
import Pagination from "../../../../Components/Shared/Pagination";
import addIcon from "../../../../Assets/Images/Group 2784.svg";
import wightAdd from "../../../../Assets/Images/Group 2784.png";
import { languages } from "../../../../Constants/Languages";
import CustomersTable from "../../../../Components/Shared/CustomersTable";
import { bindActionCreators } from "@reduxjs/toolkit";
import { adminActions } from "../../../../actions/adminActions";

export default function Packages(props) {
  const [id, setId] = useState();
  const [isModalOpen, setModalOpen] = useState({ cancelModal: false });
  const [isHovering, toggleHover] = useState(false);
  const [packagesList, setPackages] = useState([]);
  const [tableHeaderConstatnt, setTableHeader] = useState([]);
  const dispatch = useDispatch();
  let { getPackages, deletePackageAction } = bindActionCreators(
    adminActions,
    dispatch
  );
  let { lang, dir } = useSelector((state) => state.lang);
  let { packages } = useSelector((state) => state.commonData);
  useEffect(() => {
    props.toggleLoading(true);
    getPackages();
  }, []);
  useEffect(() => {
    if (packages?.length) {
      props.toggleLoading(false);
      let packagesClone = packages.map((item) => ({
        ...item,
        name: item[lang],
        bundleClass: item.bundleClass[lang],
        bundleType: item.bundleType[lang],
        value: item.id,
      }));
      let tableHeader = [
        { title: languages[lang].packageName, key: "name" },
        { title: languages[lang].wordsNum, key: "wordCount" },
        { title: languages[lang].serviceType, key: "bundleClass" },
        { title: languages[lang].subscriptionPeriod, key: "bundleType" },
        { title: languages[lang].price, key: "price" },
      ];
      setTableHeader(tableHeader);
      setPackages(packagesClone);
    }
  }, [lang, packages.length]);
  const toggleCancelModal = (modalName, event, id) => {
    setId(id);
    setModalOpen({ ...isModalOpen, [modalName]: !isModalOpen[modalName] });
    if (event) {
      event.preventDefault();
    }
  };
  const deletePackage = async (id) => {
    props.toggleLoading(true);
    let response = await deletePackageAction(id, packages);
    if (response.data?.isSuccess) {
      toggleCancelModal("cancelModal");
      props.toggleModal("successModal");
      props.getModalMsg(response.data.message[lang]);
    } else {
      props.toggleModal("failModal");
      props.getModalMsg(
        response.data?.message
          ? response.data?.message[lang]
          : languages[lang].wentWrong
      );
    }
    props.toggleLoading(false);
  };
  return (
    <div>
      {" "}
      <div className="customers-cont__body__info" dir={dir} lang={lang}>
        <h4>{languages[lang].packages}</h4>
        <Link to="/dashboard/companies/add-package">
          <button
            onMouseEnter={() => toggleHover(true)}
            onMouseLeave={() => toggleHover(false)}
          >
            <img src={isHovering ? wightAdd : addIcon} /> {languages[lang].add}
          </button>
        </Link>
      </div>
      <RequestsSearchbar searchOnly />
      <CustomersTable
        saveToSessionStorege={"packageData"}
        toggleModal={toggleCancelModal}
        isOpen={isModalOpen.cancelModal}
        id={id}
        loading={props.loading}
        agents={packagesList}
        url={"companies/packages"}
        tableHeaderConstatnt={tableHeaderConstatnt}
        msg={languages[lang].deletePackage}
        deleteAction={deletePackage}
      />
    </div>
  );
}
