/** @format */

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import addIcon from "../../../../Assets/Images/Group 2784.svg";
import wightAdd from "../../../../Assets/Images/Group 2784.png";
import { languages } from "../../../../Constants/Languages";
import NavbarAndSidebarCont from "../../../../Components/Shared/NavbarAndSidebarCont";
import RequestsSearchbar from "../../../../Components/AdminComponents/RequestsSearchbar";
import CustomersTable from "../../../../Components/Shared/CustomersTable";
import Pagination from "../../../../Components/Shared/Pagination";
import { bindActionCreators } from "@reduxjs/toolkit";
import { adminActions } from "../../../../actions/adminActions";

export default function AdminUsers(props) {
  const [isHovering, toggleHover] = useState(false);
  const [adminUsers, setAdminUsers] = useState([]);
  const [tableHeaderConstatnt, setTableHeader] = useState([]);
  const [pagination, setPaginationData] = useState({});
  let { lang, dir } = useSelector((state) => state.lang);
  let { systemAdmins } = useSelector((state) => state.admin);
  const dispatch = useDispatch();
  let { getSystemAdminsAction } = bindActionCreators(adminActions, dispatch);
  useEffect(() => {
    getSystemAdminsAction(1);
    props.toggleLoading(true);
  }, []);
  useEffect(() => {
    if (systemAdmins?.data?.length >= 0) {
      props.toggleLoading(false);
      let tableHeader = [
        { title: languages[lang].name, key: "fullName" },
        { title: languages[lang].email, key: "email" },
        { title: languages[lang].phoneNum, key: "phoneNumber" },
      ];
      setTableHeader(tableHeader);
      setAdminUsers(systemAdmins.data);
      setPaginationData(systemAdmins.pagination);
    }
  }, [lang, systemAdmins]);
  const getPageData = (text) => {
    getSystemAdminsAction(text, 10);
  };

  return (
    <div>
      <div className='customers-cont__body__info' dir={dir} lang={lang}>
        <h4>{languages[lang].pricingForTypesOfTranslationServices}</h4>
        {props.canEdit ? (
          <Link to='/dashboard/settings/admin-user/add'>
            <button
              onMouseEnter={() => toggleHover(true)}
              onMouseLeave={() => toggleHover(false)}>
              <img src={isHovering ? wightAdd : addIcon} />{" "}
              {languages[lang].add}
            </button>
          </Link>
        ) : null}
      </div>
      {/* <RequestsSearchbar searchOnly /> */}
      <CustomersTable
        saveToSessionStorege='adminData'
        agents={adminUsers}
        url='settings/admin-user'
        tableHeaderConstatnt={tableHeaderConstatnt}
        msg={languages[lang].deleteService}
        removeDelete={true}
        disableRouting={!props.canEdit}
      />
      {pagination.TotalPages === 1 ? null : (
        <Pagination pagination={pagination} getPageData={getPageData} />
      )}
    </div>
  );
}
