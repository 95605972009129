/** @format */

import { setClintOrders } from "../Redux/clint";
import { setCompanyBundleInfo, setCompanyUsers } from "../Redux/company";
import { deleteReq, getReq, postReq, putReq } from "../Services/APIMethods";

export const clintActions = {
  getClintOrdersAction,
  editClintInfoAction,
  getCompanyBundleInfoAction,
  getTranslatorOrdersAction,
  getCompanyUsersAction,
  addNewCompanyUser,
  getCompanyOrdersAction,
};
function getClintOrdersAction(pageNum, searchString) {
  return async (dispatch) => {
    let response = await getReq(
      `Orders/get-user-orders?${
        searchString ?? ""
      }pageNumber=${pageNum}&pageSize=10`
    );
    if (response.data.isSuccess) {
      let data = prepareDataWithPagination(response);

      dispatch(setClintOrders(data));
    }
  };
}
function getCompanyOrdersAction(pageNum, searchString) {
  return async (dispatch) => {
    let response = await getReq(
      `Orders/get-company-order?${
        searchString ?? ""
      }pageNumber=${pageNum}&pageSize=10`
    );
    if (response.data.isSuccess) {
      let data = prepareDataWithPagination(response);

      dispatch(setClintOrders(data));
    }
  };
}
function getTranslatorOrdersAction(pageNum, searchString) {
  return async (dispatch) => {
    let response = await getReq(
      `Translators/get-translator-orders?${
        searchString ?? ""
      }pageNumber=${pageNum}&pageSize=10`
    );
    if (response.data.isSuccess) {
      let data = prepareDataWithPagination(response);

      dispatch(setClintOrders(data));
    }
  };
}
function getCompanyBundleInfoAction(pageNum) {
  return async (dispatch) => {
    let response = await getReq(`Companies/get-company-bundle-info`);
    if (response.data.isSuccess) {
      dispatch(setCompanyBundleInfo(response.data.data));
    }
  };
}

async function editClintInfoAction(params) {
  let response = await postReq(`Users/update-user-profile`, params);
  if (response.data.isSuccess) {
    let userData = JSON.parse(localStorage.getItem("userRole"));
    userData.name = params.fullName;
    userData.phoneNumber = params.phoneNumber;
    localStorage.setItem("userRole", JSON.stringify(userData));
  }
  return response;
}
function getCompanyUsersAction(pageNum, searchString) {
  return async (dispatch) => {
    let response = await getReq(
      `Companies/company-admin-get-users?${
        searchString ?? ""
      }pageNumber=${pageNum}&pageSize=10`
    );
    if (response.data.isSuccess) {
      let data = prepareDataWithPagination(response);

      dispatch(setCompanyUsers(data));
    }
  };
}
async function addNewCompanyUser(params) {
  let response = await postReq(`Companies/company-admin-add-user`, params);
  return response;
}
const prepareDataWithPagination = (response) => {
  return {
    pagination: JSON.parse(response.headers["x-pagination"]),
    data: response.data.data,
  };
};
