/** @format */

import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { prepareDate } from "../../../Constants/commonFunc";
import { languages } from "../../../Constants/Languages";
import Status from "../Status";
import "./index.scss";
export default function ResponsiveTable(props) {
  let { lang, dir } = useSelector((state) => state.lang);

  const prepareTranslateLang = (fromLang, toLang) => {
    return (
      <>
        {fromLang} -> {toLang}
      </>
    );
  };
  const renderConstValue = (title, value, status) => {
    if (status) {
      return (
        <div className="resposiveRow-container__row__data-row__status">
          {" "}
          <Status data={value} removeArrow />
        </div>
      );
    } else {
      return (
        <div className="resposiveRow-container__row__data-row__titleAndValue">
          <span className="resposiveRow-container__row__data-row__titleAndValue__title">
            {title}
          </span>
          <span className="resposiveRow-container__row__data-row__titleAndValue__value">
            {value}
          </span>
        </div>
      );
    }
  };
  const renderResponsiveRow = (order) => {
    return (
      <div
        onClick={() =>
          props.saveToSessionStorege
            ? localStorage.setItem(
                props.saveToSessionStorege,
                JSON.stringify(order)
              )
            : null
        }
        key={order.key}
        className="resposiveRow-container__row"
      >
        <div className="resposiveRow-container__row__data-row">
          {renderConstValue("#" + order.id, `${prepareDate(order.createdAt)} `)}
          {renderConstValue(languages[lang].serviceType, order.addOn)}
        </div>
        <div className="resposiveRow-container__row__data-row">
          {renderConstValue(
            languages[lang].translate,
            prepareTranslateLang(order.fromLang, order.toLang)
          )}
          {renderConstValue(languages[lang].translationType, order.orderType)}
        </div>
        <div className="resposiveRow-container__row__data-row">
          {renderConstValue(
            languages[lang].deleviryData,
            `${prepareDate(order.fininshedAt)} `
          )}
          {renderConstValue("", order.status, true)}
        </div>
      </div>
    );
  };
  return (
    <div className="resposiveRow-container" dir={dir} lang={lang}>
      {" "}
      {props.orders.length ? (
        props.orders?.map((order) =>
          props.disableRouting ? (
            renderResponsiveRow(order)
          ) : (
            <Link to={`/${props.url}/${order.id}`} key={order.key}>
              {renderResponsiveRow(order)}
            </Link>
          )
        )
      ) : (
        <h3>{languages[lang].noRequests}</h3>
      )}
    </div>
  );
}
