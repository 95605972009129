/** @format */
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Modal from "@mui/material/Modal";
import Checkbox from "@mui/material/Checkbox";
import Logo from "../../../Assets/Images/Group 39808.png";
import InputField from "../../InputField";
import EmailIcon from "../../../Assets/Images/Icon ionic-ios-mail.svg";
import PhoenIcon from "../../../Assets/Images/Icon ionic-md-phone-portrait.svg";
import UserIcon from "../../../Assets/Images/Icon awesome-user-alt.svg";
import EyeIcon from "../../../Assets/Images/Icon ionic-ios-eye.svg";
import closeIcon from "../../../Assets/Images/Icon ionic-ios-close-circle-outline.svg";
import "./index.scss";
import { registerUser } from "../../../Services/commonServices";
import { languages } from "../../../Constants/Languages";
import Loader from "../../Shared/Loader";
import SuccessAndFailModal from "../SuccessAndFailModal";
import { Navigate } from "react-router-dom";

const fieldsDataInit = {
  fullName: null,
  phoneNumber: null,
  email: null,
  password: null,
  confirmPassword: null,
};
const errorsInit = {
  fullName: null,
  phoneNumber: null,
  password: null,
  email: null,
  confirmPassword: null,
};
export default function SignupModal(props) {
  const [redirect, setRedireact] = useState(false);
  const [fieldsData, setData] = useState(fieldsDataInit);
  const [fieldsConst, setFieldsConst] = useState([]);
  const [navigatelink, setNavigatelink] = useState(null);
  const [errors, setErrors] = useState(errorsInit);
  const [isOpen, setOpen] = useState({ successModal: false, failModal: false });
  const [modalMsg, setModalMsg] = useState("");
  const [showPass, togglePass] = useState({
    password: false,
    confirmPassword: false,
  });
  let { lang, dir } = useSelector((state) => state.lang);
  const togglePassViability = (key) => {
    togglePass({ ...showPass, [key]: !showPass[key] });
  };
  useEffect(() => {
    setData(fieldsDataInit);
    setErrors(errorsInit);
  }, [props.open]);
  useEffect(() => {
    let {
      name,
      phoneNum,
      pleaseWriteName,
      pleaseWritePhone,
      pleaseWriteEmail,
      email,
      password,
      confirmPass,
      pleaseWritePass,
    } = languages[lang];
    const fields = [
      {
        title: name,
        placeholder: pleaseWriteName,
        key: "fullName",
        icon: UserIcon,
      },
      {
        title: phoneNum + " *",
        placeholder: pleaseWritePhone,
        key: "phoneNumber",
        icon: PhoenIcon,
        type: "phoneNumber",
      },

      {
        title: email + " *",
        placeholder: pleaseWriteEmail,
        key: "email",
        icon: EmailIcon,
        type: "email",
      },
      {
        title: password,
        placeholder: pleaseWritePass,
        key: "password",
        icon: EyeIcon,
        type: "password",
      },
      {
        title: confirmPass,
        placeholder: pleaseWritePass,
        key: "confirmPassword",
        icon: EyeIcon,
        type: "password",
      },
    ];
    setFieldsConst(fields);
  }, [lang]);
  const getChanges = (key, value) => {
    setErrors({ ...errors, [key]: null });
    setData({ ...fieldsData, [key]: value });
  };
  const toggleModal = (stateName) => {
    setOpen({ ...isOpen, [stateName]: !isOpen[stateName] });
    if (stateName === "successModal" && isOpen.successModal) {
      setRedireact(true);
    }
  };
  const validatData = (key, value) => {
    let errorsClone = { ...errors };
    errorsClone = { ...errorsClone, [key]: null };
    if (key === "email") {
      let regex = new RegExp("[a-z0-9]+@[a-z]+.[a-z]{2,3}");
      if (!regex.test(value)) {
        errorsClone = { ...errorsClone, email: languages[lang].validEmail };
      }
    } else if (key === "confirmPassword") {
      if (fieldsData.confirmPassword !== fieldsData.password) {
        errorsClone = {
          ...errorsClone,
          confirmPassword: languages[lang].passwordConfirmationError,
        };
      }
    }
    setErrors(errorsClone);
  };
  const signup = async () => {
    let errorsClone = {};
    Object.keys(errors).forEach((field) => {
      if (!fieldsData[field]?.length) {
        errorsClone = {
          ...errorsClone,
          [field]: languages[lang].requiredField,
        };
      }
    });
    setErrors(errorsClone);
    if (Object.keys(errorsClone).length === 0) {
      props.toggleLoading(true);
      let response = await registerUser(fieldsData);

      if (!response.data.isSuccess) {
        if (response.data.errors) {
          let errorObj = {};
          Object.keys(response.data.errors).forEach((errorKey) => {
            errorObj = {
              ...errorObj,
              [errorKey]: response.data.errors[errorKey].join(", "),
            };
          });
          setErrors(errorObj);
        } else if (response.data.message.ar.length) {
          setModalMsg(response.data.message[lang]);
          toggleModal("failModal");
        }
      } else {
        let data = { ...response.data.data, email: fieldsData.email };
        setModalMsg(response.data.message[lang]);
        toggleModal("successModal");
        setNavigatelink(
          window.location.href.includes("/translate")
            ? "/customer/requests/add"
            : "/customer/requests"
        );
        localStorage.setItem("userRole", JSON.stringify(data));
      }
      props.toggleLoading(false);
    }
  };
  return (
    <Modal open={props.open} onClose={() => props.toggleModal(props.modalName)}>
      <div className="signup-cont">
        <img
          src={closeIcon}
          className="closeIcon"
          onClick={() => props.toggleModal(props.modalName)}
        />

        <img className="logo" src={Logo} />
        <h5 className="signup-cont__title">{languages[lang].completeApp}</h5>
        <div style={{ textAlign: "start" }}>
          {fieldsConst.map((field) => (
            <InputField
              key={field.key}
              title={field.title}
              placeholder={field.placeholder}
              value={fieldsData[field.key]}
              getChanges={getChanges}
              stateName={field.key}
              errors={errors}
              icon={field.icon}
              type={field.type}
              showPassword={showPass[field.key]}
              togglePassViability={togglePassViability}
              validatData={validatData}
            />
          ))}

          <button onClick={signup}>{languages[lang].registerNewAcc}</button>
          <div className="signup-cont__signin-cont" dir="rtl" lang="ar">
            <span
              className="signup-cont__signin-cont__signin"
              onClick={() => props.toggleModal("login")}
            >
              {languages[lang].login}
            </span>{" "}
            <span>{languages[lang].haveAcc}</span>
          </div>
        </div>
        <Loader loading={props.loading} />
        <SuccessAndFailModal
          toggleModal={toggleModal}
          modalName={isOpen.successModal ? "successModal" : "failModal"}
          open={isOpen.successModal || isOpen.failModal}
          msg={modalMsg}
          buttonTitle={languages[lang].ok}
        />
        {navigatelink ? <Navigate to={navigatelink} /> : null}
      </div>
    </Modal>
  );
}
