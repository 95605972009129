/** @format */

import React, { useEffect } from "react";

import downArrow from "../../../Assets/Images/Icon ionic-ios-arrow-up.svg";
import EngFlag from "../../../Assets/Images/Image 14@2x.png";
import ArbFlag from "../../../Assets/Images/Flag_of_Kuwait.svg.webp";
import NotificationImg from "../../../Assets/Images/Group 2762.svg";
import AdminLogo from "../../../Assets/Images/Icon awesome-user-cog.svg";
import Logo from "../../../Assets/Images/Group 39808.png";
import cartImg from "../../../Assets/Images/Group 39860.svg";
import { changeLang } from "../../../Redux/lang";
import { useSelector, useDispatch } from "react-redux";
import { languages } from "../../../Constants/Languages";
import "./index.scss";
import NotificationModal from "../../Modals/NotificationModal";
import { useState } from "react";
import LogoutModal from "../../Modals/LogoutModal";
import { Link } from "react-router-dom";

export default function CustomNavbar(props) {
  const [showNotification, toggleNotification] = useState(false);
  const [showLogout, toggleLogout] = useState(false);
  const { lang, dir, isOpen } = useSelector((state) => state.lang);
  const { bundleInfo } = useSelector((state) => state.company);
  const dispatch = useDispatch();
  const setNotificationAsOpen = () => {
    toggleNotification(!showNotification);
  };
  useEffect(() => {
    if (showNotification) {
      let unSeenNotificationIds = props.notificationObj.notification
        ?.filter((item) => !item.isSeen)
        .map((notification) => notification.id);

      props.connection
        ?.invoke("markNotificationsAsSeen", unSeenNotificationIds)
        .catch(function (err) {
          return console.error(err.toString());
        });
      props.getNotification(props.connection);
    }
  }, [showNotification]);

  const markNotificationsAsSeen = (e) => {
    props.connection
      ?.invoke("markNotificationsAsSeen", [e.id])
      .catch(function (err) {
        return console.error(err.toString());
      });
  };
  return (
    <nav
      className="customNav-cont"
      lang={lang}
      dir={dir === "rtl" ? "ltr" : "rtl"}
    >
      <div className="customNav-cont__options">
        <Link to="/">
          <img src={Logo} className="customNav-cont__options__logo" />
        </Link>
        <div
          lang={lang}
          dir={"ltr"}
          className="customNav-cont__options__adminDrobdown"
          onClick={() => toggleLogout(!showLogout)}
        >
          <img
            src={downArrow}
            className="customNav-cont__options__adminDrobdown__arrow"
          />
          {"  "}

          <img
            src={AdminLogo}
            className="customNav-cont__options__adminDrobdown__logo"
          />
          <LogoutModal
            showModal={showLogout}
            toggleModal={() => toggleLogout(false)}
          />
        </div>
        <span
          lang={lang}
          dir={"ltr"}
          className="customNav-cont__options__lang"
          onClick={() => dispatch(changeLang(lang === "ar" ? "en" : "ar"))}
        >
          {" "}
          {lang === "ar" ? "English" : "العربية"}{" "}
          <img src={lang === "ar" ? EngFlag : ArbFlag} />
        </span>
        <div
          className="customNav-cont__options__notification"
          onClick={() => setNotificationAsOpen()}
        >
          <img src={NotificationImg} />
          {props.notificationObj.totalUnseen > 0 ? (
            <span className="customNav-cont__options__notification__num">
              {props.notificationObj.totalUnseen > 9
                ? "+" + 9
                : props.notificationObj.totalUnseen}
            </span>
          ) : null}
          <NotificationModal
            notifications={props.notificationObj.notification}
            showModal={showNotification}
            toggleModal={() => toggleNotification(false)}
            markNotificationsAsSeen={markNotificationsAsSeen}
          />
        </div>
      </div>
      {props.userSidebar === "company" ? (
        <div className="customNav-cont__reminder" lang={lang} dir={dir}>
          <span className="customNav-cont__reminder__title">
            {languages[lang].reminderWords}
          </span>{" "}
          <span className="customNav-cont__reminder__count">
            {bundleInfo?.reminder} {languages[lang].word}
          </span>{" "}
          <Link to="/company/packages">
            <span className="customNav-cont__reminder__buy-bundle">
              <img src={cartImg} />
              {languages[lang].buyBundle}
            </span>
          </Link>
        </div>
      ) : null}

      <span
        lang={lang}
        dir={dir}
        className={`customNav-cont__name__${lang}  ${
          isOpen && `customNav-cont__name__${lang}--apply-margin`
        }`}
      >
        {languages[lang].hello}:{" "}
        {JSON.parse(localStorage.getItem("userRole"))?.name}
      </span>
    </nav>
  );
}
