/** @format */
import "./index.scss";
import Line from "../../../Assets/Images/Group 39778.png";
import FolderImg from "../../../Assets/Images/Group 39779.png";
import Arrow from "../../../Assets/Images/Group 39823.png";
import TimeImg from "../../../Assets/Images/Group.png";
import PaymentImg from "../../../Assets/Images/Group 39780.png";
import DeliveryImg from "../../../Assets/Images/Group 39781.png";
import { languages } from "../../../Constants/Languages";
import { useSelector } from "react-redux";
const steps = [
  { img: FolderImg, msg: "selectFile" },
  { img: TimeImg, msg: "selectDelivery" },
  { img: PaymentImg, msg: "paymentProcess" },
  { img: DeliveryImg, msg: "overTime" },
];
export default function HowToUse() {
  let { lang, dir } = useSelector((state) => state.lang);
  return (
    <section className='use-cont' lang={lang} dir={dir}>
      <h3>{languages[lang].howToUse}</h3>
      <span>{languages[lang].howToUseBody}</span>
      <div className='use-cont__body'>
        <div className='use-cont__body__folder'>
          <img src={FolderImg} />
          <span>{languages[lang].selectFile}</span>
        </div>
        <img src={Arrow} className='use-cont__body__firstArrow' />
        <div className='use-cont__body__time'>
          <img src={TimeImg} />
          <span>{languages[lang].selectDelivery}</span>
        </div>
        <img src={Arrow} className='use-cont__body__secArrow' />
        <div className='use-cont__body__payment'>
          <img src={PaymentImg} />
          <span>{languages[lang].paymentProcess}</span>
        </div>
        <img src={Arrow} className='use-cont__body__thirdArrow' />
        <div className='use-cont__body__delivery'>
          <img src={DeliveryImg} />
          <span>{languages[lang].overTime}</span>
        </div>
        <img src={Line} className='use-cont__body__line' />
      </div>
      <div className='use-cont__body-responsive'>
        {steps.map((step) => (
          <div className='use-cont__body-responsive__cont'>
            <div className='use-cont__body-responsive__cont__img-cont'>
              <img src={step.img} />
            </div>

            <span>{languages[lang][step.msg]}</span>
          </div>
        ))}
      </div>
    </section>
  );
}
