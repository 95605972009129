/** @format */

import axios from "axios";
let baseUrl = "https://api.q8qamous.com/api/";
const prepareHeader = () => {
  let config = {
    headers: {
      "Access-Control-Expose-Headers": "*,X-Pagination",
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      "Accept-Language": localStorage.getItem("lang") ?? "ar",
      Authorization: `Bearer ${
        JSON.parse(localStorage.getItem("userRole"))?.token
      }`,
    },
  };
  return config;
};
export const postReq = async (url, params, type) => {
  let config = prepareHeader();

  // params = JSON.stringify(params);
  try {
    let response = await axios.post(`${baseUrl}${url}`, params, config);
    return response;
  } catch (error) {
    if (error.response.status == 401) {
      localStorage.clear();
      window.location.href = "/";
    }
    return error.response;
  }
};

export const postReqConfig = async (url, params, isConfig) => {
  let config = prepareHeader();

  // params = JSON.stringify(params);
  try {
    let response = await axios.post(`${baseUrl}${url}`, params, {
      ...config,
      ...isConfig,
    });
    return response;
  } catch (error) {
    if (error.response.status == 401) {
      localStorage.clear();
      window.location.href = "/";
    }
    return error.response;
  }
};
export const putReq = async (url, params, type) => {
  let config = prepareHeader();
  // params = JSON.stringify(params);
  try {
    let response = await axios.put(`${baseUrl}${url}`, params, config);
    return response;
  } catch (error) {
    if (error.response.status == 401) {
      localStorage.clear();
      window.location.href = "/";
    }
    return error.response;
  }
};
export const deleteReq = async (url, params, type) => {
  let config = prepareHeader();
  // params = JSON.stringify(params);

  try {
    let response = await axios.delete(`${baseUrl}${url}`, config);
    return response;
  } catch (error) {
    if (error.response.status == 401) {
      localStorage.clear();
      window.location.href = "/";
    }
    return error.response;
  }
};
export const getReq = async (url, params) => {
  let config = prepareHeader();
  // params = JSON.stringify(params);
  try {
    let response = await axios.get(`${baseUrl}${url}`, config);
    return response;
  } catch (error) {
    if (error.response.status == 401) {
      localStorage.clear();
      window.location.href = "/";
    }
    return error.response;
  }
};
