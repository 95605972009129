/** @format */

import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { prepareDashboardSideBar } from "../../../Constants/PrepareData";
import CustomNavbar from "../CustomNavbar";
import Sidebar from "../Sidebar";
import { toggleSideMenu } from "../../../Redux/lang";
import "./index.scss";
import Loader from "../Loader";
import SuccessAndFailModal from "../../Modals/SuccessAndFailModal";
import { languages } from "../../../Constants/Languages";
import { bindActionCreators } from "@reduxjs/toolkit";
import { clintActions } from "../../../actions/clintActions";
import { adminActions } from "../../../actions/adminActions";
import { HubConnectionBuilder } from "@microsoft/signalr";
export default function NavbarAndSidebarCont(props) {
  const [sidebar, setSidebar] = useState([]);
  const [notifications, setNotification] = useState([]);
  const [selectedOption, selectOption] = useState();
  const [connection, setConnection] = useState();
  let { lang, dir, isOpen } = useSelector((state) => state.lang);
  let { adminUserPermissions } = useSelector((state) => state.admin);

  let dispatch = useDispatch();
  let { getCompanyBundleInfoAction } = bindActionCreators(
    clintActions,
    dispatch
  );
  let { getAdminUserPermissionsAction } = bindActionCreators(
    adminActions,
    dispatch
  );

  useEffect(() => {
    let userRole = JSON.parse(localStorage.getItem("userRole"));
    if (userRole?.roles[0] === "CompanyAdmin") {
      getCompanyBundleInfoAction();
    }
    if (userRole?.roles[0] === "Admin") {
      getAdminUserPermissionsAction();
    }
    if (!props.notificationPage) {
      var newConnection = new HubConnectionBuilder()
        .withUrl("https://api.q8qamous.com/hubs/notification", {
          accessTokenFactory: () =>
            JSON.parse(localStorage.getItem("userRole"))?.token,
        })
        .withAutomaticReconnect()
        .build();
      setConnection(newConnection);
      newConnection.on("addNotification", function (notification) {
        let notificationsclone = { ...notifications };
        notificationsclone.notification = [
          ...notificationsclone.notification,
          notification,
        ];
        setNotification(notificationsclone);
      });

      newConnection?.start().then((result) => {
        newConnection.invoke("getNotificationsPage", 1, 5).then((value) => {
          setNotification(value);
        });
      });
    }
  }, []);
  useEffect(() => {
    if (props.notificationObj) {
      setNotification(props.notificationObj);
    }
  }, [props.notificationObj]);
  const getNotification = (currentConnection) => {
    console.log(connection);
    if (props.notificationPage) {
      props.getNotificationPage(
        props.notificationObj?.pagination
          ? JSON.parse(props.notificationObj?.pagination).CurrentPage
          : 1
      );
    }
    currentConnection?.invoke("getNotificationsPage", 1, 5).then((value) => {
      setNotification(value);
    });
  };
  useEffect(() => {
    let userData = JSON.parse(localStorage.getItem("userRole"));
    if (userData.roles[0] === "Admin" ? adminUserPermissions.length : true) {
      let type = "";

      if (userData.roles[0] === "User") {
        type = "customer";
      } else if (userData.roles[0] === "Translator") {
        type = "translator";
      } else if (userData.roles[0] === "CompanyAdmin") {
        type = "company";
      } else if (userData.roles[0] === "CompanyUser") {
        type = "CompanyUser";
      }

      let sidebarData = prepareDashboardSideBar(
        lang,
        "requests",
        type,
        adminUserPermissions
      );
      setSidebar(sidebarData);
    }
  }, [
    lang,
    adminUserPermissions.length,
    JSON.parse(localStorage.getItem("userRole")).roles[0],
  ]);
  useEffect(() => {
    if (props.connection) {
      setConnection(props.connection);
    }
  }, [props.connection]);
  return (
    <div className={`navbarAndSidebar `}>
      <CustomNavbar
        userSidebar={props.userSidebar}
        getNotification={getNotification}
        notificationObj={notifications}
        connection={connection}
      />
      <Sidebar
        userSidebar={props.userSidebar}
        sideMenu={sidebar}
        toggleSideMenu={() => dispatch(toggleSideMenu())}
        selectedOption={selectedOption}
        getSelectedOption={(option) => selectOption(option)}
      />
      <div
        className={`navbarAndSidebar__body ${
          isOpen && "navbarAndSidebar__body--apply-margin"
        } ${lang === "en" && "navbarAndSidebar__body--left"} ${
          isOpen && lang === "en" && "navbarAndSidebar__body--apply-margin-left"
        }`}
      >
        {props.children}
      </div>
      <Loader loading={props.loading} />
      <SuccessAndFailModal
        toggleModal={props.toggleModal}
        modalName={props.isOpen?.successModal ? "successModal" : "failModal"}
        open={props.isOpen?.successModal || props.isOpen?.failModal}
        msg={props.modalMsg}
        buttonTitle={languages[lang].ok}
      />
    </div>
  );
}
