/** @format */

import React, { useEffect, useState } from "react";
import Bill from "../../Bill";
import Checkbox from "@mui/material/Checkbox";
import KNETImg from "../../../Assets/Images/Image 26.png";
import RadioBtn from "../../../Assets/Images/Icon ionic-ios-radio-button-off.png";
import selectedRadioBtn from "../../../Assets/Images/Icon ionic-ios-radio-button-on.png";
import VisaImg from "../../../Assets/Images/Image 22.png";
import "./index.scss";
import { createInitiatePayment } from "../../../Services/PaymentServices";
import { languages } from "../../../Constants/Languages";
import { useSelector } from "react-redux";
import TermsConditionsModal from "./TermsConditionsModal";

export default function ThiredPhase(props) {
  const [agreeOnTerms, toggleAgreement] = useState(false);
  const [selectedMethodId, setMethodId] = useState(1);
  const [cards, setCards] = useState([]);
  let { lang, dir } = useSelector((state) => state.lang);

  useEffect(() => {
    const fetchData = async () => {
      let response = await createInitiatePayment({
        InvoiceAmount: props.dataParams.totalPrice,
        CurrencyIso: "KWD",
      }).then((res) => {
        if (res.data.isSuccess) {
          setCards(
            res?.data?.data?.paymentMethods.filter(
              (res) => res.paymentMethodId == 1 || res.paymentMethodId == 2
            )
          );
        } else if (res.status === 401) {
          props.setPhaseNum(2);
          props.openLogin();
        }
      });
      console.log(response);
    };
    fetchData();
  }, []);
  const [isOpen, setOpen] = useState({});

  const toggleModal = (modalName) => {
    setOpen({ [modalName]: !isOpen[modalName] });
  };
  const renderCard = (img, id, title) => {
    return (
      <div
        className={`text-center itemPay border-1 ${
          id === selectedMethodId &&
          "thired-phase-cont__cards-cont__card--selected"
        }`}
      >
        <div
          onClick={() => setMethodId(id)}
          style={{
            flexWrap: "flex-wrap",
            flexDirection: "row",
            margin: "4px",
            padding: "0 10px",
          }}
          className={`thired-phase-cont__cards-cont__card `}
        >
          <img
            src={img}
            className="thired-phase-cont__cards-cont__card__logo"
            alt="paymentLogo"
          />
          <img
            src={selectedMethodId === id ? selectedRadioBtn : RadioBtn}
            className="thired-phase-cont__cards-cont__card__radio"
            alt="radio"
          />
        </div>
        <p className="text-center m-0">{title}</p>
      </div>
    );
  };

  const prepareDate = (data) => {
    const options = { year: "numeric", month: "numeric", day: "numeric" };
    const date = data
      ? new Date(data).toLocaleDateString("en-EN", options)
      : data;
    let time =
      date +
      " | " +
      new Date(data).toLocaleTimeString(lang, {
        timeStyle: "short",
        hour12: true,
      });
    return data ? time : "-";
  };
  return (
    <div className="thired-phase-cont">
      <TermsConditionsModal
        toggleModal={toggleModal}
        modalName="termsConditions"
        open={isOpen.termsConditions}
      />
      <Bill
        selectedLanguages={props.selectedLanguages}
        wordCount={props.wordCount}
        wordPrice={props.wordPrice}
        fieldsData={props.fieldsData}
        addressData={props.addressData}
        prepareTotalPrice={() => {
          return props.dataParams.totalPrice;
        }}
        userType={props.userType}
        showAddressField={props.showAddressField}
        setPhaseNum={props.setPhaseNum}
      />
      <div style={{ width: "-webkit-fill-available" }}>
        <span style={{ textAlign: "start", display: "block" }}>
          {languages[lang].totalPrice}{" "}
        </span>
        <h2 style={{ textAlign: "start", display: "block", marginBottom: 0 }}>
          {props.dataParams.totalPrice} {languages[lang].DK}
        </h2>

        {props?.dataParams?.estimateDue ? (
          <span
            style={{ textAlign: "start", display: "block", fontWeight: 600 }}
          >
            {languages[lang].EstimateTimeDelivery}
            {" : "}
            {prepareDate(props?.dataParams?.estimateDue)}
          </span>
        ) : null}
        <h4 style={{ textAlign: "start" }}>
          {" "}
          {languages[lang].choosePaymentMethod}{" "}
        </h4>
        <div
          className="thired-phase-cont__cards-cont"
          style={{ flexWrap: "wrap" }}
        >
          {cards.map((card) =>
            renderCard(
              card.imageUrl,
              card.paymentMethodId,
              lang == "ar" ? card.paymentMethodAr : card.paymentMethodEn
            )
          )}
        </div>
        <div
          dir={dir}
          style={{ textAlign: lang == "ar" ? "right" : "left" }}
          className="thired-phase-cont__agreement-checkbox"
        >
          <Checkbox
            label="تذكرني"
            checked={agreeOnTerms}
            onClick={() => toggleAgreement(!agreeOnTerms)}
            sx={{
              color: "#1E8AB2",
              "&.Mui-checked": {
                color: "#1E8AB2",
              },
            }}
          />{" "}
          <span dir="rtl" lang="ar">
            {languages[lang].accept}{" "}
            <a
              style={{ cursor: "pointer", textTransform: "capitalize" }}
              onClick={() => toggleModal("termsConditions")}
              href={() => false}
            >
              {languages[lang].TermsConditions}{" "}
            </a>
          </span>
        </div>
        <button
          disabled={!agreeOnTerms}
          onClick={() =>
            props.sendReq({
              ...props.dataParams,
              paymentMethodId: selectedMethodId,
              DisplayCurrencyIso: "KWD",
            })
          }
        >
          {" "}
          {languages[lang].next}
        </button>
      </div>
    </div>
  );
}
