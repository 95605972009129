/** @format */
import { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import Checkbox from "@mui/material/Checkbox";
import Logo from "../../../Assets/Images/Group 39808.png";
import InputField from "../../InputField";
import EmailIcon from "../../../Assets/Images/Group 39800.svg";
import EyeIcon from "../../../Assets/Images/Icon ionic-ios-eye.svg";
import closeIcon from "../../../Assets/Images/Icon ionic-ios-close-circle-outline.svg";
import { userLogin } from "../../../Services/commonServices";
import "./index.scss";
import { useSelector } from "react-redux";
import { languages } from "../../../Constants/Languages";
import Loader from "../../Shared/Loader";
import SuccessAndFailModal from "../SuccessAndFailModal";
import { Navigate } from "react-router-dom";

const fieldsDataInit = {
  password: null,
  email: null,
  rememberMe: true,
};
const errorsInit = {
  password: null,
  email: null,
};
export default function LoginModal(props) {
  const [fieldsData, setData] = useState(fieldsDataInit);
  const [modalMsg, setModalMsg] = useState("");
  const [isOpen, setOpen] = useState({ successModal: false, failModal: false });
  const [disableSubmitt, toggleDisable] = useState(false);
  const [errors, setErrors] = useState(errorsInit);
  const [showPass, togglePass] = useState(false);
  const [navigatelink, setNavigatelink] = useState(null);

  let { lang, dir } = useSelector((state) => state.lang);
  const getChanges = (key, value) => {
    setErrors({ ...errors, [key]: null });
    setData({ ...fieldsData, [key]: value });
  };

  useEffect(() => {
    setData(fieldsDataInit);
    setErrors(errorsInit);
  }, [props.open]);
  const togglePassViability = () => {
    togglePass(!showPass);
  };
  const toggleModal = (stateName) => {
    setOpen({ ...isOpen, [stateName]: !isOpen[stateName] });
    if (stateName === "successModal" && isOpen.successModal) {
      props.toggleModal(props.modalName);
    }
  };
  const validatData = (key, value) => {
    let errorsClone = { ...errors };
    let flag = false;
    errorsClone = { ...errorsClone, [key]: null };
    if (key === "email") {
      let regex = new RegExp("[a-z0-9]+@[a-z]+.[a-z]{2,3}");
      if (!regex.test(value)) {
        errorsClone = { ...errorsClone, email: languages[lang].validEmail };
        flag = true;
      }
    }
    toggleDisable(flag);
    setErrors(errorsClone);
  };
  const login = async () => {
    let errorsClone = {};

    Object.keys(fieldsData).forEach((field) => {
      if (!fieldsData[field]?.length && field !== "rememberMe") {
        errorsClone = {
          ...errorsClone,
          [field]: languages[lang].requiredField,
        };
      }
    });
    toggleDisable(Object.keys(errorsClone).length > 0);
    setErrors(errorsClone);
    if (Object.keys(errorsClone).length === 0) {
      props.toggleLoading(true);
      let response = await userLogin(fieldsData);

      if (!response.data.isSuccess) {
        if (response.data.errors) {
          let errorObj = {};
          Object.keys(response.data.errors).forEach((errorKey) => {
            errorObj = {
              ...errorObj,
              [errorKey]: response.data.errors[errorKey].join(", "),
            };
          });
          setErrors(errorObj);
        } else if (response.data.message.ar.length) {
          setModalMsg(response.data.message[lang]);
          toggleModal("failModal");
        }
      } else {
        let data = { ...response.data.data, email: fieldsData.email };
        setModalMsg(response.data.message[lang]);
        toggleModal("successModal");
        if (data.roles[0] === "Admin") {
          setNavigatelink("/admin-dashboard");
          localStorage.setItem("selectedOption", "");
        } else if (data.roles[0] === "User") {
          setNavigatelink(
            window.location.href.includes("/translate")
              ? "/customer/requests/add"
              : "/customer/requests"
          );
        } else if (data.roles[0] === "Translator") {
          setNavigatelink("/translator/requests");
        } else if (
          data.roles[0] === "CompanyAdmin" ||
          data.roles[0] === "CompanyUser"
        ) {
          setNavigatelink("/company/requests");
        }

        localStorage.setItem("userRole", JSON.stringify(data));
      }
      props.toggleLoading(false);
    }
  };
  return (
    <Modal open={props.open} onClose={() => props.toggleModal(props.modalName)}>
      <div className="login-cont">
        <img
          src={closeIcon}
          className="closeIcon"
          onClick={() => props.toggleModal(props.modalName)}
        />
        <img className="logo" src={Logo} />
        <h5 className="login-cont__title">{languages[lang].completeApp}</h5>
        <div style={{ textAlign: "start" }}>
          <InputField
            title={languages[lang].phoneOrEmail}
            placeholder={languages[lang].writeEmailOrPhone}
            value={fieldsData.email}
            getChanges={getChanges}
            stateName={"email"}
            errors={errors}
            icon={EmailIcon}
            validatData={validatData}
            type="email"
          />
          <InputField
            title={languages[lang].password}
            placeholder={languages[lang].pleaseWritePass}
            value={fieldsData.password}
            getChanges={getChanges}
            stateName={"password"}
            errors={errors}
            icon={EyeIcon}
            type="password"
            showPassword={showPass}
            togglePassViability={togglePassViability}
          />
          <div className="login-cont__checkbox-cont">
            <div
              dir="rtl"
              lang="ar"
              onClick={() => {
                getChanges("rememberMe", !fieldsData.rememberMe);
              }}
            >
              <Checkbox
                label="تذكرني"
                checked={fieldsData.rememberMe}
                sx={{
                  color: "#1E8AB2",
                  "&.Mui-checked": {
                    color: "#1E8AB2",
                  },
                }}
              />{" "}
              <span>{languages[lang].rememberMe}</span>
            </div>
            <span
              className="login-cont__checkbox-cont__forget-pass"
              onClick={() => props.toggleModal("forgetPass")}
            >
              {languages[lang].forgetPass}
            </span>
          </div>
          <button onClick={login} disabled={disableSubmitt}>
            {languages[lang].login}
          </button>
          <div className="login-cont__signin-cont" dir={dir} lang={lang}>
            <span
              className="login-cont__signin-cont__signin"
              onClick={() => props.toggleModal("signup")}
            >
              {languages[lang].registerNewAcc}
            </span>{" "}
            <span>{languages[lang].donotHaveAcc}</span>
          </div>
        </div>
        <Loader loading={props.loading} />
        <SuccessAndFailModal
          toggleModal={toggleModal}
          modalName={isOpen.successModal ? "successModal" : "failModal"}
          open={isOpen.successModal || isOpen.failModal}
          msg={modalMsg}
          buttonTitle={languages[lang].ok}
        />
        {navigatelink ? <Navigate to={navigatelink} /> : null}
      </div>
    </Modal>
  );
}
