/** @format */
import { useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import Navbar from "../../Components/Navbar";
import Footer from "../../Components/Footer";
import InputField from "../../Components/InputField";
import { languages } from "../../Constants/Languages";
import EyeIcon from "../../Assets/Images/Icon ionic-ios-eye.svg";
import "./index.scss";
import SuccessAndFailModal from "../../Components/Modals/SuccessAndFailModal";
import Loader from "../../Components/Shared/Loader";
import { resetPassService } from "../../Services/commonServices";
import { Navigate } from "react-router-dom";
const fieldsDataInit = {
  newPassword: null,
  confirmPassword: null,
};
const errorsInit = {
  newPassword: null,
  confirmPassword: null,
};
export default function ResetPassword() {
  const [fieldsData, setData] = useState(fieldsDataInit);
  const [errors, setErrors] = useState(errorsInit);
  const [disableSubmitt, toggleDisable] = useState(false);
  const [emailAndToken, setEmailAndToken] = useState({ email: "", token: "" });
  const [loading, toggleLoading] = useState(false);
  const [modalMsg, setModalMsg] = useState("");
  const [navigate, setNavigate] = useState(false);
  const [isOpen, setOpen] = useState({ successModal: false, failModal: false });
  const [showPass, togglePass] = useState({
    newPassword: false,
    confirmPassword: false,
  });
  const [fieldsConst, setFieldsConst] = useState([]);
  let { lang, dir } = useSelector((state) => state.lang);
  useEffect(() => {
    let url = window.location.href;
    url = url.slice(url.indexOf("email=") + 6);
    let email = url.slice(0, url.indexOf("&token"));
    let token = url.slice(url.indexOf("&token") + 7);
    setEmailAndToken({ email: email, token: token });
  }, []);

  useEffect(() => {
    let { password, confirmPass, pleaseWritePass } = languages[lang];
    const fields = [
      {
        title: password,
        placeholder: pleaseWritePass,
        key: "newPassword",
        icon: EyeIcon,
        type: "password",
      },
      {
        title: confirmPass,
        placeholder: pleaseWritePass,
        key: "confirmPassword",
        icon: EyeIcon,
        type: "password",
      },
    ];
    setFieldsConst(fields);
  }, [lang]);
  const toggleModal = (stateName) => {
    setOpen({ ...isOpen, [stateName]: !isOpen[stateName] });
    if (stateName === "successModal" && isOpen.successModal) {
      // props.toggleModal(props.modalName);
      setNavigate(true);
    }
  };
  const togglePassViability = (key) => {
    togglePass({ ...showPass, [key]: !showPass[key] });
  };
  const getChanges = (key, value) => {
    setErrors({ ...errors, [key]: null });
    setData({ ...fieldsData, [key]: value });
  };
  const validatData = (key, value) => {
    let errorsClone = { ...errors };
    errorsClone = { ...errorsClone, [key]: null };
    if (key === "confirmPassword") {
      if (fieldsData.confirmPassword !== fieldsData.newPassword) {
        errorsClone = {
          ...errorsClone,
          confirmPassword: languages[lang].passwordConfirmationError,
        };
        toggleDisable(true);
      } else {
        toggleDisable(false);
      }
    }
    setErrors(errorsClone);
  };
  const resetPass = async () => {
    let errorsClone = {};

    Object.keys(fieldsData).forEach((field) => {
      if (!fieldsData[field]?.length) {
        errorsClone = {
          ...errorsClone,
          [field]: languages[lang].requiredField,
        };
      }
    });
    toggleDisable(Object.keys(errorsClone).length > 0);
    setErrors(errorsClone);
    if (Object.keys(errorsClone).length === 0) {
      toggleLoading(true);
      let params = { ...emailAndToken, ...fieldsData };
      let response = await resetPassService(params);

      if (!response.data.isSuccess) {
        if (response.data?.message?.ar.length) {
          setModalMsg(response.data.message[lang]);
          toggleModal("failModal");
        } else if (response.data.errors) {
          let errorObj = {};
          Object.keys(response.data.errors).forEach((errorKey) => {
            errorObj = {
              ...errorObj,
              [errorKey]: response.data.errors[errorKey].join(", "),
            };
          });
          setErrors(errorObj);
        }
      } else {
        setModalMsg(languages[lang].passReseted);
        toggleModal("successModal");
      }
      toggleLoading(false);
    }
  };
  return (
    <div>
      <Navbar />
      <div className='resetPassword-Cont'>
        <h5 className='login-cont__title'>{languages[lang].changePassword}</h5>
        <div style={{ textAlign: "start" }}>
          {fieldsConst.map((field) => (
            <InputField
              key={field.key}
              title={field.title}
              placeholder={field.placeholder}
              value={fieldsData[field.key]}
              getChanges={getChanges}
              stateName={field.key}
              errors={errors}
              icon={field.icon}
              type={field.type}
              showPassword={showPass[field.key]}
              validatData={validatData}
              togglePassViability={togglePassViability}
            />
          ))}
          <button disabled={disableSubmitt} onClick={resetPass}>
            {languages[lang].changePassword}
          </button>
        </div>
      </div>
      <Loader loading={loading} />
      <SuccessAndFailModal
        toggleModal={toggleModal}
        modalName={isOpen.successModal ? "successModal" : "failModal"}
        open={isOpen.successModal || isOpen.failModal}
        msg={modalMsg}
        buttonTitle={languages[lang].ok}
      />
      {navigate ? <Navigate to='/' /> : null}

      <Footer />
    </div>
  );
}
