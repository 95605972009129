/** @format */

import React from "react";
import NavbarAndSidebarCont from "../../../../Components/Shared/NavbarAndSidebarCont";
import TranslateForm from "../../../../Components/Shared/TranslateForm";

export default function AddComapnyRequest() {
  return (
    <NavbarAndSidebarCont userSidebar='company'>
      <TranslateForm userType='company' />
    </NavbarAndSidebarCont>
  );
}
